
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Editbox } from 'object/src';
import { EID, URL } from 'svc/Enum';
import { default as FileFrame } from '../view/File';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.file-box { ${cs.bg.trans} ${cs.max.wmd} ${cs.w.full} ${cs.m.t30}
    ${cs.m.left(0)} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(30)}

    .fb-box { ${cs.pos.relative} }
    .t-new { ${cs.top(5)} }

    .file { ${cs.bg.dark} ${cs.font.white} ${cs.m.t30}
      .file-frame { ${cs.min.h('calc(100% - 200px)')}
        & > .title { ${cs.disp.hide} } 
        .list .lbx-body .lbx-li { ${cs.font.white} 
          .l-tit { ${cs.font.white}
            &:hover { ${cs.font.primary} ${cs.font.underline} }
          }
          &:hover { ${cs.bg.darkhover} }
        }
      }
    }
  }

  &.popup-file {${cs.disp.popup(1999999, cs.color.trans, 400, 300)}
    .pop-box {${cs.bg.lightblack} ${cs.border.shadow()}
      .head {${cs.bg.trans} ${cs.h.get(50)}
        .tit { ${cs.disp.inblock} ${cs.font.t0} ${cs.p.l10} ${cs.font.line(50)} ${cs.h.full} }
        .sid { ${cs.disp.inblock} ${cs.font.gray} ${cs.font.lg} ${cs.m.l5} }
      }

      .body { ${cs.h.calc('100% - 100px')} ${cs.font.sm} ${cs.p.a20}
        ${cs.over.yauto} ${cs.scrollbar.t1} 
        .edit-box { ${cs.m.t10} }
        .btn-select { ${cs.m.t20} }
        .input-select { ${cs.disp.none} }

        .pb-noti { ${cs.font.sm} ${cs.font.prewrap} ${cs.font.yellow} ${cs.m.t10} }
      }

      .foot {${cs.h.get(50)} ${cs.font.right} ${cs.p.h20}
        & > .button {${cs.m.l20} }
        .cancel {${cs.align.left} }
      }
    }
  }
  
  @media screen and (max-width : 1024px) { 
    &.file-box { 
    }    
  }

  @media screen and (max-width : 800px) {
    &.file-box { .tool-bar { ${cs.p.r0} } .t-new { ${cs.right(10)} } }
  }
}`;

const STQ = ST.QNA;
// const UA = URL.ADMIN;

const File = (props) => {
  const [popup, setPopup] = useState(false);
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
  }, []);

  const onClick = (eid, item) => {
    if (eid === 'delete') {
      props.openConfirm({
        msg: STQ.ISDELETE, className: 'gray', size: 'sm',
        onClicked: (isOk) => {
          isOk && actions.doDelete(URL.API.ADMIN.FILE, { rowid: item.rowid }).then(() => setRefresh(new Date().getTime()));
        },
      });
    }
  }

  const onNew = () => {
    setPopup(true);
  }

  const onClickPopup = (eid) => {
    if (eid === EID.SAVE) {
      setRefresh(new Date().getTime());
    }
    setPopup(false);
  }

  return <StyledObject className={cx('file-box')}>
    <div className={'fb-box'}>
      <FileFrame onClick={onClick} editable={true} refresh={refresh} />
      <Button className={'sm gd-gray t-new right top'} title={ST.ADD} onClick={() => onNew()} />
    </div>
    {popup && <Popup onClick={onClickPopup} data={popup} />}
  </StyledObject>
};

export default File;

const MAXTEXT = 100;
const MAXSIZE = 1024 * 1024 * 10; // 10M

const Popup = (props) => {
  var upload = null;
  const [file, setFile] = useState(null);
  const [noti, setNoti] = useState(null);
  const [title, setTitle] = useState((props.data && props.data.title) || '');
  const [modified, setModified] = useState(false);

  useEffect(() => {
    if (!props.data) return;
    setTitle(props.data.title);
    return () => { }
  }, [props.data])

  const onChange = (value) => {
    setTitle(value);
    setModified(true);
  }

  const onSave = () => {
    if (!title) return showNoti(ST.FILES.NO_FILE_TITLE);
    if (!file) return showNoti(ST.FILES.NO_FILE);

    const form = new FormData();
    form.append('file', file, file.name);
    form.append('title', title);

    actions.doInsert(URL.API.ADMIN.FILE, form).then(({ code, result }) => {
      props.onClick && props.onClick('save');
      setModified(false);
    });
  }

  const showNoti = (msg) => {
    setNoti(msg); setTimeout(() => setNoti(null), 3000);
  }

  const onClickUpload = () => {
    upload.click();
  }

  const onChangeFile = (e) => {
    const tfile = e.target.files[0];
    const { name, size } = tfile;
    if (!tfile || !name) return;
    if (size > MAXSIZE) {
      upload.value = null;
      return showNoti(ST.FILES.MAX_SIZE(`${(MAXSIZE / 1024 / 1024).toFixed(0)}MB`));
    }

    if (!title) setTitle(name);
    setFile(tfile);
    setModified(true);
  }

  return <StyledObject className={'popup-file'}>
    <div className={'pop-bg'} />

    <div className={'pop-box'}>
      <div className={'head'}>
        <span className={'tit'}>{`${ST.FILES.TITLE}`}</span>
      </div>
      <div className={'body'}>
        <Editbox className={'cont md'} value={title} label={ST.TITLE}
          focus={true} onChange={onChange} maxLength={MAXTEXT} />
        <Button className={'sm gd-gray btn-select full'} title={file ? file.name : ST.FILES.SELECT} onClick={onClickUpload} />
        <input className={'input-select'} ref={ref => upload = ref} type="file" accept={'*'}
          name="file" id="file" placeholder="file" onChange={onChangeFile}></input>
        {noti && <div className={'pb-noti'}>{noti}</div>}
      </div>
      <div className={'foot'}>
        <Button className={'sm green btn-save'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
        <Button className={'sm gd-gray close'} title={ST.CLOSE} onClick={() => props.onClick && props.onClick('close')} />
      </div>
    </div>
  </StyledObject>
}