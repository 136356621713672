import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Cardlist, Button, Util, TabButton, Editbox, Storage, Tablebox, Loading, Svg, Formgroup, Uploadbox } from 'object/src';
import { EID } from 'object/src/Config';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';

const StyledObject = styled.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }

  &.ad-set { ${cs.bg.trans}
    .tab-grp { ${cs.m.t20} ${cs.p.l20} }
    .hp-frame { ${cs.p.h20} ${cs.p.t10} }
  }

  &.sect {
    ${cs.m.v0} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.b10}
    ${cs.max.wmd} ${cs.w.full} ${cs.noselect}
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box { ${cs.p.a10} 
      div.edit-box { .guide { ${cs.font.xs} ${cs.font.lightprimary} ${cs.m.t3} } }
    }
    .p-noti { ${cs.m.v10} ${cs.font.yellow} ${cs.font.preline} ${cs.p.l5} ${cs.font.sm} 
      &.hover:hover { ${cs.font.underline} ${cs.mouse.pointer} } 
    }
    .sec-foot { ${cs.m.t20} ${cs.font.right} ${cs.p.h10}
      & > .button { ${cs.m.l10} } 
      .save, .test { ${cs.w.get(120)} }
    }
  }

  &.mg-tables {
    ${cs.max.wlg}
    .card-list .tline .trow .tcol { ${cs.font.md} ${cs.font.bold} ${cs.font.center}
      .tcol-label { ${cs.font.prewrap} ${cs.font.line(16)} ${cs.align.ycenter} ${cs.pos.relative} 
        ${cs.font.right}
      }
      .tcol-txt {}
    }
  }

  &.mg-webset {
    .sec-box { ${cs.p.h20}

      .mi-box {
        ${cs.m.t20} ${cs.h.get(240)}
        & > div { ${cs.h.full} ${cs.disp.inblock}  ${cs.pos.relative} ${cs.w.get('50%')} ${cs.font.center} }

        .l-box { ${cs.float.left} 
          .uploader { ${cs.size.get(100)} ${cs.m.t5} ${cs.over.inherit} ${cs.top(20)}
            .upf-preview { ${cs.border.trans} }
            .upv-img { ${cs.bg.color("#20202050")} }
            .svg-icon { ${cs.size.get(34)} }
            .upv-clear { ${cs.size.get(20)} ${cs.top(-10)} ${cs.right(-10)} }
          }
        }
        .r-box { .thumb { ${cs.w.get(260)} ${cs.h.get(140)} } }
        
        .p-noti { ${cs.p.a20} ${cs.font.xs} ${cs.align.bottom} ${cs.w.full} }
      }

      div.edit-box { ${cs.m.t5} .guide { ${cs.font.yellow} } }

      .sec-preview { ${cs.w.full} ${cs.h.get(240)} ${cs.pos.relative}
        .prev-tit { ${cs.font.center} }
        .prev-box {
          ${cs.w.get(260)} ${cs.h.full} ${cs.m.t10} ${cs.bg.white} ${cs.border.radius(5)}
          ${cs.font.dark} ${cs.p.h10} ${cs.align.xcenter}
          .thumb { ${cs.w.full} ${cs.h.get(130)} }
          
          p { ${cs.font.line(20)} ${cs.font.left} }
          .p-tit { ${cs.m.t10} }
          .p-desc { ${cs.font.darkgray} }
          .p-url { ${cs.font.gray} ${cs.font.thin} ${cs.font.sm} }
        }
      }
    }
  }

  &.mg-admins {
    ${cs.max.wlg}
    .add-box {
      .add-btn { ${cs.mouse.pointer} ${cs.pos.relative} & > .svg-icon { ${cs.bottom(5)} ${cs.right(5)} } }
      .add-edit { &.edit-box .box .input.readonly { ${cs.bg.frame} ${cs.mouse.pointer} } }
    }

    .pwd-btn { ${cs.align.rbottom} ${cs.z.over} }
    .new-btn { ${cs.top(0)} ${cs.right(10)} }
  }

  &.mg-logins {
    ${cs.max.wlg}
    .tab-grp { ${cs.align.rtop} ${cs.top(30)} ${cs.right(10)} }
    .search-frame { .sc-combo { ${cs.z.menu} } }
    .auth { ${cs.font.gray} ${cs.p.h2} ${cs.font.sm} }
  }

  
  &.mg-querys {
    ${cs.max.wxl}
    .tab-grp { ${cs.align.rtop} ${cs.top(30)} ${cs.right(10)} }
    .search-frame { .sc-combo { ${cs.z.menu} } }
    .table-box {
      .p-query { ${cs.font.ellipsis} }
      .p-error { ${cs.font.ellipsis} }
    }
  }

  &.pop-query {
    ${cs.m.t50} ${cs.pos.fixed} ${cs.left(0)} ${cs.top(0)} ${cs.size.full} ${cs.z.popup}
    ${cs.over.hidden}

    .p-bg { ${cs.size.full} ${cs.disp.block} }

    .p-frame { ${cs.align.center} ${cs.bg.black} ${cs.border.shadow()} ${cs.h.fit}
      ${cs.border.radius(10)} ${cs.box.light} ${cs.over.hidden} ${cs.w.dslg}
      .p-head { ${cs.h.get(40)} ${cs.border.bottom} ${cs.border.dark} ${cs.align.top} ${cs.left(0)}
        ${cs.w.full} ${cs.disp.block} ${cs.bg.black} ${cs.z.front}
        .cancel { ${cs.top(10)} ${cs.right(10)} } 
        & > p { ${cs.font.lg} ${cs.font.white} ${cs.font.thickbold} ${cs.font.line(40)} ${cs.p.l20} }
      }
      .p-body {  ${cs.min.h(300)}
        .pb-box {  ${cs.scrollbar.t3} ${cs.over.hidden} ${cs.over.yauto} ${cs.max.h(600)} ${cs.p.v50} ${cs.p.h20} }
        .p-tit { ${cs.disp.block} ${cs.w.full} ${cs.font.md} ${cs.p.b5} ${cs.p.t5} ${cs.font.underline} ${cs.font.gray} ${cs.font.bold} }
        .p-txt { ${cs.font.sm} ${cs.font.preline} ${cs.p.b10} ${cs.p.h5} ${cs.font.lightgray} }
      }
      .p-foot { ${cs.h.get(40)} ${cs.border.top} ${cs.border.dark} ${cs.align.bottom} ${cs.left(0)} 
        ${cs.w.full} ${cs.disp.block} ${cs.bg.black} ${cs.z.front}
      }
    }
  }

  &.admin-modal { 
    .edit-box { ${cs.m.t10} }
  }

  @media screen and (max-width : 1024px) {
    .card-list .tline .trow {
      ${cs.w.calc('50% - 10px')}
    }
  }

  @media screen and (max-width : 600px) {
    .search-frame .search-box { ${cs.w.full} }

    &.mg-querys, &.mg-logins { 
      .table-box { ${cs.m.t50} } 
      .tab-grp { ${cs.p.l0} ${cs.align.unset} ${cs.align.left} ${cs.left(10)} ${cs.top(40)} 
        .button { ${cs.p.h0} .btn-label { ${cs.font.xs} } } 
      }
    }
    .card-list .tline .trow {
      ${cs.w.full} ${cs.m.b30}
    }

    &.mg-webset {
      ${cs.p.b50}
      .sec-box { ${cs.p.b30}
        .mi-box { ${cs.h.fit}
          .l-box, .r-box { ${cs.disp.block} ${cs.w.full} ${cs.float.none} .p-noti { ${cs.pos.relative} } }
        }

        .sec-preview { ${cs.disp.block} ${cs.pos.relative} }
      }
    }
  }
}`;

const STA = ST.ADMIN.MGMT;
const API = URL.API.ADMIN;

const Management = (props) => {
  const userinfo = Storage.getLocalItem(Storage.key.userinfo);
  const issys = userinfo && userinfo.auth === 'system';

  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('admins');
  const [init, setInit] = useState(false);

  useEffect(() => {
    actions.doSelect(API.MGMT_SYS, null).then(({ result }) => {
      if (result) {
        const { database, user } = result;
        setInit((database.indexOf('nuri') >= 0 && user.indexOf('nuri') >= 0));
      }
    })
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const doReload = (code) => {
    Util.showAlert(props, code);
    setRefresh(new Date());
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
  }

  let TABS = issys ?
    [
      { id: 'admins', title: STA.ADMINS },
      { id: 'webset', title: STA.WEBSET },
      { id: 'search', title: STA.SEARCH },
      { id: 'systables', title: STA.TABLES },
      { id: 'logins', title: STA.LOGINS },
      { id: 'querys', title: STA.QUERYS },
    ] :
    [{ id: 'admins', title: STA.ADMINS }];

  if (init) TABS = [{ id: 'sysinits', title: STA.INITS }, ...TABS];

  return <StyledObject className={cx('ad-set')}>
    <TabButton className={"tab-grp"} size={'md'} onClick={onClick} list={TABS} select={tab} color={'gd-gray'} />
    {tab === 'sysinits' && <div className={cx('hp-frame', tab)}>
      <SysInits doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'webset' && <div className={cx('hp-frame', tab)}>
      <SysWebset doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'search' && <div className={cx('hp-frame', tab)}>
      <SysSearch doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'admins' && <div className={cx('hp-frame', tab)}>
      <SysAdmins doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'systables' && <div className={cx('hp-frame', tab)}>
      <SysTables doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'logins' && <div className={cx('hp-frame', tab)}>
      <LoginLogs doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'querys' && <div className={cx('hp-frame', tab)}>
      <QueryLogs doReload={doReload} refresh={refresh} />
    </div>}
  </StyledObject>
};

export default Management;

const SysAdmins = (props) => {
  const [list, setList] = useState(null);
  const user = Storage.getLocalItem(Storage.key.userinfo);
  const issys = user && user.auth === 'system';

  const head = [
    { key: 'no', title: ST.NUM, flex: '1 1 60px' },
    { key: 'userid', title: ST.ADMIN.MEMBER.USERID, flex: '3 1 80px', mobile: 'hide' },
    { key: 'accountid', title: ST.ADMIN.MEMBER.ACCOUNTID, flex: '5 1 120px', mobile: 'hide' },
    { key: 'auth', title: ST.ADMIN.MEMBER.AUTH, flex: '3 1 80px', mobile: 'hide' },
    { key: 'email', title: ST.ADMIN.MEMBER.EMAIL, flex: '5 1 120px', mobile: 'hide' },
    { key: 'name', title: ST.ADMIN.MEMBER.NAME, flex: '3 1 100px' },
    { key: 'phone', title: ST.ADMIN.MEMBER.PHONE, flex: '4 1 100px', mobile: 'hide', type: 'phone' },
    {
      key: 'ctime', title: ST.ADMIN.MEMBER.SDATE, flex: '4 1 80px', tablet: 'hide', type: 'datetime', format: 'YYYY.MM.DD',
      formatter: (v, item, pos) => {
        const isok = item.accountid === user.accountid;
        return <React.Fragment>
          <span>{moment(v).format('YYYY.MM.DD')}</span>
          {(isok || issys) && <Button className={'pwd-btn gd-gray sm'} title={ST.PASSWORD_EDIT} onClick={(eid, e) => onChangePass(item, e)} />}
        </React.Fragment>
      }
    }
  ];

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const doReload = (value) => {
    actions.doSelect(API.ADMINS, null).then(({ result }) => {
      result && result.map((a, i) => a['no'] = i + 1);
      setList(result)
    })
  }

  const onChangePass = (item, e) => {
    e.stopPropagation();
    global.openModal({
      title: STA.ADMINS, className: 'dark',
      size: 'xs', children: ModalPass, data: item, onOk: (value) => {
        if (value) {
          actions.doUpdate(API.ADMINS_PASS, { ...value, rowid: item.userid }).then(({ code }) => {
            global.openConfirm({ type: 'info', size: 'sm', className: 'dark', msg: STA.PASS_RESET(value.pwd), cancel: false });
            doReload();
          });
        }
      },
    });
  }

  const onNew = (eid, e) => {
    global.openModal({
      title: STA.ADMINS, className: 'dark',
      size: 'sm', children: ModalAdmin, data: null, onOk: (value) => {
        if (value) {
          actions.doInsert(API.ADMINS, value).then(({ code }) => {
            Util.showAlert(props, code);
            doReload();
          });
        }
      },
    });
  }

  const onSelect = (id) => {
    let item = list.find(a => Number(a.userid) === Number(id));
    global.openModal({
      title: STA.ADMINS, className: 'dark',
      size: 'sm', children: ModalAdmin, data: item, onOk: (value) => {
        if (item) {
          actions.doUpdate(API.ADMINS, { ...value, rowid: item.userid }).then(({ code }) => {
            Util.showAlert(props, code);
            doReload();
          });
        }
      },
    });
  }

  const onClickDelete = (rowid, e) => {
    // let item = list.find(a => Number(a.userid) === Number(rowid));
    if (rowid <= 1230000000010) {
      global.openConfirm({ type: 'info', size: 'sm', className: 'dark', msg: STA.NO_DELETE_ADMIN, cancel: false });
    } else {
      global.openConfirm({
        type: 'info', size: 'sm', className: 'dark', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.ADMINS, { rowid }, null).then(({ code, result }) => {
            Util.showAlert(props, code);
            doReload();
          });
        }
      });
    }
  }

  if (!list) return null;

  return <StyledObject className={cx('mg-admins sect')}>
    <p className={'title'}>{STA.ADMINS}</p>
    <div className={'sec-box'}>
      <Cardlist className={'dark shadow'} head={head} list={list} onClickDelete={onClickDelete}
        onSelect={onSelect} rowid="userid" width={320} height={20} />
    </div>
    <Button className={'green top right new-btn'} title={ST.NEW} eid={EID.NEW} onClick={onNew} />
  </StyledObject>
}

var counter = 10;
var timer = null;
var readonly = 10;

const SysInits = (props) => {
  var refs = {};
  const [origin, setOrigin] = useState(null);
  const [data, setData] = useState(null);
  const [istest, setTest] = useState(false);
  const [modified, setModified] = useState(false);
  const [isenable, setEnable] = useState(false);
  const [reboot, setReboot] = useState(-1);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    doReload();
    return () => { }
  }, [props.refresh]);

  const doReload = (value) => {
    actions.doSelect(API.MGMT_SYS, null).then(({ result }) => {
      setOrigin(result);
      setData(result);
    });
  }

  const onChange = (val, label) => {
    const temp = JSON.parse(JSON.stringify(data));
    temp[label] = val;
    setData(temp);
    setModified(true);
  }

  const isValidate = () => {
    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return false;

    const isCheck = (v) => {
      // eslint-disable-next-line no-useless-escape
      const r = /^[0-9a-zA-Z-_\.]*$/;
      return r.test(v); // 형식에 맞는 경우 true 리턴
    };

    if (!refs.database.isValidate(!isCheck(refs.database.getValue()), STA.HOST_ONLY)) return false;
    if (!refs.host.isValidate(!isCheck(refs.host.getValue()), STA.HOST_ONLY)) return false;
    if (!refs.user.isValidate(!isCheck(refs.user.getValue()), STA.HOST_ONLY)) return false;
    // if (!refs.password.isValidate(Util.isPassword(refs.password.getValue()))) return;

    return true;
  }

  const onSave = () => {
    if (!isValidate()) return;

    global.openConfirm({
      type: 'info', size: 'sm', msg: STA.DAB_SAVE, className: 'dark', onClicked: (isOk) => {
        (isOk) && actions.doInsert(API.MGMT_SYS, data).then(({ code, result }) => {
          if (code === CODE.SUCCESS) {
            Util.showAlert(props, code);
            doReboot(result || 10);
          }
        });
      }
    });
  }

  const doReboot = (time) => {
    setReboot(time);
    timer = setInterval(() => {
      setReboot(counter--);
      if (counter <= 0) {
        clearInterval(timer);
        Util.logout();
        window.location.href = URL.ADMIN.ROOT;
      };
    }, 1000);
  }

  const onCancel = () => {
    setData(origin);
    setTest(false);
    setModified(false);
  }

  const onTest = () => {
    actions.doSelect(API.MGMT_CONN_TEST, data).then(({ code, result }) => {
      if (code === CODE.SUCCESS) {
        global.openConfirm({ type: 'success', size: 'sm', className: 'dark', msg: STA.CONN_SUCCESS, cancel: false });
        setTest(true);
        setModified(true);
      } else {
        global.openConfirm({ type: 'err', size: 'sm', className: 'dark', msg: STA.CONN_FAIL, cancel: false });
        setTest(false);
        setModified(false);
      }
    });
  };


  if (!data) return null;
  return <StyledObject className={cx('mg-sysinfo sect')}>
    <p className={'title'} onClick={() => { readonly--; if (readonly <= 0) setEditable(true); }}>{STA.INITS}</p>
    <div className={'sec-box'}>
      {/* <p className={'p-noti'} onClick={() => { readonly--; if (readonly <= 0) setEditable(true); }}>{STA.CLEAR_NOTI}</p> */}
      <Formgroup inline={true} >
        <Editbox ref={(ref) => { refs.database = ref }} className={"database"} value={data.database} validate={true}
          label="database" type="text" onChange={(val) => onChange(val, 'database')} />
        {/* <p className={'p-noti'}>{`* ${STW.KAKAO_KEY_NOTI_SCRIPT}`}</p> */}
        <Editbox ref={(ref) => { refs.host = ref }} className={"host"} value={data.host} validate={true}
          label="host" type="text" onChange={(val) => onChange(val, 'host')} readonly={!editable} />
      </Formgroup>
      <Formgroup inline={true} >
        <Editbox ref={(ref) => { refs.user = ref }} className={"user"} value={data.user} validate={true}
          label="user" type="text" onChange={(val) => onChange(val, 'user')} />
        <div className={'p-li'}>
          <Editbox ref={(ref) => { refs.password = ref }} className={"password"} value={data.password} validate={true}
            label="password" type={isenable ? "text" : "password"} onChange={(val) => onChange(val, 'password')} />
          <Svg className={'right'} icon={'show'} onClick={() => setEnable(!isenable)} />
        </div>
      </Formgroup>
      <p className={'p-noti'}>{`* ${STA.INPUT_ONLY}`}</p>
    </div>

    <div className={'sec-foot'}>
      <Button className={'gd-gray'} title={ST.CANCEL} onClick={onCancel} />
      {!istest && <Button className={'green test'} title={STA.CONN_TEST} onClick={onTest} disable={!modified} />}
      {istest && <Button className={'red save'} title={ST.SAVE} onClick={onSave} />}
    </div>
    {reboot > 0 && <div className={'s-reboot'}><span className={'s-txt'}>{STA.INIT_SUCCESS(reboot)}</span></div>}
  </StyledObject>
}

const SysWebset = (props) => {
  const [html, setHtml] = useState(null);
  const [icon, setIcon] = useState(null);
  const [image, setImage] = useState(null);

  const [modified, setModified] = useState(false);
  const [reboot, setReboot] = useState(-1);

  useEffect(() => {
    doReload();
    return () => { }
  }, [props.refresh]);

  const doReload = (value) => {
    actions.doSelect(API.MGMT_WEBSET, null).then(({ result }) => {
      setHtml(result.html);
      setIcon(result.icon);
      setImage(result.image);

      // 도메인이 접속한 도메인과 다르면 팝업
      if (result.html && result.html.url) {
        const { url } = result.html;
        const { origin } = window.location;
        if (origin !== url) {
          global.openConfirm({
            type: 'info', size: 'sm', msg: STA.WEBSET_DOMAIN_RE(url, origin), className: 'dark',
            onClicked: (isOk) => {
              if (isOk) {
                setHtml({ ...result.html, url: origin });
                setModified(true);
              }
            }
          });
        }
      }
    });
  }

  const onChangeHtml = (value, label) => {
    setHtml({ ...html, [label]: value });
    setModified(true);
  }

  const onChangeIcon = (result, target, item, type) => {
    setIcon(result);
    setModified(true);
  }

  const onChangeImage = (result, target, item, type) => {
    setImage(result);
    setModified(true);
  }

  const onSave = () => {
    global.openConfirm({
      type: 'info', size: 'sm', msg: STA.WEBSET_SAVE, className: 'dark', onClicked: (isOk) => {
        (isOk) && actions.doInsert(API.MGMT_WEBSET, { html, icon, image }).then(({ code, result }) => {
          if (code === CODE.SUCCESS) {
            Util.showAlert(props, code);
            doReboot(result || 10);
          }
        });
      }
    });
  }

  const doReboot = (time) => {
    setReboot(time);
    timer = setInterval(() => {
      setReboot(counter--);
      if (counter <= 0) {
        clearInterval(timer);
        Util.logout();
        window.location.href = URL.ADMIN.ROOT;
      };
    }, 1000);
  }

  const onCancel = () => {
    setModified(false);
  }

  return <StyledObject className={cx('mg-webset sect')}>
    <p className={'title'}>{STA.INITS}</p>
    <div className={'sec-box'}>
      <Editbox className={"ed-url"} value={html ? html.url : ''} validate={true} helper={STA.WEBSET_URL_H}
        label="URL" type="text" onChange={(val) => onChangeHtml(val, 'url')} />
      <Editbox className={"ed-title"} value={html ? html.title : ''} validate={true} helper={STA.WEBSET_TITLE_H}
        label="TITLE" type="text" onChange={(val) => onChangeHtml(val, 'title')} />
      <Editbox className={"ed-description"} value={html ? html.description : ''} validate={true} helper={STA.WEBSET_DESC_H}
        label="DESCRIPTION" type="text" onChange={(val) => onChangeHtml(val, 'description')} />
      <Editbox className={"ed-maintitle"} value={html ? html.maintitle : ''} validate={true} helper={STA.WEBSET_MAIN_H}
        label="MAINTITLE" type="text" onChange={(val) => onChangeHtml(val, 'maintitle')} />

      <div className={'mi-box'}>
        <div className={'l-box'}>
          <Uploadbox className={cx('')} fit={'scale-down'} size={'xs'} inline={false} onChange={onChangeIcon}
            value={icon} title={'icon'} image_check={{ width: 120, height: 120 }} ext={'.ico'} />
          <p className={'p-noti'}>{`${STA.WEBSET_ICON_H}`}</p>
        </div>
        <div className={'r-box'}>
          <div className={'thumb-box'}>
            <Uploadbox className={cx('')} fit={'cover'} size={'xs'} inline={false} onChange={onChangeImage}
              value={image} title={'icon'} image_check={{ width: 600, height: 400 }} ext={'.png, .jpg'} />
            <p className={'p-noti'}>{`${STA.WEBSET_IMAGE_H}`}</p>
          </div>
        </div>
      </div>

      <div className={'sec-preview'}>
        <div className={'prev-tit'}>{STA.PREVIEW}</div>
        <div className={'prev-box'}>
          <img className={'thumb'} src={image} style={{ objectFit: 'cover' }} alt={'compony'} />
          {/* <Thumbbox className={'thumb'} src={image} /> */}
          <p className={'p-tit'}>{html && html.title}</p>
          <p className={'p-desc'}>{html && html.description}</p>
          <p className={'p-url'}>{html && html.url}</p>
        </div>
      </div>
    </div>

    <div className={'sec-foot'}>
      <Button className={'gd-gray'} title={ST.CANCEL} onClick={onCancel} />
      <Button className={'red save'} title={ST.SAVE} onClick={onSave} disable={!modified} />
    </div>
    {reboot > 0 && <div className={'s-reboot'}><span className={'s-txt'}>{STA.INIT_SUCCESS(reboot)}</span></div>}
  </StyledObject>
}


const SysSearch = (props) => {
  const [value, setValue] = useState(null);
  const [modified, setModified] = useState(false);
  const [reboot, setReboot] = useState(-1);

  useEffect(() => {
    doReload();
    return () => { }
  }, [props.refresh]);

  const doReload = () => {
    actions.doSelect(API.MGMT_SEARCH, null).then(({ result }) => {
      setValue({ ...result });
    });
  }

  const onChange = (v, label) => {
    setValue({ ...value, [label]: v });
    setModified(true);
  }

  const onSave = () => {
    global.openConfirm({
      type: 'info', size: 'sm', msg: STA.SEARCH_SAVE, className: 'dark', onClicked: (isOk) => {
        (isOk) && actions.doInsert(API.MGMT_SEARCH, { ...value }).then(({ code, result }) => {
          if (code === CODE.SUCCESS) {
            Util.showAlert(props, code);
            doReboot(result || 10);
          }
        });
      }
    });
  }

  const doReboot = (time) => {
    setReboot(time);
    timer = setInterval(() => {
      setReboot(counter--);
      if (counter <= 0) {
        clearInterval(timer);
        Util.logout();
        window.location.href = URL.ADMIN.ROOT;
      };
    }, 1000);
  }

  const onCancel = () => {
    setModified(false);
  }

  return <StyledObject className={cx('mg-webset sect')}>
    <p className={'title'}>{STA.INITS}</p>
    <div className={'sec-box'}>
      <Editbox className={"ed-url"} value={value ? value.naver : ''} validate={true} helper={STA.SEARCH_NAVER}
        label="NAVER" type="text" onChange={(val) => onChange(val, 'naver')} />
    </div>

    <div className={'sec-foot'}>
      <Button className={'gd-gray h-btn'} title={ST.HELPER} onClick={() => window.open(`${URL.ADMIN.GUIDE}/naver`)} />
      <Button className={'gd-gray'} title={ST.CANCEL} onClick={onCancel} />
      <Button className={'red save'} title={ST.SAVE} onClick={onSave} disable={!modified} />
    </div>
    {reboot > 0 && <div className={'s-reboot'}><span className={'s-txt'}>{STA.INIT_SUCCESS(reboot)}</span></div>}
  </StyledObject>
}

const SysTables = (props) => {
  const [list, setList] = useState(null);

  const head = [
    { key: 'name', title: STA.TABEL, flex: '1 1 80px', align: 'left' },
    { key: 'commant', title: STA.COMMENT, flex: '5 1 200px', align: 'left', mobile: 'hide' },
    {
      key: 'rows', title: STA.ROWS, flex: '1 1 80px', type: 'number', tablet: 'hide',
      formatter: (v, item, pos) => {
        let color = cs.color.white;
        if (Number(v) > 100000) color = cs.color.red;
        return <span style={{ color: color }}>{Util.commas(v)}</span>
      }
    },
    {
      key: 'size', title: STA.SIZE, flex: '1 1 100px', type: 'number',
      formatter: (v, item, pos) => {
        let color = cs.color.white;
        if (Number(v) > 100) color = cs.color.red;
        return <span style={{ color: color }}>{Util.commas(v)}</span>
      }
    },
    {
      key: 'utime', title: ST.UTIME, flex: '2 1 100px', mobile: 'hide',
      formatter: (v, item, pos) => {
        if (v) {
          return moment(v).format("YY.MM.DD HH:mm");
        } else {
          return moment(item.ctime).format("YY.MM.DD HH:mm");
        }
      }
    },
    // {
    //   key: 'utime', title: ST.UTIME, flex: '2 1 100px',
    //   formatter: (v, item, pos) => moment(v).format("YY.MM.DD HH:mm")
    // },
    {
      key: 'button', title: STA.CLEAR, flex: '2 1 100px',
      formatter: (v, item, pos) => {
        // 10MB가 넘거나 10000 레코드가 넘을경우에만 정리 버튼 표시
        const is = () => (item.size > 10 || item.rows > 10000);

        let enable;
        switch (item.name) {
          case 'notify': enable = is(); break;
          case 'z_login': enable = is(); break;
          case 'z_query': enable = is(); break;
          default: return '';
        }

        return <Button className={'primary sm'} title={STA.CLEAR} disable={!enable} onClick={() => onSelect(item)} />
      }
    },
  ];

  useEffect(() => {
    doReload();
    return () => { }
  }, [props.refresh]);

  const doReload = (value) => {
    actions.doSelect(API.MGMT_TABLE, null).then(({ result }) => {
      result && result.map((a, i) => a['no'] = i + 1);
      setList(result);
    })
  }


  const onSelect = (item) => {
    var value = 3;
    global.openConfirm({
      size: 'sm', type: 'info', className: 'dark', title: ST.ALARM, msg: STA.CLEARL_MSG,
      ok: ST.YES, cancel: ST.NO,
      children: () => {
        const StyledComp = styled.div`{
          &.clear-pop { .label { ${cs.m.t5} ${cs.m.b20} } 
            .tab-grp { ${cs.pos.relative} ${cs.m.t10} } 
          }
        }`;
        const tabs = [{ id: '3', title: STA.THREE }, { id: '4', title: STA.FOUR }, { id: '5', title: STA.FIVE }];
        const onClickTab = (eid) => {
          value = eid;
        }

        return <StyledComp className={'clear-pop'}>
          <TabButton className={"tab-grp"} size={'sm'} onClick={onClickTab} list={tabs} select={tabs[0].id} color={'gd-gray'} />
          <p className={'label'}>{STA.CLEARL_MSG}</p>
        </StyledComp>
      },
      onClicked: (isOk) => {
        (isOk) && actions.doDelete(API.MGMT_TABLE, { value: value, table: item.name }).then(({ code, result }) => {
          Util.showAlert(props, code);
          doReload();
        });
      }
    });
  }

  return <StyledObject className={cx('mg-tables sect')}>
    <p className={'title'}>{STA.TITLE}</p>
    <div className={'sec-box'}>
      <p className={'p-noti'}>{STA.CLEAR_NOTI}</p>
      <Tablebox className={'dark shadow md'} head={head} list={list} rowid="name" height={30} />
    </div>
  </StyledObject>
}

const LoginLogs = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [running, setRunning] = useState(false);
  const order = 'stime desc';
  const limit = 15;

  const head = [
    { key: 'no', title: ST.NUM, flex: '1 1 40px' },
    { key: 'userid', title: STA.USERID, flex: '2 1 100px' },
    {
      key: 'user', title: STA.ACCOUNTID, flex: '5 1 200px', align: 'left',
      formatter: (v, item, pos) => {
        const data = JSON.parse(v);
        return <div>
          <span>{data.accountid}</span><span className={'auth'}>{`(${data.auth})`}</span>
        </div>
      }
    },
    { key: 'stime', title: STA.LOGINTIME, flex: '2 1 100px', type: 'datetime', format: 'YY.MM.DD HH:mm', mobile: 'hide' },
  ];

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const searchs = [
    { name: STA.USERID, id: 'userid' },
    { name: STA.ACCOUNT, id: 'accountid' },
    { name: STA.AUTH, id: 'auth' },
  ];

  const doReload = (p = page, s = search, o = order, l = limit, stab = 'admin') => {
    setRunning(true);

    let value = {};

    if (s && s.value) {
      value['where'] = `${s.key} like '%${s.value}%'`;

      if (s.key === 'accountid') {
        value['where'] = `user like '%"accountid":%${s.value}%'`;
      }

      if (s.key === 'auth') {
        value['where'] = `user like '%"auth":%${s.value}%'`;
      }
    }

    let where = '';
    if (stab === 'admin')
      where = `(user like '%"auth":"system"%' or user like '%"auth":"super"%')`;
    else
      where = `(user like '%"auth":"user"%')`;

    if (value.where) {
      value['where'] += ' and ' + where;
    } else {
      value['where'] = where;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.MGMT_LOGS, value, true).then(({ result }) => {
      if (!result.page) return;

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setRunning(false);
    })
  };

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  if (!list) return null;

  // const tabs = [{ id: 'admin', title: STA.ADMIN }, { id: 'user', title: STA.USER }];
  return <StyledObject className={cx('mg-logins sect')}>
    <p className={'title'}>{STA.LOGINS}</p>
    <div className={'sec-box'}>
      <Tablebox className={cx('dark')} naviClass={'white'} head={head} list={list} searchkey={search ? search.key : searchs[0].id}
        onClickSearch={onClickSearch} onClickPage={onClickPage} pos={page} max={max} total={total} searchs={searchs} rowid="rowid" />
    </div>
    {running && <Loading className={''} type='ring' />}
  </StyledObject>
}

const QueryLogs = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [tab, setTab] = useState('error');
  const [running, setRunning] = useState(false);
  const [popup, setPopup] = useState(null);
  // const [order, setOrder] = useState('stime desc');
  // const [limit, setLimit] = useState(15);
  const order = 'ctime desc';
  const limit = 15;

  const head = [
    { key: 'no', title: ST.NUM, flex: '1 1 40px' },
    { key: 'type', title: STA.TYPE, flex: '2 1 100px' },
    {
      key: 'query', title: STA.QUERY, flex: '5 1 200px', align: 'left', mobile: 'hide',
      formatter: (v, item, pos) => {
        return <div className={'p-query'}><span>{v}</span></div>
      }
    },
    {
      key: 'error', title: STA.ERROR, flex: '5 1 200px', align: 'left',
      formatter: (v, item, pos) => {
        let text;
        if (!v || v === '""') {
          text = '';
        } else {
          text = v;
          // text = JSON.parse(v);
        }
        return <div className={'p-error'}><span>{text}</span></div>
      }
    },
    { key: 'ctime', title: ST.CTIME, flex: '2 1 100px', type: 'datetime', format: 'YY.MM.DD HH:mm:ss', mobile: 'hide' },
  ];

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const searchs = [
    // { name: STA.TYPE, id: 'type' },
    { name: STA.QUERY, id: 'query' },
    { name: STA.ERROR, id: 'error' },
  ];

  const doReload = (p = page, s = search, o = order, l = limit, stab = tab) => {
    setRunning(true);

    let value = {};

    if (s && s.value) {
      value['where'] = `${s.key} like '%${s.value}%'`;
    }

    let where = '';
    if (stab && stab !== 'all') where = `type='${stab}'`;
    if (stab && stab === 'error') where = `error != '""'`;

    if (value.where && where) {
      value['where'] += ' and ' + where;
    } else if (!value.where && where) {
      value['where'] = where;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.MGMT_LOGS, { ...value, type: 'query' }, true).then(({ result }) => {
      if (!result.page || !result.list) return;

      result.list.map(a => a.type = a.type.toLowerCase());

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setRunning(false);
    })
  };

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
    if (eid === 'all') {
      doReload(1, search, order, limit, '');
    } else {
      doReload(1, search, order, limit, eid);
    }
  }

  const onSelect = (id) => {
    let item = list.find(a => Number(a.rowid) === Number(id));
    setPopup(item);
  };

  const onClickPopup = () => {
    setPopup(null);
  }

  if (!list) return null;

  const tabs = [
    { id: 'all', title: 'ALL' }, { id: 'insert', title: 'INSERT' },
    { id: 'update', title: 'UPDATE' }, { id: 'delete', title: 'DELETE' },
    { id: 'error', title: 'ERROR' }
  ];

  return <StyledObject className={cx('mg-querys sect')}>
    <p className={'title'}>{STA.QUERYS}</p>
    <div className={'sec-box'}>
      <TabButton className={"tab-grp"} size={'md'} onClick={onClick} list={tabs} select={tab} color={'gd-gray'} activeColor={'primary'} />
      <Tablebox className={cx('dark')} naviClass={'white'} head={head}
        list={list} searchkey={search ? search.key : searchs[0].id}
        onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
        pos={page} max={max} total={total} searchs={searchs} rowid="rowid" />
    </div>
    {running && <Loading className={''} type='ring' />}
    {popup && <PopQuery data={popup} onClick={onClickPopup} />}
  </StyledObject>
}

/*******************************************************************
 Popup
*******************************************************************/

const PopQuery = (props) => {
  const { onClick, data } = props;
  const { query, error = '' } = data;

  return <StyledObject className={'pop-query'} >
    <div className={'p-bg'} onClick={(e) => onClick('cancel', e)} />
    <div className={'p-frame'} >
      <div className={'p-head'}>
        <p>{STA.QUERYS}</p>
        <Svg className={'cancel right'} icon={'cancel'} onClick={(e) => onClick('cancel', e)} />
      </div>
      <div className={'p-body'}>
        <div className={'pb-box'}>
          <span className={'p-tit'}>{STA.QUERY}</span>
          <p className={'p-txt'}>{query}</p>
          <span className={'p-tit'}>{STA.ERROR}</span>
          <p className={'p-txt p-log'}>{error !== '""' ? error : ''}</p>
        </div>
      </div>
      <div className={'p-foot'}>
        <Button className={'right'} icon={'exit'} title={ST.CLOSE} onClick={(e) => onClick('cancel', e)} />
      </div>
    </div>
  </StyledObject>
}

const ModalAdmin = (props) => {
  var refs = {};
  const { data } = props;

  props.act.getData = (checkValidate) => {
    // validate 체크하여 통과하지 못하면 false를 리턴(창이 닫히지 않는다.)
    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return false;

    let value = refs.accountid.getValue();
    if (!Util.isEngNum(value)) {
      refs.accountid.showNoti(STA.NO_ENGLISH);
      return false;
    }

    value = refs.pwd.getValue();
    if (!Util.isPassword(value, 8, 20)) {
      refs.pwd.showNoti(STA.PASSWORD);
      return false;
    }

    let datas = {};
    Object.keys(refs).map(key => datas[key] = refs[key].getValue());
    return datas;
  }

  const isup = data && data.userid;
  return (
    <StyledObject className={'admin-modal'}>
      {isup && <Editbox name="userid" className="right dark" type="text"
        value={data.userid} label={ST.ADMIN.MEMBER.USERID} readonly={true} />}
      {isup ? <Editbox name="accountid" className={cx("inbox right dark")} type="text"
        value={data.accountid} label={ST.ADMIN.MEMBER.ACCOUNTID} readonly={true} />
        : <Editbox name="accountid" className={cx("inbox right white")} type="text" ref={(ref) => { refs.accountid = ref }}
          value={data.accountid} label={ST.ADMIN.MEMBER.ACCOUNTID} validate={true} focus={!isup} />}
      {!isup && <Editbox name="pwd" className="inbox right white" type="password" ref={(ref) => { refs.pwd = ref }}
        value={data.pwd} label={ST.ADMIN.MEMBER.PASSWORD} readonly={false} helper={STA.PASSWORD} validate={true} />}
      <Editbox name="email" className={cx("inbox right white")} type="text" ref={(ref) => { refs.email = ref }}
        value={data.email} label={ST.ADMIN.MEMBER.EMAIL} readonly={false} focus={isup} />
      <Editbox name="name" className="inbox right white" type="text" ref={(ref) => { refs.name = ref }}
        value={data.name} label={ST.ADMIN.MEMBER.NAME} readonly={false} />
      <Editbox name="phone" className="inbox right white" type="phone" ref={(ref) => { refs.phone = ref }}
        value={data.phone} label={ST.ADMIN.MEMBER.PHONE} readonly={false} />
      {isup && <Editbox className="inbox right dark" value={moment(data.ctime).format('YYYY/MM/DD HH:mm:ss')}
        name="ctime" type="text" label={ST.CTIME} readonly={true} />}
    </StyledObject>
  );
}


/*******************************************************************
 Popup
*******************************************************************/
const ModalPass = (props) => {
  var refs = {};
  const { data } = props;

  props.act.getData = (checkValidate) => {
    // validate 체크하여 통과하지 못하면 false를 리턴(창이 닫히지 않는다.)
    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return false;

    let value = refs.pwd.getValue();
    if (!Util.isPassword(value, 8, 20)) {
      refs.pwd.showNoti(STA.PASSWORD);
      return false;
    }

    return { pwd: value };
  }

  return (
    <StyledObject className={'admin-modal'}>
      <Editbox name="userid" className="right dark" type="text"
        value={data.userid} label={ST.ADMIN.MEMBER.USERID} readonly={true} focus={true} />
      <Editbox name="accountid" className={cx("inbox right dark")} type="text"
        value={data.accountid} label={ST.ADMIN.MEMBER.ACCOUNTID} readonly={true} />
      <Editbox name="pwd" className="inbox right white" type="password" ref={(ref) => { refs.pwd = ref }}
        value={data.pwd} label={ST.ADMIN.MEMBER.PASSWORD} readonly={false} helper={STA.PASSWORD} validate={true} />
    </StyledObject>
  );
}