import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Imagebox, Loading, cs, Editbox, Uploadbox, Util } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS } from './PageCom';

const StyledObject = styled.div`{ 
  &.prod-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black} ${cs.min.h(100)}
    // ${cs.anim.showin()}

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative}

      .p-tit { 
        ${cs.p.a0} ${cs.font.xl} ${cs.font.center} ${cs.font.bold}
      }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.pointer} };

      &.loaded { ${cs.opac.visible} }
    }

    @media screen and (max-width : 600px) {
      ${cs.max.w('100%')} 
      .p-frame {
        .p-tit { ${cs.p.a5} }
        .p-cont {
        }
      }
    }
  }

  &.prod-viewer {
    .p-frame {
      ${cs.over.hidden} 
      // .p-img { ${cs.over.hidden} }
      .p-img .ib-frame { ${cs.opac.invisible} }
      .p-tit { ${cs.opac.invisible} }

      &.noanim { 
        .p-img .ib-frame { ${cs.opac.visible} }
        .p-tit { ${cs.opac.visible} }
      }
      &.type1 {
        .p-tit { ${cs.opac.invisible} 
          ${cs.anim.showin('1s', '0', '1', 'prb-type1-text')} ${cs.anim.delay('1s')}
        }
        .p-img .ib-frame { ${cs.anim.zoomin('3s', '1.1', '1', 1, 'prb-type1')} }
      }
      &.type2 {
        .p-tit { ${cs.opac.invisible} 
          ${cs.anim.slideup('0.5s', '-100%', '0', 'prb-type2-text')} ${cs.anim.delay('1s')}
        }
        .p-img .ib-frame { 
          ${cs.anim.rotatezoom('2s', '0', '-1080deg', 'prb-type2', 'ease-out')} 
          ${cs.anim.origin('right top')} ${cs.anim.delay('0.1s')} 
        }
      }
      &.type3 {
        .p-tit { ${cs.opac.invisible} 
          ${cs.anim.foldin('1s', '0', '100%', 'prb-type3-text')} ${cs.anim.delay('1s')}
        }
        .p-img .ib-frame { ${cs.anim.rotatezoom('2s', '0', '-1080deg', 'prb-type3', 'ease-out')} }
      }
    }

    &.onepage {
      .p-frame .p-cont {
        ${cs.p.a0}
        &.p-img { ${cs.font.center} }
        .ib-frame { ${cs.w.get('auto')} }
      }

      ${cs.p.top(20)}
      .p-frame { ${cs.h.calc('(100% - 100px)')} ${cs.min.h(400)} }
    }

    @media screen and (max-width : 600px) {
    }
  }

  &.prod-editor { ${cs.p.b40} ${cs.bg.dark}
    .p-frame {
      .p-cont { ${cs.disp.block} ${cs.h.full} ${cs.pos.relative} ${cs.object.fill} ${cs.p.a0}
        .up-box { ${cs.min.h(300)} ${cs.p.v2} }
        .p-upframe { ${cs.pos.relative} }
      }
    }
  }

};`;

const ProdBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default ProdBox;

const BoxViewer = (props) => {
  const { title, className, bg, path, url, opts, link } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box, label } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const { pageopts } = global;

  const [loaded, setLoaded] = useState(false);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  let height = '100%';
  // 원페이지 스타일일 경우
  if (pageopts && pageopts.style && pageopts.style === 'onepage') {
    height = `calc(100% - ${label.margin} - ${label.size} - ${box.padding} - ${box.padding})`
  }

  return (
    <StyledObject className={cx("prod-box prod-viewer", className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded }, anim || 'noanim')} style={{ maxWidth, margin: `${box.margin} 0`, padding: `0 ${OPTS.maxPadding(maxWidth)}`, ...frame.border }} >
        {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options, paddingTop: 0, paddingBottom: label.margin }}>{title}</p>}
        <Imagebox className={cx("p-img")} url={urldata} onLoad={onLoad} onError={onError} size={"full"} eid={"url"}
          onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null}
          imagestyle={{ ...box.options }} style={{ padding: box.padding, height: height }} />
      </div>

      {props.children}
      {!loaded && <Loading />}
    </StyledObject>
  )
}

const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onChange = (value, e, label, file) => {
    if (!props.item) return;
    const req = props.item.request;
    if (label === 'url') req[label] = file;
    else req[label] = Util.toText(value);
  }

  const { title, type, className, bg, path, url, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  return (
    <StyledObject className={cx("prod-box prod-editor", className, box.size)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth }}>
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <label className={'p-noti'}>{ST.PAGE.IMAGE_H + ` ${ST.IMAGE_SIZE('1600 * MAX(5000)')}`}</label>
          <div className={'p-upframe'}>
            <Uploadbox className="p-img up-box" type={type} fit={'contain'} value={urldata} size={"wide"}
              onLoad={onLoad} onError={onError} eid={"url"} maxImage={{ width: 2400, height: 5000 }}
              onChange={(v, e, f) => onChange(v, e, 'url', f)} onClickLinked={() => props.onClickLink('show', props.item)} />
          </div>
        </div>
      </div>
      {props.children}
    </StyledObject>
  )
}