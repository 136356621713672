import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Imagebox, Loading, cs, Editbox, TabButton } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS, ThumbUploader } from './PageCom';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import { useSwipeable } from "react-swipeable";

const API = URL.API.ADMIN;
const DEFULT_STYLE = { type: '5' };

const StyledObject = styled.div`{ 
  &.gallery-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()} 

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.disp.block} ${cs.object.contain}
      ${cs.h.fit} 
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} ${cs.h.fit}
          .p-tit { 
            ${cs.p.a0} ${cs.font.t1} ${cs.m.b10} ${cs.font.center} ${cs.font.bold}
          }

          .p-txt { 
            ${cs.font.lg} ${cs.m.t20} ${cs.font.center} ${cs.scrollbar.t0}
            ${cs.over.hidden} ${cs.over.yauto} ${cs.font.prewrap} ${cs.max.h(100)}
          }

          .p-grid { ${cs.min.h(250)} ${cs.h.fit} ${cs.p.a0} ${cs.w.full} ${cs.pos.relative} 
            ${cs.font.left} ${cs.m.center(0)}
            .img-box { ${cs.disp.inblock} ${cs.pos.relative} }
          }
        }

        &.boxbg { ${cs.min.h('100vh')}
        }

        .p-img { ${cs.align.left} ${cs.top(0)} ${cs.z.back} ${cs.min.h(480)} }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }
      }
      
      .p-noti { ${cs.font.orange} &.sguide { ${cs.align.ltop} } }
      .pointer { ${cs.mouse.pointer} };
      
      &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
    }

    &.fit { .p-frame, .p-cont { ${cs.h.fit} } }

    @media screen and (max-width : 1280px) {}  
    @media screen and (max-width : 1024px) {}  
  }

  &.gallery-viewer {
    .p-frame .p-cont .p-grid .img-box { 
      .link-icon { ${cs.align.rbottom} ${cs.right(10)} ${cs.bottom(10)} ${cs.anim.flicking('1000ms', '1', '0.3', 1)}  }
      &.link:hover { 
        & > div {${cs.border.shadow()}}
        img {
          ${cs.anim.zoomin('0.3s', '1.05', '1.1', 0.9, 'trp-link-zoom')}
        }
      }

      ${cs.opac.invisible}
      &.noanim { ${cs.opac.visible} }
      &.type1 { ${cs.anim.slidein('0.3s', '100%', 0, 'glb-type1', 'ease-out')} }
      &.type2 { ${cs.anim.slideup('0.3s', '100%', 0, 'glb-type2', 'ease-in-out')} }
      &.type3 { ${cs.opac.visible} ${cs.anim.zoomup('1s', '0', '1.0', 'glb-type3', 'ease-out')} ${cs.anim.origin('top')} }
    }

    .p-frame .p-cont {
      .p-tit { ${cs.opac.invisible} ${cs.anim.showin('0.3s')} ${cs.anim.delay('0.3s')} }
      .p-txt { ${cs.opac.invisible} ${cs.anim.showin('0.3s')} ${cs.anim.delay('0.5s')} }
    }

    
    &.onepage {
      .p-tit { ${cs.align.ctop} ${cs.top('calc(10%)')} }
      .p-txt { ${cs.align.ctop} ${cs.top('calc(50% + 240px)')} }

      .p-cont { ${cs.h.get('100vh')} ${cs.max.h('100vh !important')} ${cs.min.h(440)}
        ${cs.p.v50}
        .p-box { ${cs.over.hidden} ${cs.over.yauto} ${cs.h.full}  ${cs.pos.relative} 
          ${cs.scrollbar.t1} ${cs.scrollbar.hide}
          &:hover { ${cs.scrollbar.t1} }
          .p-grid {
            ${cs.pos.relative} ${cs.align.ycenter} ${cs.p.get("100px 0 !important")}
            &.scroll { ${cs.align.unset} ${cs.p.b50} }
          }
        }
      }

      @media screen and (max-width : 1000px) {
        .p-cont {
          .p-box {
          }
        }
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .p-cont .p-box { ${cs.over.hidden} }
      }
    }
  }

  &.gallery-editor { ${cs.bg.trans} ${cs.p.b40}
    .p-frame { ${cs.p.v20} ${cs.min.h(480)} ${cs.border.none}

      .p-cont { 
        .p-txt { ${cs.m.t20} }
        .p-box {
          // ${cs.max.h(500)}
          .p-grid { ${cs.box.line} ${cs.p.v40} ${cs.border.gray} ${cs.box.dashed} ${cs.border.radius(5)} ${cs.m.t10} 
            ${cs.p.top(60)} ${cs.box.sizing('border-box')}
            .uploader { ${cs.h.full} }
            .p-noti { ${cs.m.t30} }
          }
        }
        .tab-style { ${cs.m.v10} }
        .input { ${cs.box.line} }
      }

      .add-img { ${cs.box.line} ${cs.box.radius} ${cs.bg.frame} ${cs.font.line(30)} ${cs.h.fit}
        ${cs.align.xcenter} ${cs.w.fit} ${cs.p.h20} ${cs.top(-5)} ${cs.mouse.pointer} ${cs.z.front}
        &.hide { ${cs.opac.get(0.2)} }
        &:hover { ${cs.bg.gray} }
        & > span { }
      }
    }

    &.onepage {
      .p-frame { 
        .p-cont { 
          .p-box {
            ${cs.max.h(500)} ${cs.over.yauto} ${cs.scrollbar.t0}
            .p-grid { }
          }
        }
      }
    }
  }
};`;

const GalleryBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default GalleryBox;

var timeouts = [];
const BoxViewer = (props) => {
  const { title, cont, className, bg, path, url, jsons, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const images = OPTS.jsons(jsons);
  const { frame, box, label, desc, style = { ...DEFULT_STYLE } } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const isfit = !bg && !url && !title && !cont;
  const { pageopts } = global;

  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [delta, setDelta] = useState(0);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');
  const [array, setArray] = useState(box && (box.anim === 'noanim' || !box.anim) ? images : images.forEach(a => a.anim = 'hide'));

  useEffect(() => {
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);

      if (box.anim === 'noanim') {
        // setArray([...images]);
      } else {
        images.forEach((a, i) => {
          a.anim = 'hide';
          timeouts.push(setTimeout(() => {
            a.anim = box.anim;
            setArray([...images]);
          }, 100 * i));
        });
      }
    } else {
      images && setArray([...images.map(a => { a['anim'] = 'noanim'; return a; })]);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  useEffect(() => {
    setLoaded(true);

    const onResize = () => {
      setRefresh(new Date().getTime());
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      if (timeouts) {
        timeouts.forEach(a => clearTimeout(a));
        timeouts = [];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlers = useSwipeable({
    onSwiped: (e) => {
      const { dir, absY } = e;
      if (absY < 100) return;

      const isup = (dir && dir.toLowerCase() === 'up');
      const { event } = e;
      event['deltaY'] = isup ? 100 : -100
      onWheel(event, 100);
    },
  });

  const onWheel = (e, limit = 300) => {
    const { currentTarget } = e;
    const isdown = e.deltaY > 0;
    const { innerHeight } = window;

    if (currentTarget) {
      const { clientHeight, scrollTop, scrollHeight } = currentTarget;
      if (scrollHeight <= innerHeight) return;
      const pos = clientHeight + scrollTop;
      if (isdown && pos < scrollHeight) return e.stopPropagation();
      if (!isdown && scrollTop > 1) return e.stopPropagation();

      const d = delta + Math.abs(e.deltaY);
      setDelta(d);
      if (d < limit) return e.stopPropagation();
      setDelta(0);
    }
  }

  var { width, height, padding } = OPTS.thumbsize(box.size);
  width = Number(String(width).replace(/[^0-9.]/g, ''));
  height = Number(String(height).replace(/[^0-9.]/g, ''));

  const screenwidth = window.innerWidth;
  let count = Math.floor(screenwidth / width).toFixed(0);
  count = array && array.length < count ? array.length : count;
  let boxwidth = `${Number(width) * count}`;
  const max = OPTS.maxWidth(box.size, '');
  if (global.oauth && global.oauth === 'admin') {
    if (max === '100%') boxwidth = OPTS.maxWidth('xl', '');
    else if (boxwidth > max) boxwidth = max;
  } else {
    if (max !== '100%' && boxwidth > max) boxwidth = max;
  }

  let unset = '';
  if (pageopts && pageopts.style === 'onepage' && array && array.length > 4 && screenwidth < 600) {
    unset = { 'top': 'unset', 'bottom': 'unset', 'left': 'unset', 'right': 'unset', 'transform': 'none' };
  }

  let w = String(maxWidth).replace(/[^0-9]/g, '');
  const isadmin = global.oauth && global.oauth === 'admin';
  if ((maxWidth === '100%' && screenwidth > 1024) || screenwidth > Number(maxWidth === '100%' ? screenwidth : w)) {
    count = style.type ? Number(style.type) : 5;
    width = maxWidth === '100%' ? screenwidth * 0.95 / count : (w - 20) / count;
    if (maxWidth === '100%' && isadmin) width = boxwidth * 0.95 / count;
    boxwidth = width * count;
    height = width / 3 * 4;
  }

  let scroll = false;
  if (pageopts.style === 'onepage') {
    count = Number(count);
    if (array && array.length / count > 2) scroll = true;
    (screenwidth < Number(w)) && (boxwidth = screenwidth);
    if (isadmin) { (screenwidth < Number(w)) && (boxwidth = screenwidth - 30); }
    width = (boxwidth - 20) / count;
    height = width / 3 * 4;
  }

  const { preview = false } = props;

  return (
    <Fragment>
      {pageopts && pageopts.style === 'onepage' && !preview && <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />}
      <StyledObject className={cx("gallery-box gallery-viewer", isfit && 'fit', className, box.size, pageopts && pageopts.style)} >
        {pageopts && pageopts.style !== 'onepage' && <PageBackground src={bgdata} refresh={refresh} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />}
        <div className={cx("p-frame", { loaded }, url && 'boxbg')} style={{ maxWidth, margin: `${box.margin} 0`, ...frame.border }}>
          <div className={cx('p-cont')}>
            {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{title}</p>}
            {cont && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{cont}</p>}

            <div className={"p-box"} onWheel={onWheel} {...handlers} style={{ padding: `${box.padding} 0` }}>
              {array && array.length > 0 && <div className={cx('p-grid', { scroll })} style={{ width: boxwidth + 'px', ...unset, textAlign: 'center' }} >
                {array.map((item, i) => {
                  const { link = null } = item;
                  const imgpath = Util.getImage(item.url, path);
                  const onLoaded = (e, value) => { }
                  return <span className={cx('img-box show', { link }, item.anim || 'noanim')} key={item.uuid}
                    style={{ width: width + 'px', height: height + 'px', padding }} >
                    <Imagebox className="g-img" type={'image'} fit={'cover'} src={imgpath} size={'full'}
                      onLoad={(e) => onLoaded(e, item)} onError={(e) => onLoaded(e, item)} style={{ ...box.options }}
                      onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null} />
                    {/* {link && <Svg className={'dark md link-icon'} icon={'link'} />} */}
                  </span>
                })}
              </div>}
            </div>
          </div>
        </div>
        {props.children}
        {url && !loaded && <Loading />}
      </StyledObject>
    </Fragment>
  )
}

var list = [];
const BoxEditor = (props) => {
  const NEWITEM = { 'uuid': Util.getGenerateKey(), 'url': '', edited: true, new: true };
  const { title, cont, className, bg, path, request, opts } = props.item;

  const [refresh, setRefresh] = useState(new Date());
  const [limit, setLimit] = useState(20);
  const [style, setStyle] = useState(opts && opts.style ? { ...opts.style } : { ...DEFULT_STYLE });

  useEffect(() => {
    if (props.item && props.item.jsons) {
      list = JSON.parse(props.item.jsons);
      setRefresh(new Date());
    } else {
      list = [];
    }

    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    (global.pageopts && global.pageopts.style === 'onepage') && setLimit(10);

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, e, label) => {
    if (!props.item) return;
    const req = props.item.request;
    req[label] = Util.toText(value);
  }

  const onChangeImage = (value, index) => {
    list[index]['url'] = value;
    list[index]['edited'] = true;
    setRefresh(new Date());
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickNew = () => {
    if (list && list.length >= limit) return;
    list = list ? [...list, NEWITEM] : [NEWITEM];
    setRefresh(new Date());
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickDelete = (index) => {
    if (!list || list.length <= 0) return;
    const { rowid } = props.item;
    const value = list[index];

    if (value && value.edited && value.new) {
      list = [...list.slice(0, index), ...list.slice(index + 1)];
      setRefresh(new Date());
      props.item.request['noti'] = null;
    } else {
      global.openConfirm({
        type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
            if (code !== CODE.SUCCESS) return Util.showAlert(props, code);

            list = [...list.slice(0, index), ...list.slice(index + 1)];
            setRefresh(new Date());
            props.item.request['noti'] = null;
          });
        }
      });
    }
  }

  const onClickSave = (index) => {
    const { rowid = 0 } = props.item;
    const value = list[index];
    delete value['edited'];
    delete value['new'];
    actions.doUpdate(API.PAGE_IMAGES, { rowid, value, index }).then((res) => {
      const { code, data } = res;
      if (code !== CODE.SUCCESS) return Util.showAlert(props, code);
      list[index] = data;
      setRefresh(new Date());
      props.item.request['noti'] = null;
    });
  }

  const onClickLink = (item) => {
    const doUpdate = (value) => {
      actions.doUpdate(API.PAGE_IMAGELINK, { rowid: props.item.rowid, link: value }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) return Util.showAlert(props, code);
        props.onClickLink('close');
      });
    }

    props.onClickLink('show', { ...props.item, sellinkid: item.uuid }, (eid, page, target) => {
      const temp = list.find(a => String(a.uuid) === String(item.uuid));
      temp && (temp['link'] = JSON.parse(target));
      doUpdate(JSON.stringify(list));
    });
  }


  const onClickOptions = (eid, value, e) => {
    if (!eid || !value) return;

    const temps = { ...style, [eid]: value };
    setStyle({ ...temps });
    const params = { rowid: props.item.rowid, opts: { ...props.item.opts, style: { ...temps } } };
    actions.doUpdate(URL.API.ADMIN.PAGE_OPTS, params).then(({ code }) => {
      (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
    });
  }

  const bgdata = OPTS.image(bg, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const { pageopts } = global;
  var { width, height, padding } = OPTS.thumbsize(box.size);

  const COUNT = ST.ADMIN.PAGE.COUNT;
  const TABS = [
    { id: '5', title: COUNT(5) },
    { id: '6', title: COUNT(6) },
    { id: '7', title: COUNT(7) },
    { id: '8', title: COUNT(8) },
    { id: '9', title: COUNT(9) },
    { id: '10', title: COUNT(10) },
  ];

  const screenwidth = window.innerWidth;
  let w = String(maxWidth).replace(/[^0-9]/g, '');
  if ((maxWidth === '100%' && screenwidth > 1024) || screenwidth > Number(maxWidth === '100%' ? screenwidth : w)) {
    const count = style.type ? Number(style.type) : 5;
    width = maxWidth === '100%' ? screenwidth * 0.95 / count : (w - 10) / count;
    if (maxWidth === '100%' && screenwidth > 1400) width = 1400 * 0.95 / count;
    height = width / 3 * 4;
    // console.dir({ width, height });
  }

  // if (pageopts.style === 'onepage') {
  //   let count = Math.floor(screenwidth / width).toFixed(0);
  //   if (list && array.length / count > 2) scroll = true;
  //   (screenwidth < Number(w)) && (boxwidth = screenwidth);
  // }

  return (
    <StyledObject className={cx("gallery-box gallery-editor", className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { refresh })} style={{ maxWidth }}>
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <Editbox className={cx("p-txt white sizefix", !cont && 'nodata')} value={request.cont || cont} guide={ST.PAGE.CONTENT_H(1000)} maxLength={1000}
            onChange={(v, e) => onChange(v, e, 'cont')} multi={true} minheight={100} maxheight={100} height={100} />
          <TabButton className={"tab-style"} size={'sm'} list={TABS} select={style.type} color={'gray'}
            onClick={(eid) => onClickOptions('type', eid)} label={ST.ADMIN.PAGE.BOX_SIZE} />
          <div className={"p-box"} style={{ padding: `${box.padding} 0` }}>
            {/* {array && array.length > 0 && <div className={cx('p-grid', { scroll })} style={{ width: boxwidth + 'px', ...unset, textAlign: 'center' }} > */}
            <div className={'p-grid'} style={{ textAlign: 'center' }}>
              <label className={'p-noti sguide'}>{ST.IMAGE_SIZE('200 * 240')}</label>
              {list && list.length > 0 && list.map((item, i) => {
                return <ThumbUploader key={item.uuid} item={item} style={{ width, height, padding }}
                  maxImage={{ width: 370, height: 480 }} index={i} path={path} onChangeImage={onChangeImage}
                  onClickDelete={onClickDelete} onClickSave={onClickSave} onClickLink={onClickLink} />
              })}
              <span className={cx('add-img')} onClick={onClickNew}>
                <span>{list && list.length >= limit ? ST.PAGE.MAX_IMG : ST.PAGE.NEW_IMG}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </StyledObject>
  )
}