import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Util, Button, Uploadbox, Svg, Combobox, TabButton } from 'object/src';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import * as PAGE from '../pages';
import { EID } from 'object/src/Config';
import OptionBar from './OptionBar';
import LinkEditor from './LinkEditor';
import { PageOptions as OPTS, WIDTH } from '../pages/PageCom';

const StyledObject = styled.div`{
  &.page-layer { ${cs.pos.relative} ${cs.h.calc('100% - 37px')} 
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.m.t30} }
    .p-line { ${cs.h.get(2)} ${cs.bg.gray} ${cs.w.full} }

    .p-tbar { ${cs.pos.sticky} ${cs.bottom(0)} ${cs.font.left} ${cs.h.get(40)} ${cs.over.hidden} 
      ${cs.bg.color('#353535f0')} ${cs.border.shadow()} ${cs.m.b0} ${cs.z.over} 
      ${cs.border.bottom} ${cs.border.alphablack}
      .tbar-l { ${cs.opac.get(0.1)}
        ${cs.m.l5} ${cs.p.a5} ${cs.font.gray} ${cs.font.sm} ${cs.font.line(20)}
        .tit { ${cs.disp.inblock} ${cs.w.get(300)} ${cs.font.ellipsis} ${cs.font.line(28)} }
      }
      .tbar-r { ${cs.opac.get(0.1)}
        ${cs.w.fit} ${cs.align.right} ${cs.bottom(3)} ${cs.over.hide}
        .btn { ${cs.m.r10} }
        .delete { ${cs.m.r30} }
        .tl-opt { ${cs.m.r30} }
        .tl-odr { ${cs.disp.inblock} ${cs.m.r30} .move { ${cs.m.l10} } .show { ${cs.m.r30} } }
      }
      &.active { ${cs.bg.black}
        .tbar-l, .tbar-r { ${cs.anim.showin()} }
      }
    }

    .page-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.opac.get(0.8)} ${cs.p.h10} ${cs.p.v2} ${cs.font.ellipsis} ${cs.bg.dark} }

    &.onepage { ${cs.h.get('90vh')} ${cs.p.b50}
      .page-frame { ${cs.size.full} 
        & > div { ${cs.size.full} 
          & > .p-frame { ${cs.align.center} ${cs.pos.relative} 
            .p-cont { ${cs.max.h('80vh')} } 
          }

          &.full, &.xl { .p-frame { ${cs.max.w(`${WIDTH('md')}px !important`)} } }
        }

        .btn-bg { ${cs.align.cbottom} ${cs.bottom(0)} }
      }
    }

    &.show {
      &:hover { 
        .p-tbar { .tbar-l, .tbar-r { ${cs.anim.showin('0.5s')} } } 
      }
    }

    &.hide { 
      .p-tbar { ${cs.opac.show} }
      &.onepage { ${cs.h.get('0vh')} }
    }
  }

  &.popup-bg {
    ${cs.disp.popup('999999', cs.color.alphablack, 600, 460)} ${cs.top(0)}
    .pop-box { ${cs.bg.dark} ${cs.border.black} ${cs.border.shadow()} ${cs.font.white}
      .head { ${cs.h.get(40)} ${cs.bg.trans} ${cs.font.lg} ${cs.font.line(40)} ${cs.p.l10} 
        .close { ${cs.align.right} ${cs.top(5)} ${cs.right(5)} }
      }
      .body { ${cs.h.calc('100% - 80px')} ${cs.p.a10} ${cs.pos.relative} ${cs.bg.trans} 
        .p-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.m.b3} ${cs.z.over} 
          // ${cs.align.ltop} ${cs.left(20)} ${cs.top(10)}
        }
        .bg-img { ${cs.h.calc('100% - 100px')}
          &.zoomout {
            .cont-frame { ${cs.anim.zoomin('5s', '1.3', '1', 1, 'page-zoom-out')} }
          }

          &.showin {
            .cont-frame { ${cs.anim.fadein('3s', '0.1', '1', 'page-show-in', 'ease-out')} }
          }

          &.slideup {
            .cont-frame { ${cs.anim.slideup('2s', '50%', '0', 'page-slide-up', 'ease-in-out')} }
          }
        }
        .bg-tabs { ${cs.m.t10} }
      }
      .foot { ${cs.h.get(40)} ${cs.font.right} ${cs.font.line(40)} ${cs.p.t5} 
        ${cs.border.top} ${cs.bg.trans}
        .button { ${cs.m.r10} &.delete { ${cs.align.left} ${cs.left(10)} } }
      }
    }
  }

  &.page-maker {
    ${cs.align.right} ${cs.pos.fixed} ${cs.h.get('calc(100vh - 60px)')} ${cs.w.get(300)} ${cs.top(60)} ${cs.z.popup}
    ${cs.bg.darkhover} ${cs.border.left} ${cs.anim.slidein('300ms', '100%', '0')}
    .head, .foot { ${cs.disp.block} ${cs.align.top} ${cs.h.get(40)} ${cs.w.full} ${cs.border.bottom} ${cs.p.a10} ${cs.p.l20}
      ${cs.bg.black} ${cs.border.darkgray} ${cs.z.over} ${cs.font.md} ${cs.font.thickbold} ${cs.font.gray}
      .close { ${cs.align.right} ${cs.top(5)} ${cs.right(10)} }
      &.foot { ${cs.align.bottom} 
        .cancel { ${cs.left(5)} }
        .save { ${cs.right(5)} ${cs.min.w(80)} }
        .new { ${cs.right(5)} ${cs.min.w(80)} }
      }
    }
    .body { ${cs.disp.block} ${cs.h.full} ${cs.over.yauto} ${cs.scrollbar.t2} ${cs.p.t40} ${cs.p.b40}
      .le-combo { ${cs.h.get(30)} ${cs.p.a5} }
      .le-ul { ${cs.m.t20} ${cs.p.h5} ${cs.p.b10}
        .le-li { ${cs.p.v10} ${cs.mouse.pointer} ${cs.p.h5} ${cs.border.radius(3)} 
          ${cs.box.line} ${cs.border.trans} ${cs.bg.alphablack} ${cs.m.v10} ${cs.over.hidden}
          .le-box { ${cs.zoom(0.2)} }
        }
      }
    }
  }

  @media screen and (max-width : 600px) { 
    &.page-layer { 
      .title { ${cs.font.md} }
      .p-tbar { ${cs.h.get(70)}
        .tbar-l { ${cs.disp.block} ${cs.w.full} }
        .tbar-r { ${cs.disp.block} ${cs.w.full} ${cs.font.right}
          .tl-odr { ${cs.float.left} }
          .btn { ${cs.m.r5} }
          .delete { ${cs.m.r10} }
        }
      }
    }
  }
}`;

const API = URL.API.ADMIN;

export const PageLayer = (props) => {
  const { list, className = '' } = props;
  const [popup, setPopup] = useState(null);
  const [option, setOption] = useState(null);
  const [linked, setLinked] = useState(null);
  const [show, setShow] = useState(true);
  const [active, setActive] = useState(-1);
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    setPopup(null);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.list]);

  const onClick = (eid, item, index) => {
    setShow(true);
    if (eid === EID.EDIT) {
      const temp = list.find(a => a.rowid === item.rowid);
      temp['edited'] = true;
      props.onReload && props.onReload([...list]);
    } else if (eid === EID.NEW) {
      props.onClick && props.onClick(EID.NEW, index + 1);
    } else if (eid === EID.DELETE) {
      global.openConfirm({
        type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.PAGE, { rowid: item.rowid }, null).then(({ code, result }) => {
            if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
            window.location.reload();
          });
        }
      });
    } else if (eid === EID.CANCEL) {
      // window.location.reload();
      props.onReload();
    } else if (eid === EID.SAVE) {
      const { rowid = 0, menuid, layid, odr, request } = item;

      // 데이터가 변경되지 않으면 패스
      if (Object.keys(request).length <= 0) { props.onReload && props.onReload(); return; }

      // 노티가 있으면 팝업
      if (request && request.noti) {
        global.openConfirm({ type: 'info', size: 'sm', className: 'gray', msg: request.noti, cancel: false });
        return;
      }

      const onEvent = (eventid, e) => { }

      const data = new FormData();
      data.append('rowid', rowid);
      Object.keys(request).forEach(key => {
        const value = request[key];
        if (value !== null) {
          if (key === 'opts' || key === 'jsons') {
            data.append(key, JSON.stringify(value));
          } else {
            // data.append(key, value, (value && value.name) || null);
            if (typeof value === 'object') data.append(key, value, value.name);
            else data.append(key, value);
          }

          // if (typeof object === 'object') data.append(key, object, object.name);
          // else data.append(key, object);
        }
      });

      // 데이터 업데이트
      if (rowid && rowid > 0) {
        actions.doUpload(API.PAGE, data, onEvent).then(({ code, result }) => {
          if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
          props.onReload && props.onReload();
        });

        // actions.doUpdate(API.PAGE, { rowid, ...request }, null, onEvent).then(({ code, result }) => {
        //   if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
        //   props.onReload && props.onReload();
        // });
      } else {  // 데이터 추가
        actions.doInsert(API.PAGE, { menuid, layid, odr, ...request }).then(({ code, result }) => {
          if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
          props.onReload && props.onReload();
        });
      }
    } else if (eid === 'option') {
      setOption(!option ? item : null);
      props.onClick && props.onClick('option', item);
    } else if (eid === 'bg') {
      setPopup(item);
    } else if (eid === 'reload') {
      setPopup(null);
      item['edited'] = true;
      const temps = list.map(a => {
        if (a.rowid === item.rowid) return item;
        else return a;
      });
      props.onReload && props.onReload([...temps]);
    }
    // else { }
  }

  const onClickMove = (eid, item, index) => {
    if (eid === 'move') {
      global.openConfirm({
        size: 'sm', type: 'info', className: 'dark', title: ST.ADMIN.NAVI.MENU, cancel: false, ok: ST.CLOSE,
        children: () => {
          const StyledComp = styled.div`{
            &.menus-pop {
              .m-ul { ${cs.m.t20}
                .m-li { ${cs.font.md} ${cs.disp.block} ${cs.border.bottom} ${cs.bg.darkhover} 
                  ${cs.mouse.pointer} ${cs.p.h10}
                  &:hover { ${cs.bg.black} }
                } 
              }
            }
          }`;

          const onClickMenu = (menu) => {
            if (!item || !menu || menu.rowid === item.menuid) return;

            actions.doUpdate(URL.API.ADMIN.PAGE_MENU, { rowid: item.rowid, menuid: menu.rowid, pmenuid: item.menuid }).then(({ code }) => {
              if (code === CODE.SUCCESS) { global.showAlert({ msg: ST.PAGE.MOVE_MENU(menu.title), type: 'info' }); }
              else { Util.showAlert(props, code); return; }
              global.closeConfirm();
              props.onReload && props.onReload();
            });
          }

          return <StyledComp className={'menus-pop'}>
            <ul className={'m-ul'}>
              {props.menus && props.menus.map(a => {
                const { title } = a;
                if (a.rowid === item.menuid) return null;
                return <li key={a.rowid} className={'m-li'} onClick={() => onClickMenu(a)}>{title}</li>
              })}

            </ul>
            {/* <p className={'label'}>{ }</p> */}
          </StyledComp>
        },
        onClicked: (isOk) => { }
      });
    }
  }

  const onClickOrder = (eid, item, index) => {
    const doUpdateOrder = (a, b) => {
      const value = { list: [{ rowid: a.rowid, odr: a.odr }, { rowid: b.rowid, odr: b.odr }] };
      actions.doUpdate(URL.API.ADMIN.PAGE_ODR, value).then(({ code }) => {
        if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
      });
    }

    if (eid === 'show') {
      setShow(false);
    } else if (eid === 'hide') {
      setShow(true);
    } else if (eid === 'up') {
      if (index < 1) return;
      const temps = [...list.slice(0, index - 1), list[index], list[index - 1], ...list.slice(index + 1)];
      temps.forEach((t, i) => t.odr = i + 1);
      props.onReload && props.onReload([...temps]);

      setActive(index - 1); setTimeout(() => setActive(-1), 1000);
      doUpdateOrder(list[index], list[index - 1]);
    } else if (eid === 'down') {
      if (index >= list.length) return;

      const temps = [...list.slice(0, index), list[index + 1], list[index], ...list.slice(index + 2)];
      temps.forEach((t, i) => t.odr = i + 1);
      props.onReload && props.onReload([...temps]);

      setActive(index + 1); setTimeout(() => setActive(-1), 1000);
      doUpdateOrder(list[index], list[index + 1]);
    } else {
      // console.dir(eid);
    }
  }

  // const onClose = () => {
  //   setPopup(null);
  // }

  const onCloseOptions = (options) => {
    setOption(null);
    props.onReload && props.onReload();
  }

  const doReload = (item) => {
    const index = list.findIndex(a => a.rowid === item.rowid);
    props.onReload && props.onReload([...list.slice(0, index), item, ...list.slice(index + 1)]);
  }

  const onClickPopup = (item) => {
    onClick('reload', { ...item });
  }

  const onSaveOptions = (options, item) => {
    actions.doUpdate(URL.API.ADMIN.PAGE_OPTS, { rowid: item.rowid, opts: options }).then((res) => {
      const { code } = res;
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
      const temp = { ...item, opts: options };
      doReload(temp);
      setOption(null);
    });
  }

  const onChangeOptions = (options, item, eid = null) => {
    const value = list.find(a => a.rowid === item.rowid);

    if (eid && (eid === 'anim' || eid === 'boxsize')) {
      setTimeout(() => {
        setRefresh(new Date().getTime());
      }, 100);
    };

    value.opts = options;
    props.onReload && props.onReload([...list]);

    // setTimeout(() => {
    //   setRefresh(new Date().getTime());
    // }, 100);
    // doReload(temp);
  }

  const onClickLink = (eid, item, callback = null) => {
    if (eid === 'open') {
      if (!item) return;
      const value = (typeof item === 'string') ? JSON.parse(item) : item;
      if (value.type === 'other' || global.oauth === 'admin') window.open(value.url);
      else actions.go(value.url, { ...value });
    } else if (eid === 'close') {
      setLinked(null);
    } else {
      item['callback'] = callback;
      setLinked(linked ? null : item);
    }
  }

  const onClickLinkEditor = (eid, item, target) => {
    if (eid === 'select') {
      actions.doUpdate(API.PAGE_LINK, { rowid: item.rowid, link: target }).then((res) => {
        const { code, data } = res;
        if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
        const value = list.find(a => a.rowid === item.rowid);
        value.link = data;
        props.onReload && props.onReload([...list]);
      });
    }
    // else { }
    setLinked(null);
  }

  return <Fragment >
    {list.map((item, i) => {
      const Component = item.component;
      const isfull = item.opts && item.opts.box && item.opts.box.size && item.opts.box.size === 'full';
      !item['request'] && (item['request'] = {});
      // const bgcolor = (item.opts && item.opts.frame && item.opts.frame.color) || 'transparent';

      // return <StyledObject key={`pg-${i}`} className={cx('page-layer', show ? 'show' : 'hide', className)} style={{ background: bgcolor }}>
      return <StyledObject key={`pg-${i}`} className={cx('page-layer', show ? 'show' : 'hide', className)}>
        {show && <div className={cx('page-frame')}>
          <Component item={item} index={i} edited={item.edited} refresh={refresh} active={true}
            doReload={doReload} onClickLink={onClickLink} >
            {item.edited && <span className={'btn-bg'} onClick={() => onClick('bg', item, i)}>{ST.PAGE.BG_ADD}</span>}
          </Component>
        </div>}
        {isfull && <div className={'page-noti'}>{ST.ADMIN.NOTI.FULL_PAGE}</div>}
        <div className={cx('p-tbar', i === active && 'active')}>
          <div className={'p-line'} />
          <div className={'tbar-l'}>
            <p className={'tit'}>{`${item.odr}. ${item.title}`}</p>
            {/* {item.title && <p className={'tit'}>{`${item.title}`}</p>} */}
          </div>
          <div className={'tbar-r'}>
            <span className={'tl-odr'}>
              <Svg className={'white show radius'} icon={show ? 'min' : 'max'} onClick={() => onClickOrder(show ? 'show' : 'hide', item, i)} />
              <Svg className={'white up'} icon={'arrowup'} onClick={() => onClickOrder('up', item, i)} disabled={item.odr <= 1} />
              <Svg className={'white down'} icon={'arrowdn'} onClick={() => onClickOrder('down', item, i)} disabled={item.odr >= list.length} />
              <Svg className={'white move radius'} icon={'next'} onClick={() => onClickMove('move', item, i)} />
            </span>

            <Button className={'btn sm gd-gray tl-opt'} title={'OPTIONS'} onClick={() => onClick('option', item, i)} disabled={item.edited || props.isnew} />

            {item.edited && <Fragment>
              <Button className={'btn sm black delete'} title={ST.DELETE} onClick={() => onClick(EID.DELETE, item, i)} />
              <Button className={'btn sm gd-gray cancel'} title={ST.CANCEL} onClick={() => onClick(EID.CANCEL, item, i)} />
              <Button className={'btn sm red save'} title={ST.SAVE} onClick={() => onClick(EID.SAVE, item, i)} />
            </Fragment>}

            {!item.edited && <Fragment>
              <Button className={'btn sm black delete'} title={ST.DELETE} onClick={() => onClick(EID.DELETE, item, i)} />
              <Button className={'btn sm green edit'} title={ST.EDIT} onClick={() => onClick(EID.EDIT, item, i)} />
              <Button className={'btn sm primary add'} title={ST.ADD} onClick={() => onClick(EID.NEW, item, i)} />
            </Fragment>}
          </div>
        </div>
      </StyledObject>
    })}
    {popup && <PopupBg item={popup} onClick={onClickPopup} onClose={() => setPopup(null)} />}
    {option && <OptionBar className={'dark'} item={option} title={ST.OPTS.TITLE} showAlert={props.showAlert}
      onClose={onCloseOptions} onSave={onSaveOptions} onChange={onChangeOptions}
      rowid={option.rowid} data={option.opts || OPTS.default()} state={'UPDATE'} />}
    {linked && <LinkEditor item={linked} onClose={() => setLinked(null)} onClick={linked && linked.callback ? linked.callback : onClickLinkEditor} />}
  </Fragment>
}

const PopupBg = (props) => {
  const { item } = props;
  const [value, setValue] = useState(null);
  const [file, setFile] = useState(null);
  const [anim, setAnim] = useState('no');
  const [noti, setNoti] = useState(null);

  useEffect(() => {
    if (!props.item) return;

    setAnim(props.item && props.item.opts && props.item.opts.frame ? props.item.opts.frame.anim : 'no');
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  const onLoad = (e) => {
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    props.onLoad && props.onLoad('error');
  }

  // const onClicked = (e) => {
  //   props.link && window.open(props.link);
  // }

  const onClose = () => {
    props.onClose && props.onClose();
  }

  const onChange = (v, e, label, f) => {
    setValue(v);
    setFile(f);
  }

  const onDelete = (v) => {
    global.openConfirm({
      type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
        (isOk) && actions.doDelete(API.PAGE_BG, { rowid: item.rowid }).then(({ code }) => {
          if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
          setTimeout(() => {
            props.onClick && props.onClick({ ...item, bg: '' });
          }, 500);
        });
      }
    });
  }

  const onSave = () => {
    let action = null;

    if (value === null || (!item.bg && value === '')) return onClose();
    else if (!item.bg && !value) return onClose();
    else if (item.bg && value === '') action = actions.doDelete;
    else action = actions.doUpload;

    const data = new FormData();
    data.append('image', file, file.name);
    data.append('rowid', item.rowid);

    action(API.PAGE_BG, data).then((res) => {
      const { code } = res;
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
      setTimeout(() => {
        props.onClick && props.onClick({ ...item, bg: res.data });
      }, 500);
    });
  }

  const max = item.ctag ? 'ProdBox' === { width: 1900, height: 5000 } : { width: 1900, height: 1100 }

  const TABS = [
    { id: 'no', title: 'NO', label: '' },
    { id: 'zoomout', title: 'ZOOM OUT', label: ST.ADMIN.ANIM.ZOOMOUT },
    { id: 'showin', title: 'SHOW IN', label: ST.ADMIN.ANIM.SHOWIN },
    { id: 'slideup', title: 'SLIDE UP', label: ST.ADMIN.ANIM.SLIDEUP },
  ];

  const onClickAnim = (eid, v) => {
    setAnim(eid);
    setNoti(v.label);

    const opts = JSON.parse(JSON.stringify(item.opts));
    (opts && opts.frame) ? (opts.frame['anim'] = eid) : (opts['frame'] = { 'anim': eid });

    actions.doUpdate(API.PAGE_OPTS, { rowid: item.rowid, opts }).then((res) => { });
  }

  return <StyledObject className={cx("popup-bg")}>
    <div className={'pop-bg'} onClick={onClose} />
    <div className={'pop-box'}>
      <div className={'head'}>
        <span>{ST.PAGE.BG_TITLE}</span>
        <Svg className={'close gray md right top'} icon={EID.CANCEL} onClick={onClose} />
      </div>
      <div className={'body'}>
        <label className={'p-noti'}>{ST.PAGE.IMAGE_H + ` ${ST.IMAGE_SIZE('1600 * 900')}`}</label>
        <Uploadbox className={cx("bg-img", anim)} fit={'cover'} value={item.bg} path={item.path} size={"wide"}
          onLoad={onLoad} onError={onError} eid={"bg"} maxImage={max}
          onChange={(v, e, file) => onChange(v, e, 'bg', file)} />
        <div className={'bg-tabs'}>
          <TabButton className={'tab-btn full'} size={'md'} list={TABS} onClick={onClickAnim} select={anim} />
          <p className={'p-noti'}>{noti}</p>
        </div>
      </div>
      <div className={'foot'}>
        <Button className={'btn sm black delete'} title={ST.DELETE} onClick={onDelete} disabled={!item.bg} />
        <Button className={'btn sm gray cancel'} title={ST.CANCEL} onClick={onClose} />
        <Button className={'btn sm red save'} title={ST.SAVE} onClick={onSave} />
      </div>
    </div>
  </StyledObject>
}


const SURL = 'https://www.nuriohome.co.kr/sample';
const NEWITEM = (menuid, layout, odr, jsons = null, opts = null, rest) => {
  return {
    component: PAGE[layout.ctag],
    rowid: 0,
    menuid: menuid,
    ctag: layout.ctag,
    layid: layout.layid,
    title: '',
    cont: '',
    odr: odr,
    jsons: jsons ? JSON.stringify(jsons) : "",
    link: "",
    opts: opts || OPTS.default(),
    type: "image",
    url: '',
    bg: '',
    new: 'new',
    ...rest,
  }
}

export const PageMaker = (props) => {
  const [list, setList] = useState(null);

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = (eid, e, item) => {
    // setSelect(eid);
    makeCreate(item.id);
  }

  const onClose = () => {
    props.onClose && props.onClose()
  }

  const makeCreate = (type) => {
    const { menuid, layouts } = props;

    const slide = layouts.find(a => a.ctag === "SlideBox");
    const page = layouts.find(a => a.ctag === "PageBox");
    const prod = layouts.find(a => a.ctag === "ProdBox");
    const news = layouts.find(a => a.ctag === "NewsBox");
    const text = layouts.find(a => a.ctag === "TextBox");
    // const jumbo = layouts.find(a => a.ctag === "JumboBox");
    // const gallery = layouts.find(a => a.ctag === "GalleryBox");
    const triple = layouts.find(a => a.ctag === "TripleBox");
    const history = layouts.find(a => a.ctag === "HistoryBox");

    const uuid = Util.getGenerateKey();
    const SLIDE = {
      json: [
        { 'uuid': uuid + 1, url: `${SURL}/sample_slider_1.jpg` },
        { 'uuid': uuid + 2, url: `${SURL}/sample_slider_2.jpg` },
      ],
      opts: {
        "box": { "size": "md", "padding": "0px", "border": "0px", "radius": "0px", "color": "#ffffff", "margin": "40px", "anim": "noanim" },
        "label": { "size": "28px", "color": "#000000", "align": "left", "weight": "bold", "margin": "10px" },
        "desc": { "size": "14px", "color": "#000000", "align": "left", "weight": "semi", "margin": "10px" },
        "frame": { "color": "#ffffff00", "opacity": "1.0" }
      }
    }

    const PRODUCT = {
      json: '',
      opts: {
        "box": { "size": "full", "padding": "0px", "border": "0px", "radius": "0px", "color": "#ffffff", "margin": "0px", "anim": "noanim" },
        "label": { "size": "28px", "color": "#000000", "align": "left", "weight": "bold", "margin": "10px" },
        "desc": { "size": "14px", "color": "#000000", "align": "left", "weight": "semi", "margin": "10px" },
        "frame": { "color": "#ffffff00", "opacity": "1.0" }
      }
    }

    const IMAGE = {
      opts:
      {
        "box": { "size": "md", "padding": "0px", "border": "0px", "radius": "0px", "color": "#ffffff", "margin": "50px", "anim": "type1" },
        "label": { "size": "28px", "color": "#000000", "align": "left", "weight": "bold", "margin": "10px" },
        "desc": { "size": "14px", "color": "#000000", "align": "left", "weight": "semi", "margin": "10px" },
        "frame": { "color": "#ffffff00", "opacity": "1.0" }
      }
    }

    const TRIPLE = {
      json: [
        { 'uuid': uuid + 1, url: `${SURL}/sample_gallery_1.jpg` },
        { 'uuid': uuid + 2, url: `${SURL}/sample_gallery_2.jpg` },
        { 'uuid': uuid + 3, url: `${SURL}/sample_gallery_3.jpg` },
        { 'uuid': uuid + 4, url: `${SURL}/sample_gallery_4.jpg` },
        { 'uuid': uuid + 5, url: `${SURL}/sample_gallery_5.jpg` },
      ],
      opts: {
        "box": { "size": "md", "padding": "40px", "border": "0px", "radius": "0px", "color": "#ffffff", "margin": "80px", "anim": "type1" },
        "label": { "size": "28px", "color": "#f44e3b", "align": "center", "weight": "bold", "margin": "0px" },
        "desc": { "size": "14px", "color": "#000000", "align": "left", "weight": "semi", "margin": "10px" },
        "frame": { "color": "#ffffff00", "opacity": "1.0" }
      },
    }

    const TEXT = {
      json: '',
      opts: {
        "box": { "size": "md", "padding": "20px", "border": "0px", "radius": "0px", "color": "#14508e", "margin": "80px", "anim": "type1" },
        "label": { "size": "28px", "color": "#ffffff", "align": "center", "weight": "bold", "margin": "10px" },
        "desc": { "size": "22px", "color": "#ffffff", "align": "center", "weight": "bold", "margin": "50px" },
        "frame": { "color": "#285a8c", "opacity": "1.0", "bcolor": "#00000000" }
      }
    }

    var year = 2011;
    const historyjson = [
      { 'uuid': uuid + 0, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 1, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 2, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 3, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 4, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 5, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 6, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 7, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 8, 'title': year++, 'desc': ST.MAKER.HISTORY },
      { 'uuid': uuid + 9, 'title': year++, 'desc': ST.MAKER.HISTORY },
    ];

    const PGUIDE = { title: '제품 문의 및 안내', cont: '전화 000-0000-0000\n\n이메일 email@email.com\n\n주소 서울 강남구 ....' };
    const index = props.list ? props.list.length : 0;
    let temps = [];
    if (type === 'type-1') {
      temps = [
        NEWITEM(menuid, slide, index + 1, SLIDE.json, SLIDE.opts),
        NEWITEM(menuid, triple, index + 2, TRIPLE.json, TRIPLE.opts, { title: '제품 소개', cont: '' }),
        NEWITEM(menuid, text, index + 3, TEXT.json, TEXT.opts, PGUIDE),
      ]
    } else if (type === 'type-2') {
      // var item = NEWITEM(menuid, news, index + 2, '', '', { url: `${SURL}/sample_prod.jpg` });
      // item.opts = OPTS.default(null, { color: cs.color.white }, { color: cs.color.white });
      temps = [
        NEWITEM(menuid, prod, index + 1, PRODUCT.json, PRODUCT.opts, { url: `${SURL}/sample_titlebar_1.jpg` }),
        NEWITEM(menuid, triple, index + 2, TRIPLE.json.slice(0, 3), { ...TRIPLE.opts, "style": { "type": "wide" } }, { title: '제품 소개', cont: '' }),
        NEWITEM(menuid, text, index + 3, TEXT.json, TEXT.opts, PGUIDE),
        // item,
      ]
    } else if (type === 'type-3') {
      temps = [
        NEWITEM(menuid, prod, index + 1, PRODUCT.json, PRODUCT.opts, { url: `${SURL}/sample_titlebar_1.jpg` }),
        NEWITEM(menuid, prod, index + 2, null, IMAGE.opts, { url: `${SURL}/sample_image_1.jpg` }),
        NEWITEM(menuid, prod, index + 3, null, IMAGE.opts, { url: `${SURL}/sample_image_2.jpg` }),
        NEWITEM(menuid, prod, index + 4, null, IMAGE.opts, { url: `${SURL}/sample_image_3.jpg` }),
        NEWITEM(menuid, text, index + 5, TEXT.json, TEXT.opts, PGUIDE),
      ]
    } else if (type === 'type-4') {
      const opts = OPTS.default(
        { "size": "md", "padding": "30px", "border": "0px", "radius": "0px", "color": "#ffffff", "margin": "65px", "anim": "noanim" },
        { "size": "28px", "color": "#ffffff", "align": "center", "weight": "bold", "margin": "30px" },
        { "size": "14px", "color": "#ffffff", "align": "center", "weight": "semi", "margin": "50px" }
      );
      temps = [
        NEWITEM(menuid, prod, index + 1, PRODUCT.json, PRODUCT.opts, { url: `${SURL}/sample_titlebar_1.jpg` }),
        NEWITEM(menuid, prod, index + 2, '', IMAGE.opts, { url: `${SURL}/sample_image_1.jpg` }),
        NEWITEM(menuid, page, index + 3, '', IMAGE.opts, { url: `${SURL}/sample_image_2.jpg` }),
        NEWITEM(menuid, history, index + 4, historyjson, '', { url: `${SURL}/sample_gallery_1.jpg` }),
        NEWITEM(menuid, news, index + 5, '', opts, { url: `${SURL}/sample_prod.jpg`, ...PGUIDE }),
        // NEWITEM(menuid, text, index + 6, TEXT.json, TEXT.opts, PGUIDE),
      ]
    }

    setList([...temps]);
  }

  const onSave = () => {
    const pages = list.filter(a => a.new === 'new');
    actions.doInsert(API.PAGE_MAKE, { menuid: props.menuid, list: pages }).then(({ code, result }) => {
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
      props.onReload && props.onReload();
      onClose();
    });
  }

  const combo = [
    { id: 'none', name: ST.MAKER.SELECT },
    { id: 'type-1', name: ST.MAKER.TYPE1 },
    { id: 'type-2', name: ST.MAKER.TYPE2 },
    { id: 'type-3', name: ST.MAKER.TYPE3 },
    { id: 'type-4', name: ST.MAKER.TYPE4 },
  ]

  return <StyledObject className={cx("page-maker")}>
    <div className={'head'}>
      <span>{ST.PAGE.SEL_LAYOUT}</span>
      <Button className={'btn sm black close'} title={ST.CLOSE} onClick={onClose} />
    </div>
    <div className={'body'}>
      <div className={'le-combo'}>
        <Combobox className={'sm full dark'} list={combo} onClick={onSelect} />
      </div>
      {list && <ul className="le-ul">
        {list.map((item, i) => {
          if (props.item && item.rowid === props.item.rowid) return null;
          const { component } = item;
          const Component = component;
          return <li key={item.layid} className={cx('le-li')}>
            <div className={'le-box'}><Component item={item} index={i} active={true} /></div>
          </li>
        })}
      </ul>}
    </div>
    <div className={'foot'}>
      <Svg className={'white md middle left cancel'} icon={'exit'} onClick={onClose} />
      {/* <Button className={'btn sm green new'} title={ST.NEW} onClick={onCreate} /> */}
      <Button className={'btn sm red save right middle'} title={ST.SAVE} onClick={onSave} />
    </div>
  </StyledObject>
}