import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Imagebox, Loading, cs, Editbox, TabButton } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS, ThumbUploader } from './PageCom';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const API = URL.API.ADMIN;
const DEFULT_STYLE = { type: 'normal' };  //normal, wide

const StyledObject = styled.div`{ 
  &.triple-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} ${cs.font.black} ${cs.bg.trans}

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.disp.block} ${cs.object.contain}
      ${cs.p.a5} ${cs.h.fit} 
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} ${cs.h.fit}
          .p-tit { 
            ${cs.p.a0} ${cs.font.t1} ${cs.m.b10} ${cs.font.center} ${cs.font.bold} 
          }

          .p-txt { 
            ${cs.font.lg} ${cs.m.v20} ${cs.font.center} ${cs.scrollbar.t0}
            ${cs.over.hidden} ${cs.over.yauto} ${cs.font.prewrap} ${cs.max.h(300)}
          }

          .p-grid { ${cs.p.a0} ${cs.w.full} ${cs.pos.relative} ${cs.font.center} ${cs.h.fit}
            .img-box { ${cs.w.r20} ${cs.h.fit} ${cs.p.h10} ${cs.disp.inblock} ${cs.pos.relative} }

            &.wide { .img-box { ${cs.max.w(400)} ${cs.max.h(240)} } }
          }
        }

        &.boxbg { ${cs.max.h(500)} ${cs.h.get('100vh')} 
          .p-cont { ${cs.align.ycenter} ${cs.pos.relative}
            .p-grid { ${cs.m.t30} } 
          } 
        }

        .p-img { ${cs.align.left} ${cs.top(0)} ${cs.z.back} ${cs.min.h(480)} }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }
      }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.alias} };
      
    &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
    }

    &.fit { .p-frame, .p-cont { ${cs.h.fit} } }
  }

  &.triple-viewer {
    .p-frame .p-cont .p-grid { ${cs.over.hidden}
      .img-box { 
        &.link:hover { 
          & > div {${cs.border.shadow()}}
          img {
            ${cs.anim.zoomin('3s', '1.0', '1.1', 0.9, 'trp-link-zoom')}
          }
        }

        ${cs.opac.invisible}
        &.noanim { ${cs.opac.visible} }
        &.type1 { ${cs.anim.slidein('0.5s', '100%', 0, 'trb-type1', 'ease-out')} }
        &.type2 { ${cs.anim.slideup('0.5s', '100%', 0, 'trb-type2', 'ease-in-out')} }
        &.type3 { ${cs.opac.visible} ${cs.anim.zoomout('2s', '0', '1.0', 'trb-type3', 'ease-out')} ${cs.anim.origin('top')} }
      }
    }

    &.onepage {
      .p-frame { 
      }
    }

    
    .mobile { ${cs.pos.relative}
      .p-box {
        .p-grid { ${cs.font.unset} ${cs.align.xcenter} ${cs.over.hidden}
          .img-box { ${cs.opac.visible} }
          .active { ${cs.z.front} }
          .prev, .next { ${cs.opac.get(0.5)} ${cs.z.back} ${cs.pos.absolute}
            &:hover { ${cs.opac.get(0.8)} }
            .g-img { ${cs.mouse.pointer} }
          }
          .prev { left: unset; right: 50%; transform: translateX(calc(-50% + 10px)); marginLeft: 10px; }
          .next { right: unset; left: 50%; transform: translateX(calc(50% - 10px)); }

          .active.anim { ${cs.anim.zoomin('300ms', 0.8, 1.0, 1, 'tr-active', 'ease-in')} }
          .prev.anim { ${cs.anim.fadeout('200ms', 1.0, 0.8, 'tr-prev', 'ease-in')} }
          .next.anim { ${cs.anim.fadein('200ms', 0, 0.8, 'tr-next', 'ease-in')} }
        }
      }
    }

    .desktop { }
  }

  &.triple-editor { ${cs.p.b40} ${cs.bg.trans}
    .p-frame { ${cs.p.a0} ${cs.min.h(240)} ${cs.border.none}

      .p-cont { 
        .p-txt { ${cs.m.t20} }
        .p-grid { ${cs.box.line} ${cs.p.v30} ${cs.border.gray} ${cs.box.dashed} ${cs.border.radius(5)} ${cs.m.t10}
          .upf-preview .upv-img { ${cs.bg.frame} }
          .remove { ${cs.top(5)} ${cs.right(-10)} ${cs.box.border} ${cs.border.radius(5)} ${cs.bg.black} .svg { ${cs.p.a3} }  }
          .save { ${cs.bottom(12)} ${cs.right(12)} }
          .upload { ${cs.bottom(12)} ${cs.right(12)} ${cs.w.calc('100% - 24px')} }
        }

        .input { ${cs.box.line} }
      }

      .add-img { ${cs.box.line} ${cs.box.radius} ${cs.bg.frame} ${cs.font.line(30)} ${cs.h.fit}
        ${cs.align.xcenter} ${cs.w.fit} ${cs.p.h20} ${cs.top(-5)} ${cs.mouse.pointer} ${cs.z.front}
        &.hide { ${cs.opac.get(0.2)} }
        &:hover { ${cs.bg.gray} }
        & > span { }
      }
    }

    &.onepage {
      .btn-bg { ${cs.align.cbottom} ${cs.bottom(20)} }
    }
  }

};`;

const TripleBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default TripleBox;

var timeouts = [];
const BoxViewer = (props) => {
  const { title, cont, className, bg, path, url, jsons, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const images = OPTS.jsons(jsons);
  const { frame, box, label, desc, style = { ...DEFULT_STYLE } } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const isfit = !bg && !title && !cont;
  const { pageopts } = global;
  const { innerWidth } = window;
  const ismobile = innerWidth < 600;

  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');
  const [array, setArray] = useState(box && (box.anim === 'noanim' || !box.anim) ? images : images.forEach(a => a.anim = 'hide'));

  useEffect(() => {
    if (props.active) {
      if (box && box.anim && box.anim !== 'noanim') {
        setAnim('no');
        setTimeout(() => setAnim(box.anim), 200);

        if (box.anim === 'noanim') {
          setArray([...images]);
        } else {
          images.forEach((a, i) => {
            a.anim = 'hide';
            timeouts.push(setTimeout(() => {
              a.anim = box.anim;
              setArray([...images]);
            }, 300 * i));
          });
        }
      } else {
        images && setArray([...images.map(a => { a['anim'] = 'noanim'; return a; })]);
      }
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  useEffect(() => {
    setLoaded(true);

    const onResize = () => {
      setRefresh(new Date().getTime());
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);

      if (timeouts) {
        timeouts.forEach(a => clearTimeout(a));
        timeouts = [];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!array) return null;
  return (
    <StyledObject className={cx("triple-box triple-viewer", isfit && 'fit', className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded }, url && 'boxbg')} style={{ maxWidth, margin: `${box.margin} 0`, ...frame.border }} refresh={refresh}>
        {ismobile
          ? <Mobile {...{ array, box, path, style, title, cont, label, desc, maxWidth, anim }} onClickLink={props.onClickLink} />
          : <Desktop {...{ array, box, path, style, title, cont, label, desc, maxWidth, anim }} onClickLink={props.onClickLink} />}
      </div>
      {props.children}
      {url && !loaded && <Loading />}
    </StyledObject>
  )
}

const Desktop = (props) => {
  const { array, box, path, style, desc, label, title, cont, maxWidth, anim } = props;

  useEffect(() => {
    return () => {
    }
  }, [props]);

  const { pageopts } = global;
  var { width, height, padding } = OPTS.thumbsize(box.size);
  if (pageopts && pageopts.style === 'onepage') {
    width = Math.floor(String(width).replace(/[^0-9]/g, '')).toFixed(0) + 'px';
  } else {
    // width = Number(width) * array.length;
  }

  if (style.type === 'wide') {
    let w = String(maxWidth).replace(/[^0-9]/g, '');
    if (pageopts && pageopts.style === 'onepage' && (w < 400 || w > 1024)) { w = 1024; }
    width = Number(1 / array.length * 100).toFixed(5) + '%';
    if (width < 110) { width = 100 }
  }

  return <div className={cx('p-cont desktop', anim || 'noanim')}>
    {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{title}</p>}
    {cont && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{cont}</p>}

    <div className={"p-box"} style={{ padding: `${box.padding} 0` }}>
      {array && array.length > 0 && <div className={cx('p-grid', style.type)} style={{ width: `${Number(width) * array.length}px` }} >
        {array.map((item, i) => {
          const { link = null } = item;
          const imgpath = Util.getImage(item.url, path);
          const onLoaded = (e, value) => { }
          return <span className={cx('img-box', { link }, item.anim)} key={item.uuid} style={{ width, height, padding }} >
            <Imagebox className="g-img" type={'image'} fit={style.type === 'wide' ? 'fill' : 'cover'}
              src={imgpath} size={style.type === 'wide' ? 'wide' : 'full'}
              onLoad={(e) => onLoaded(e, item)} onError={(e) => onLoaded(e, item)} style={{ ...box.options }}
              onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null} />
          </span>
        })}
      </div>}
    </div>
  </div>
}

const Mobile = (props) => {
  const [pos, setPos] = useState(0);
  const [scroll, setScroll] = useState(true);
  const { array, box, path, style, desc, label, title, cont, anim } = props;
  const { innerWidth } = window;
  const istablet = innerWidth > 400;

  useEffect(() => {
    setPos(0);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onLoaded = (e, value) => { }

  const onClick = (eid) => {
    let index = eid === 'prev' ? pos - 1 : pos + 1;
    if (index <= 0) index = 0;
    if (index >= array.length) index = array.length - 1;
    setPos(index);
    setScroll(eid);
  }

  const thumbsize = (s = '') => {
    let value = null;
    s = istablet ? 'lg' : 'md';
    switch (s) {
      case 'lg': value = { height: 400, width: 320 }; break;  // 400 * 320
      case 'md': value = { height: 300, width: 240 }; break;  // 300 * 240
      // case 'sm': value = { height: 200, width: 160 }; break;  // 200 * 160
      default: value = { height: 260, width: 208 }; break;  // 280 * 202.8
    }
    return value;
  };

  const onEventAnim = (eid) => {
    setScroll(null);
  }

  if (!array) return null;

  const item = array[pos];
  const prev = array[pos - 1] || null;
  const next = array[pos + 1] || null;
  const { link = null } = item;
  const imgpath = Util.getImage(item.url, path);

  var { width, height } = thumbsize(box.size);
  let boxstyle = { height: `calc(${height}px)`, width: '100%' };
  let istyle = { width: width + 'px', height: height + 'px' };
  let nstyle = { width: `${width - width / 10}px`, height: `${height - height / 10}px`, marginTop: `${height / 10 / 2}px` };

  if (style.type === 'wide') {
    istyle = { height: '50vw', width: '80vw' };
    nstyle = { height: '40vw', width: '70vw', marginTop: nstyle.marginTop };
    boxstyle = { ...boxstyle, height: 'calc(50vw)' }
  }

  const objectfit = style.type === 'wide' ? 'fill' : 'cover'
  return <div className={cx('p-cont mobile', anim || 'noanim')}>
    {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{title}</p>}
    {cont && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{cont}</p>}
    <div className={"p-box"} style={{ ...boxstyle }}>
      <div className={cx('p-grid', style.type)} style={{ width: '100%', height: 'auto' }} >
        <span className={cx('img-box prev', { scroll })} style={nstyle}>
          {prev && <Imagebox className="g-img" type={'image'} fit={objectfit} onClick={() => onClick('prev')}
            src={Util.getImage(prev.url, path)} size={'full'} style={{ ...box.options }} />}
        </span>
        <span className={cx('img-box active', { link }, { scroll })} style={istyle} onAnimationEnd={() => onEventAnim('end')}>
          <Imagebox className="g-img" type={'image'} fit={objectfit} src={imgpath} size={'full'} style={{ ...box.options }}
            onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null} />
        </span>
        <span className={cx('img-box next', { scroll })} style={nstyle}>
          {next && <Imagebox className="g-img" type={'image'} fit={objectfit} onClick={() => onClick('next')}
            src={Util.getImage(next.url, path)} size={'full'} style={{ ...box.options }} />}
        </span>
      </div>
    </div>
  </div>
}

var list = [];
const BoxEditor = (props) => {
  const MAXLEN = 5;
  const NEWITEM = { 'uuid': Util.getGenerateKey(), 'url': '', edited: true, new: true };
  const { title, cont, className, bg, path, request, opts } = props.item;
  const [refresh, setRefresh] = useState(new Date());
  const [style, setStyle] = useState(opts && opts.style ? { ...opts.style } : { ...DEFULT_STYLE });

  useEffect(() => {
    if (props.item && props.item.jsons) {
      list = JSON.parse(props.item.jsons);
      setRefresh(new Date());
    } else {
      list = [];
    }
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, e, label) => {
    if (!props.item) return;
    const req = props.item.request;
    req[label] = Util.toText(value);
  }

  const onChangeImage = (value, index) => {
    list[index]['url'] = value;
    list[index]['edited'] = true;
    setRefresh(new Date());
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickNew = () => {
    if (list && list.length >= MAXLEN) return;
    list = list ? [...list, NEWITEM] : [NEWITEM];
    setRefresh(new Date());
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickDelete = (index) => {
    if (!list || list.length <= 0) return;
    const { rowid } = props.item;
    const value = list[index];

    if (value && value.edited && value.new) {
      list = [...list.slice(0, index), ...list.slice(index + 1)];
      setRefresh(new Date());
      props.item.request['noti'] = null;
    } else {
      global.openConfirm({
        type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
            if (code !== CODE.SUCCESS) return Util.showAlert(props, code);
            list = [...list.slice(0, index), ...list.slice(index + 1)];
            setRefresh(new Date());
            props.item.request['noti'] = null;
          });
        }
      });
    }
  }

  const onClickSave = (index) => {
    const { rowid = 0 } = props.item;
    const value = list[index];
    delete value['edited'];
    delete value['new'];

    actions.doUpdate(API.PAGE_IMAGES, { rowid, value, index }).then((res) => {
      const { code, data } = res;
      if (code !== CODE.SUCCESS) return Util.showAlert(props, code);
      list[index] = data;
      setRefresh(new Date());
      props.item.request['noti'] = null;
    });
  }

  const onClickLink = (item) => {
    const doUpdate = (value) => {
      actions.doUpdate(API.PAGE_IMAGELINK, { rowid: props.item.rowid, link: value }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) return Util.showAlert(props, code);
        props.onClickLink('close');
      });
    }

    props.onClickLink('show', { ...props.item, sellinkid: item.uuid }, (eid, page, target) => {
      const temp = list.find(a => String(a.uuid) === String(item.uuid));
      temp && (temp['link'] = JSON.parse(target));
      console.dir({ eid, page, target, temp });
      doUpdate(JSON.stringify(list));
    });
  }

  const onClickOptions = (eid, value, e) => {
    if (!eid || !value) return;

    const temps = { ...style, [eid]: value };
    setStyle({ ...temps });
    const params = { rowid: props.item.rowid, opts: { ...props.item.opts, style: { ...temps } } };
    actions.doUpdate(URL.API.ADMIN.PAGE_OPTS, params).then(({ code }) => {
      (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
    });
  }

  const TABS = [
    { id: 'normal', title: ST.ADMIN.PAGE.TRIPLE_NORMAL },
    { id: 'wide', title: ST.ADMIN.PAGE.TRIPLE_WIDE },
  ]

  const bgdata = Util.getImage(bg, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const { pageopts } = global;

  var { width, height, padding } = OPTS.thumbsize(box.size);
  if (pageopts && pageopts.style === 'onepage') {
    width = Math.floor(String(width).replace(/[^0-9]/g, '')).toFixed(0) + 'px';
  }

  if (style.type === 'wide') {
    width = Math.floor(100 / list.length).toFixed(5) + '%';
  }

  return (
    <StyledObject className={cx("triple-box triple-editor", className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { refresh })} style={{ maxWidth }}>
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <Editbox className={cx("p-txt white sizefix", !cont && 'nodata')} value={request.cont || cont} guide={ST.PAGE.CONTENT_H(500)} maxLength={500}
            onChange={(v, e) => onChange(v, e, 'cont')} multi={true} height={200} />
          <TabButton className={"tab-align"} size={'sm'} list={TABS} select={style.type} color={'gray'}
            onClick={(eid) => onClickOptions('type', eid)} />
          <div className={cx('p-grid', style.type)}>
            {list && list.length > 0 && list.map((item, i) => {
              return <ThumbUploader key={item.uuid} item={item} style={{ width: width, height, padding }}
                maxImage={{ width: 370, height: 480 }} index={i} path={path}
                onChangeImage={onChangeImage} onClickDelete={onClickDelete}
                onClickSave={onClickSave} onClickLink={onClickLink} />
            })}
            <span className={cx('add-img')} onClick={onClickNew}>
              <span>{list && list.length >= MAXLEN ? ST.PAGE.MAX_IMG : ST.PAGE.NEW_IMG}</span>
            </span>
          </div>
        </div>
      </div>
      {props.children}
    </StyledObject>
  )
}