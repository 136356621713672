import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Imagebox, Loading, Editbox, Util } from 'object/src';
import { IMG } from 'object/src/Icons';
import { PageBackground, BgUploader, PageOptions as OPTS, ScrollNavi } from './PageCom';

const StyledObject = styled.div`{
  &.news-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()}

    .p-frame { 
      ${cs.disp.block} ${cs.h.full} ${cs.max.wlg} ${cs.box.radius} ${cs.max.h(480)}
      ${cs.align.xcenter} ${cs.pos.relative} ${cs.object.fill} ${cs.min.h(400)} 
      ${cs.box.line} ${cs.border.color('rgba(100,100,100,0.1)')} ${cs.p.a30} ${cs.over.hidden}

      .p-cont {
        ${cs.pos.absolute} ${cs.float.left} ${cs.w.get('80%')} ${cs.max.w(680)} ${cs.h.full} ${cs.z.get(2)}
        ${cs.pos.relative} ${cs.font.white} ${cs.over.hidden}

        .p-tit { 
          ${cs.p.a0} ${cs.font.t1} ${cs.font.line(40)} ${cs.font.left} ${cs.opac.show} ${cs.font.spacing(5)}
          ${cs.font.ellipsis} 
        }
  
        .p-txt { 
          ${cs.opac.get(0.8)} ${cs.font.preline} ${cs.p.h5} ${cs.max.h(200)} 
          ${cs.over.hidden} ${cs.over.yauto} ${cs.scrollbar.t1} ${cs.font.md}
        }

        .media-box { 
          .cont-frame { ${cs.align.unset} }
        }
      }

      .p-frame-bg  { 
        ${cs.align.left} ${cs.size.full} ${cs.z.front} ${cs.top(0)}
        ${cs.left(0)} ${cs.bg.size('100% 100%')} ${cs.bg.pos('center')}
      }

      .p-noti { ${cs.font.orange} ${cs.z.front} }
      .pointer { ${cs.mouse.pointer} };

      &.loaded { ${cs.opac.visible} }
    }

    @media screen and (max-width : 860px) {
      .p-frame {
        ${cs.min.h(320)} ${cs.max.h(380)}
      }
    }
    @media screen and (max-width : 600px) {
      .p-frame { ${cs.p.a10} ${cs.p.t20}
        ${cs.min.h(240)} ${cs.max.h(320)}
        .p-cont { ${cs.p.a10} ${cs.p.t20}
          .p-tit { ${cs.p.h5} ${cs.font.prewrap} }
          .p-txt { ${cs.p.h5} ${cs.p.l10} }  
        }
      }
    }
  }

  &.news-viewer {
    .p-frame { 
      &.link:hover { ${cs.border.shadow()} ${cs.mouse.pointer} }

      .p-img > img { ${cs.opac.invisible} }
      .p-cont { .p-tit, .p-txt { ${cs.opac.invisible} } }

      &.noanim {
        .p-img > img { ${cs.opac.visible} }
        .p-cont { .p-tit, .p-txt { ${cs.opac.visible} } }
      }
      &.type1 {
        .p-img > img { ${cs.anim.zoomin('2s', '1.1', '1', 1, 'nb-type1')} }
        .p-cont { 
          .p-tit { ${cs.opac.invisible} ${cs.anim.showin('1s', '0', '1', 'nb-type1-text-1')} }
          .p-txt { ${cs.opac.invisible} ${cs.anim.showin('1s', '0', '1', 'nb-type1-text-2')} ${cs.anim.delay('1s')} }
        }
      }
      &.type2 {
        .p-img > img { ${cs.opac.visible} ${cs.anim.foldup('1s', '0', '100%', 'nb-type2', 'ease-out')} ${cs.float.right} }
        .p-cont { 
          .p-tit { ${cs.opac.invisible} ${cs.anim.slideup('1s', '-100%', '0', 'nb-type2-text-1')} }
          .p-txt { ${cs.opac.invisible} ${cs.anim.slideup('1s', '100%', '0', 'nb-type2-text-2')} ${cs.anim.delay('1s')} }
        }
      }
      &.type3 {
        .p-img > img { ${cs.anim.fadein('1s', '0.2', '1', 'nb-type3', 'ease-out')} ${cs.anim.delay('0.3s')} }
        .p-cont { 
          .p-tit { ${cs.opac.invisible} ${cs.anim.zoomin('1s', 0, 1, 1, 'nb-type3-text-1')} }
          .p-txt { ${cs.anim.foldup('2s', '0', '100%', 'nb-type3-text-2', 'ease-out')} ${cs.anim.delay('1s')} }
        }
      }
    }
  }

  &.news-editor { ${cs.p.b40} ${cs.bg.dark}
    .p-frame { ${cs.pos.relative} ${cs.m.a0} 
      .p-cont { 
        .p-txt { ${cs.m.t20} ${cs.p.h0} ${cs.p.l0} }
      }

      .p-frame-bg { }

      .p-show-bg { ${cs.align.right} ${cs.align.ycenter} ${cs.w.get(200)} ${cs.h.fit} ${cs.box.radius} 
        ${cs.font.orange} ${cs.font.sm} ${cs.font.line(20)} ${cs.right(30)} ${cs.box.line} ${cs.p.a20}
        ${cs.font.center} ${cs.z.get(99)} ${cs.font.bold} ${cs.mouse.pointer} ${cs.bg.alphablack}
      } 
    }
  }
}`;

const NewsBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default NewsBox;

const BoxViewer = (props) => {
  const { title, cont, className, bg, path, url, opts, link } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const mp = OPTS.maxPadding(maxWidth);
  const maxstyle = mp ? { width: `calc(100% - ${mp} - ${mp})` } : {}

  const [loaded, setLoaded] = useState(false);
  const [delta, setDelta] = useState(0);
  const [layer, setLayer] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onWheel = (e) => {
    const { currentTarget } = e;
    const isdown = e.deltaY > 0;

    if (currentTarget) {
      const { clientHeight, scrollTop, scrollHeight } = currentTarget;
      const pos = clientHeight + scrollTop;
      setRefresh(new Date().getTime());
      if (isdown && pos < scrollHeight) return e.stopPropagation();
      if (!isdown && scrollTop > 1) return e.stopPropagation();

      const d = delta + Math.abs(e.deltaY);
      setDelta(d);
      if (d < 300) return e.stopPropagation();
      setDelta(0);
    }
  }

  const maxHeight = `calc(100% - ${label.margin} - ${label.lineHeight} - ${box.margin} - ${box.margin})`;
  return (
    <StyledObject className={cx("news-box news-viewer", className, box.size)} >
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx('p-frame', { loaded }, { link }, anim || 'noanim')}
        style={{ ...box.options, maxWidth, marginTop: box.margin, marginBottom: box.margin, padding: box.padding, ...maxstyle, ...frame.border }}>
        {url && <Imagebox className={cx("p-img right")}
          url={urldata} size={"full"} fit="cover" onLoad={onLoad} onError={onError} />}
        <div className="p-frame-bg" style={{ backgroundImage: `url(${IMG.BgFeature01})` }} />
        <div className={cx("p-cont")} style={{ padding: 30, paddingTop: 50 }}>
          <div className={cx("p-tit", !title && 'nodata')} eid={"title"} style={{ ...label.options }}>{title}</div>
          <p className={cx("p-txt", !cont && 'nodata')} eid={"cont"} ref={ref => setLayer(ref)} onWheel={onWheel}
            style={{ ...desc.options, maxHeight }}>{cont}</p>
          <ScrollNavi layer={layer} refresh={refresh} style={{ right: '26px', bottom: '25px' }} />
        </div>
      </div>

      {props.children}
      {url && !loaded && <Loading />}
    </StyledObject>
  )
}

const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }


  const onChange = (value, e, label, file) => {
    if (!props.item) return;
    const req = props.item.request;
    if (label === 'url') req[label] = file;
    else req[label] = Util.toText(value);
  }

  const { title, type, cont, className, bg, path, url, opts, request } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  return (
    <StyledObject className={cx("news-box news-editor", className, box.size)} contbg={IMG.BgFeature01} >
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx('p-frame', { loaded })} style={{ maxWidth }}>
        <BgUploader type={type} src={urldata} size={"full"}
          maxImage={{ width: 1500, height: 600 }} noti={ST.PAGE.IMAGE_H + ` ${ST.IMAGE_SIZE('1000 * 400')}`}
          onLoad={onLoad} onError={onError} onChange={onChange}
          onClickLinked={() => props.onClickLink('show', props.item)} />

        <div className="p-frame-bg" style={{ backgroundImage: `url(${IMG.BgFeature01})` }} />
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <Editbox className={cx("p-txt white", !cont && 'nodata', 'sizefix')} value={request.cont || cont} guide={ST.PAGE.CONTENT_H(1000)} maxLength={500}
            onChange={(v, e) => onChange(v, e, 'cont')} multi={true} minheight={200} maxheight={200} height={200} />
        </div>
      </div>
      {props.children}
    </StyledObject>
  )
}