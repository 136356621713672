import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Imagebox, Loading, cs, Editbox, Svg } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS, ThumbUploader } from './PageCom';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{ 
  &.tile-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()} 

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.disp.block} ${cs.object.contain}
      ${cs.h.fit} 
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} ${cs.h.fit}
          .p-tit { 
            ${cs.p.a0} ${cs.font.t1} ${cs.m.b10} ${cs.font.center} ${cs.font.bold}
          }

          .p-txt { 
            ${cs.font.lg} ${cs.m.t20} ${cs.font.center} ${cs.scrollbar.t0}
            ${cs.over.hidden} ${cs.over.yauto} ${cs.font.prewrap} ${cs.max.h(100)}
          }

          .p-grid { ${cs.min.h(250)} ${cs.h.fit} ${cs.p.a0} ${cs.w.full} ${cs.pos.relative} 
            ${cs.font.left} ${cs.m.center(0)}
            .img-box { ${cs.disp.inblock} ${cs.pos.relative} }
          }

          .p-box { ${cs.pos.relative}
            .p-vline {
              ${cs.opac.invisible} ${cs.align.xcenter} ${cs.top(0)} ${cs.w.get(2)} ${cs.bg.get("#ffffffa0")}
              ${cs.h.full} ${cs.anim.showin('1s')} ${cs.anim.delay('3s')}
            }
          }
        }

        &.boxbg { ${cs.min.h('100vh')}
        }

        .p-img { ${cs.align.left} ${cs.top(0)} ${cs.z.back} ${cs.min.h(480)} }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }
      }
      
      .p-noti { ${cs.font.orange} &.sguide { ${cs.align.ltop} } }
      .pointer { ${cs.mouse.pointer} };
      
      &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
    }

    &.fit { .p-frame, .p-cont { ${cs.h.fit} } }

    @media screen and (max-width : 1280px) {}  
    @media screen and (max-width : 1024px) {}  
  }

  &.tile-viewer {
    .p-frame .p-cont .p-grid .img-box { ${cs.opac.invisible}
      .link-icon { ${cs.align.rbottom} ${cs.right(10)} ${cs.bottom(10)} ${cs.anim.flicking('1000ms', '1', '0.3', 1)} }

      &.noanim { ${cs.anim.showin('0.3s')} }
      &.type1 { ${cs.opac.visible} 
        &.odd .image-box .ib-frame { ${cs.anim.slidein('0.5s', '-100%', 0, 'tib-type1-odd', 'ease-out')} }
        &.even .image-box .ib-frame { ${cs.anim.slidein('0.5s', '100%', 0, 'tib-type1-even', 'ease-out')} }
      }
      &.type2 { ${cs.opac.visible} 
        &.odd .image-box .ib-frame { ${cs.anim.slidein('0.5s', '100%', 0, 'tib-type2-odd', 'ease-in')} }
        &.even .image-box .ib-frame { ${cs.anim.slidein('0.5s', '-100%', 0, 'tib-type2-even', 'ease-in')} }
      }
      &.type3 { ${cs.opac.visible} 
        &.odd .image-box .ib-frame { ${cs.anim.foldin('2s', 0, '100%', 'tib-type3-odd', 'ease-in-out')} ${cs.float.right} }
        &.even .image-box .ib-frame { ${cs.anim.foldin('2s', 0, '100%', 'tib-type3-even', 'ease-in-out')} }
      }

      &.link:hover {
        ${cs.over.hidden}
        .image-box { 
          ${cs.anim.zoomin('0.3s', '1.0', '1.1', 1, 'tib-link-zoom', 'ease-out')}
        }
      }
    }

    .p-frame .p-cont {
      .p-tit { ${cs.opac.invisible} ${cs.anim.showin('0.3s')} ${cs.anim.delay('0.3s')} }
      .p-txt { ${cs.opac.invisible} ${cs.anim.showin('0.3s')} ${cs.anim.delay('0.5s')} }
    }

    
    &.onepage {
      .p-tit { ${cs.align.ctop} ${cs.top('calc(50% - 300px)')} }
      .p-txt { ${cs.align.ctop} ${cs.top('calc(50% + 240px)')} }

      .p-cont { ${cs.h.get('100vh')} ${cs.max.h('100vh !important')} ${cs.min.h(440)}
        ${cs.p.v50}
        .p-box { ${cs.over.hidden} ${cs.over.yauto} ${cs.h.full}  ${cs.pos.relative} 
          ${cs.scrollbar.t1} ${cs.scrollbar.hide}
          &:hover { ${cs.scrollbar.t1} }
          .p-grid {
            ${cs.align.ycenter} ${cs.pos.relative}
            // ${cs.font.left}
          }
        }
      }

      @media screen and (max-width : 1000px) {
        .p-cont {
          .p-box {
            // .p-grid { ${cs.align.unset} ${cs.pos.relative} }
          }
        }
      }
    }
  }

  &.tile-editor { ${cs.bg.trans} ${cs.p.b40}
    .p-frame { ${cs.p.v20} ${cs.min.h(480)} ${cs.border.none}

      .p-cont { 
        .p-txt { ${cs.m.t20} }
        .p-grid { ${cs.p.v40} ${cs.m.t30}
          // ${cs.box.line} ${cs.p.v40} ${cs.border.gray} ${cs.box.dashed} ${cs.border.radius(5)} ${cs.m.t10} 
          // ${cs.box.sizing('contents')}
          .uploader { ${cs.h.full} }
        }

        .input { ${cs.box.line} }
      }

      .add-img { ${cs.box.line} ${cs.box.radius} ${cs.bg.frame} ${cs.font.line(30)} ${cs.h.fit}
        ${cs.align.xcenter} ${cs.w.fit} ${cs.p.h20} ${cs.top(-5)} ${cs.mouse.pointer} ${cs.z.front}
        &.hide { ${cs.opac.get(0.2)} }
        &:hover { ${cs.bg.gray} }
        & > span { }
      }
    }
  }

};`;

const TileBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default TileBox;

var timeouts = [];
const BoxViewer = (props) => {
  const { title, cont, className, bg, path, url, jsons, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const images = OPTS.jsons(jsons);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const isfit = !bg && !url && !title && !cont;
  const { pageopts } = global;

  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [delta, setDelta] = useState(0);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');
  const [array, setArray] = useState(box && (box.anim === 'noanim' || !box.anim) ? images : images.forEach(a => a.anim = 'hide'));


  useEffect(() => {
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);

      if (box.anim === 'noanim') {
        setArray([...images]);
      } else {
        // 개별 슬라이딩.
        // images.forEach((a, i) => {
        //   a.anim = 'hide';
        //   timeouts.push(setTimeout(() => {
        //     a.anim = box.anim;
        //     setArray([...images]);
        //   }, 100 * i));
        // });

        images.forEach((a, i) => a.anim = box.anim);
        setTimeout(() => setArray([...images]), 100);
      }
    } else {
      images && setArray([...images.map(a => { a['anim'] = 'hide'; return a; })]);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  useEffect(() => {
    setLoaded(true);

    const onResize = () => {
      setRefresh(new Date().getTime());
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);

      if (timeouts) {
        timeouts.forEach(a => clearTimeout(a));
        timeouts = [];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWheel = (e) => {
    const { currentTarget } = e;
    const isdown = e.deltaY > 0;
    const { innerHeight, } = window;

    if (currentTarget) {
      const { clientHeight, scrollTop, scrollHeight } = currentTarget;
      if (scrollHeight <= innerHeight) return;
      const pos = clientHeight + scrollTop;
      if (isdown && pos < scrollHeight) return e.stopPropagation();
      if (!isdown && scrollTop > 1) return e.stopPropagation();

      const d = delta + Math.abs(e.deltaY);
      setDelta(d);
      if (d < 300) return e.stopPropagation();
      setDelta(0);
    }
  }

  const mwidth = !maxWidth || maxWidth === '100%' ? window.innerWidth : Number(String(maxWidth).replace(/[^0-9.]/g, ''));
  let screenwidth = Math.min(mwidth, window.innerWidth);
  const ismobile = (screenwidth < 600);
  (global.oauth && global.oauth === 'admin' && screenwidth > 1280) && (screenwidth = 1280)
  const width = ismobile ? '100%' : '50%';
  let height = screenwidth / 2 * 9 / 18;
  height = ismobile ? height * 2 : height;
  const lineheight = props.active && array ? height * (array.length / 2) : 0;
  // width = screenwidth < 600 ? '100%' : '50%';

  const { preview = false } = props;

  return (
    <Fragment>
      {pageopts && pageopts.style === 'onepage' && !preview && <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />}
      <StyledObject className={cx("tile-box tile-viewer", isfit && 'fit', className, box.size, pageopts && pageopts.style)}>
        {pageopts && pageopts.style !== 'onepage' && <PageBackground src={bgdata} refresh={refresh} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />}
        <div className={cx("p-frame", { loaded }, url && 'boxbg')} style={{ maxWidth, margin: `${box.margin} 0`, ...frame.border }}>
          <div className={cx('p-cont')}>
            {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{title}</p>}
            {cont && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{cont}</p>}

            <div className={"p-box"} onWheel={onWheel} style={{ padding: `${box.padding} 0` }}>
              {array && array.length > 0 && <div className={'p-grid'} >
                {/* style={{ marginTop: box.padding, marginBottom: box.padding }}> */}
                {array.map((item, i) => {
                  const { link = null } = item;
                  const imgpath = Util.getImage(item.url, path);
                  const even = i % 2;
                  const onLoaded = (e, value) => { }

                  const float = ismobile ? 'left' : even ? 'right' : 'left';
                  return <span className={cx('img-box show', { link }, item.anim || 'noanim', even ? 'even' : 'odd')} key={item.uuid}
                    style={{ width: width, height: height + 'px', float: float }} >
                    <Imagebox className="g-img" type={'image'} fit={'cover'} src={imgpath} size={'full'}
                      onLoad={(e) => onLoaded(e, item)} onError={(e) => onLoaded(e, item)} style={{ ...box.options }}
                      onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null} />
                    {link && <Svg className={'dark md link-icon'} icon={'link'} />}
                  </span>
                })}
                {width === '50%' && <div className={'p-vline'} style={{ height: lineheight }} />}
              </div>}
            </div>
          </div>
        </div>
        {props.children}
        {url && !loaded && <Loading />}
      </StyledObject>
    </Fragment>
  )
}

var list = [];
const BoxEditor = (props) => {
  // const [loaded, setLoaded] = useState(false);
  // var [list, setList] = useState(null);
  const [refresh, setRefresh] = useState(new Date());
  const [limit, setLimit] = useState(20);
  const NEWITEM = (uuid) => { return { 'uuid': uuid, 'url': '', edited: true, new: true } };
  const { title, cont, className, bg, path, request, opts } = props.item;

  useEffect(() => {
    if (props.item && props.item.jsons) {
      list = JSON.parse(props.item.jsons);
      setRefresh(new Date());
    } else {
      list = [];
    }

    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    (global.pageopts && global.pageopts.style === 'onepage') && setLimit(4);

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, e, label) => {
    if (!props.item) return;
    const req = props.item.request;
    req[label] = Util.toText(value);
  }

  const onChangeImage = (value, index) => {
    list[index]['url'] = value;
    list[index]['edited'] = true;
    setRefresh(new Date());
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickNew = () => {
    if (list && list.length >= limit) return;
    const key = Util.getGenerateKey();
    list = list ? [...list, NEWITEM(key)] : [NEWITEM(key)];
    setRefresh(new Date());
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickDelete = (index) => {
    if (!list || list.length <= 0) return;
    const { rowid } = props.item;
    const value = list[index];

    if (value && value.edited && value.new) {
      list = [...list.slice(0, index), ...list.slice(index + 1)];
      setRefresh(new Date());
      props.item.request['noti'] = (list.length % 2) ? ST.PAGE.TILE_EVEN_NOTI : null;
    } else {
      global.openConfirm({
        type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
            if (code !== CODE.SUCCESS) return Util.showAlert(props, code);

            list = [...list.slice(0, index), ...list.slice(index + 1)];
            setRefresh(new Date());
            props.item.request['noti'] = (list.length % 2) ? ST.PAGE.TILE_EVEN_NOTI : null;
          });
        }
      });
    }
  }

  const onClickSave = (index) => {
    const { rowid = 0 } = props.item;
    const value = list[index];
    delete value['edited'];
    delete value['new'];
    actions.doUpdate(API.PAGE_IMAGES, { rowid, value, index }).then((res) => {
      const { code, data } = res;
      if (code !== CODE.SUCCESS) return Util.showAlert(props, code);
      list[index] = data;
      setRefresh(new Date());

      const even = list.length % 2;
      props.item.request['noti'] = (even) ? ST.PAGE.TILE_EVEN_NOTI : null;
    });
  }

  const onClickLink = (item) => {
    const doUpdate = (value) => {
      actions.doUpdate(API.PAGE_IMAGELINK, { rowid: props.item.rowid, link: value }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) return Util.showAlert(props, code);
        props.onClickLink('close');
      });
    }

    props.onClickLink('show', { ...props.item, sellinkid: item.uuid }, (eid, page, target) => {
      const temp = list.find(a => String(a.uuid) === String(item.uuid));
      temp && (temp['link'] = JSON.parse(target));
      doUpdate(JSON.stringify(list));
    });
  }

  const bgdata = OPTS.image(bg, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const { pageopts } = global;

  const mwidth = !maxWidth || maxWidth === '100%' ? window.innerWidth : Number(String(maxWidth).replace(/[^0-9.]/g, ''));
  // const mwidth = Number(String(maxWidth).replace(/[^0-9.]/g, ''));
  let screenwidth = Math.min(mwidth, window.innerWidth);
  screenwidth > 1280 && (screenwidth = 1280);
  const width = screenwidth / 2;
  const height = width * 9 / 16;

  return (
    <StyledObject className={cx("tile-box tile-editor", className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { refresh })} style={{ maxWidth }}>
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <Editbox className={cx("p-txt white sizefix", !cont && 'nodata')} value={request.cont || cont} guide={ST.PAGE.CONTENT_H(1000)} maxLength={1000}
            onChange={(v, e) => onChange(v, e, 'cont')} multi={true} minheight={100} maxheight={100} height={100} />
          {/* <div className={'p-grid'} style={{ width: boxwidth + 'px' }} > */}
          <div className={'p-grid'} style={{ textAlign: 'center' }}>
            <label className={'p-noti sguide'}>{ST.IMAGE_SIZE('200 * 240')}</label>
            {list && list.length > 0 && list.map((item, i) => {
              return <ThumbUploader key={item.uuid} item={item} style={{ width: '50%', height: height + 'px' }}
                maxImage={{ width: 370, height: 480 }} index={i} path={path}
                onChangeImage={onChangeImage} onClickDelete={onClickDelete}
                onClickSave={onClickSave} onClickLink={onClickLink} />
            })}
            <span className={cx('add-img')} onClick={onClickNew}>
              <span>{list && list.length >= limit ? ST.PAGE.MAX_IMG : ST.PAGE.NEW_IMG}</span>
            </span>
          </div>
        </div>
      </div>
      {props.children}
    </StyledObject>
  )
}