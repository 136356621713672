export { default as Login } from "./Login";
// export { default as Main } from "./Main";
export { default as Menu } from "./Menu";
export { default as Page } from "./Page";
export { default as Board } from "./Board";
export { default as Qna } from "./Qna";
export { default as ProdQna } from "./ProdQna";
export { default as About } from "./About";
export { default as Setting } from "./Setting";
export { default as File } from "./File";
export { default as Management } from "./Management";
export { default as SysInits } from "./SysInits";
export { default as GuideBox } from "./GuideBox";