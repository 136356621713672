import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Loading, cs, Editbox, Uploadbox, Thumblist, Carousel, Button, Svg } from 'object/src';
import { ST } from 'svc/Lang';
import { URL, CODE, EID } from 'svc/Enum';
import { PageBackground, PageOptions as OPTS } from './PageCom';
import * as actions from 'object/src/actor/Action';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{ 
  &.slide-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black}

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.p.a0} ${cs.h.fit}
      ${cs.disp.block} ${cs.object.contain} 
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} 
          .p-tit { 
            ${cs.p.a0} ${cs.font.t1} ${cs.m.b10} ${cs.font.center} ${cs.font.bold}
          }

          .p-slide { ${cs.pos.relative} ${cs.min.h(100)} ${cs.anim.fadein('0.2s')} }
        }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }
      }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.pointer} };
      
      &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
    }

    &.bg { ${cs.min.h('calc(100vh - 300px)')} }
 
    @media screen and (max-width : 1280px) {}  
    @media screen and (max-width : 1024px) {}
  }

  &.slide-viewer {
    &.full { ${cs.h.fit} ${cs.p.v0} ${cs.max.none}
      .p-frame { ${cs.align.unset} ${cs.pos.relative} ${cs.w.full} ${cs.max.none} ${cs.p.a0} 
        .p-cont {
          .p-tit {
            ${cs.align.ctop} ${cs.top(20)} ${cs.z.over} ${cs.w.full} ${cs.p.h20}
          }
        }
      } 
    }
  }

  &.slide-editor { ${cs.p.b40} ${cs.bg.dark}
    .p-frame { 
      .p-txt { ${cs.m.t20} }
      .p-box { ${cs.min.h(100)} }
      
      .p-tlist { ${cs.min.h(60)} ${cs.h.fit}
        .v-line { }
      }
    }
  }

  &.s-popup {
    ${cs.disp.popup(999, cs.color.alphablack, 600, 400)} ${cs.top(0)}
    .pop-box { ${cs.bg.dark} ${cs.border.black} ${cs.border.shadow()} ${cs.font.white}
      .head { ${cs.h.get(40)} ${cs.bg.trans} ${cs.font.lg} ${cs.font.line(40)} ${cs.p.l10} 
        .close { ${cs.align.right} ${cs.top(5)} ${cs.right(5)} }
      }
      .body { ${cs.h.calc('100% - 80px')} ${cs.p.a20} ${cs.pos.relative} ${cs.bg.trans} ${cs.p.t30}
        .p-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.m.b3} ${cs.align.ltop} 
          ${cs.left(20)} ${cs.top(10)} ${cs.z.over} 
        }
        .uploader { ${cs.h.full} }
      }
      .foot { ${cs.h.get(40)} ${cs.font.right} ${cs.font.line(40)} ${cs.p.t5} 
        ${cs.border.top} ${cs.bg.trans}
        .button { ${cs.m.r10} &.delete { ${cs.align.left} ${cs.left(10)} } }
      }
    }
  }

};`;

const SlideBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default SlideBox;


const getRate = (size) => {
  let rate = '16:4';
  switch (size) {
    case 'xl': case 'lg': rate = "12:4"; break;
    case 'md': rate = "10:4"; break;
    case 'sm': rate = "9:4"; break;
    case 'xs': rate = "6:3"; break;
    default: rate = '20:6';
  }
  return rate;
}

const BoxViewer = (props) => {
  const { title, jsons, className, bg, path, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const list = OPTS.jsons(jsons);
  const { frame, box, label } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const canim = props.anim == null || props.anim === true ? true : false;

  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh, props.item.opts]);

  useEffect(() => {
    setLoaded(true);

    const onResize = (e) => {
      setRefresh(new Date());
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onClickLink = (e, item, index) => {
    if (!item || !item.link) return;
    props.onClickLink && props.onClickLink('open', item.link);
  }

  return (
    <StyledObject className={cx("slide-box slide-viewer", className, box.size)} refresh={refresh}>
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth, margin: `${box.margin} 0`, ...frame.border }}>
        <div className={cx('p-cont')} style={{ padding: box.padding }}>
          {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options, paddingTop: 0, paddingBottom: `${label.margin}` }}>{title}</p>}
          <Carousel className={cx("p-slide full")} list={list && list.map(a => { a.path = path; return a })}
            onLoad={onLoad} onError={onError} rate={getRate(box.size)} anim={canim} resize={props.refresh}
            style={{ ...box.options }} onSelect={onClickLink} />
        </div>
      </div>
      {props.children}
      {!loaded && <Loading />}
    </StyledObject>
  )
}

const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [popup, setPopup] = useState(null);
  const [list, setList] = useState(null);

  useEffect(() => {
    if (props.item && props.item.jsons) {
      setList(JSON.parse(props.item.jsons));
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onChange = (value, e, label) => {
    if (!props.item) return;
    const req = props.item.request;
    req[label] = Util.toText(value);
  }

  const onClickDelete = (uuid) => {
    if (!list || list.length <= 0) return;
    const { rowid } = props.item;
    const index = list.findIndex(a => a.uuid === uuid);
    const value = list[index];

    global.openConfirm({
      type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
        (isOk) && actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
          if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }

          const temps = [...list.slice(0, index), ...list.slice(index + 1)];
          setList([...temps]);
          props.item.request['noti'] = null;
        });
      }
    });
  }

  const onSelect = (uuid) => {

  }

  const onClickNew = (eid, pos) => {
    setPopup(props.item);
  }

  const onSave = (rowid, value, index) => {
    actions.doUpdate(API.PAGE_IMAGES, { rowid, value, index }).then((res) => {
      const { code, data } = res;
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }

      const temps = list ? [...list, data] : [data];
      setList([...temps]);
      setPopup(null);
    });
  }

  const onClickPopup = (eid, index, val) => {
    const { rowid = 0 } = props.item;

    if (eid === EID.SAVE) {
      const value = list && list[index] ? list[index] : { uuid: Util.getGenerateKey(), url: '' };
      value['url'] = val;

      onSave(rowid, value, index);
    } else if (eid === EID.DELETE) {
      const value = list[index];
      global.openConfirm({
        type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
            if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
            props.onReload && props.onReload();
          });
        }
      });
    } else {
      setPopup(null);
      props.onReload && props.onReload();
    }
  }


  const onDragDrop = (eid, arrays, e) => {
    if (eid === 'drop') {
      request['jsons'] = arrays; //JSON.parse(JSON.stringify(arrays));
      setList([...arrays]);
      // props.onReload && props.onReload();
    }
  }

  const onClickLink = (item, index, e) => {
    if (!item) return;

    const doUpdate = (value) => {
      actions.doUpdate(API.PAGE_IMAGELINK, { rowid: props.item.rowid, link: value }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
        props.onClickLink('close');
      });
    }

    props.onClickLink('show', { ...props.item, sellinkid: item.uuid }, (eid, page, target) => {
      const temp = list.find(a => String(a.uuid) === String(item.uuid));
      temp && (temp['link'] = JSON.parse(target));
      console.dir({ eid, page, target, temp });
      doUpdate(JSON.stringify(list));
    });

    // console.dir(item);
    // (item, index) => onClickLink('show', props.item)
  }

  const thead = [
    { key: 'url', title: ST.URL, flex: '4 1 200px', align: 'left' },
    { key: 'color', title: ST.TEXTCOLOR, flex: '2 1 100px', align: 'center', type: 'color' },
    { key: 'title', title: ST.TITLE, flex: '3 1 100px', align: 'left' },
  ];

  const { title, className, bg, path, request, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const { frame, box } = OPTS.options(opts);
  // const maxWidth = OPTS.maxWidth(box.size);

  return (
    <StyledObject className={cx("slide-box slide-editor", className, box.size)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth: '1024px' }}>
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <div className={cx("p-box")}>
            {list && <Carousel className={cx("p-slide full")} list={list} path={path} onLoad={onLoad} onError={onError}
              rate={getRate(box.size)} anim={false} onClickLinked={onClickLink} height={'440px'} width={'1024px'} />}
          </div>
        </div>

        <Thumblist className={cx("p-tlist")} head={thead} list={list}
          onClickDelete={onClickDelete} onSelect={onSelect} onClickNew={onClickNew} path={path}
          onDragDrop={onDragDrop} pos={1} max={1} total={list ? list.length : 0} rowid="uuid" />
      </div>

      {popup && <PopupBox item={popup} pos={list ? list.length : 0} onClose={() => setPopup(null)} onClick={onClickPopup}
        openConfirm={global.openConfirm} showAlert={global.showAlert} />}
      {props.children}
    </StyledObject>
  )
}

const PopupBox = (props) => {
  const { item, pos } = props;
  const [value, setValue] = useState(null);

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    props.onLoad && props.onLoad('error');
  }

  const onClose = () => {
    props.onClose && props.onClose();
  }

  const onChange = (v) => {
    setValue(v);
  }

  const onSave = () => {
    props.onClick && props.onClick(EID.SAVE, pos, value);
  }

  return <StyledObject className={cx("s-popup")}>
    <div className={'pop-bg'} onClick={onClose} />
    <div className={'pop-box'}>
      <div className={'head'}>
        {/* <p className={'text'}>{type === 'pri' ? ST.COMPANY.PRIVATE : ST.COMPANY.PUBLIC}</p> */}
        <span>{ST.PAGE.BG_TITLE}</span>
        <Svg className={'close gray md right top'} icon={EID.CANCEL} onClick={onClose} />
      </div>
      <div className={'body'}>
        <label className={'p-noti'}>{ST.PAGE.IMAGE_H + ` ${ST.IMAGE_SIZE('1600 * 900')}`}</label>
        <Uploadbox className="bg-img" fit={'contain'} value={`${item.path}${item.bg}`} size={"wide"}
          onLoad={onLoad} onError={onError} maxImage={{ width: 2400, height: 1100 }}
          onChange={(v, e) => onChange(v)} />
      </div>
      <div className={'foot'}>
        {/* <Button className={'btn sm black delete'} title={ST.DELETE} onClick={onDelete} /> */}
        <Button className={'btn sm gray cancel'} title={ST.CANCEL} onClick={onClose} />
        <Button className={'btn sm red save'} title={ST.SAVE} onClick={onSave} />
      </div>
    </div>
  </StyledObject>
}