import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames/bind';
import { cs, Storage, Loading, Svg } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import { PopBoard } from '../admin/Board';
import * as PAGE from '../pages';
import { WIDTH } from '../pages/PageCom';
import { useSwipeable } from "react-swipeable";

const StyledObject = styled.div`{
  &.homepage { ${cs.bg.white} ${cs.noliststyle} ${cs.noselect} 
    .menu-bar .mb-ul .mb-li { ${cs.m.h20} }    
  
    .phead { 
      ${cs.h.fit} ${cs.min.h(100)} ${cs.font.center} ${cs.p.t40} ${cs.p.b20}
      .label { ${cs.font.md} ${cs.font.darkgray} } 
      .title { ${cs.font.t1} ${cs.m.t10} ${cs.font.orangehover} } 
    }

    .pline { ${cs.disp.block} ${cs.w.full} ${cs.h.get(1)} ${cs.border.bottom} ${cs.m.v5} }

    .pbody { ${cs.h.fit} ${cs.min.h(600)} ${cs.max.w(1200)} ${cs.m.center(20)}
      ${cs.disp.autoflex}
      .ul .li { ${cs.pos.relative}
        &.li { ${cs.mouse.pointer}}
        
        ${cs.w.get(240)} ${cs.disp.inblock} ${cs.p.h10}
        .thumb { ${cs.pos.relative} ${cs.size.get(220)}
          .label {
            ${cs.align.bottom} ${cs.left(0)} ${cs.bottom(0)} ${cs.w.full} ${cs.p.a5} ${cs.font.ellipsis}
            ${cs.bg.get('#d0d0d090')} ${cs.z.front} ${cs.font.sm} ${cs.font.orangehover} ${cs.font.line(14)}
          }
        }

        .foot { ${cs.disp.block} ${cs.w.full} ${cs.h.get(140)} ${cs.over.hidden} 
          .title { ${cs.font.bold} ${cs.font.md} ${cs.font.line(20)} ${cs.m.t10} }
          .sale { ${cs.w.full} ${cs.font.right} ${cs.font.gray} ${cs.font.cancelline} ${cs.font.sm} ${cs.disp.block} }
          .bottom { ${cs.font.right} ${cs.font.line(20)} ${cs.m.t5}
            .price { ${cs.font.xl} ${cs.font.bold} ${cs.font.orangehover} }
            .capacity { ${cs.font.sm} ${cs.font.darkgray} }
          }
        }

        .h-tags { ${cs.w.full} ${cs.align.rtop} ${cs.left(5)} ${cs.top(5)}
          .tag { ${cs.font.sm} ${cs.bg.get('rgba(255, 0, 0, 0.8)')} ${cs.font.white} ${cs.border.radius(3)} ${cs.p.h5} ${cs.p.v5} }
          .sale { }
        }
        .h-status { ${cs.size.full} ${cs.align.top} ${cs.left(0)} ${cs.bg.alphablack} 
          .stat { ${cs.align.center} ${cs.font.color('rgba(250, 0, 0, 0.9)')} ${cs.font.t0} ${cs.font.center} 
            ${cs.p.a5} ${cs.w.full} ${cs.bg.color('#dededed0')} 
          }
        }
      }
    }

    &.p-main {
      .pm-head { 
        .carousel-box { ${cs.max.h(480)} }
      }
    }

    &.p-view {
    }

    @media screen and (max-width : 1280px) { 
      .pbody { ${cs.max.w(960)} }
    }

    @media screen and (max-width : 1024px) { 
      .pbody { ${cs.max.w(720)} }
    }

    @media screen and (max-width : 740px) { 
      .pbody { ${cs.max.w(480)} }
    }
    
    @media screen and (max-width : 600px) { 
      .pbody {
        .ul {
          .li {
            ${cs.w.r50} ${cs.p.h10}
            .thumb { ${cs.m.center()} ${cs.size.get('100%')} .thumb-box { ${cs.size.full} } }
            .foot { ${cs.p.h20} }
          }
        }
      }
    }
  }
}`;

const Homepage = (props) => {
  const { data } = props;
  const opts = data && data.opts ? JSON.parse(data.opts) : { style: 'scroll' };

  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [list, setList] = useState(null);
  const [frame, setFrame] = useState(null);
  const [select, setSelect] = useState(0);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (!props.data) return;

    var prev = global.device.type;
    const onResize = () => {
      const { type } = global.device;
      if (prev !== type) {
        prev = type;
        setRefresh(true);
        setTimeout(() => setRefresh(false), 200);
      }
    }

    const { ishome, rowid = 0 } = props.data;
    if (ishome === 'Y') {
      actions.doSelect(URL.API.APPINFO, { stag: 'boardpop', skey: 'show' }).then(({ result }) => {
        const value = result ? result[0] : null;
        setShow(value && value.sval === 'enable');
      });
    }

    const param = actions.getParam();
    if (!(rowid || param)) return null;
    const menuid = param && param.menuid ? param.menuid : rowid;
    if (param && param.menuid) {
      setCurrent(param);
      actions.popParam();
      opts.style === 'scroll' ? setTimeout(() => setSelect(param.pageid || 0), 500) : setSelect(param.pageid || 0);
    }

    actions.doSelect(URL.API.PAGE, { menuid }).then((result) => {
      const { value } = result;
      value.forEach(a => {
        a.component = PAGE[a.ctag];
        if (!a.component) a.component = PAGE.NoPage;  // 기본 태그
        a.opts && JSON.parse(a.opts);
        return null;
      });

      setFrame(result.frame);
      setList(value);
    });

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const onClickPop = (eid, value) => {
    setShow(false);

    // 해당 팝업은 더이상 보이지 않는다.
    if (eid === 'exit') {
      const { uuid } = value;
      const puid = Storage.getLocalItem('board-pop-uuid');
      if (!puid && uuid !== puid) {
        Storage.setLocalItem('board-pop-uuid', uuid);
      }
    }
  }

  const onClick = (eid, item) => {
    if (eid === 'openlink') {
      if (!item) return;
      const value = (typeof item === 'string') ? JSON.parse(item) : item;
      if (current && current.menuid === item.menuid) {
        setSelect(item.pageid);
      } else {
        if (value.type === 'other') window.open(value.url);
        else actions.go(value.url, { ...value });
      }
    }
  }


  if (!data) return null;

  global.pageopts = { ...opts };

  return <StyledObject className={cx('homepage')}>
    <div className={'hp-frame'}>
      <div className={cx('page p-main')}>
        <div className={'pm-head'}> </div>
        <div className={'pm-body'}>
          {(!opts || !opts.style || opts.style) === 'scroll' && <Page frame={frame} select={select} list={list} onClick={onClick} />}
          {opts.style === 'onepage' && <OnePage frame={frame} list={list} select={select} onClick={onClick} />}
        </div>
        {show && <PopBoard onClick={onClickPop} />}
      </div>
    </div>
    {refresh && <Loading className={'fixed sm'} type={'ring'} />}
  </StyledObject>
};

export default Homepage;

const sliding = (v = '150ms', s = '0%', e = '-10%', c = 1, name = 'sliding', type = 'linear') =>
  `animation: ${name} ${type} ${c} forwards ${v}; 
  @keyframes  ${name} { 
  from  { transform: translateY(${s}); opacity: 0.3; } 
  to { transform: translateY(${e}); opacity: 1; }
}`;

const pageslide = (v = '150ms', s = '0', e = '-100%', name = 'slideup', type = 'ease-in', os = 1.0, oe = 0) =>
  `animation: ${name} ${type} 1 forwards ${v}; 
@keyframes ${name} { 
  from  { transform: translateY(${s}); opacity: ${os}; } 
  to { transform: translateY(${e}); opacity: ${oe}; } 
};`;

const StyledPage = styled.div`{
  &.page-layer {
    ${cs.bg.white} ${cs.w.full} ${cs.bg.size('cover')} ${cs.bg.repeat('no-repeat')} ${cs.bg.attach('fixed')}
    ${cs.bg.pos('center')} ${cs.min.h('calc(100vh - 300px)')}
  }

  &.s-scroll {
    @media screen and (-webkit-min-device-pixel-ratio:0){
      .page-frame {
        ${cs.opac.visible} 
        .p-frame { ${cs.opac.invisible} } 
        .page-bg { ${cs.opac.invisible} ${cs.anim.fadein('0.3s', 0, 1, 'page-bg-anim', 'ease-in')} ${cs.anim.delay('0.1s')} }
        &.active {
          .p-frame { ${cs.anim.fadein('0.3s', 0, 1, 'page-frame-anim', 'ease-in')} ${cs.anim.delay('0.1s')} }
          .page-bg { ${cs.opac.visible} }
        }
      }
    }
    
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .page-frame { ${cs.opac.visible} }
    }
  }

  &.s-onepage {
    ${cs.pos.relative} ${cs.h.get('100vh')} ${cs.z.over}
    
    .page-navi { ${cs.font.right} ${cs.align.cbottom} ${cs.z.top} ${cs.bottom(0)} ${cs.w.full}
      ${cs.max.wlg}
      
      .p-navi { ${cs.w.fit} ${cs.opac.get(0.3)}
        &:hover { ${cs.opac.show} }
        .n-icon { ${cs.m.a5} }
      }

      .p-scroll { ${cs.w.fit} ${cs.align.xcenter} ${cs.disp.inblock} ${cs.z.top} ${cs.top(-10)} ${cs.w.get(80)}
        ${cs.opac.get(0.3)}
        &:hover { ${cs.opac.show} .svg-icon { svg .svg-path { ${cs.fill.primary} ${cs.opac.show} } } }

        .s-btn { ${cs.w.full}
          ${cs.mouse.pointer} ${cs.disp.block} ${cs.pos.absolute} ${cs.z.over}
          & > span { ${cs.w.full} ${cs.h.get(20)} }
          & > .prev { ${cs.disp.block} }
          & > .next { ${cs.disp.block} }
        }

        .s-icon { ${cs.w.full} 
          .svg-icon { ${cs.disp.invisible} ${cs.disp.block} ${cs.w.full} svg { ${cs.p.a0} } }
          .show { ${cs.disp.visible} }

          & > .prev { ${cs.top(-7)} ${sliding('1s', '10%', '0%', 3, 's-down', 'ease-in-out')} }
          & > .next { ${cs.top(-30)} ${sliding('1s', '0%', '10%', 3, 's-up', 'ease-in-out')} }
        }
      }
    }

    .page-frame { ${cs.size.full} ${cs.disp.block} ${cs.opac.invisible}
      &.slide-1 {
        &.prev { ${cs.pos.absolute} ${cs.disp.block} ${cs.opac.visible} ${cs.top(0)} ${cs.left(0)} 
          &.show { ${cs.opac.invisible} }
          &.up { ${pageslide('0.5s', '0', '30%', 'p-slideup-1', 'ease-in')} ${cs.z.back} }
          &.down { ${pageslide('0.5s', '0', '-30%', 'p-slidedown-1', 'ease-in')} ${cs.z.back} }
        }
        &.active { ${cs.pos.absolute} ${cs.disp.block} ${cs.opac.visible} ${cs.top(0)} ${cs.left(0)}
          &.show { ${cs.anim.showin()} }
          &.up { ${pageslide('0.5s', '-100%', '0', 'p-activeup-1', 'ease-in-out', 0, 1)} ${cs.z.front} }
          &.down { ${pageslide('0.5s', '100%', '0', 'p-activedown-1', 'ease-in-out', 0, 1)} ${cs.z.front} }
        }
      }

      &.slide-2 {
        &.prev { ${cs.pos.absolute} ${cs.disp.block} ${cs.opac.visible} ${cs.top(0)} ${cs.left(0)}
          &.show { ${cs.opac.invisible} }
          &.up { ${pageslide('0.8s', '0', '50%', 'p-slideup-2', 'ease-in')} ${cs.z.back} }
          &.down { ${pageslide('1.0s', '0', '-100%', 'p-slidedown-2', 'ease-in-out')} ${cs.z.front} }
        }
        
        &.active { ${cs.pos.absolute} ${cs.disp.block} ${cs.opac.visible} ${cs.top(0)} ${cs.left(0)}
          &.show { ${cs.anim.showin()} }
          &.up { ${pageslide('1.0s', '-100%', '0', 'p-activeup-2', 'ease-in-out', 0, 1)} ${cs.z.front} }
          &.down { ${pageslide('0.8s', '50%', '0', 'p-activedown-2', 'ease-in-out', 0, 1)} ${cs.z.back} }
        }
      }

      &.slide-3 {
        &.prev { ${cs.pos.absolute} ${cs.disp.block} ${cs.opac.visible} ${cs.top(0)} ${cs.left(0)}
          &.show { ${cs.opac.invisible} }
          &.up { ${cs.anim.slide('1.0s', '0', '100%', 'p-slideup-3', 'ease-in-out')} ${cs.z.front} }
          &.down { ${cs.anim.slide('1.0s', '0', '-100%', 'p-slidedown-3', 'ease-in-out')} ${cs.z.front} }
        }
        
        &.active { ${cs.pos.absolute} ${cs.disp.block} ${cs.opac.visible} ${cs.top(0)} ${cs.left(0)}
          &.show { ${cs.anim.showin()} }
          &.up { ${cs.anim.slide('1.0s', '-50%', '0', 'p-activeup-3', 'ease-in-out', 0, 1)} ${cs.z.back} }
          &.down { ${cs.anim.slide('0.8s', '50%', '0', 'p-activedown-3', 'ease-in-out', 0, 1)} ${cs.z.back} }
        }
      }

      &.front { ${cs.z.over} }

      .page-section {
        ${cs.align.relative} ${cs.size.full} ${cs.disp.block} ${cs.left(0)} 
        & > div { ${cs.size.full} 
          // & > .p-frame { ${cs.p.get('0 !important')} ${cs.m.get('0 !important')} ${cs.align.center} ${cs.pos.relative} .p-cont { ${cs.max.h('90vh')} } }
          & > .p-frame { ${cs.m.get('0 !important')} ${cs.align.center} ${cs.pos.relative} .p-cont { ${cs.max.h('90vh')} } }
          &.full, &.xl { .p-frame { ${cs.max.w(`${WIDTH('md')}px !important`)} } }
        }
      }
    }

    @media screen and (max-width : 600px) { 
      .page-navi { 
        .p-navi {
          .n-icon { ${cs.m.h5} }
        }
      }
      
      .page-frame {
        .page-section {
          & > div { 
            & > .p-frame { .p-cont { ${cs.max.h('100vh')} } }
          }
        }
      }
    }
  }
}`;

const moveToScroll = (id = null, margin = 70, smooth = true) => {
  const node = id ? document.getElementById(id) : null;
  if (node) {
    if (smooth) {
      window.scrollTo({ top: node.offsetTop - margin, behavior: 'smooth' });
    } else {
      window.scrollTo(0, node.offsetTop - margin);
    }
  }
};

// var timerinit = null;
var plist = null;
export const Page = (props) => {
  const [refresh, setRefresh] = useState(null);
  const [list, setList] = useState(null);
  const [rect, setRect] = useState(0);
  const { frame } = props;

  useEffect(() => {
    const isIE =  /*@cc_on!@*/false || !!document.documentMode;
    if (isIE) return;

    const doCheck = () => {
      checkActive(plist);
    };

    window.addEventListener('touchmove', doCheck);
    window.addEventListener('scroll', doCheck);

    return () => {
      window.removeEventListener('touchmove', doCheck);
      window.removeEventListener('scroll', doCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      global.onLoaded && global.onLoaded(true);
      (props.select) && moveToScroll(props.select);
    }, 300);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.select]);

  useEffect(() => {
    if (!props.list || props.list.length < 1) return;
    setTimeout(() => {
      const frames = document.querySelectorAll('.page-frame');
      frames.forEach(element => {
        const { id } = element;
        const item = props.list.find(a => String(id) === String(a.rowid));
        item && (item['pos'] = element.offsetTop);
      });
      checkActive(plist);
    }, 500);
    plist = props.list;

    setList(plist && [plist[0]]);
    setTimeout(() => {
      setList([...plist]);
    }, 100);

    return () => {
    }
  }, [props.list])

  const onClickLink = (eid, item) => {
    props.onClick && props.onClick('openlink', item);
  }

  const checkActive = (array) => {
    const { body, documentElement } = document;
    const doc = documentElement;

    const frames = document.querySelectorAll('.page-frame');
    frames.forEach(element => {
      const { id } = element;
      const item = array.find(a => String(id) === String(a.rowid));
      item && (item['pos'] = element.offsetTop);
    });

    const scrollTop = Math.max(doc.scrollTop, body.scrollTop);
    const { clientHeight } = documentElement;
    let update = true;
    setRect(scrollTop + clientHeight);

    update && setRefresh(new Date().getTime());
  }

  return <StyledPage className={cx('page-layer s-scroll')} refresh={refresh}
    style={{ backgroundImage: frame && frame.bg && `url(${frame.path + frame.bg})` }}>
    {list && list.length > 0 && list.map((item, i) => {
      let { component, active = false, pos = 0 } = item;
      const Component = component;
      (i === 0 || pos < rect - 100) && (active = true);
      return <div key={i} className={cx('page-frame', { active })} ctag={item.ctag}
        pgno={item.rowid} id={item.rowid} >
        <Component key={i} item={item} index={i} onClickLink={onClickLink} active={active} />
      </div>
    })}
  </StyledPage>
}

const slidelist = ['slide-1', 'slide-2', 'slide-3'];
const index = Math.floor((Math.random() * slidelist.length - 1) + 1);
const slide = slidelist[index];

export const OnePage = (props) => {
  const { frame, list } = props;
  const [pos, setPos] = useState(0);
  const [down, setDown] = useState('show');
  const [bg, setBg] = useState(null);
  const [show, setShow] = useState(true);
  const [refresh, setRefresh] = useState(null);
  // const [anim, setAnim] = useState(false);

  useEffect(() => {
    if (!props.list) return;

    global.onLoaded && global.onLoaded(true);
    const index = props.select ? props.list.findIndex(a => String(a.rowid) === String(props.select)) : 0;
    setPos(index);

    const object = document.getElementById("body-frame");
    object.style.overflow = "hidden";

    // 원페이지 스타일의 헤더에 클래스 추가 및 컬러 수정
    const header = document.getElementsByTagName("header");
    if (header && header.length > 0) {
      const headframe = header[0];
      if (headframe) {
        headframe.classList.add('alpha');
        let style = headframe.style;
        style['position'] = 'absolute';
      }
    }

    setTimeout(() => {
      setBg(frame && frame.bg && `url(${frame.path + frame.bg})`);
    }, 200);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.list, props.select]);

  const handlers = useSwipeable({
    onSwiped: (event) => {
      const { dir, absY } = event;
      if (absY < 100) return;

      if (dir && dir.toLowerCase() === 'up') onWheel({ deltaY: 100 })
      else { onWheel({ deltaY: -100 }) }
    },
  });

  const onClickLink = (eid, item) => {
    props.onClick && props.onClick('openlink', item);
  }

  const onWheel = (e) => {
    const isdown = e.deltaY > 0;  // 0보다 크면 아래로
    let idx = isdown ? pos + 1 : pos - 1;

    if (idx >= list.length - 1) idx = list.length - 1;
    if (idx < 0) idx = 0;

    if (idx === pos) return;
    setDown(isdown ? 'down' : 'up');
    setPos(idx);
    setShow(false); setTimeout(() => setShow(true), 100);
  }

  const onAnimationEnd = () => {
    list.forEach(a => a['front'] = false);
    const item = list[pos];
    (item) && (item['front'] = true);
    setRefresh(new Date().getTime());
  }

  if (!list) return null;
  return <StyledPage className={cx('page-layer s-onepage')} onWheel={onWheel}
    style={{ backgroundImage: bg }} {...handlers} refresh={refresh}>
    <div className={'page-navi'}>
      <span className={'p-scroll'}>
        <div className={'s-btn'}>
          <span className={cx('prev')} onClick={(e) => onWheel({ deltaY: -100 })}></span>
          <span className={cx('next')} onClick={(e) => onWheel({ deltaY: 100 })}></span>
        </div>
        <div className={'s-box'}></div>

        <div className={'s-icon'}>
          <Svg className={cx('lg black prev', show && pos > 0 && 'show')} icon={'arrowup'} />
          <Svg className={cx('lg black next', show && pos < list.length - 1 && 'show')} icon={'arrowdn'} />
        </div>
      </span>
      <span className={'p-navi'}>
        {list && list.length > 0 && list.map((item, i) => {
          return <Svg key={i} className={'xs black n-icon'} icon={i === pos ? 'show' : 'unradio'}
            onClick={() => { setPos(i); setDown('show') }} />
        })}
      </span>
    </div>
    {list && list.length > 0 && list.map((item, i) => {
      const Component = item.component;
      const prev = down === 'down' ? i === pos - 1 : i === pos + 1;
      const active = i === pos;
      const { front = false } = item;
      return <div key={i} className={cx('page-frame', { active }, { prev }, down, slide)}
        style={front ? { zIndex: '999' } : {}}
        onAnimationEnd={onAnimationEnd} ctag={item.ctag} pgno={item.rowid} id={item.rowid} >
        <div className={cx('page-section')}>
          <Component className={cx('page-comp')} item={item} index={i} onClickLink={onClickLink} active={active} />
        </div>
      </div>
    })}
  </StyledPage>
}
