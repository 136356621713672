/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import moment from 'moment';
import { cs, Listbox, Svg, Button } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const StyledObject = styled.div`{
  &.file { ${cs.pos.relative} ${cs.bg.white}
    .file-frame { ${cs.w.full} ${cs.max.wmd} ${cs.min.h('calc(100vh - 100px)')} ${cs.align.xcenter} ${cs.pos.relative} 
      ${cs.h.fit} ${cs.p.h50} ${cs.p.b40}
      .title { ${cs.font.xl} ${cs.p.t40} ${cs.p.b20} ${cs.m.b20} ${cs.border.bottom} ${cs.font.t0} ${cs.font.himelody} ${cs.font.thickbold} }

      .list { ${cs.font.xl} ${cs.min.h('70vh')} ${cs.disp.block}
        .search-frame { ${cs.font.center}
          .search-box { ${cs.bg.white} ${cs.box.light}
            .input { ${cs.bg.white} }
            .cb-sel { ${cs.bg.trans} }
          }
        }
        .lbx-body { ${cs.over.inherit} }
        .lbx-body .lbx-li {
          ${cs.border.color(cs.color.lightgray)} ${cs.font.line(40)} ${cs.h.get(40)}
          .svg-icon { ${cs.m.l20} ${cs.pos.absolute} ${cs.bottom(5)} }
        }
        .lbx-cnt { ${cs.disp.none} }
        .lbx-date { ${cs.align.rbottom} ${cs.font.line(10)} }

        .l-tit { ${cs.font.md} ${cs.font.dark} ${cs.font.bold} ${cs.p.left(12)}
          &:hover { ${cs.font.primary} ${cs.font.underline} }
          .down.svg-icon { ${cs.m.l2} ${cs.align.left} ${cs.bottom(7)} }
        }
        .l-size { ${cs.font.orangehover} ${cs.p.l0} ${cs.font.xs} }
        .btn-copy { ${cs.align.right} ${cs.right(-50)} ${cs.bottom(-2)} }
      }
    }
    
    
  @media screen and (max-width : 1024px) { }
  
  @media screen and (max-width : 860px) { 
    .br-frame { ${cs.p.h20} }
    .list-box { ${cs.p.t0} 
      &.list {
        .lbx-body .lbx-li { ${cs.font.line(20)} ${cs.h.fit} & > div { ${cs.p.b10} } }
        .l-tit { ${cs.font.sm} }
        .l-req { ${cs.font.xs} }
      }
    }

    &.file .file-frame { 
      ${cs.p.h20} 
      .list .search-frame { ${cs.font.left} } 
    }
  }

  @media screen and (max-width : 480px) {
    &.file .file-frame { 
      .list-box { ${cs.p.b0} }
      .list .search-frame { ${cs.font.left} ${cs.w.full} .search-box { ${cs.w.full} ${cs.m.bottom(40)} } }
    }
  }
}`;

const KB = 1024 * 1;

const File = (props) => {
  var down = null;

  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: 'title', value: '' });
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [compnay, setCompany] = useState('');
  const order = 'ctime desc';
  const limit = 15;

  const searchs = [
    { name: ST.SUBJECT, id: 'title', check: true },
    { name: ST.CTIME, id: 'ctime', check: false },
    // { name: ST.UTIME, id: 'utime', check: false },
  ];

  useEffect(() => {
    const param = actions.getParam();
    doReload(1, search, order, limit, (array = null) => {
      setLoaded(true);
      setTimeout(() => props.onLoaded && props.onLoaded(true), 100);
      setTimeout(() => global.onLoaded && global.onLoaded(true), 100);
    });
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.refresh) return;
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh])

  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    // setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "${s.value}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(URL.API.FILE, value, true).then(({ result }) => {
      setTotal(result.page && result.page.total)
      setPage(result.page && result.page.current);
      setMax(result.page && result.page.max);
      setSearch(s);
      setCompany(result && result.comp && result.comp.length > 0 && result.comp[0].sval);

      result.list = result.list.map(item => {
        item.subject = `${item.no}. ${item.title}`;
        item.date = item.ctime ? item.ctime.substr(0, 8) : '';
        return item;
      });

      setList(result.list);
      // setRunning(false);

      callback && callback(result.list);
    });
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  // const onSelect = (rowid, e, item) => {
  //   // props.onClick && props.onClick(item);
  //   item.path && window.open(item.path);
  // }

  const formatter = (item) => {
    const { opts } = item;
    const json = opts && JSON.parse(item.opts);
    let size = (json && json.size) || '';

    if (size) {
      size = (Number(size) / KB).toFixed(1); //KB
      if (size >= 1000) {
        size = (Number(size) / KB).toFixed(1) + 'MB'; //MB
      } else {
        size = size + "KB";
      }
    }

    const url = window.location.origin + item.path;
    let color = cs.color.gray;
    return <div className={''}>
      <a className={'l-tit'} href={item.path} download>{item.title} <Svg className={'sm gray down'} icon={'disk'} /> </a>
      {size && <span className={'l-size'} style={{ color }}>{size}</span>}
      {props.editable && <Svg className={'sm gray delete'} icon={'delete'} onClick={() => props.onClick('delete', item)} />}
      {props.editable &&
        <CopyToClipboard text={url} onCopy={() => global.showAlert({ msg: ST.FILES.COPY_OK })} >
          <Button className={'xs primary btn-copy'} title={ST.FILES.COPY_LINK} onClick={() => { }} />
        </CopyToClipboard>}
      <span className={cx('lbx-date')}>{moment(item.ctime).format('YYYY.MM.DD')}</span>
    </div>
  }

  // if (!loaded) return <div></div>;

  return <StyledObject className={cx('file')}>
    <div className={'file-frame'}>
      <div className={'title'}>{ST.FILES.TITLE}</div>
      <div className={cx('fb-box')}>
        {loaded && <Listbox className={cx("list")} list={list} title={'subject'} naviClass={'primary'}
          pos={page} max={max} total={total} rowid="rowid" searchkey={search.key} searchs={searchs}
          onClickPage={onClickPage} onClickSearch={onClickSearch} formatter={formatter} />}
      </div>
    </div>
  </StyledObject>
};

export default File;