export const PRIVACY = (v = '[*****]', sdate = '') => `${v}(이하 ‘회사’)은 다음과 같은 원칙에 의하여 회원의 개인정보를 수집, 이용 및 관리하고 있고, 이와 관련하여 정보통신서비스 제공자가 준수하여야 하는 대한민국의 관계법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다.

회사는 개인정보 처리방침을 통하여, 회원의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 회사가 어떠한 조치를 취하는지 알려드립니다.
회사의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.

1. 개인정보의 수집 및 이용목적
2. 수집하는 개인정보 항목 및 방법
3. 개인정보의 보유 및 이용기간
4. 개인정보의 공유 및 제공
5. 개인정보 처리위탁
6. 개인정보 파기 절차 및 방법
7. 회원 및 법정대리인의 권리와 그 행사방법
8. 개인정보 자동수집 장치의 설치/운영 및 그 거부에 관한 사항
9. 개인정보보호 관련 기술적/관리적 보호 대책
10. 개인정보보호책임자 및 담당자의 연락처
11. 개인정보관련 신고 및 분쟁조정
12. 고지의 의무

1. 개인정보의 수집 및 이용목적
회사는 다음의 목적을 위하여 개인정보를 수집 및 이용합니다.

1) 회원관리
 - 서비스 이용에 따른 본인확인 및 개인 식별
 - 불량회원의 부정이용 방지와 비인가 사용 방지 및 중복가입방지
 - 가입의사 확인, 가입 및 가입횟수 제한
 - 회원상담 진행, 회원불만 접수 및 처리, 분쟁조정을 위한 기록보존
 - 만 19세 미만 미성년자 개인정보 수집 시 법정대리인 동의 여부 확인, 법정대리인 권리행사 시 본인확인
 - 공지사항 전달

2) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
 - 서비스 및 콘텐츠 제공
 - 물품배송 또는 청구서 등 발송
 - 구매 및 요금 결제
 - 요금추심

3) 신규 서비스 개발 및 마케팅, 광고
 - 신규 서비스(제품) 개발 및 특화
 - 인구통계학적 특성에 따른 서비스 제공 및 광고 게재
 - 접속 빈도 파악
 - 서비스의 이용실적 분석
 - 회원의 서비스 이용에 대한 통계
 - 이벤트 등 광고성 정보 전달
 - 새로운 서비스 및 상품 안내

* 개인정보의 목적 외 이용
원칙적으로 회사는 회원의 개인정보를 수집 및 이용목적에 한해서만 이용하며 타인 또는 타기업/기관에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
① 정보주체로부터 별도의 동의를 받은 경우
② 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
③ 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
④ 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
⑤ 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
⑥ 업무상 연락을 위하여 회원의 정보(이름, 주소, 휴대전화, 전화번호)를 사용하는 경우

2. 수집하는 개인정보 항목 및 방법
회사는 위 제1항과 같은 ‘개인정보의 수집 및 이용목적’을 위하여 아래와 같은 원칙에 의하여 개인정보를 수집하고 있습니다.
다만, 회원의 사상, 신념, 과거의 병력 등 개인의 권리, 이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감정보는 수집하지 않습니다.

1) 수집 개인정보 항목
해당 서비스의 본질적 기능을 수행하기 위한 정보는 필수정보로서 수집하며 회원이 그 정보를 회사에 제공하지 않는 경우 서비스 이용에 제한이 가해질 수 있지만, 선택정보 즉, 보다 특화된 서비스를 제공하기 위해 추가 수집되는 정보의 경우에는 이를 입력하지 않은 경우에도 서비스 이용제한은 없습니다.
서비스 이용에 따라 다음과 같은 정보를 수집합니다.
① 회원 가입 시 수집항목
 - 로그인 아이디, 비밀번호, 이메일
② 이용요금 결제 시 수집항목
 - 담당자 이름, 전화번호, 이메일
③ 만 19세 미만 미성년자 이용계약 시 수집항목
 - 담당자 이름, 전화번호, 이메일, 법정대리인 동의서
④ PG(결제 대행사) 이용 시 수집항목
 - PG사(KG이니시스, 나이스페이) : 상호, 사업자등록번호, 대표자 이름, 사업장주소, 대표 전화번호
 - 카카오페이 : 상호, 사업자등록번호, 대표자 이름, 사업장주소, 대표 전화번호, 사업자 유형, 업태, 업종, 우편번호, 대표 이메일, 담당자 이름, 담당자 전화번호, 담당자 휴대폰 번호, 담당자 이메일
 - 페이레터 : 상호, 사업자등록번호, 대표자 이름, 사업장주소, 우편번호, 은행, 예금주, 계좌번호, 담당자 이름, 담당자 전화번호, 담당자 휴대폰 번호, 정산 담당자, 정산 담당자 전화번호, 정산 담당자 휴대폰 번호
⑤ 그 외 부가서비스 서비스 신청 시 수집항목
 - 부가서비스 신청 과정에서 개인정보 중 일부 항목을 추가 수집할 수 있으며, 수집항목은 각 부가서비스 신청 페이지 개인정보 수집 및 이용 동의 영역의 별도 항목으로 대체합니다.
⑥ 상담 및 민원사항 처리 시
 - 로그인 아이디, 이름, 이메일, 연락처, 개별 서비스 계정 아이디 및 비밀번호(테스트, 계정 연동 필요 시), 사업자등록증 등 증빙서류, 계좌정보(거래은행명, 계좌번호, 예금주 성명)
 - 기타 수집항목 : 서비스 이용기록, 접속 로그, 쿠키, 접속 IP정보, 결제 기록

2) 개인정보 수집 방법
회사는 다음과 같은 방법으로 개인정보를 수집합니다.
 - 홈페이지 회원 가입 및 이용요금 결제 시점
 - 홈페이지 내 부가서비스 별 페이지
 - 전화, 채팅, 이메일을 통한 회원상담 및 서비스 이용관리
 - 생성정보 수집툴을 통한 수집

3. 개인정보의 보유 및 이용기간
회원이 회사의 회원으로서 서비스를 계속 이용하는 동안 회원의 개인정보를 계속 보유하며 서비스 제공 등을 위해 이용합니다.
회원의 개인정보는 그 수집 및 이용 목적(설문조사, 이벤트 등 일시적인 목적을 포함)이 달성되거나 회원이 탈퇴하는 경우에 재생할 수 없는 방법으로 파기됩니다.

다만, 회원의 권리 남용, 악용 방지, 권리침해/명예훼손 분쟁 및 수사협조 등의 요청이 있었을 경우에는 이의 재발에 대비하여 회원의 탈퇴 시점로부터 1년 동안 회원의 개인정보를 보관할 수 있습니다.
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 당사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 당사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

1) 회사 내부 방침에 의한 정보보유 사유
회원탈퇴 시 개인정보 보존기간은 아래와 같습니다.
① 보존근거: 이용약관 제19조 2항에 의거하여 불량 회원의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조
② 보존기간: 회원탈퇴 후 1년

2) 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

① 계약 또는 청약철회 등에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 5년

② 대금결제 및 재화 등의 공급에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 5년

③ 회원의 불만 또는 분쟁처리에 관한 기록
보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
보존 기간: 3년

④ 본인확인에 관한 기록
보존 이유: 정보통신망 이용촉진 및 정보보호 등에 관한 법률
보존 기간: 6개월

⑤ 방문에 관한 기록
보존 이유: 정보통신망 이용촉진 및 정보보호 등에 관한 법률
보존 기간: 3개월

4. 개인정보의 공유 및 제공
회사는 회원의 개인정보를 수집 및 이용목적으로 정한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 외부에 공개/제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
1) 제3자에게 제공하거나 공유하는 것에 대하여 회원이 사전에 동의한 경우
2) 법령의 규정에 의거하거나, 수사목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

5. 개인정보 처리위탁
회사는 회원서비스 관리 및 민원사항에 대한 처리 등 원활한 업무 수행을 위하여 아래와 같이 개인정보 처리 업무를 위탁하여 운영하고 있습니다.
또한 위탁계약 시 개인정보보호의 안전을 기하기 위하여 개인정보보호 관련 법규의 준수, 개인정보에 관한 제3자 공급 금지 및 사 고시의 책임부담 등을 명확히 규정하고 있습니다.
1) 결제 및 에스크로 서비스 : KG이니시스, 나이스페이, 카카오페이, 페이레터
2) 카카오알림톡/문자메시지 발송 : 써머스플랫폼  - 비즈엠
3) 상품 배송추적 : 써머스플랫폼  - 스마트택배
동 업체가 변경될 경우, 변경된 업체 명을 공지사항 내지 개인정보처리방침 화면을 통해 고지 하겠습니다.
개인정보 처리위탁 시 개인정보 보유 및 이용기간은 회원 탈퇴 시 혹은 위탁계약 종료시까지입니다.

6. 개인정보 파기 절차 및 방법
회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
파기절차 및 방법은 다음과 같습니다.
1) 파기 및 분리보관 절차
① 회원이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB또는 테이블로 옮겨져 분리 보관되며 (종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.
② 별도 DB또는 테이블로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.

2) 파기방법
① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
② 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

3) 휴면 회원의 개인정보 파기 등
① 회사는 정보통신망법 제 29조에 근거하여 회원의 개인정보 보호를 위해 1년의 기간 동안 서비스를 이용하지 아니하는 회원의 경우 사전 통지 후 개인정보를 파기하거나 별도로 분리하여 저장 관리합니다. 단, 다른 법령에서 별도의 기간을 정하고 있는 경우에는 그 기간 동안 회원의 개인정보를 보관합니다.
② 쇼핑몰 또는 홈페이지 서비스 이용 회원은 각 서비스 요금제별 이용계약 기간이 서비스를 이용하는 기간으로 적용됩니다.
③ 체험판 회원은 최종 로그인 후 1년의 기간 동안 로그인을 하지 않을 경우 사전 통지 후 개인정보를 파기하거나 별도로 분리하여 저장 관리합니다.

7. 회원 및 법정대리인의 권리와 그 행사방법
회사는 회원 및 법정대리인의 권리를 다음과 같이 보호하고 있습니다.
1) 언제든지 자신의 개인정보를 조회하고 수정할 수 있습니다.
2) 언제든지 개인정보 제공에 관한 동의 철회(탈퇴)를 요청할 수 있습니다.
3) 만 19세 미만 미성년자 법정대리인의 법령 상의 권리를 보장합니다. (만 19세 미성년자의 개인정보에 대한 법정대리인의 열람, 정정/삭제, 개인정보 처리 정지 요구권)
4) 정확한 개인정보의 이용 및 제공을 위해 회원이 개인정보 수정 진행 시 수정이 완료될 때까지 회원의 개인정보는 이용되거나 제공되지 않습니다. 이미 제 3자에게 제공된 경우에는 지체 없이 제공받은 자에게 사실을 알려 수정이 이루어질 수 있도록 하겠습니다.
5) 권리 행사는 회원, 회원의 법정대리인 뿐만 아니라 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 회사에서 요구하는 부가서류(본인확인 증빙자료 및 위임장 등)를 제출하여야 합니다.

8. 개인정보 자동수집 장치의 설치/운영 및 그 거부에 관한 사항
회사는 회원에게 개별적으로 특화된 맞춤서비스를 제공하기 위해서 회원의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 당사의 웹사이트를 운영하는데 이용되는 서버가 사용자의 브라우저에 보내는 소량의 텍스트 파일로서 사용자의 컴퓨터 하드디스크에 저장되며, 사용자의 컴퓨터는 식별하지만 사용자를 개인적으로 식별하지는 않습니다.
1) 쿠키 등 사용목적
회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
① 회원이 설정한 서비스 이용 환경을 유지하여 편리한 인터넷 서비스 제공
② 회원의 방문 및 이용 행태 등을 분석하여 최적화된 서비스 제공

2) 쿠키 설정 거부 방법
회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 그러나 회사 홈페이지에 접속하여 서비스를 이용하기 위해서는 쿠키를 허용하여야 하며, 이를 거부할 경우 로그인이 필요한 회사의 서비스의 이용이 어려울 수 있습니다.
 - 설정방법의 예
① Internet Explorer의 경우 : 웹 브라우저 상단의 도구 메뉴 → 인터넷 옵션 → 개인정보 → 고급
② Chrome의 경우 : 웹 브라우저 우측의 설정 메뉴 → 화면 하단의 고급 설정 표시 → 개인정보 및 보안 → 사이트 설정 → 쿠키 및 사이트 데이터

9. 개인정보보호 관련 기술적/관리적 보호 대책
1) 회원의 개인정보는 기본적으로 회원의 아이디와 비밀번호에 의하여 보호되며, 회사는 회원의 개인정보를 처리함에 있어 개인정보가 유출, 변조 또는 훼손되지 않도록 안전성을 확보하기 위하여 다음과 같은 기술적/관리적 대책을 마련하고 있습니다.
① 기술적 대책
 - 회원 계정의 비밀번호는 암호화되어 저장되므로 본인만이 알 수 있습니다. 따라서 계정 로그인이 필요한 개인정보의 확인 및 수정은 해당 아이디와 비밀번호를 알고 있는 본인 만이 가능합니다.
 - 회사는 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 컴퓨터 바이러스 등에 의해 회원들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다.
 - 회사는 결제 등의 경우에 있어 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 보안장치를 채택하고 있습니다.
 - 회사는 해킹 등에 의한 정보의 유출을 방지하기 위해 침입차단시스템(방화벽)을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적인 보안성을 확보하기 위해 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
② 관리적 대책
 - 회사는 회원 본인의 비밀번호 요청 등에 의해 개인정보를 다룰 때 가능한 최선의 방법으로 본인임을 확인하고 안전하게 정보가 처리될 수 있도록 최선을 다합니다.
 - 회사는 개인정보에 대한 접근권한을 개인정보보호책임자 등 개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 처리가 불가피한 자로 제한하며, 개인정보를 처리하는 직원에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.

2) 위와 같은 회사의 노력 이외에 회원은 아이디와 비밀번호, 주민등록번호 등 개인정보가 인터넷 상에 노출되거나 타인에게 유출되지 않도록 주의하여야 합니다. 회원 본인의 부주의나 관리소홀로 아이디와 비밀번호 등 개인정보가 유출되었다면 이에 대해서 회사는 책임을 지지 않습니다.
3) 따라서, 회원의 아이디와 비밀번호는 반드시 본인만 사용하시고, 비밀번호를 자주 바꿔주시는 것이 좋으며, 비밀번호는 영문자, 숫자를 혼합하여 타인이 유추하기 어려운 번호를 사용하는 것이 좋습니다.
4) 또한 서비스의 이용을 마친 후에는 항상 로그아웃을 하여 주시고 웹 브라우저를 종료하는 것이 좋습니다. 특히, 다른 사람과 컴퓨터를 공유하거나, 공공장소에서 이용하는 경우에 개인정보의 보안을 위해서는 이와 같은 절차가 더욱 필요합니다.

10. 개인정보보호책임자 및 담당자의 연락처
1) 회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
 - 이름 : *****
 - 소속 : *****
 - 직위 : *****
 - 전화번호 : *****
 - 이메일 : *****

2) 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 회원들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

11. 개인정보관련 신고 및 분쟁조정
개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 한국인터넷진흥원(KISA) 개인정보침해신고센터로 문의하시기 바랍니다. 또한, 귀하가 개인정보침해를 통한 금전적, 정신적 피해를 입으신 경우에는 개인정보분쟁조정위원회에 피해구제를 신청하실 수 있습니다.
 - KISA 개인정보보호 (http://privacy.kisa.or.kr / (국번 없이) 118)
 - 개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)
 - 개인정보분쟁조정위원회 (kopico.go.kr / 1833-6972)
 - 대검찰청 사이버수사과 (spo.go.kr / 국번 없이 1301)
 - 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번 없이 182)

12. 고지의 의무
법령·정책 또는 보안기술의 변경에 따라 현 개인정보처리방침 내용의 추가·삭제 및 수정이 있을 시에는 개정안 적용일의 7일 전부터 회사의 홈페이지 내 고지할 것입니다.
다만, 개인정보의 수집 및 활용, 제 3자 제공 등과 같이 회원 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.

공고일자: ${sdate}, 시행일자: ${sdate}`;

export const PUBLIC = (v = '[*****]', home = '', sdate = '') => `제1장 총칙

제1조 (목적)
이 약관은 ${v}(이하 “회사”라 합니다)와 회사가 제공하는 ${v} 쇼핑몰 또는 홈페이지 호스팅 서비스를 이용하는 회원(이하 “회원”이라 합니다)간에 서비스 이용에 관한 권리•의무 및 기타 필요한 제반 사항을 규정하여, 회원과 회사의 권익을 보호하고 상호 번영에 기여함을 목적으로 합니다.

제2조 (용어의 정의)
① 이 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다.
1. "쇼핑몰 호스팅 서비스"(이하 "쇼핑몰 서비스")란 회사가 회원에게 서버공간을 제공하고 기본 또는 부가서비스를 제공하여 회원이 인터넷상에서 상품 및 서비스 등을 판매할 수 있는 쇼핑몰을 운영할 수 있도록 하는 호스팅 서비스를 말합니다.
2. "홈페이지 호스팅 서비스"(이하 "홈페지이 서비스")란 회사가 회원에게 서버공간을 제공하고 기본 또는 부가서비스를 제공하여 회원이 홈페이지를 운영할 수 있도록 하는 호스팅 서비스를 말합니다.
3. "서버"란 회원이 홈페이지를 운영할 수 있도록 구성한 관리 소프트웨어 또는 하드웨어를 말합니다.
4. "회원"이란 회사와 쇼핑몰 또는 홈페이지 호스팅 서비스 이용계약을 체결하고 서비스를 받는 자를 말합니다.
5. "아이디"란 회원의 식별과 서비스 이용을 위하여 회원에게 제공되는 문자와 숫자의 조합을 말합니다.
6. "비밀번호"란 회원의 개인정보 및 권익 보호를 위하여 회원에게 제공되는 문자와 숫자의 조합으로 회원의 아이디(ID)와 함께 회원의 신원확인에 사용됩니다.
7. "이용계약"이란 호스팅 서비스 이용과 관련하여 회사와 회원간에 체결하는 계약을 말합니다.
8. "부가서비스"란 회사가 제공하는 기본 서비스 외에 회원이 쇼핑몰 또는 홈페이지 운영을 위해 추가로 신청하는 서비스를 말합니다.
9. "체험판"이란 회원이 회사 서비스 가입 후 쇼핑몰 또는 홈페이지 서비스를 결제하지 않은 상태이거나 결제 후 환불을 진행한 계정의 상태를 말합니다.
10. "가입자"란 회원이 운영하는 쇼핑몰 또는 홈페이지에 가입한 자를 말합니다.
11. "접속자"란 회원이 운영하는 쇼핑몰 또는 홈페이지에 접속한 자를 말합니다.
12. "휴면 가입자"란 회원이 운영하는 쇼핑몰 또는 홈페이지 가입자 중 최종 로그인 일자부터 1년 동안 로그인을 하지 않는 경우, 정보가 암호화 되어 별도 보관되는 가입자를 말합니다.
13. "스팸"이란 정보통신망을 통해 가입자가 원하지 않는데도 불구하고 일방적으로 전송 또는 게시되는 영리목적의 광고성 정보를 말합니다
14. "불법스팸"이란 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반하여 전송 또는 게시되는 영리목적의 광고성 정보를 말합니다.
② 전 항에서 정한 것을 제외하고는 관계법령이나 기타 서비스 별 안내에서 정하는 바에 따릅니다.

제3조 (약관의 명시 및 변경)
① 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.
② 회사는 합리적인 사유가 발생할 경우 관련법령에 위배되지 않는 범위에서 이 약관을 개정할 수 있습니다.
③ 개정된 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 적용일자 7일 이전부터 적용일자 전일까지 고지합니다. 다만, 고객의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 30일 전에 고지하고 변경 약관 및 변경사유를 회원이 등록한 이메일으로 통지합니다.
④ 회원은 변경된 약관에 동의하지 않을 경우 이용계약 해지 및 탈퇴를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.

제4조 (약관 외 적용)
이 약관에 명시되지 아니한 사항에 대해서는 관계법령, 상관례 및 서비스 별 안내에 따릅니다.

제2장 이용계약

제5조 (서비스 종류 및 변경)
① 회사는 서비스 종류, 서비스 내용, 가격 및 기타 서비스 관련사항을 회사 홈페이지(${home})를 통하여 게시하며 회원은 회사 홈페이지를 기준으로 서비스 이용신청을 해야 합니다.
② 또한 회사는 서비스 종류의 신설 및 변경사항에 대해서도 홈페이지를 통하여 게시하며 이미 서비스 이용 중인 회원에 대한 서비스의 변경 사항은 홈페이지에 게시하거나 회원의 메일 주소를 통해서 통보하며 게시 또는 통보 후 7일 이내에 이의를 제기하지 않으면 회원이 변경사항에 대해서 동의한 것으로 간주합니다.

제6조 (이용신청 및 약관동의)
① 이용신청은 회원이 이 약관에 동의하고 회사가 정한 신청양식을 인터넷에서 작성 후 신청하는 것으로 이루어집니다. 이 이용약관에 대한 동의는 이용신청 당시 회사 홈페이지의 ‘동의함’ 버튼을 누름으로써 의사표시를 합니다.
② 회원은 이용신청 시 실명, 실제정보를 입력하여야 하며, 이를 위반한 회원은 법적인 보호를 받을 수 없으며 또한 서비스 이용에 제한을 받을 수 있습니다.
③ 회원이 이용신청 시 제공한 개인정보는 관계법령 및 회사의 개인정보보호정책에 따라 보호를 받습니다.

제7조 (이용신청 승낙)
① 회사는 회원이 제6조에서 정한 사항을 정확히 기재하여 이용신청을 하였을 때 특별한 사정이 없는 한 접수 순서에 따라서 이용신청을 승낙합니다.
② 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
1. 서비스 관련 설비의 용량이 부족한 경우
2. 기술상 장애사유가 있는 경우
3. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
4. 등록내용에 허위, 기재누락, 오기가 있는 경우
5. 만 14세 미만 아동
6. 기타 회사가 필요하다고 인정되는 경우

제8조 (이용계약의 성립 및 서비스 개통)
① 이용계약은 회원이 홈페이지에서 서비스를 신청하고 제20조제2항의 이용요금을 결제하면 회사가 이를 승낙하여 성립합니다.
② 회원은 이용계약 시 실명, 실제정보를 입력하여야 하며, 이를 위반한 회원은 법적인 보호를 받을 수 없으며 또한 서비스 이용에 제한을 받을 수 있습니다.
③ 이용계약 시 카드결제는 결제 즉시, 무통장 입금은 회원이 이용요금 입금 후 입금 완료 이메일을 회사 고객센터로 발송한 일자 기준 3영업일 이내 서비스가 개통됩니다.
④ 홈페이지 서비스 1개월 정기결제, 홈페이지 기간제(6개월, 1년, 2년) 요금제의 경우 서비스 개통일이 다음 서비스 이용요금 결제 기준일자가 됩니다.
⑤ 제3항에 따라 서비스를 개통하지 못한 경우 그 사유와 개통일자를 다시 정하여 이메일, 전화 등의 방법으로 회원에게 통보합니다.
⑥ 만 19세 미만 미성년자가 이용계약을 하려면 반드시 이용계약에 관한 법정대리인(부모)의 동의를 받아야하며, 법정대리인의 이용계약에 관한 동의 의사표시는 회사에서 정한 필요 서류를 이메일로 회사 고객센터에 제출하는 것으로 확인합니다. 만약, 만 19세 미성년자인 회원이 법정대리인의 동의 없이 허위로 계약하는 경우 본인 또는 법정대리인이 이를 취소할 수 있습니다.

제9조 (서버환경 지원 및 서비스 이전)
① 회사는 회원이 정상적으로 쇼핑몰 또는 홈페이지를 운영할 수 있도록 최적의 서버 환경을 지원해야 하지만 회원이 특별한 서버환경을 요청하는 경우에는 회사가 응하지 아니 합니다.
② 호스팅 서비스를 타사로 이전하는 경우 아래 각호를 유의해야 합니다.
1. 네임서버의 캐시(cache) 등으로 인하여 회사측의 과실 없이 접속이 1~2일 정도 중단되더라도 이는 인터넷 환경의 특성이므로 회사는 이에 대한 책임을 지지 아니 합니다. 또한 이전과 관련하여 기존에 사용하는 파일의 이전과 권한 설정작업은 회원이 직접하여야 합니다.
2. 회원의 쇼핑몰 혹은 홈페이지의 휴면 가입자를 포함한 모든 가입자 정보는 회원의 쇼핑몰 혹은 홈페이지 이용약관과 개인정보처리방침에 호스팅 이전 시 정보 제공 목적이 기재되고 가입자 동의를 받은 경우에만, 회사는 암호화 해제된 휴면 가입자 정보를 포함한 모든 가입자 정보를 회원에게 제공할 수 있습니다.

제3장 의무

제10조 (회사의 의무)
① 회사는 회원에게 본 약관에 명시된 서비스를 안정적이고 지속적으로 제공해야 합니다.
② 회사는 서비스 운영에 지장이 되는 장애가 발생했을 경우 이를 즉시 수리 또는 복구해야 하며 안정적인 서비스 운영을 위해 최선을 다해야 합니다.
③ 회사는 원활한 서비스 신청 및 운영을 위해 회원의 개인정보를 수집하여 보관하며 본인의 허락 없이는 제3자에게 이를 제공할 수 없습니다. 다만 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 정보통신윤리위원회의 요청이 있는 경우, 회사의 이용요금을 체납하여 신용정보사업자 또는 신용정보집중기관에 제공하는 경우는 예외로 합니다.
④ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약 관련 절차 및 내용 등에 있어 회원에게 편의를 제공하도록 노력합니다.
⑤ 회사는 회원이 쇼핑몰 또는 홈페이지 내 판매하는 상품과 이미지, 관련 정보 등에 관하여 어떠한 책임도 부담하지 아니합니다.
⑥ 회사는 서비스 이용에 따른 회원의 매출, 회원 쇼핑몰 또는 홈페이지 가입자 수 및 접속자 수 등의 관련 정보를 수집하여 통계자료 등으로 활용할 수 있으며, 이를 회원의 서비스 이용에 지원하거나 회사의 효율적인 서비스 제공에 사용할 수 있습니다.
⑦ 회사는 시스템 장애 및 오류의 복구, 기술적/관리적으로 긴급을 요하는 경우 회원의 관리자 어드민(admin) 접근하여 해당 사안을 조치할 수 있습니다.
⑧ 회사는 회원이 서비스를 이용함에 있어 등록한 상품 및 그 정보 등이 불법정보에 해당하여 방송통신심의위원회 등 관련 기관으로부터 일정의 행정조치를 전달을 받은 경우 그에 따른 조치를 이행할 수 있습니다.
⑨ 회사는 전자상거래를 함에 있어 회원이 기재한 사업자정보가 허위이거나 사기 쇼핑몰 또는 홈페이지로 판단되어 서울시 전자상거래센터로부터 서비스의 정지요청이 있는 경우 관련 안내페이지의 게재 및 서비스의 이용을 제한할 수 있습니다.
⑩ 회사는 회원과 회사 또는 제 3자간 법적 분쟁이 발생하여 법적 절차의 진행에 따라 서비스제공의 중단이 필요한 경우 임시적으로 서비스의 이용을 제한할 수 있습니다.
⑪ 회사는 회원이 불법스팸을 전송한 사실을 확인한 경우, 한국정보보호진흥원 불법스팸대응센터에 관련 자료를 첨부하여 신고할 수 있습니다.
⑫ 회사는 회원이 쇼핑몰 또는 홈페이지를 범죄(불법, 사기 등) 도구로 사용한다고 판단될 경우 서울시 전자상거래센터, 경찰청 사이버 안전국, 방송통신심의위원회 등 기관에 신고할 수 있습니다.

제11조 (회원의 의무)
① 회원은 서비스 계약에 필요한 개인신상정보 등을 회사에 허위로 제공하여서는 안 되며, 정보 변경시 지체없이 회사에 통보하여 갱신하여야 합니다.
② 회원은 회사가 정한 서비스 이용요금을 지정된 일자에 결제할 의무가 있습니다.
③ 회원은 회사의 서비스 제공 목적 외의 용도로 서비스를 이용해서는 안되며 회사의 동의 없이 회사가 제공하는 서비스를 제3자에게 제공, 임대하여서는 안 됩니다.
④ 회원은 회사와 타 회원의 서비스 운영에 방해가 되는 행위를 하지 않아야 합니다.
⑤ 회원은 회사로부터 제공받은 계정의 모든 데이터의 보전, 유지, 관리의 의무가 있으며, 이에 대한 백업 본을 만들어 직접 별도로 보관하여야 합니다.
⑥ 회원은 자신의 각종 아이디(ID)와 비밀번호를 제3자에게 누출할 수 없으며, 누출로 발생하는 손해에 대한 책임은 회원에게 있습니다.
⑦ 회원은 자신의 쇼핑몰 또는 홈페이지를 통하여 제공하는 정보의 저작권에 대하여 책임을 지고 불법적인 행위를 하지 않아야 합니다.
⑧ 회원은 서비스 신청시 회사에 제공한 정보의 변경내용이 발생했을 경우 해당 절차를 거쳐 빠른 시일 내에 수정, 보완해야 하며, 그로 인하여 발생하는 문제에 대한 책임은 회원에게 있습니다.
⑨ 회원은 회사로부터 제공받은 설비, 계정 및 소프트웨어를 훼손하거나 장애를 초래한 경우에는 그 보충, 수선 또는 기타 공사에 소요되는 비용을 부담하여야 합니다.
⑩ 회원은 자신의 판단 하에 쇼핑몰 또는 홈페이지 운영 전반에 관한 사항을 결정하며 쇼핑몰 또는 홈페이지 운영에 관련한 일체의 책임을 부담합니다.
⑪ 회원은 서비스를 이용하여 국내 및 다양한 국가의 소비자를 대상으로 전자상거래를 함에 있어 대상국가의 전자상거래 및 정보통신이용 등에 관한 관련법령을 준수합니다.
⑫ 회원은 쇼핑몰 또는 홈페이지 내 상품 및 이미지 등이 청소년유해매체물에 해당하는 경우 관련법에 명시된 규정에 따라 청소년유해매체물 표시사항 및 성인 인증 등의 의무사항을 준수합니다.
⑬ 회원은 공정거래위원회의 ‘전자상거래 등에서의 상품 등의 정보제공에 관한 고시’ 등에 따라 자신이 판매하는 상품에 대한 상세 정보를 명확하게 제공합니다.
⑭ 회원은 쇼핑몰 또는 홈페이지의 게시판 등에 접속자가 게재한 불법, 부당한 광고성 문구 등을 삭제, 관리합니다.
⑮ 회원은 적법한 절차에 따라 소비자의 개인정보를 수집하며 개인정보와 관련한 법령에 따라 기술적, 관리적 보호조치를 수행합니다. 또한 그에 따른 개인정보처리방침을 작성하여 홈페이지 초기화면에 게시하고 수집한 개인정보를 자신의 책임 하에 운영합니다.
⑯ 회원은 공공질서, 미풍양속, 기타 사회질서를 저해할 수 있는 일련의 상품 또는 이미지 등을 판매하거나 게재하지 아니합니다.
⑰ 회원은 서비스이용에 있어 광고집행, 이벤트, 프로모션 등 단기간에 많은 접속자가 예상되는 계획이 예정되어 있는 경우 해당 사실은 회사에 사전 통지하여 상호간 대응방안을 마련하여야 하며, 이를 사전에 공유하지 아니하거나 상호간 마련한 대책을 초과하여 장애가 발생한 경우 회사는 그에 따른 회원의 손해에 대하여 배상책임을 부담하지 아니합니다.
⑱ 회원은 정보통신망이용촉진및정보보호등에관한법률의 광고성 정보 전송 시 의무사항 및 회사의 이용약관을 준수하여야 합니다.
⑲ 회원은 스팸 또는 불법스팸을 전송함으로써 발생하는 모든 민·형사상의 책임을 부담합니다.

제12조 (보안 시스템 관리 및 의무)
① 회사는 보안상 심각하고 시급을 요하는 프로그램 결함이나 장애 혹은 그에 준하는 문제발생 시 회원관련 인증정보 및 해당 부분을 일괄적으로 패치 할 수 있습니다.
② 회원은 회사의 서비스를 이용하여 회사 또는 다른 회원의 보안을 위협하는 다음 각 호를 하지 아니합니다.
1. 컴퓨터 바이러스 등 악성프로그램 등 유포행위
2. 회원 정보시스템 및 이외의 시스템을 대상으로 한 취약점 스캐닝, 불법 침입 행위
3. 대량의 트래픽(traffic)을 유발시켜 정상적인 서비스를 방해하는 행위
4. 기타 회사의 업무를 방해하는 각종 침해 행위
③ 회사는 본 조에 의한 보안관련 조치 내용을 조치 후 통지하거나 통지를 제외할 수 있습니다.
④ 회사는 본 조와 관련한 조치에 있어 회원에게 손해가 발생하지 아니하도록 가능한 최선을 다하여 조치를 진행합니다. 그러나 부득이 다수의 회원의 안전을 위하여 긴급한 조치가 필요한 경우 일부 회원의 손해에 대한 배상책임을 부담하지 아니하고 조치를 이행할 수 있습니다.

제13조 (저작권의 귀속 및 이용제한)
① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다. 회원은 회사 및 제3자의 지적재산권을 침해해서는 안 됩니다.
② 회원은 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
③ 회원이 서비스를 이용하여 구축한 쇼핑몰 또는 홈페이지 상의 저작물에 대한 관리 책임은 회원 본인에게 있으며, 제3자의 저작권 침해 등에 대한 책임 또한 회원에게 있습니다.
④ 회원이 서비스 내에 등록한 게시물의 저작권은 등록한 회원에게 귀속합니다. 단, 회사는 서비스의 운영 및 홍보, 배포 등의 목적으로 회원의 별도 동의 없이 회원이 등록한 게시물을 무상으로 사용할 수 있습니다.
⑤회사는 본 조 제4항 이외의 목적으로 회원의 게시물을 사용하고자 하는 경우 사전에 회원의 동의를 얻어 사용합니다.
⑥ "회원"의 게시물이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"와 "회원"은 관련법에 따라 조치를 취하여야 합니다.
⑦"회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.

제4장 이용

제14조 (서비스의 이용시간)
서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 서비스 제공을 원칙으로 하나 정기점검이나 시스템의 업그레이드가 필요한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 일시중단은 회사 홈페이지를 통해 사전에 공지합니다.

제15조 (서비스의 중단)
① 회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현제 제공되는 서비스를 완전히 중단할 수 있습니다.
② 회사는 다음 각 호의 경우에 서비스 제공을 일시 중단할 수 있습니다. 서비스 중단이 발생할 경우 회사는 회원에게 이러한 사실을 이메일 등의 방법으로 사전 또는 사후에 통보하여야 하며, 회사가 운영하는 홈페이지를 통하여 개시하는 것으로 대신할 수 있습니다.
1. 서버 설비의 보수 혹은 공사상 불가피한 경우
2. 전용회선 경로상의 장애가 발생한 경우
3. 천재지변, 국가비상사태 등의 사유로 인하여 기계적인 작동 불능이 발생한 경우
③ 회사는 회사가 통제할 수 없는 사유로 인한 서비스중단의 경우(시스템관리자의 고의, 과실 없는 디스크장애, 시스템다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의, 과실로 인한 시스템 중지 등의 경우에는 통지하지 않습니다.

제5장 이용제한

제16조 (이용제한)
① 회사는 회원의 서비스 이용 내용이 다음 각호에 해당할 경우 서비스 이용을 일시적으로 제한할 수 있습니다.
1. 제11조 규정에 의한 회원의 의무를 중대하게 위반한 경우
2. 타인의 정보 유출, 비밀번호 도용 등의 부정한 행위를 한 경우
3. 관련 법령에 위반되는 행위를 하는 경우
4. 음란하고 선정적인 사진이나 글 등을 게재하거나 공공질서나 미풍양속에 저해되는 행위를 한 경우
5. 회사의 승낙 없이 제3자에게 게시판, 접속통계, 카운터, 포워딩, 리디렉터리 서비스 등을 하는 경우
6. 고의 또는 중대한 과실로 인한 프로그램의 오작동 등으로 서버의 중앙처리장치(CPU)와 메모리 점유율이 10%를 초과하는 경우
7. 회사가 제공하는 서비스를 직접 혹은 간접으로 이용하는 장비의 이용에 중대한 지장을 초래한 경우
8. 스팸 메일 발송, 바이러스 유포, 기타 네트워크 장애를 유발하는 행위를 한 경우
9. 회원이 서비스 기간 만료일까지 다음 서비스 이용요금을 추가 결제하지 않은 경우
10. 회원의 쇼핑몰 또는 홈페이지의 운영 문제(불법, 사기 등)가 회사에 신고되고 사실로 확인된 경우
11. 회원이 회사의 허락 없이 기본 포트(telnet, FTP, web 등) 이외 다른 포트(시스템에서 각각의 서비스를 구분하기 위해 사용되는 번호)를 점유하는 임의의 데몬(시스템에서 대기하면서 사용자의 요청에 대해 미리 지정된 규칙에 따라 응답 및 통신하기 위해 사용되는 프로그램)을 사용한 경우
12. 서버의 회선 대역폭을 고의 또는 중대한 과실로 과다 점유하여 다른 회원의 서비스에 불편을 주는 경우
13. 정보통신부 또는 한국정보보호진흥원이 불법스팸 전송사실을 확인하여 이용정지를 요청하는 경우
14. 대량으로 스팸을 전송하여 시스템 장애를 야기했거나 야기할 우려가 있는 경우
15. 스팸릴레이로 이용되거나 웜에 감염되어 스팸을 전송한 경우
16. 광고를 수신한 자가 수신거부를 요청하였으나 지속적으로 재전송한 경우
17. 정보통신망법에 의거 수신자의 사전 동의 없는 문자를 발송한 경우 등
18. 이 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위
② 1항에 의거한 이용제한 시 이용제한에 대한 별도 배상은 없습니다.

제17조 (이용제한 및 해제 절차)
① 회사는 제16조 1항에 의거하여 이용제한을 할 경우에는 해당 회원 또는 대리인에게 그 사유 및 기간 등을 고지한 후에 서비스 이용을 제한할 수 있으나 해당회원의 오작동 또는 과다사용으로 인하여 시스템 운영에 중대한 장애를 초래하거나 사안이 긴급한 경우에는 먼저 해당 회원에 대한 서비스를 정지한 후 이를 사후에 통보할 수 있습니다.
② 회사는 전항에 의하여 서비스가 제한된 회원이 그 위반행위를 해소할 경우에는 관련 절차에 따라 서비스 이용제한을 즉시 해제합니다.

제6장 계약변경, 이용계약 해지 및 탈퇴

제18조 (계약사항의 변경)
회원 및 사실상 요금납입의 책임을 지기로 한 자의 상호 및 성명 또는 이메일, 연락처의 변경의 경우 즉시 회사 홈페이지에서 수정해야 하며 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제19조 (계약 갱신)
① 홈페이지 서비스 중 1개월 정기결제 요금제는 회원 또는 회사가 이용기간 만료 전일까지 이용계약을 갱신하지 않는다는 의사표시가 없으면 동일한 조건으로 이용계약기간이 연장되는 것으로 봅니다. 다만, 이용기간 만료일에 정기결제가 이뤄지지 않을 시 만료일 다음 날 서비스 이용이 중지됩니다.
② 홈페이지 서비스 중 기간제 요금제(6개월, 1년, 2년)는 계약기간 만료일 이전 회원 측에서 이용계약을 하지 않을 시 만료일 다음 날 서비스 이용이 중지됩니다.
③ 쇼핑몰 서비스 요금제(PG, 카카오프리미엄, 해외 쇼핑몰)는 계약 갱신이 없는 평생 이용 요금제입니다.

제20조 (이용계약 해지 및 탈퇴)
① 회원이 이용계약을 해지하고자 할 경우 제 22조에 의거하여 회원은 회사 고객센터에 해지 요청 이메일을 접수해야하며 회사는 이용요금을 환불합니다.
② 회사는 회원이 다음 각호에 해당할 경우에 사전통지 없이 이용계약을 해지할 수 있으며 다음 각호에 의해 해지된 회원이 다시 이용신청을 하는 경우 이용승낙을 거부하거나 사후에 이용계약을 해지할 수 있습니다.
1. 가입 신청 및 요금제 계약 시 기재한 내용이 허위로 판명된 경우
2. 서비스 운영을 고의로 방해하여 회사에 막대한 손해를 입힌 경우
3. 제16조에 의하여 이용 제한된 후 그 이용제한 기간 내에 이용제한 사유를 해소하지 않는 경우
4. 제13조에 위반하는 경우
5. 지속적으로 불법스팸을 전송하여 정보통신부 또는 한국정보보호진흥원에서 계약해지를 요청하는 경우
③ 2항에 따른 이용계약의 해지 시 회사는 제22조에 의거하여 이용요금을 환불합니다.
④ 회원은 탈퇴하고자 할 경우 회사 홈페이지에서 직접 탈퇴를 진행합니다.
⑤ 서비스 이용요금을 결제하지 않은 체험판 회원은 즉시 가능하나 서비스 이용계약 중인 회원은 서비스 이용계약 해지 후 탈퇴가 가능합니다.

제7장 이용요금

제21조 (이용요금 일반원칙)
① 서비스 이용요금의 세부 내역 및 그 변경사항은 제5조에 따라 회사의 홈페이지에 명기합니다.
② 회원이 납부하여야 하는 서비스의 이용요금 종류는 다음 각호와 같습니다.
1. "홈페이지 서비스 1개월 정기 결제 요금제"란 매월 정기 결제되는 홈페이지 서비스 요금을 말합니다.
2. "홈페이지 서비스 기간제 요금제"란 6개월, 1년, 2년 기간에 대한 홈페이지 서비스 요금을 말합니다.
3. "쇼핑몰 서비스(PG) 요금제"란 계정 당 최초 1회 일괄 결제 혹은 8회 분할 납부 지급하는 쇼핑몰 서비스(PG) 요금을 말합니다.
4. "쇼핑몰 서비스(카카오 프리미엄, 해외 쇼핑몰) 요금제"란 계정 당 최초 1회 일괄 결제하는 쇼핑몰 서비스(카카오 프리미엄, 해외 쇼핑몰) 요금을 말합니다.
5. "부가서비스 이용요금"이란 기본 서비스 외 회원이 별도의 신청하는 부가서비스에 대한 요금을 말합니다.
③ 이용요금의 납부는 선납이 원칙이나 회사의 정책 혹은 서비스 종류에 따라 후납으로 할 수 있습니다.
④ 회원은 정해진 납부일자에 이용요금을 결제해야 합니다. 이를 결제하지 못할 경우에는 제15조에 따른 서비스 이용제한 또는 제19조제2항에 의거 이용계약이 해지될 수 있습니다. 해지된 후 이에 대한 재사용을 원하는 경우 회원은 다시 결제를 하면 됩니다.
⑤ 이용계약기간 중 이용요금이 변경되더라도 변경 현재 진행 중인 계약기간에 대해서는 특별한 사정이 없는 한 소급 적용되지 않습니다.

제22조 (이용요금 결제)
① 서비스 이용요금의 결제 수단은 카드와 무통장 입금이며 결제 방법은 다음 각호와 같습니다.
1. 카드 결제는 회사 홈페이지 로그인 후 직접 결제를 진행합니다.
2. 무통장 입금은 회원이 이메일로 회사 고객센터에 무통장 입금 진행 요청을 함으로서 진행되며, 회원은 무통장 입금 후 입금 완료 이메일을 회사 고객센터로 발신해야 합니다.
② 서비스별 이용요금의 결제는 다음 각호와 같습니다.
1. 홈페이지 서비스 1개월 정기 결제 요금제는 서비스를 최초로 결제한 일자부터 30일 마다 결제 등록 카드로 결제되고 무통장 입금 결제는 불가합니다.
2. 홈페이지 서비스 기간제 요금제(6개월, 1년, 2년)는 카드, 무통장 입금 결제가 가능합니다.
3. 쇼핑몰 서비스 요금제(PG만 가입)는 일괄 결제 시 카드, 무통장 입금 결제가 가능하며 8회 분할 납부 시에는 카드 결제만 가능합니다.
4. 쇼핑몰 서비스 요금제(카카오프리미엄, 해외 쇼핑몰)는 카드, 무통장 입금 결제가 가능합니다.
5. 부가서비스 요금 결제는 각 서비스 별 안내에 따릅니다.

제23조 (이용요금 환불)
① 회원이 서비스 이용 중 서비스를 변경하거나 해지하는 경우에는 회사 고객센터로 환불 요청 이메일을 접수해야 합니다.
② 회원이 회사 고객센터로 환불 요청 이메일을 접수한 일자가 환불 기준 일자입니다. 회사는 회원의 환불 요청 이메일을 확인 후 환불 안내 이메일을 회신합니다.
③ 회원은 회사가 환불 안내 이메일을 회신한 일자를 기준으로 5영업일 이내 최종 환불 요청 이메일을 회신해야 합니다. 5영업일 이내 회신하지 않을 경우 환불 요청 건이 취소되며 다시 환불 요청 이메일을 접수해야 합니다.
④ 회사는 회원이 3항의 최종 환불 요청 이메일을 회신한 일자 기준 3영업일 이내 환불합니다.
⑤ 서비스별 이용요금 환불은 다음 각 호와 같이 합니다.
1. 홈페이지 서비스 요금제는 결제 후 48시간 이내 회사 고객센터 이메일로 환불 접수 후 환불 절차를 완료할 경우 전액 환불 가능합니다.
2. 홈페이지 서비스 요금제는 결제 후 48시간 이후 회사 고객센터 이메일로 환불 환불 접수 후 환불 절차를 완료할 경우 선납 금액에서 사용 금액을 제한 잔액을 환불합니다
* 선납 금액  - 사용 금액(정가 기준 일할금액 x 사용 일수) = 잔액
* 홈페이지 1년, 2년 요금제의 할인율 적용은 계약 기간을 정상적인 만기 형태로 이용한다는 전제로 제공된 것이며 중도 환불 시 할인율 적용 금액이 아닌 정가 금액 요금 기준으로 금액을 계산합니다.
3. 쇼핑몰 서비스 요금제(PG만 가입(일괄 결제), 카카오 프리미엄, 해외 쇼핑몰)는 결제 후 14일 이내 회사 고객센터 이메일로 환불 접수 후 환불 절차를 완료할 경우 전액 환불 가능합니다.
4. 쇼핑몰 서비스 요금제(PG만 가입(일괄 결제), 카카오 프리미엄, 해외 쇼핑몰)는 결제 후 14일 이후 환불 불가합니다. 단, PG사에서 업종으로 인한 가입을 거부한 경우 결제 후 60일 이내에만 환불 가능합니다.
5. 쇼핑몰 서비스 요금제(PG만 가입(8회 분할 납부)는 최초 1회차 납부 후 14일 이내 회사 고객센터 이메일로 환불 접수 후 환불 절차를 완료할 경우 전액 환불 가능합니다.
6. 쇼핑몰 서비스 요금제(PG만 가입(8회 분할 납부)는 최초 1회차 납부 후 14일 이후에는 모든 회차 납부 금액에 대한 환불이 불가합니다.
7. 부가서비스 이용요금 환불은 각 서비스 별 안내에 따릅니다.

제8장 기타

제24조 (손해배상의 책임 및 범위)
① 회사와 회원은 자신의 귀책사유로 이 약관을 위반하여 상대방에게 손해가 발생한 경우 그 손해에 대하여 배상책임을 부담합니다.
② 다만, 서비스 중지 및 장애에 따른 손해배상은 잦은 서비스 업데이트와 인터넷 환경의 변화 등으로 완벽한 서비스 안정성을 보장이 불가하기 때문에 서비스 요금제 기반으로 아래와 같이 배상합니다.
③ 회사의 귀책사유로 회원이 서비스를 이용하지 못하는 경우에 회사는 회원이 그 사실을 회사에 통보한 때(또는 그 전에 회사가 그 사실을 알았거나 알 수 있게 된 때)로부터 계속 3시간 이상 서비스 중지 또는 장애가 발생한 경우 그 손해배상액은 서비스 중지 또는 장애시간에 대하여 최근 3개월(3개월 미만인 경우 해당 기간 적용)의 1일 평균요금에 서비스제공 중지 또는 장애시간을 24로 나눈 수를 곱하여 산출한 금액의 5배로 합니다. 다만 단수가 1시간 미만인 경우에는 1시간으로 합니다.
④ 회사는 제휴서비스 등 회사가 직접 운영하지 아니하는 서비스에 대해서는 그로 인한 회원의 손해에 대하여 배상책임을 부담하지 아니합니다.

제25조 (면책)
① 회사는 회원이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스로부터 수반되는 잠재가치 및 서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 없습니다.
② 회사는 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임을 지지 않습니다.
③ 회사의 책임은 물리적인 호스팅 서비스 사용에 한하며, 특히 회원이 게시 또는 전송하거나 전송 받은 자료의 내용 및 가치에 대해서는 책임이 없습니다.
④ 회사는 회원 상호간 또는 이용 회원과 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우 해당 거래로 인해 발생하는 손실에 대해 책임을 지지 않습니다.
⑤ 불법적인 침입으로 인해 발생한 회원의 설비 및 정보에 대한 손해는 배상하지 아니 합니다.
⑥ 회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.
⑦ 회사는 회원과 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
⑧ 회사는 회원이 제작한 쇼핑몰 또는 홈페이지를 감시하지 않으며, 본 서비스를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 행위를 하여 발생하는 모든 법적인 책임은 회원에게 있습니다.
⑨ 회사는 제29조에 따른 손해가 다음 각 호의 1에 의해 발생한 경우에는 책임을 지지 아니 합니다.
1. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 불가항력적인 경우
2. 회원의 고의 또는 과실로 인하여 발생한 경우
3. 전기통신사업법에 의한 회사 외 타 기간통신사업자가 제공하는 전기통신서비스 장애 및 설비부족으로 인한 경우
4. 전기통신서비스의 특성상 불가피한 사유로 서비스 제공이 불가능한 경우

제26조 (양도양수)
① 서비스의 이용권한 및 기타 이용계약상의 지위를 타인에게 양도할 수 있으나 양도양수 간 발생하는 문제는 모두 양도자, 양수자 간 책임이며 회사는 책임을 지지 않습니다.
② 양도양수 시 '결제정보 결제 담당자'와 '법적 필수 정보'를 최신의 정보로 변경처리해야 하며 이용계약을 승계한 회원(양수자)은 기존의 고객과 동일한 지위에서 본 약관 및 기존 회원이 회사와 체결한 일체의 권리 및 의무를 가집니다.

제27조 (관할법인)
이 약관 및 서비스의 이용과 관련된 분쟁에 관한 소송은 회사의 본사 소재지를 관할하는 법원에 제기합니다.

공고일자: ${sdate}, 시행일자: ${sdate}`