import * as OBJ from 'object/src/Config';

export const EID = OBJ.EID;
export const KEY = OBJ.KEY;
export const CODE = OBJ.CODE;
export const CONT_TYPE = OBJ.CONT_TYPE;
export const SCREEN = OBJ.SCREEN;
export const STAT = OBJ.STAT;
export const AUTH = OBJ.AUTH;

export const SIZE = {
  full: { label: 'full', width: '100%' },
  xxl: { label: 'xxl', width: '1600px' },
  xl: { label: 'xl', width: '1400px' },
  lg: { label: 'lg', width: '1280px' },
  md: { label: 'md', width: '1024px' },
  sm: { label: 'sm', width: '800px' },
  xs: { label: 'xs', width: '600px' },
  xxs: { label: 'xxs', width: '400px' },
  none: { label: 'none', width: 'auto' },
}

export const ERR = {
  ER_DUP: 'ER_DUP_ENTRY',
  STR: {
    ER_DUP_LOGIN_ID: '중복된 아이디 입니다.',
  },
}

export const OPTAG = {
  BOX: "box",
  LABEL: "label",
  DESC: "desc",
  FRAME: 'frame',
};

// export const SIGN_T = {
//   'ka': '카카오',
//   'fa': '페이스북',
//   'na': '네이버',
// }

export const SIGN_T = {
  'ka': '카카오 등록',
  'fa': '페이스북 등록',
  'na': '네이버 등록',
}

// export const ODR_STAT = {
//   R: '입금대기중',
//   S: '주문/결제완료',
//   T: '배송준비중',
//   F: '배송완료',
// }

export const EVENT = {
  CLICKED: 'clicked',
}

const ROOT = {
  PAGE: '/page',
  ADMIN: '/admin',
  API: {
    ROOT: '/api',
    PAGE: '/api/page',
    ADMIN: '/api/admin',
  }
}

export const URL = {
  GMAIL: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp&hl=ko',
  DAUMMAP: (addr = '') => `https://map.kakao.com/?map_hybrid=true&q=${addr}`,
  SOCKPORT: '9100',

  ROOT: '/',
  HOME: '/',

  LOGOUT: '/logout',

  PASSWORD: '/password',
  DAILYLIST: '/daily',
  ICONS: '/icons',
  RULE: '/rule',
  BOARD: '/board',
  QNA: '/qna',
  FILE: '/file',
  PRIVACY: '/privacy',
  PUBLIC: '/public',
  ABOUT: '/about',
  PREVIEW: '/preview',

  ADMIN: {
    ROOT: `/admin`,
    LOGIN: `/adminlogin`,
    MAIN: `${ROOT.ADMIN}/menu`,
    MENU: `${ROOT.ADMIN}/menu`,
    PAGE: `${ROOT.ADMIN}/page`,
    ABOUT: `${ROOT.ADMIN}/about`,
    BOARD: `${ROOT.ADMIN}/board`,
    WEBSET: `${ROOT.ADMIN}/webset`,
    SETTING: `${ROOT.ADMIN}/setting`,
    MANAGEMENT: `${ROOT.ADMIN}/mgmt`,
    MEMBER: `${ROOT.ADMIN}/member`,
    GUIDE: `${ROOT.ADMIN}/guide`,
    QNA: `${ROOT.ADMIN}/qna`,
    PRODQNA: `${ROOT.ADMIN}/prodqna`,
    SYSINIT: `${ROOT.ADMIN}/sysinit`,
    FILE: `${ROOT.ADMIN}/file`,
  },

  API: {
    LOGOUT: `${ROOT.API.ROOT}/logout`,
    APP: `${ROOT.API.ROOT}/app`,
    APPINFO: `${ROOT.API.ROOT}/appinfo`,
    MENU: `${ROOT.API.ROOT}/menu`,
    BOARD: `${ROOT.API.ROOT}/board`,
    QNA: `${ROOT.API.ROOT}/qna`,
    QNA_REQ: `${ROOT.API.ROOT}/qna/req`,
    QNA_CHECK: `${ROOT.API.ROOT}/qna/check`,
    PRIVACY: `${ROOT.API.ROOT}/privacy`,
    TOKEN: `${ROOT.API.ROOT}/token`,
    PAGE: `${ROOT.API.ROOT}/page`,
    FRAME: `${ROOT.API.ROOT}/frame`,
    PROD_QNA: `${ROOT.API.ROOT}/prod/qna`,
    FILE: `${ROOT.API.ROOT}/file`,
    // UPLOAD_IMAGE: `${ROOT.API.ADMIN}/upload/image`,

    ADMIN: {
      LOGOUT: `${ROOT.API.ADMIN}/logout`,
      STATE: `${ROOT.API.ADMIN}/state`,
      MAIN: `${ROOT.API.ADMIN}/main`,
      MEMBER: `${ROOT.API.ADMIN}/member`,
      MANAGER: `${ROOT.API.ADMIN}/manager`,
      MENU: `${ROOT.API.ADMIN}/menu`,
      BOARD: `${ROOT.API.ADMIN}/board`,
      ADMINS: `${ROOT.API.ADMIN}/admins`,
      FILE: `${ROOT.API.ADMIN}/file`,
      PAGE: `${ROOT.API.ADMIN}/page`,
      PAGE_MAKE: `${ROOT.API.ADMIN}/page/make`,
      PAGE_BG: `${ROOT.API.ADMIN}/page/bg`,
      PAGE_LINK: `${ROOT.API.ADMIN}/page/link`,
      PAGE_IMAGES: `${ROOT.API.ADMIN}/page/images`,
      PAGE_IMAGELINK: `${ROOT.API.ADMIN}/page/imagelink`,
      PAGE_JSON: `${ROOT.API.ADMIN}/page/json`,
      PAGE_OPTS: `${ROOT.API.ADMIN}/page/opts`,
      PAGE_ODR: `${ROOT.API.ADMIN}/page/odr`,
      PAGE_MENU: `${ROOT.API.ADMIN}/page/menu`,
      PAGE_FRAMEBG: `${ROOT.API.ADMIN}/page/framebg`,
      ADMINS_PASS: `${ROOT.API.ADMIN}/admins/pass`,
      MGMT_SYS: `${ROOT.API.ADMIN}/mgmt/sys`,
      MGMT_WEBSET: `${ROOT.API.ADMIN}/mgmt/webset`,
      MGMT_SEARCH: `${ROOT.API.ADMIN}/mgmt/search`,
      MGMT_LOGS: `${ROOT.API.ADMIN}/mgmt/logs`,
      MGMT_TABLE: `${ROOT.API.ADMIN}/mgmt/table`,
      MGMT_CONN_TEST: `${ROOT.API.ADMIN}/mgmt/conn/test`,
      SETTING: `${ROOT.API.ADMIN}/setting`,
      UPLOAD_APP: `${ROOT.API.ADMIN}/upload/app`,
      MENU_ORDER: `${ROOT.API.ADMIN}/menu/order`,
      MAIL_SEND: `${ROOT.API.ADMIN}/mail/send`,
      NOTI: `${ROOT.API.ADMIN}/noti`,
      NOTI_CLEAR: `${ROOT.API.ADMIN}/noti/clear`,
      RELOAD: `${ROOT.API.ADMIN}/reload`,
    }
  }
};

export const PAGE = {
  'pagebox': 'PageBox',
  'newsbox': 'NewsBox',
  'slidebox': 'SlideBox',
  'prodbox': 'ProdBox',
  'jumbobox': 'JumboBox',
  'triplebox': 'TripleBox',
  'historybox': 'HistoryBox',
  'gallerybox': 'GalleryBox',
  'textbox': 'TextBox',
  'qnabox': 'QnaBox',
  'bannerbox': 'BannerBox',
  'photobox': 'PhotoBox',
}

// export const LAYOUT = {
//   'PAGE': '1000',
//   'BOARD': '2000',
//   'MEMBER': '3000',
// }