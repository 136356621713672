/* eslint-disable react/no-direct-mutation-state */
import React, { useState, useEffect } from 'react';
import { EID, URL, CODE } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { Util, Loading, Listbox, Button, Texteditor, cs, Editbox, Uploadbox, Imagebox, TabButton, Formgroup, Storage } from 'object/src';
import * as actions from 'object/src/actor/Action';
import styled from 'styled-components';
import cx from 'classnames/bind';

const StyledObject = styled.div`{
  &.ad-board { ${cs.pos.relative} ${cs.max.wlg} ${cs.p.h30} ${cs.min.h('100%')} ${cs.p.t30}
    .editor-frame { ${cs.m.t0} ${cs.m.b50} }
    .list-box {
      .dark .btn-new { ${cs.bg.green} } 
      .lbx-body { ${cs.m.t50} }
    }
    .pop-btn { ${cs.top(30)} ${cs.right(120)} ${cs.z.over} }
  }

  &.link-list { ${cs.disp.popup(99999)}
    .search-frame { ${cs.m.b10} ${cs.font.center} }
    & > .pop-box { ${cs.bg.dark} ${cs.border.radius(5)} ${cs.border.shadow()} 
      ${cs.p.a20}
    }
    .foot { ${cs.m.t30} ${cs.font.right} & > .button { ${cs.m.l30} } 
      .preview { ${cs.float.left} ${cs.m.l0} }
    }
  }

  
  @media screen and (max-width : 1024px) {
  }

  @media screen and (max-width : 860px) {
    &.ad-board {
      ${cs.p.h10}  ${cs.p.t10}
      .pop-btn { ${cs.pos.fixed} ${cs.bottom(10)} ${cs.left(10)} ${cs.w.get(140)} }
      .editor-frame { 
        ${cs.m.t0} ${cs.p.bottom(70)} ${cs.p.h2}
        .ed-body { ${cs.m.t10} ${cs.min.h('calc(100vh - 180px)')} }
        .ed-navi { ${cs.pos.absolute} ${cs.p.v10} ${cs.m.a0} ${cs.bottom(0)} ${cs.left(0)} ${cs.p.h2} ${cs.bg.dark} ${cs.w.fit}
          .button:first-child { ${cs.m.l0} }
        } 
      }
    }
  }

  @media screen and (max-width : 600px) {
  }
}`;

const API = URL.API.ADMIN;
const isIE =  /*@cc_on!@*/false || !!document.documentMode;

const searchs = [
  { name: ST.SUBJECT, id: 'title', check: true },
  { name: ST.CTIME, id: 'ctime', check: false },
  { name: ST.UTIME, id: 'utime', check: false },
];

const Board = (props) => {
  const [loaded, setLoaded] = useState(false);
  // const [modified, setModified] = useState(false);
  // const [draging, setDraging] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: 'title', value: '' });
  // eslint-disable-next-line no-unused-vars
  const [order, setOrder] = useState('ctime desc');
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [running, setRunning] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editor, setEditor] = useState({ show: false });
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    doReload(1, search, order, limit, () => setLoaded(true));
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "${s.value}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.BOARD, value, true).then(({ result }) => {
      if (!result.page) return setRunning(false);

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);

      result.list = result.list.map(item => {
        item.subject = `${item.no}. ${item.title}`;
        item.date = item.ctime ? item.ctime.substr(0, 8) : '';
        return item;
      });

      setList(result.list);
      setEditor({ show: false })
      setRunning(false);

      callback && callback();
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickNew = (eid, id) => {
    setEditor({ show: true });
    setSelected(0);
  }

  const onClick = (eid, code = 0) => {
    if (eid === EID.SAVE) {
      Util.showAlert(props, code);
      doReload(1);
    } else if (eid === EID.DELETE) {
      showAlert({ msg: ST.NOTI.DELETE, type: 'info', align: "center" });
      doReload(1);
    } else {
      setEditor({ show: false });
    }
  }

  const onSelect = (id) => {
    setEditor({ show: true });
    setSelected(id);
  }

  const showAlert = (eid, e) => {
    switch (eid) {
      case EID.CLOSE:
        Util.showAlert(props, -1, 'left');
        break;
      case EID.SAVE:
        Util.showAlert(props, 0, 'center');
        break;
      case EID.CANCEL:
        Util.showAlert(props, 0, 'right');
        break;
      default: break;
    }
  }

  const onClickPopup = (eid) => {
    if (eid === 'back' || eid === 'cancel') {
      setPopup(false);
    }
  }

  // const { list, page, editor, loaded, selected, search } = this.state;

  if (!loaded) return <Loading />
  if (popup) return <PopEditor onClick={onClickPopup} openConfirm={props.openConfirm} showAlert={props.showAlert} />
  return (
    <StyledObject className="ad-board">
      {editor.show && !isIE && <Texteditor title={ST.ADD} rowid={selected} onClick={onClick} api={URL.API.ADMIN.BOARD} />}
      {!editor.show && <React.Fragment>
        <Button className={'pop-btn primary right'} title={ST.BOARD.POPUP} onClick={() => setPopup(true)} />
        <Listbox className={"board-frame dark"} naviClass={'white'} list={list} searchkey={search.key} searchs={searchs}
          rowid="rowid" title={'subject'} datealign={'bottom'} titlealign={'left'} pos={page} max={max} total={total}
          onClickPage={(p) => doReload(p)} onSelect={onSelect} onClickSearch={onClickSearch} onClickNew={onClickNew} />
      </React.Fragment>}
      {running && <Loading className={''} type={'ring'} />}
    </StyledObject>
  )
};

export default Board;

const StyledEditor = styled.div`{
  &.pop-editor { ${cs.pos.relative} ${cs.max.wmd} ${cs.p.a30} ${cs.min.h('100%')}
    .p-back { ${cs.font.white} }
    .p-tabs { ${cs.align.rtop} ${cs.top(20)} ${cs.right(30)} }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.width(3)} ${cs.m.b30} }
    .pl { ${cs.pos.relative} ${cs.font.center} ${cs.m.b20} ${cs.p.b50}
      .uploader { ${cs.size.get(320)} ${cs.m.t5} .upf-preview { ${cs.border.trans} } .upv-img { ${cs.bg.color("#20202050")} } }
    }

    .pr {
      .edit-box { ${cs.m.t20} }
      .guide { ${cs.font.yellow} ${cs.font.left} ${cs.font.sm} ${cs.m.l5} }
      .subject { ${cs.m.t5} }
      .link { ${cs.m.t30} }
    }

    .foot { ${cs.m.t30} ${cs.font.right} & > .button { ${cs.m.l30} } 
      .preview { ${cs.float.left} ${cs.m.l0} }
    }
  }

  @media screen and (max-width : 860px) {
    &.pop-editor { ${cs.p.h10} 
      .p-tabs { ${cs.right(10)} }
    }
  }
}`;

const PopEditor = (props) => {
  var refs = {};
  var thumb = null;
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  // const [modified, setModified] = useState(false);
  const [preview, setPreview] = useState(false);
  const [imagechange, setImagechange] = useState(false);
  const [running, setRunning] = useState(false);
  const [enable, setEnable] = useState('disable');
  const [linklist, showLinklist] = useState(false);

  const SMAX = 20, TMAX = 50;

  useEffect(() => {
    doReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (code = null, callback = null) => {
    actions.doSelect(URL.API.APPINFO, { stag: 'boardpop' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setData(json);
      setList(result && result.length > 0 ? result : null);
      setImagechange(false);
      // setModified(false);
      setEnable(json.show || 'disable')
      callback && callback();
    });

    // code !== null && Util.showAlert(props, code);
  }

  const onSave = (eid, e) => {
    if (!refs) return;

    const save = (callback = null) => {
      let item = list ? list.find(a => a.skey === 'show') : null;
      if (!item) {
        array.push({ rowid: null, stag: 'boardpop', skey: 'show', sval: 'enable' });
      }

      item = list ? list.find(a => a.skey === 'link') : null;
      if (!item) {
        data.link && array.push({ rowid: null, stag: 'boardpop', skey: 'link', sval: data.link });
      } else {
        array.push({ ...item, rowid: item.cfgid, sval: data.link });
      }

      item = list ? list.find(a => a.skey === 'linktitle') : null;
      if (!item) {
        data.linktitle && array.push({ rowid: null, stag: 'boardpop', skey: 'linktitle', sval: data.linktitle });
      } else {
        array.push({ ...item, rowid: item.cfgid, sval: data.linktitle });
      }

      let isupdate = array.every(a => {
        const t = list && list.find(b => a.skey === b.skey);
        if (!t) return false;
        return a.sval === t.sval;
      })

      if (!isupdate) {
        // 팝업 고유 아이디를 만들자.
        item = list ? list.find(a => a.skey === 'uuid') : null;
        if (!item) {
          array.push({ rowid: null, stag: 'boardpop', skey: 'uuid', sval: Util.getUuid() });
        } else {
          array.push({ ...item, rowid: item.cfgid, sval: Util.getUuid() });
        }
      }

      actions.doInsert(URL.API.ADMIN.SETTING, array).then(({ code, result }) => {
        if (callback) return callback(code);

        setRunning(true);
        doReload(code, () => setRunning(false));
        (code !== CODE.SUCCESS) && Util.showAlert(props, code)
      });
    }

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'boardpop', skey: key, sval: refs[key].getValue() }
    });

    if (imagechange) {
      props.openConfirm({
        title: ST.ALARM, type: 'info', msg: ST.BOARD.IMAGE_SAVE, size: 'sm', className: 'gray',
        onClicked: (isOk) => {
          isOk && onUpload(() => {
            save((code) => {
              setTimeout(() => {
                setData({ ...data, boardimg: null });
                setRunning(true);
                doReload(code, () => setRunning(false));
                Util.showAlert(props, code)
              }, 1000);
            });
          });
        }
      });
    } else {
      save();
    }
  }

  const onUpload = (callback = null) => {
    const image = thumb.getValue(); //base64
    const key = 'boardimg';
    const item = list && list.find(a => a.skey === key);
    const value = { rowid: item ? item.cfgid : null, stag: 'boardpop', skey: key, image: image, filename: key }

    actions.doInsert(URL.API.ADMIN.UPLOAD_APP, value).then(({ code, result }) => {
      if (callback) {
        callback();
      } else {
        // setRunning(true);
        // setData({ ...data, boardimg: null });
        // setTimeout(() => {
          // setData({ ...data, boardimg: null });
          // doReload(code, () => setRunning(false));
        // }, 1000);
        setImagechange(false);
        (code !== CODE.SUCCESS) && Util.showAlert(props, code);
      }
    });

    // // 팝업 고유 아이디를 만들자.
    let temp = list ? list.find(a => a.skey === 'uuid') : null;
    if (!temp) {
      temp = { rowid: null, stag: 'boardpop', skey: 'uuid', sval: Util.getUuid() };
    } else {
      temp = { ...temp, rowid: temp.cfgid, sval: Util.getUuid() };
    }

    actions.doInsert(URL.API.ADMIN.SETTING, temp).then(({ code, result }) => {
      // setRunning(true);
      // doReload(code, () => setRunning(false));
      // Util.showAlert(props, code)
    });
  }

  const onChangeImage = (result, target, item, type) => {
    setData({ ...data, boardimg: result });
    setImagechange(true);
  }

  // const onBack = () => {
  //   props.onClick && props.onClick('back');
  // }

  const onCancel = () => {
    props.onClick && props.onClick('back');
  }

  const onChange = (name, value) => {
    // const temp = JSON.parse(JSON.stringify(data));
    if (name === 'subject' && value.length > SMAX) {
      props.openConfirm({
        size: 'sm', className: 'gray', type: 'info', cancel: false, msg: ST.MAXLEN(ST.BOARD.SUBJECT, SMAX),
        onClicked: (isOk) => {
          data[name] = value.substr(0, SMAX);
          setData({ ...data });
        }
      });
      return;
    };
    if (name === 'text' && value.length > TMAX) {
      props.openConfirm({
        size: 'sm', className: 'gray', type: 'info', cancel: false, msg: ST.MAXLEN(ST.BOARD.TEXT, TMAX),
        onClicked: (isOk) => {
          data[name] = value.substr(0, TMAX);
          setData({ ...data });
        }
      });
      return;
    }

    data[name] = value;
    setData(data);
    // setModified(true);
  }

  const onDelete = () => {
    //삭제처리해야한다.
    props.openConfirm({
      size: 'sm', className: 'gray', type: 'info', onClicked: (isOk) => {
        (isOk) && actions.doDelete(URL.API.ADMIN.SETTING, { stag: 'boardpop' }, null).then(({ code, result }) => {
          setRunning(true);
          doReload(code, () => setRunning(false));
        });
      }
    });
  }

  const onEnable = (eid) => {
    setEnable(eid);

    const item = list.find(a => a.skey === 'show');
    let value = null;

    if (item && item.sval === eid) return;

    if (item) {
      value = item;
      value.rowid = item.cfgid;
      value.sval = eid;
    } else {
      value = { rowid: null, stag: 'boardpop', skey: 'show', sval: eid };
    }

    actions.doInsert(URL.API.ADMIN.SETTING, value).then(({ code, result }) => {
      setRunning(true);
      doReload(code, () => setRunning(false));
    });
  }

  const onLink = () => {
    // setModified(true);
    showLinklist(true);
  }

  const onSelectLink = (item) => {
    if (item) {
      data['link'] = item.rowid;
      data['linktitle'] = item.title;
      setData(data);
      // setModified(true);
    }

    showLinklist(false);
  }

  if (!data) return null;
  const linktl = data.linktitle ? data.linktitle.length > 20 ? data.linktitle.substr(0, 20) + "..." : data.linktitle : null;
  return <StyledEditor className={'pop-editor'}>
    {/* <Button className={'p-back lg trans'} title={ST.BACK} onClick={onBack} /> */}
    <p className={'title'}>{ST.BOARD.POPUP}</p>
    <TabButton className={'p-tabs'} size={'md'} list={[{ id: 'enable', title: ST.BOARD.ENABLE }, { id: 'disable', title: ST.BOARD.DISABLE }]}
      onClick={(eid, item) => onEnable(eid)} select={enable} />

    {/* <span>
      <Button className={cx('enable-btn', enable ? 'green' : 'gd-gray')} title={ST.ADMIN.UPLOAD} onClick={onEnable} />
    </span> */}
    <div className={'box'}>
      <Formgroup inline={true}>
        <div className={'pl'}>
          <Uploadbox ref={(ref) => thumb = ref} className={cx('')} fit={'cover'} size={'md'} inline={false} onChange={onChangeImage}
            value={data.boardimg} title={ST.BOARD.ICON_IMG} image_check={{ width: 320, height: 320 }} />
          <Button className={'green bottom center up-btn'} title={ST.ADMIN.UPLOAD} onClick={() => onUpload()} disabled={!imagechange} />
        </div>

        <div className={'pr'}>
          <Editbox ref={(ref) => { refs.subject = ref }} className={"subject"}
            value={data.subject} type="text" label={ST.BOARD.SUBJECT} focus={false}
            helper={ST.MAXLEN(ST.BOARD.SUBJECT, SMAX)} validate={true} onChange={(v) => onChange('subject', v)} />
          <Editbox ref={(ref) => { refs.text = ref }} className={"text"} multi={true} height={100}
            value={data.text} maxheight={160} minheight={80} type="text" label={ST.BOARD.TEXT} focus={false}
            helper={ST.MAXLEN(ST.BOARD.TEXT, TMAX)} validate={true} onChange={(v) => onChange('text', v)} />
          <Button className={'link full gd-gray'} title={linktl ? ST.BOARD.LINKAT(linktl) : ST.BOARD.LINK} onClick={onLink} />
          <span className={'guide'}>{ST.BOARD.LINK_H}</span>
          {/* <Editbox ref={(ref) => { refs.link = ref }} className={"link"} focus={false}
            value={data.link} type="text" label={ST.BOARD.LINK}
            helper={ST.BOARD.LINK_H} onChange={(v) => onChange('link', v)} /> */}
        </div>
      </Formgroup>
    </div>
    <div className={'foot'}>
      <Button className={'primary preview'} title={ST.PREVIEW} onClick={() => setPreview(true)} />
      <Button className={'gd-gray cancel'} title={ST.CANCEL} eid={EID.SAVE} onClick={onCancel} />
      <Button className={'black delete'} title={ST.DELETE} eid={EID.DELETE} onClick={onDelete} />
      <Button className={'red save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} />
    </div>

    {running && <Loading className={'p-running'} type={'ring'} />}
    {preview && <PopBoard onClick={() => setPreview(false)} />}
    {linklist && <Linklist onClick={onSelectLink} />}
  </StyledEditor>
}

const Linklist = (props) => {
  const [running, setRunning] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: '', value: '' });
  // eslint-disable-next-line no-unused-vars
  const [order, setOrder] = useState('ctime desc');
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    doReload(1, search, order, limit);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "${s.value}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.BOARD, value, true).then(({ result }) => {
      if (!result.page) return setRunning(false);

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);

      result.list = result.list.map(item => {
        item.subject = `${item.no}. ${item.title}`;
        item.date = item.utime ? item.utime.substr(0, 8) : '';
        return item;
      });

      setList(result.list);
      setRunning(false);

      callback && callback();
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (id, e, item) => {
    props.onClick && props.onClick(item);
  }

  return <StyledObject className={'link-list'}>
    <div className={'pop-bg'} onClick={() => props.onClick && props.onClick(null)} />
    <div className={'pop-box'}>
      <Listbox className={"list-frame dark"} naviClass={'white'} list={list} searchkey={search.key} searchs={searchs}
        rowid="rowid" title={'subject'} datealign={'bottom'} titlealign={'left'} pos={page} max={max} total={total}
        onClickPage={onClickPage} onSelect={onSelect} onClickSearch={onClickSearch} />
    </div>
    {running && <Loading type='ring' />}
  </StyledObject>
}

const StyledPop = styled.div`{
  &.pop-board { ${cs.w.get(320)} ${cs.h.get(320)} ${cs.pos.fixed} ${cs.bottom(20)} ${cs.right(20)} 
    ${cs.font.center} ${cs.font.dark} ${cs.noselect} ${cs.z.top}

    .pop-frame { ${cs.size.full} ${cs.align.right}
      ${cs.border.lightgray} ${cs.border.shadow()} ${cs.bg.white} 
      ${cs.anim.slideup('300ms', '120%', '0', 'pop-board-anim')}

      &.link { ${cs.mouse.pointer} }

      .p-tit { ${cs.disp.block} ${cs.w.full} ${cs.align.top} ${cs.top(0)} ${cs.font.thickbold}
        ${cs.p.v5} ${cs.font.md} ${cs.bg.get("#b7b7b7a0")}
      }
      .p-txt { ${cs.disp.block} ${cs.w.full} ${cs.align.bottom} ${cs.bottom(0)} 
        ${cs.p.v5} ${cs.p.b30} ${cs.font.sm} ${cs.font.prewrap} ${cs.font.breakword} ${cs.bg.get("#b7b7b790")}
      }

      .p-btn { ${cs.disp.block} ${cs.w.full} ${cs.align.bottom} 
        ${cs.border.top} ${cs.border.dark} ${cs.disp.block} ${cs.h.get(28)} ${cs.bg.get("#b7b7b7a0")}
        & > span { ${cs.bottom(2)} ${cs.mouse.pointer} ${cs.p.a5} ${cs.p.h10} ${cs.font.sm} 
          &:hover { ${cs.font.underline} } 
        }
        .p-close { ${cs.align.right} ${cs.right(5)} }
        .p-exit { ${cs.align.left} ${cs.left(10)} }
      }
    }
  }
}`;

export const PopBoard = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    doReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = () => {
    actions.doSelect(URL.API.APPINFO, { stag: 'boardpop' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);

      // 더이상 보지 않기 클릭시 보이지 않음.
      // Storage.removeLocalItem('board-pop-uuid');
      const puid = Storage.getLocalItem('board-pop-uuid');
      if (json && puid && puid === json.uuid) return setData(null);

      setData(json);
    });
  }

  const onExit = (e) => {
    e.stopPropagation();
    props.onClick && props.onClick('exit', data);
  }

  const onClose = (e) => {
    e.stopPropagation();
    props.onClick && props.onClick('close', data);
  }

  const onClick = (e) => {
    if (data && data.link) {
      actions.go(URL.BOARD, { rowid: data.link });
    };
  }

  if (!data) return null;

  const link = data.link ? true : false;
  return <StyledPop className={'pop-board'}>
    {/* <Button className={'p-back lg trans'} title={ST.BACK} onClick={onBack} /> */}
    <div className={cx('pop-frame', { link })} onClick={onClick}>
      <Imagebox fit={'cover'} size={'full'} inline={false} src={data.boardimg} title={ST.BOARD.ICON_IMG} image_check={{ width: 480, height: 480 }} />

      <p className={'p-tit'}>{data.subject}</p>
      <p className={'p-txt'}>{data.text}</p>

      <div className={'p-btn'}>
        <span className={'p-exit'} onClick={onExit} >{ST.BOARD.CLOSE}</span>
        <span className={'p-close'} onClick={onClose}>{ST.CLOSE}</span>
      </div>
    </div>
  </StyledPop>
}