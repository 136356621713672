/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Loading,  Button } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.tail-bar {
    ${cs.bg.trans} ${cs.font.gray} ${cs.font.md} ${cs.font.center} 
    ${cs.p.v20} ${cs.h.get('auto')} ${cs.w.full} 
  }

  &.head-bar {
    ${cs.bg.get("rgba(255, 255, 255, 0.9);")} ${cs.font.gray} ${cs.font.md} ${cs.font.center} ${cs.font.line(60)} ${cs.h.get(60)} 
    ${cs.w.full} ${cs.font.t4} ${cs.font.eastsea}
    & > span { ${cs.mouse.pointer} }
  }

  &.pri-box { ${cs.pos.relative} ${cs.bg.frame}
    .pb-frame {
      ${cs.w.full} ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} 
      ${cs.h.fit} ${cs.p.h50} ${cs.p.b40} ${cs.noselect} ${cs.bg.white}

      .p-tabs {
        ${cs.m.b30} ${cs.p.t30} ${cs.border.bottom} ${cs.border.width(3)} ${cs.border.gray}
        ${cs.font.t0}
        .button:hover { ${cs.bg.lightgray} }
        .active { ${cs.font.primaryhover} .btn-label { ${cs.font.underline} } }
      }

      .p-body { ${cs.p.a10} ${cs.font.md} ${cs.font.line(20)} ${cs.font.prewrap} ${cs.font.breakword} 
        .p-top { ${cs.p.a20} ${cs.bg.lightwhite} ${cs.border.radius(3)} ${cs.m.b30} }
      }
    }

    @media screen and (max-width : 1024px) { }
  
    @media screen and (max-width : 600px) { 
      .pb-frame { ${cs.p.h20} }
    }
  
    @media screen and (max-width : 480px) { }
    }
  }
}`;

const API = URL.API;

export const Headbar = ({ title = '' }) => {
  const StyledComp = styled.div`{
    &.head-bar {
      ${cs.bg.get("rgba(255, 255, 255, 0.9);")} ${cs.font.gray} ${cs.font.md} 
      ${cs.font.center} ${cs.font.line(60)} ${cs.h.get(60)} ${cs.w.full} ${cs.font.t4} 
      ${cs.font.eastsea} ${cs.pos.sticky} ${cs.top(0)} ${cs.bg.white} ${cs.z.over} 
      ${cs.border.bottom} ${cs.border.lightwhite}
      & > span { ${cs.mouse.pointer} }
    }
  }`;

  return <StyledComp className={"head-bar"}>
    <span onClick={() => window.location.href = URL.ROOT}>{title}</span>
  </StyledComp>
}

export const Tailbar = ({ title = '' }) => {
  const StyledComp = styled.div`{
    &.tail-bar {
      ${cs.bg.trans} ${cs.font.gray} ${cs.font.md} ${cs.font.center} 
      ${cs.p.v20} ${cs.h.get('auto')} ${cs.w.full} 
    }
  }`;

  return <StyledComp className="tail-bar">
    <span>{title}</span>
  </StyledComp>
}

const Privacy = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [select, setSelect] = useState('pri');
  const [pri, setPri] = useState({ sval: '' });
  const [pub, setPub] = useState({ sval: '' });
  const [comp, setComp] = useState(null);

  useEffect(() => {
    const param = actions.getParam();
    const { type = 'pri' } = param;

    actions.doSelect(URL.API.PRIVACY).then(({ result }) => {
      if (!result) return;

      let json = {}
      result.comp.map(a => json[a.skey] = a.sval);

      setComp(json);
      result.pri && setPri(result.pri);
      result.pub && setPub(result.pub);
      setSelect(type);
      setLoaded(true);

      setTimeout(() => global.onLoaded && global.onLoaded(true), 100);
    });

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (eid) => {
    setSelect(eid);
  }

  if (!loaded) return <Loading />;

  const data = select === 'pri' ? pri.sval : pub.sval;
  return <StyledObject className={cx('pri-box')}>
    <Headbar title={comp && comp.name} />
    <div className={'pb-frame'}>
      <div className={'p-tabs'}>
        <Button className={cx('p-btn trans', select === 'pri' && 'active')} title={ST.COMPANY.PRIVATE} onClick={() => onClick('pri')} />
        <Button className={cx('p-btn trans', select === 'pub' && 'active')} title={ST.COMPANY.PUBLIC} onClick={() => onClick('pub')} />
        {/* <Button className={'trans'} title={ST.COMPANY.PRIVATE} onClick={() => onClick('')} /> */}
      </div>
      <div className={'p-body'}>
        <div className={'p-top'}>{select === 'pri' ? ST.INFO.PRI(comp.name || '') : ST.INFO.PUB(comp.name || '')}</div>
        <p className={'p-txt'}>{data || 'No Data'}</p>
      </div>
    </div>
    {comp && <Tailbar title={ST.COPYRIGHT(comp.name_eng || comp.name, comp.year)} />}
  </StyledObject>
};

export default Privacy;