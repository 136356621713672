import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Util, Loading, Button, Imagebox, Uploadbox, Svg, Combobox } from 'object/src';
import { URL, CODE, PAGE as LAYOUT } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import * as PAGE from '../pages';
import { EID } from 'object/src/Config';
import { PageLayer, PageMaker } from './PageLayer';
import { PageOptions as OPTS } from '../pages/PageCom';

const StyledObject = styled.div`{
  &.ad-page { 
    ${cs.bg.trans} ${cs.m.a30} ${cs.pos.relative} ${cs.min.height(100)} ${cs.m.bottom(80)}
    ${cs.max.w(1400)} ${cs.w.full} ${cs.noselect}
    ${cs.bg.size('cover')} ${cs.bg.repeat('no-repeat')} ${cs.bg.attach('fixed')} ${cs.bg.pos('center')}

    .frame-bg { ${cs.disp.block} ${cs.pos.fixed} ${cs.top(0)} ${cs.left(0)} ${cs.z.get(0)} ${cs.opac.get(0.5)} 
      ${cs.h.get(('100vh'))} ${cs.w.full} ${cs.bg.dark}
    }

    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.h5} ${cs.m.t0} 
      ${cs.disp.block} ${cs.m.b10} ${cs.pos.relative} ${cs.h.get(40)} ${cs.bg.dark}
      .tit { ${cs.disp.inblock} ${cs.mouse.pointer} ${cs.font.line(36)} &:hover { ${cs.font.primary} } }
      .sim { ${cs.disp.inblock} ${cs.p.h10} ${cs.font.gray} }
      .t-menu { ${cs.float.none} ${cs.m.bottom(-5)} ${cs.min.w(160)} ${cs.font.lg}
        .cb-sel { ${cs.border.none} ${cs.border.radius()} } 
        .cb-ul { ${cs.min.w(150)} ${cs.max.h(400)} }
      }
      .preview { ${cs.font.lg} ${cs.font.gray} ${cs.mouse.pointer} ${cs.p.h5} ${cs.m.l20} &:hover { ${cs.font.underline} } }

      .btns { ${cs.float.right} & > span { ${cs.m.l10} } }
    }

    .btn-bg { ${cs.font.orange} ${cs.font.md} ${cs.font.bold} ${cs.m.t30} ${cs.m.b10} ${cs.align.xcenter} 
      ${cs.mouse.pointer} ${cs.p.a5} ${cs.p.h20} ${cs.disp.inblock} ${cs.font.center} ${cs.border.radius(5)}
      ${cs.pos.relative}
      &:hover { ${cs.font.orangehover} ${cs.bg.alphablack} ${cs.anim.showin()} }
    }
  }

  &.page-new {
    ${cs.size.get(160)} ${cs.border.radius()} ${cs.box.line} ${cs.border.darkgray} ${cs.align.xcenter} ${cs.top(200)}
    ${cs.w.get(300)} ${cs.mouse.pointer}
    p {
      ${cs.w.full} ${cs.p.a20} ${cs.align.center} ${cs.font.center} 
      ${cs.font.yellow} ${cs.font.md} ${cs.font.line(24)} ${cs.font.prewrap}
    }
    &:hover { ${cs.bg.alphablack} }
  }

  &.page-tool {
    ${cs.align.right} ${cs.pos.fixed} ${cs.h.get('calc(100vh - 60px)')} ${cs.w.get(300)} ${cs.top(60)} ${cs.z.popup}
    ${cs.bg.darkhover} ${cs.border.left} ${cs.anim.slidein('300ms', '100%', '0')}
    .head { ${cs.disp.block} ${cs.align.top} ${cs.h.get(40)} ${cs.w.full} ${cs.border.bottom} ${cs.p.a10} ${cs.p.l20}
      ${cs.bg.black} ${cs.border.darkgray} ${cs.z.over} ${cs.font.md} ${cs.font.thickbold} ${cs.font.gray}
      .cancel { ${cs.align.right} ${cs.top(5)} ${cs.right(10)} }
    }
    .body { ${cs.disp.block} ${cs.h.full} ${cs.over.yauto} ${cs.scrollbar.t2} ${cs.p.a20} ${cs.p.t40} ${cs.p.b20}
      .pt-ul { ${cs.h.fit} 
        .pt-li { ${cs.p.a10} ${cs.border.line} ${cs.border.trans} ${cs.border.width(2)} ${cs.m.t10} ${cs.mouse.pointer} ${cs.min.h(180)}
          &:hover { ${cs.bg.alphablack} ${cs.border.radius(5)} ${cs.border.primary} }
          .l-tit { ${cs.font.sm} ${cs.font.bold} }
        }
      }
    }
  }
  
  &.popup-page {
    ${cs.disp.popup('999999', cs.color.alphablack, 600, 400)} ${cs.top(0)}
    .pop-box { ${cs.bg.dark} ${cs.border.black} ${cs.border.shadow()} ${cs.font.white}
      .head { ${cs.h.get(40)} ${cs.bg.trans} ${cs.font.lg} ${cs.font.line(40)} ${cs.p.l10} 
        .close { ${cs.align.right} ${cs.top(5)} ${cs.right(5)} }
      }
      .body { ${cs.h.calc('100% - 80px')} ${cs.p.a20} ${cs.pos.relative} ${cs.bg.trans} ${cs.p.t30}
        .p-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.m.b3} ${cs.align.ltop} 
          ${cs.left(20)} ${cs.top(10)} ${cs.z.over} 
        }
        .uploader { ${cs.h.full} }
      }
      .foot { ${cs.h.get(40)} ${cs.font.right} ${cs.font.line(40)} ${cs.p.t5} 
        ${cs.border.top} ${cs.bg.trans}
        .button { ${cs.m.r10} &.delete { ${cs.align.left} ${cs.left(10)} } }
      }
    }
  }

  @media screen and (max-width : 800px) { 
    &.ad-page { ${cs.p.a10} ${cs.m.a0}
    }
  }

  @media screen and (max-width : 600px) { 
    &.ad-page { ${cs.p.a10}
      .title { ${cs.font.lg} ${cs.font.thickbold}
        .btns { ${cs.disp.block} ${cs.float.none} ${cs.p.t20} & > span { ${cs.m.l0} ${cs.m.r10} } }
      }
    }

    &.page-tool {
    }
  }
}`;

const API = URL.API.ADMIN;

const NewPage = (item) => {
  let options = OPTS.default();
  if (item.ctag === LAYOUT.newsbox) {
    options = OPTS.default(null, { color: cs.color.white }, { color: cs.color.white });
  }

  let newpage = {
    rowid: item.rowid || 0,
    bg: "",
    cont: "",
    ctag: item.ctag,
    layid: item.layid,
    menuid: item.menuid,
    odr: item.odr,
    opts: options,
    jsons: "",
    title: "",
    type: "image",
    url: "",
    edited: true,
    path: item.path || "",
    // creid: Util.getGenerateKey(),
    // ...item,
  }

  let temp = JSON.parse(JSON.stringify(newpage));
  temp.component = PAGE[item.ctag];
  return temp;
}

const Page = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [pos, setPos] = useState(0);
  const [tool, showTool] = useState(false);
  const [layoutlist, setLayoutlist] = useState(null);
  const [menulist, setMenulist] = useState(null);
  const [menuid, setMenuid] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [popup, setPopup] = useState(null);
  const [maker, setMaker] = useState(null);
  const [frame, setFrame] = useState(null);

  useEffect(() => {
    const param = actions.getParam();
    setMenuid(param && param.rowid);
    doReload(param && param.rowid);

    var prev = global.device.type;
    const onResize = () => {
      const { type } = global.device;
      if (prev !== type) {
        prev = type;
        setRefresh(true);
        setTimeout(() => setRefresh(false), 200);
      }
    }

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.noti) return;
    doReload();
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.noti]);

  const doReload = (rowid = menuid, callback = null) => {
    actions.doSelect(URL.API.PAGE, { menuid: rowid, layoutgrp: null }).then((result) => {
      const { value, layouts, menus } = result;
      value.map(a => {
        a.component = PAGE[a.ctag];
        if (!a.component) a.component = PAGE.NoPage;  // 기본 태그
        a.opts = a.opts ? JSON.parse(a.opts) : OPTS.default();
        return null;
      });

      const opts = result.frame && result.frame.opts ? JSON.parse(result.frame.opts) : { style: 'scroll' };
      global.pageopts = { ...opts };

      setFrame(result.frame);
      setPos(0);
      setList(value);
      setLayoutlist(layouts);
      setMenulist(menus);
      setLoaded(true);

      callback && callback(value);
    });
  }

  const onClickNew = (item, index) => {
    const value = NewPage({ ...item, odr: index + 1, menuid });
    actions.doInsert(API.PAGE, { menuid, ...value }).then((res) => {
      const { code, result, data } = res;
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }

      list.forEach(a => a['edited'] = false);
      const arr = [...list.slice(0, index), NewPage({ ...value, rowid: result, path: data.path }), ...list.slice(index)];
      setList(arr);
      showTool(false);

      // 추가한 항목으로 화면 스크롤
      const moveToScroll = (ipos = 0, margin = 0, smooth = true) => {
        const node = document.getElementsByClassName('page-layer')[ipos];
        const layer = document.getElementsByClassName('admin-frame')[0];
        if (node && layer) {
          if (smooth) {
            layer.scrollTo({ top: node.offsetTop - margin, behavior: 'smooth' });
          } else {
            layer.scrollTo(0, node.offsetTop - margin);
          }
        }
      };
      setTimeout(() => moveToScroll(index), 300);
    });
  }

  const onClick = (eid, item, index) => {

    if (eid === 'new') {
      const isediting = list.find(a => a.edited);
      if (isediting) {
        global.openConfirm({
          type: 'info', size: 'sm', className: 'gray', cancel: false, msg: ST.ADMIN.NOTI.EDITIMG, onClicked: (isOk) => { }
        });
        return;
      }

      setPos(index);
      showTool(true);
      setPopup(null);
      setMaker(null);
    } else if (eid === 'option') {
      showTool(false);
      setPopup(null);
      setMaker(null);
    }
  }

  const onClickPreview = () => {
    actions.open(URL.HOME, { rowid: frame.rowid });
  }

  const onClickPopup = (item) => {
    setPopup(null);
    window.location.reload();
  }

  const onClickMaker = (eid, array) => {
    setList([...array]);
  }

  const openMaker = () => {
    setMaker(frame);
    setPopup(null);
    showTool(false);
  }

  const openPopup = () => {
    setPopup(frame);
    setMaker(null);
    showTool(false);
  }

  const onClickMenu = (eid, e, item) => {
    // console.dir(item);
    actions.go(URL.ADMIN.PAGE, { rowid: item.id });
    window.location.reload();
  }

  if (loaded) {
    const menu = menulist ? menulist.find(a => String(a.rowid) === String(menuid)) : null;
    const opts = frame && frame.opts ? JSON.parse(frame.opts) : { style: 'scroll' };
    // global.pageopts = { ...opts };

    return <StyledObject className="ad-page" style={{ backgroundImage: frame && frame.bg && `url(${frame.path + frame.bg})` }}>
      <div className={cx('title')}>
        <span className={'tit'} onClick={() => actions.go(URL.ADMIN.MENU)}>{ST.ADMIN.NAVI.MENU}</span>
        <span className={'sim'}>{'>'}</span>
        <Combobox className={'black sm t-menu'} list={menulist.map(a => { return { name: a.title, id: a.rowid } })} select={menu.rowid || 0} onClick={onClickMenu} />
        <span className={'preview'} onClick={onClickPreview} >{`(preview)`}</span>
        <span className={'btns'}>
          <Button className={'btn sm gd-gray newpage'} title={'PAGE MAKER'} onClick={openMaker} />
          <Button className={'btn sm gd-gray pagebg'} title={'PAGE BG'} onClick={openPopup} />
        </span>
      </div>
      {list && list.length > 0 ?
        <PageLayer list={list} menus={menulist} onClick={(eid, index) => onClick(eid, null, index)} className={opts && opts.style}
          onReload={(arr) => arr ? (setList([...arr]), showTool(false)) : doReload(menuid)} isnew={tool} /> :
        <PageNew onClick={() => onClick(EID.NEW, null, 0)} />}
      {tool && <PageTool list={layoutlist} pos={pos} onClick={onClickNew} onCancel={() => showTool(false)} />}
      {popup && <PopupPage item={popup} onClick={onClickPopup} onClose={() => setPopup(null)} />}
      {refresh && <Loading className={'fixed'} type={'ring'} />}
      {maker && <PageMaker menuid={menuid} list={list} layouts={layoutlist} onClick={onClickMaker}
        onReload={() => doReload(menuid)} onClose={() => setMaker(null)} />}
    </StyledObject >
  } else {
    return <Loading />;
  }
};

export default Page;

const PageNew = (props) => {
  return <StyledObject className={'page-new'} onClick={props.onClick}>
    <p>{ST.PAGE.NEW_PAGE}</p>
  </StyledObject>
}

const PageTool = (props) => {
  const { list, pos } = props;

  const onClick = (item) => {
    props.onClick && props.onClick(item, pos);
  }

  const onCancel = () => {
    props.onCancel && props.onCancel();
  }

  return <StyledObject className={'page-tool'}>
    <div className={'head'}>
      <span>{ST.PAGE.SEL_LAYOUT}</span>
      <Button className={'btn sm black cancel'} title={ST.CLOSE} onClick={onCancel} />
    </div>
    <div className={'body'}>
      <ul className="pt-ul">
        {list && list.map((item, i) => {
          const { path, thumb, title } = item;
          const url = thumb.indexOf('http') >= 0 ? thumb : `${path}${thumb}`;
          return <li key={item.layid} className={'pt-li'} onClick={() => onClick(item)}>
            <Imagebox className={'sm'} fit={'cover'} size={'full'} inline={false} src={url}
              title={title} image_check={{ width: 480, height: 480 }} />
            <span className={'l-tit'}>{title}</span>
          </li>
        })}
      </ul>
    </div>
  </StyledObject>
}

const PopupPage = (props) => {
  const { item } = props;
  const [value, setValue] = useState(null);

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    props.onLoad && props.onLoad('error');
  }

  const onClose = () => {
    props.onClose && props.onClose();
  }

  const onChange = (v, e, label) => {
    setValue(v);
  }

  const onDelete = () => {
    global.openConfirm({
      type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
        (isOk) && actions.doDelete(API.PAGE_FRAMEBG, { rowid: item.rowid }).then(({ code, result }) => {
          if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
          setTimeout(() => {
            props.onClick && props.onClick({ ...item, bg: '' });
          }, 500);
        });
      }
    });
  }

  const onSave = () => {
    actions.doUpdate(API.PAGE_FRAMEBG, { rowid: item.rowid, bg: value }).then((res) => {
      const { code, data } = res;
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
      setTimeout(() => {
        props.onClick && props.onClick({ ...item, bg: data });
      }, 500);
    });
  }

  return <StyledObject className={cx("popup-page")}>
    <div className={'pop-bg'} onClick={onClose} />
    <div className={'pop-box'}>
      <div className={'head'}>
        <span>{ST.PAGE.BG_TITLE}</span>
        <Svg className={'close gray md right top'} icon={EID.CANCEL} onClick={onClose} />
      </div>
      <div className={'body'}>
        <label className={'p-noti'}>{ST.PAGE.IMAGE_H + ` ${ST.IMAGE_SIZE('1600 * 900')}`}</label>
        <Uploadbox className="bg-img up-box" fit={'contain'} value={`${item.path}${item.bg}`} size={"wide"}
          onLoad={onLoad} onError={onError} eid={"bg"} onChange={(v, e) => onChange(v, e, 'bg')} />
      </div>
      <div className={'foot'}>
        <Button className={'btn sm black delete'} title={ST.DELETE} onClick={onDelete} />
        <Button className={'btn sm gray cancel'} title={ST.CANCEL} onClick={onClose} />
        <Button className={'btn sm red save'} title={ST.SAVE} onClick={onSave} />
      </div>
    </div>
  </StyledObject>
}