
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Listbox, Util, Button, Editbox, Storage } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.qna { ${cs.pos.relative} ${cs.bg.white}
    .qna-frame { ${cs.w.full} ${cs.max.wlg} ${cs.min.h('calc(100vh - 100px)')} ${cs.align.xcenter} ${cs.pos.relative} 
      ${cs.h.fit} ${cs.p.h50} ${cs.p.b40}
      .title { ${cs.font.xl} ${cs.p.t40} ${cs.p.b20} ${cs.m.b20} ${cs.border.bottom} ${cs.font.t0} ${cs.font.himelody} ${cs.font.thickbold} }

      .q-box { ${cs.pos.relative} ${cs.disp.block}
        .btn-new { ${cs.z.over} }
        &.hide { ${cs.disp.none} }
        &.show { ${cs.anim.showin()} }
      }
      .list { ${cs.font.xl} ${cs.min.h('70vh')} ${cs.disp.none}
        &.show { ${cs.disp.block} }
        .search-frame { ${cs.font.center}
          .search-box { ${cs.bg.white} ${cs.box.light}
            .input { ${cs.bg.white} }
            .cb-sel { ${cs.bg.trans} }
          }
        }
        .lbx-body .lbx-li { ${cs.border.color(cs.color.lightgray)} ${cs.font.line(40)} ${cs.h.get(40)} }
        .lbx-cnt { ${cs.disp.none} }
        .lbx-date { ${cs.align.rbottom} ${cs.font.line(10)} }

        .l-tit { ${cs.font.md} }
        .l-req { ${cs.font.orangehover} ${cs.p.l10} ${cs.font.xs} }
      }

      .edit-frame { ${cs.z.popup} ${cs.bg.white} ${cs.pos.relative} ${cs.top(0)} ${cs.left(0)} 
        ${cs.max.w(800)} ${cs.m.center()} ${cs.anim.showin()}
        .tit { ${cs.m.t30} ${cs.box.light} ${cs.m.b20} }
        .e-help { ${cs.disp.block} ${cs.m.b10} ${cs.pos.relative} ${cs.font.gray} ${cs.font.sm} 
          ${cs.font.prewrap} ${cs.m.t3} ${cs.min.h(20)}
          .e-right { ${cs.align.rtop} ${cs.top(0)} } 
        }
        .cont { 
          ${cs.box.light} ${cs.min.h(100)}
        }
        
        &.readonly { ${cs.border.none} ${cs.noselect}
          .btn-bar { ${cs.z.over} ${cs.bottom(5)} ${cs.font.primaryhover} ${cs.pos.relative} ${cs.font.right}
            .btn-back { ${cs.align.lbottom} ${cs.left(-30)} } 
            .btn-delete { ${cs.float.none} ${cs.m.l20} }
          }
          .tit { ${cs.border.none} ${cs.box.bottom(3)} ${cs.border.dark} ${cs.p.b5} ${cs.font.lg} ${cs.font.bold} ${cs.p.r50}} 
          .cont { ${cs.border.none} ${cs.font.md} ${cs.font.prewrap} ${cs.p.bottom(100)} 
            .r-line { ${cs.w.full} ${cs.border.bottom} ${cs.m.v20} ${cs.disp.block} ${cs.font.center} 
              ${cs.pos.relative}
              & > span { ${cs.align.center} ${cs.bg.white} ${cs.p.h10} ${cs.font.sm} ${cs.font.gray} } 
            }
            .r-text { ${cs.m.t10} ${cs.p.a20} ${cs.bg.frame} ${cs.border.radius(5)} }
          }
        }

        .btn-bar { ${cs.m.t10} ${cs.font.right} .button { ${cs.m.l10} } .btn-delete { ${cs.float.left} ${cs.m.l0} } }
      }
    }
    
    
  @media screen and (max-width : 1024px) { }
  
  @media screen and (max-width : 860px) { 
    .br-frame { ${cs.p.h20} }
    .list-box { ${cs.p.t0} 
      &.list {
        .lbx-body .lbx-li { ${cs.font.line(20)} ${cs.h.fit} & > div { ${cs.p.b10} } }
        .l-tit { ${cs.font.sm} }
        .l-req { ${cs.font.xs} }
      }
    }

    &.qna .qna-frame { 
      ${cs.p.h20} 
      .list .search-frame { ${cs.font.left} } 
    }
  }

  @media screen and (max-width : 480px) {
    &.qna .qna-frame { 
      .list-box { ${cs.p.b0} }
      .list .search-frame { ${cs.font.left} ${cs.w.full} .search-box { ${cs.w.full} ${cs.m.bottom(40)} } }
      .btn-new { ${cs.top(40)} }
    }
    // &.qna.qna .qna-frame .list .search-frame .sc-combo .cb-sel { ${cs.w.get(120)} }
  }
}`;

const Qna = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: 'title', value: '' });
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [select, setSelect] = useState(null);
  const [compnay, setCompany] = useState('');
  const order = 'ctime desc';
  const limit = 15;

  const searchs = [
    { name: ST.SUBJECT, id: 'title', check: true },
    { name: ST.CTIME, id: 'ctime', check: false },
    // { name: ST.UTIME, id: 'utime', check: false },
  ];

  useEffect(() => {
    const param = actions.getParam();
    doReload(1, search, order, limit, (array = null) => {
      setLoaded(true);
      if (param) {
        const { rowid } = param;
        if (rowid && array) {
          const t = array.find(a => String(a.rowid) === String(rowid))
          setSelect(t);
        }
      }
      setTimeout(() => global.onLoaded && global.onLoaded(true), 100);
    });
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.reitem) return;

    const rid = props.reitem.rowid;
    const array = list.map(a => a.rowid === rid ? props.reitem : a);
    setList(array);
    setSelect(props.reitem);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reitem])

  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    // setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "${s.value}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(URL.API.QNA, value, true).then(({ result }) => {
      setTotal(result.page && result.page.total)
      setPage(result.page && result.page.current);
      setMax(result.page && result.page.max);
      setSearch(s);
      setCompany(result && result.comp && result.comp.length > 0 && result.comp[0].sval);
      setSelect(null);

      result.list = result.list.map(item => {
        item.subject = `${item.no}. ${item.title}`;
        item.date = item.ctime ? item.ctime.substr(0, 8) : '';
        return item;
      });

      setList(result.list);
      // setRunning(false);

      callback && callback(result.list);
    });
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (rowid, e, item) => {
    setSelect(item);
    props.onClick && props.onClick(item);
  }

  const onClick = () => {
    setSelect(null);
    props.onClick && props.onClick(null);
  }

  const onNew = () => {
    const user = Storage.getUser();
    if (!user) return window.location.href = URL.HOME;

    setSelect({ show: true });
  }

  const onClickBox = () => {
    setSelect(false);
    props.onClick && props.onClick(null);
  }

  const formatter = (item) => {
    const { status } = item;
    let label = '', color = cs.color.gray;
    switch (status) {
      case 'new': label = ST.QNA.NEW; color = cs.color.primaryhover; break;
      case 'req': label = ST.QNA.REQUEST_OK; color = cs.color.orangehover; break;
      default: label = ""; break;
    }

    return <div className={''}>
      <span className={'l-tit'}>{item.title}</span>
      {label && <span className={'l-req'} style={{ color }}>{label}</span>}
      <span className={cx('lbx-date')}>{Util.toStringSymbol(item.ctime).substr(0, 10)}</span>
    </div>
  }

  if (!loaded) return <div></div>;

  return <StyledObject className={cx('qna')}>
    <div className={'qna-frame'}>
      <div className={'title'}>{ST.QNA.TITLE}</div>
      <div className={cx('q-box', select ? 'hide' : 'show')}>
        <Button className={'right top sm gd-gray btn-new'} title={ST.WRITE} onClick={onNew} />
        <Listbox className={cx("list", !select && 'show')} list={list} title={'subject'} naviClass={'primary'}
          pos={page} max={max} total={total} rowid="rowid" searchkey={search.key} searchs={searchs}
          onClickPage={onClickPage} onSelect={onSelect} onClickSearch={onClickSearch} formatter={formatter} />
      </div>
      {select && <Editor data={select} onClick={onClickBox} doReload={() => doReload(1)} openConfirm={props.openConfirm} />}
    </div>
  </StyledObject>
};

export default Qna;

const MAXTITLE = 100;
const MAXTEXT = 3000;

const Editor = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [modified, setModified] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [self, setSelf] = useState(false);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (!props.data || !props.data.rowid) {
      setLoaded(true);
      setEditable(true);
      return;
    }

    actions.doSelect(URL.API.QNA, { rowid: props.data.rowid }).then(({ result }) => {
      if (!result) return setLoaded(true);
      // 1608623180555
      const user = Storage.getUser();
      if (String(user.userid) === String(result.userid)) setSelf(true);

      setData(result);
      setTitle(result.title);
      setText(result.ctext);
      setLoaded(true);
    })

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const onClick = (eid) => {
    console.dir(eid);
    props.onClick && props.onClick(eid);
  }

  const onChange = (eid) => {
    setModified(true);

    let value = Util.toText(refs.title.getValue());
    refs.title.setValue(value);
    setTitle(value);

    value = Util.toText(refs.ctext.getValue())
    refs.ctext.setValue(value);
    setText(value);

    // setTitle(Util.toText(refs.title.getValue()));
    // setText(Util.toText(refs.ctext.getValue()));
  }

  const onSave = () => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    let temp = {};
    Object.keys(refs).map(key => {
      temp[key] = Util.toText(refs[key].getValue());
      return null;
    });

    // const value = { rowid: props.data.rowid || null, ...temp };
    if (props.data.rowid) {
      actions.doUpdate(URL.API.QNA, { rowid: props.data.rowid, ...temp }).then(({ code, result }) => {
        props.doReload && props.doReload(code);
      });
    } else {
      actions.doInsert(URL.API.QNA, { ...temp }).then(({ code, result }) => {
        props.doReload && props.doReload(code);
      });
    }
  }

  const onDelete = () => {
    if (data && data.rtext) {
      props.openConfirm({ size: 'sm', className: 'white', type: 'info', cancel: false, msg: ST.QNA.NO_DELETE });
      return
    }

    props.openConfirm({
      size: 'xs', className: 'white', type: 'info', onClicked: (isOk) => {
        (isOk) && actions.doDelete(URL.API.QNA, { rowid: props.data.rowid }, null).then(({ code, result }) => {
          props.doReload && props.doReload(code);
        });
      }
    });
  }

  const onEdit = () => {
    if (data && data.rtext) {
      props.openConfirm({ size: 'sm', className: 'white', type: 'info', cancel: false, msg: ST.QNA.NO_DELETE });
      return
    }

    setEditable(true);
    setModified(true);
  }

  if (!loaded) return null;
  const readonly = props.data && props.data.rowid;
  return <StyledObject className={cx('edit-frame', { readonly })}>
    {!editable && <div>
      <div className={'tit md'}><p>{title}</p></div>
      <div className={'cont md'}>
        <p>{text}</p>
        {data && data.rtext && <div><span className='r-line'><span>{ST.QNA.REQUEST}</span></span><p className={'r-text'}>{data.rtext}</p></div>}
      </div>
      <div className={'btn-bar'}>
        <Button className={'sm gd-gray btn-delete'} title={ST.DELETE} onClick={() => onDelete()} disabled={!self} />
        <Button className={'sm gd-gray btn-edit'} title={ST.EDIT} onClick={() => onEdit()} disabled={!self} />
        <Button className={'md right top btn-back'} title={ST.PREV} onClick={() => onClick('cancel')} />
      </div>
    </div>}
    {editable && <div>
      <Editbox ref={(ref) => { refs.title = ref }} className={'tit md'} value={title} focus={true} onChange={onChange}
        maxLength={MAXTITLE} guide={ST.QNA.HELP_MAX(MAXTITLE, ST.QNA.SUBJECT)} />
      <Editbox ref={(ref) => { refs.ctext = ref }} className={'cont md sizefix'} value={text}
        multi={true} maxheight={500} minheight={500} height={500} onChange={onChange} maxLength={MAXTEXT}
        guide={ST.QNA.HELP_MAX(MAXTEXT, ST.QNA.TEXT)} />
      <div className={'e-help'} >
        <p >{ST.QNA.HELP(MAXTITLE, MAXTEXT)}</p>
        <span className={'e-right'}>{`${Util.commas(text ? text.length : 0)}/${Util.commas(MAXTEXT)}`}</span>
      </div>
      <div className={'btn-bar'}>
        <Button className={'sm gd-gray btn-cancel'} title={ST.CANCEL} onClick={() => onClick('cancel')} />
        <Button className={'sm gd-gray btn-save'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
      </div>
    </div>}
  </StyledObject>
}