import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Mediabox, Loading, cs, Editbox, Uploadbox, Util } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS, ScrollNavi } from './PageCom';

const StyledObject = styled.div`{ 
  &.page-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()}

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.disp.block}
      .p-cont { ${cs.disp.block} ${cs.h.full} ${cs.w.full} ${cs.p.t30}
        ${cs.align.xcenter} ${cs.pos.relative} ${cs.object.fill} ${cs.p.a0}
        ${cs.min.h(200)} 

        .p-tit { 
          ${cs.p.a0} ${cs.font.t1} ${cs.m.t10} ${cs.font.line(40)} ${cs.opac.show} ${cs.font.spacing(5)}
        }
  
        .p-txt { 
          ${cs.w.full} ${cs.opac.show} ${cs.font.line(20)} ${cs.m.t20} ${cs.scrollbar.t3} ${cs.p.b30}
          ${cs.over.yauto} ${cs.font.prewrap}
        }

        .p-img.link { ${cs.mouse.pointer} }
      }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.pointer} };

      &.loaded { ${cs.opac.visible} }
    }

    @media screen and (max-width : 600px) {
      .p-frame {
        .p-cont {
          .p-tit { ${cs.p.h5} }
          .p-txt { ${cs.p.h5} }
        }
      }
    }
  }
  
  &.page-viewer {
    &.onepage {
      .p-frame .p-cont {
        .p-txt { ${cs.max.h(200)} ${cs.over.yauto} ${cs.scrollbar.t1} ${cs.pos.relative} }
      }

      @media screen and (max-width : 1440px) {
        .p-frame .p-cont {
          .cont-frame { ${cs.object.fit('cover !important')} }
        }
      }
    }

    .p-frame {
      .p-cont {
        .p-img .cont-frame { ${cs.opac.invisible} }
        .p-tit, .p-txt { ${cs.opac.invisible} }

        &.noanim { 
          .p-img .cont-frame { ${cs.opac.visible} }
          .p-tit, .p-txt { ${cs.opac.visible} }
        }
        &.type1 {
          .p-tit, .p-txt { ${cs.opac.invisible} 
            ${cs.anim.showin('0.3s', '0', '1', 'pb-type1-text')} ${cs.anim.delay('2s')}
          }
          .p-img .cont-frame { ${cs.anim.zoomin('3s', '1.1', '1', 1, 'pb-type1')} }
        }
        &.type2 {
          .p-tit, .p-txt { ${cs.opac.invisible} 
            ${cs.anim.slideup('0.5s', '100%', '0', 'pb-type2-text')} ${cs.anim.delay('2s')}
          }
          .p-img .cont-frame { ${cs.anim.foldup('2s', '0', '100%', 'pb-type2', 'ease-out')} }
        }
        &.type3 {
          .p-tit, .p-txt { ${cs.opac.invisible} 
            ${cs.anim.fadein('0.5s', '0', '1', 'pb-type3-text')} ${cs.anim.delay('2s')}
          }
          .p-img .cont-frame { ${cs.anim.rotatezoom('2s', '0', '-1080deg', 'pb-type3', 'ease-out')} }
        }
      }
    }
  }

  &.page-editor { ${cs.p.b40} ${cs.bg.trans}
    .p-frame {
      .p-cont {
        .up-box { ${cs.p.v2} ${cs.min.h(200)} }
        .p-txt { ${cs.m.t20} }

        .p-upframe { ${cs.pos.relative} }

        .input { ${cs.box.line} }
      }
    }

    &.onepage {
      .p-frame .p-cont { ${cs.p.h10} ${cs.p.b20} ${cs.over.yauto} ${cs.scrollbar.t1} }
    }
  }

};`;

const Pagebox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default Pagebox;

const BoxViewer = (props) => {
  const { title, type, link, cont, className, bg, path, url, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const { pageopts } = global;
  const { preview = false } = props;

  const [loaded, setLoaded] = useState(false);
  const [delta, setDelta] = useState(0);
  const [layer, setLayer] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onWheel = (e) => {
    const { currentTarget } = e;
    const isdown = e.deltaY > 0;

    if (currentTarget) {
      const { clientHeight, scrollTop, scrollHeight } = currentTarget;
      const pos = clientHeight + scrollTop;
      setRefresh(new Date().getTime());
      if (isdown && pos < scrollHeight) return e.stopPropagation();
      if (!isdown && scrollTop > 1) return e.stopPropagation();

      const d = delta + Math.abs(e.deltaY);
      setDelta(d);
      if (d < 300) return e.stopPropagation();
      setDelta(0);
    }
  }

  return (
    <Fragment>
      {pageopts && pageopts.style === 'onepage' && !preview && <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />}
      <StyledObject className={cx("page-box page-viewer", className, box.size, pageopts && pageopts.style)}>
        {pageopts && pageopts.style !== 'onepage' && <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />}
        <div className={cx("p-frame", { loaded })} style={{ maxWidth, padding: box.padding, margin: `${box.margin} 0`, ...frame.border }}>
          <div className={cx("p-cont", anim || 'noanim')} style={{ padding: `0 ${OPTS.maxPadding(maxWidth)}` }}>
            <Mediabox className={cx("p-img", { link })} type={type} fit={'fill'} url={urldata} size={"wide"}
              resize={props.refresh} onLoad={onLoad} onError={onError} eid={"url"} maxWidth={maxWidth}
              onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null}
              imagestyle={{ ...box.options }} />
            {title && <p className={cx("p-tit")} style={{ ...label.options }}>{title}</p>}
            {cont && <p className={cx("p-txt")} ref={ref => setLayer(ref)} onWheel={onWheel} style={{ ...desc.options }}>{cont}</p>}
            <ScrollNavi layer={layer} refresh={refresh} />
          </div>
        </div>

        {props.children}
        {!loaded && <Loading />}
      </StyledObject>
    </Fragment>
  )
}

const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onChange = (value, e, label, file) => {
    if (!props.item) return;
    const { request } = props.item;
    if (label === 'url') request[label] = file;
    else request[label] = Util.toText(value);
  }

  const onSelectedMedia = (eid, e) => {
    if (!props.item) return;
    const { request } = props.item;
    request['type'] = eid;
  }

  const { title, type, cont, className, bg, path, url, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box } = OPTS.options(opts);
  const { pageopts } = global;

  return (
    <Fragment>
      <PageBackground src={bgdata} color={frame.color} />
      <StyledObject className={cx("page-box page-editor", className, box.size, pageopts && pageopts.style)} >
        <div className={cx("p-frame", { loaded })}>
          <div className="p-cont">
            <label className={'p-noti'}>{ST.PAGE.IMAGE_H + ` ${ST.IMAGE_SIZE('1200 * 600')}`}</label>
            <div className={'p-upframe'}>
              <Uploadbox className="p-img up-box" type={type} fit={'fill'} value={urldata} size={"wide"}
                onLoad={onLoad} onError={onError} eid={"url"} maxImage={{ width: 2400, height: 1600 }} height={600}
                onSelectedMedia={onSelectedMedia} onChange={(v, e, file) => onChange(v, e, 'url', file)}
                onClickLinked={() => props.onClickLink('show', props.item)} />
            </div>
            <Editbox className={cx("p-tit white", !title && 'nodata')} value={title} guide={ST.PAGE.SUBJECT_H}
              onChange={(v, e) => onChange(v, e, 'title')} maxLength={50} />
            <Editbox className={cx("p-txt white", !cont && 'nodata')} value={cont} guide={ST.PAGE.CONTENT_H(1000)} maxLength={1000}
              onChange={(v, e) => onChange(v, e, 'cont')} multi={true} minheight={200} maxheight={800} height={300} />
          </div>
        </div>
        {props.children}
      </StyledObject>
    </Fragment>
  )
}