import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import reducers from 'object/src/actor/Reducer';
import { Confirm, Alert, Modal, Sidemenu, Util } from 'object/src';
import { Provider } from 'react-redux';
import App from './App';

const store = createStore(reducers);

const onReady = () => {
  // body 페이지 로딩이 완료된후 공통으로 처리할 작업은 여기에서하자..
  // ReactDOM.render(<Footer />, document.getElementById('footer'));
}

const Root = () => {
  useEffect(() => {
    // onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  const onResize = (e) => {
    const { type = 's-pc', width = window.innerWidth } = Util.getScreenType();
    global['device'] = { type, width };
    // console.dir(global['device']);
  }

  onResize();

  return (
    <Provider store={store} >
      <App onReady={onReady} />
      <Confirm />
      <Alert />
      <Modal />
      <Sidemenu />
    </Provider>
  )
}

ReactDOM.render(<Root />, document.getElementById('body'), () => {
  // const obj = document.getElementById('main-title');
  // let comp = Storage.getLocalItem('company_name');
  // if (comp) {
  //   obj.text = comp;
  // } else {
  //   actions.doSelect(URL.API.APPINFO, { stag: 'company', skey: 'name' }).then(({ result }) => {
  //     const company = result && result.length > 0 ? result[0].sval : '';
  //     obj.text = company;
  //     company && Storage.setLocalItem('company_name', company);
  //   });
  // }
});