import * as OBJ from "object/src/Config";

const KOR = {
  ...OBJ.ST,

  START_YEAR: "2020",
  COMPANY_NAME: "COMPANY",
  COMPANY_KOR: "회사명",
  COPYRIGHT: (name = '', y = 2020, isfull = false) => isfull ? `Copyright © ${name} ${y && String(y).substr(0, 4)}, All rights reserved.` : `© ${y && String(y).substr(0, 4)} ${name}`,
  CARDVIEW: "카드보기",
  TABLEVIEW: "리스트보기",
  MEM_COUNT: "명",
  YESTERDAY: "어제",
  TODAY: "오늘",
  WEEK: "주일",
  MONTH: "개월",
  MEMBER_TOTAL: "전체",
  T_SEARCH: "배송조회",
  SEND: '보내기',
  SHORTCAT: '바로가기',
  BACK: '뒤로',
  SELECT: '선택',
  HELPER: '도움말',
  TFORMAT: 'YYYY-MM-DD HH:mm분',
  SUBJECT: "제목",
  NOADMIN: "이 화면은 관리자권한으로 접근이 제한되었습니다.",
  LOGINADMIN: "관리자 권한으로 로그인해야 합니다.\n관리자 로그인 화면으로 이동합니다.",
  LOGINSYSADMIN: "시스템 관리자 권한이 필요합니다.\n시스템 관리자로 다시 로그인하세요.",
  ADMIN_LOGIN: "처음사용을 위해 시스템을 설정해야합니다. 시스템관리자로 로그인하세요.\n(아이디:sysadmin, 초기비밀번호:0000)",
  YES: "예",
  NO: "아니오",
  WRITE: "글쓰기",
  MOBILE_NOTI: "휴대폰에서는 정상적으로 보이지 않을수 있습니다.",
  HOUR: "시",
  MINITE: "분",
  SECOND: "초",
  REST: "기타",
  DIRECT_INPUT: '직접입력',
  MY_PAGE: '화면선택',

  PASSWORD: "비밀번호",
  PASSWORD_EDIT: "비밀번호변경",
  OR: "또는",
  WON: "원",
  NOLIST: (v) => `${v} 내역이 없습니다.`,
  BOARD_TITLE: (v = '') => `${v ? v + '에서 ' : ''}알려드립니다.`,
  REQUMENT_ERROR: (v = '') => `${v}는(은) 필수 입력사항입니다.`,
  PREVPAGE: '이전 페이지',
  NEXTPAGE: '다음 페이지',
  MAXLEN: (t = '', v = 20) => `${t}을 ${v}글자 이내로 작성하세요.`,
  IMAGE_SIZE: (v, s = null) => `이미지 권장사이즈는 [${v}] ${s ? `, 최대사이즈는 [${s}] ` : ''}입니다.`,

  QNA: {
    TITLE: "문의하실 내용이 있으면 아래에 글을 남겨주세요.",
    NO_DELETE: "답변이 달린 글은 편집하거나 삭제할 수 없습니다.\n\n편집 또는 삭제를 원할경우 고객센터에 문의하세요.",
    REQUEST: '답글',
    REQUEST_OK: '답글확인',
    NEW: '새글',
    REQ_NEW: '답글쓰기',
    REQ_EDIT: '답글편집',
    REQ_DELETE: '답글삭제',
    ISDELETE: '답글을 정말로 삭제 하시겠습니까?',
    NO_REQ_TEXT: "아직 답글이 없습니다.",
    SUBJECT: "제목",
    TEXT: "내용",
    NOPHONE: "이메일 또는 연락처 중 하나를 입력하세요.",
    HELP_MAX: (v = 100, t = '') => `${t ? t + '은 ' : ''}${v}자까지 입력할수 있습니다.`,
    HELP: (t = 100, v = 1000) => `제목은 ${t}자, 내용은 ${v}자까지 입력할수 있습니다.`,
  },

  FILES: {
    TITLE: "파일",
    UPLOAD: "파일 업로드",
    SELECT: "파일 선택",
    NO_FILE_TITLE: "파일의 제목을 입력하세요.",
    NO_FILE: "업로드할 파일을 선택하세요.",
    DOWNLOAD: "클릭하면 파일을 다운로드 할수 있습니다.",
    COPY_LINK: "주소복사",
    COPY_OK: "클립보드에 URL 주소가 복사되었습니다.",
    MAX_SIZE: (s = '10MB') => `최대 업로드 파일사이즈는 ${s}까지입니다.`,
  },

  INFO: {
    PRI: (v) => `${v} 서비스를 이용하셨다면 ${v} 아래의 개인정보처리방침이 적용됩니다.`,
    PUB: (v) => `${v} 서비스를 이용하셨다면 ${v} 아래의 이용약관이 적용됩니다.`,
  },

  PAGE: {
    SEL_LAYOUT: '레이아웃을 선택하세요.',
    SEL_ITEM: '항목을 선택하세요.',
    SEL_PAGE: '클릭시 이동할 화면을 선택하세요.',
    NEW_PAGE: '새로운 페이지를 추가하려면\n여기를 클릭하세요.',
    SUBJECT: '제목',
    CONTENT: '내용',
    EMAIL: '이메일',
    PHONE: '연락처',
    QNA_SAVE: '문의접수',
    QNA_TITLE: '문의내용',
    QNA_OK: '문의접수가 완료되었습니다.',
    BG_H: '배경 이미지 추가(편집)하려면 여기를 클릭하세요',
    IMAGE_H: '업로드할 이미지를 선택하세요.',
    EMAIL_H: '답변 받을 이메일을 입력하세요.',
    PHONE_H: '연락 가능한 연락처를 입력하세요.',
    SUBJECT_H: '제목을 입력하세요.',
    CONTENT_H: (v = 100) => `내용을 입력하세요(최대 ${v}글자).`,
    BG_ADD: '배경에 이미지를 추가하려면 여기를 클릭하세요.',
    BG_TITLE: '추가(편집)할 배경이미지를 선택하세요.',
    NEW_IMG: '이미지를 추가하려면 여기를 클릭하세요.',
    MAX_IMG: '더이상 추가할수 없습니다.',
    SAVE_IMG: '추가(편집)한 이미지를 먼저 저장하세요.',
    BOX_BG: '박스의 배경이미지를 추가(편집)하려면 여기를 클릭하세요.',
    PRE_SAVE: '추가하려면 먼저 저장 해야합니다.\n 저장후 계속 진행 하시겠습니까?',
    NO_SAVE_IMG: '이미지를 먼저 업로드하세요.',
    NEW_ITEM: '추가하려면 여기를 클릭하세요.',
    DIRECT_INPUT: '클릭시 이동할 LINK(URL)을 아래에 직접 입력하세요.',
    QNA_GUIDE: '아래는 문의하기 화면 레이아웃으로 미리보기 화면입니다.',
    QNA_HELP: '고객이 문의하기 작성시 참고할 내용을 아래에 입력하세요.',
    BANNER_H: '필요할 경우 타이틀(상단표시)과 내용(하단표시)을 입력할수 있습니다.',
    BANNER_G: '[미리보기] 버튼 클릭시 아래에 표시됩니다.',
    BANNER_L: '1개 이상의 항목을 추가할 경우 사용자 접식시 무작위(랜덤)로 1개의 항목이 표시됩니다.\n최대 5개끼자 추가가 가능합니다.',
    TILE_EVEN_NOTI: '타일 이미지는 짝수개 여야합니다.',
    MULTI_UPLOAD_TITLE: '추가할 이미지를 선택하세요.',
    // UPLOAD: (v = 10) => `한번에 업로드할 수 있는 최대개수는 ${v}개입니다.`,
    MULTI_UPLOAD: (v = 10) => `한번에 업로드할 수 있는 최대개수는 ${v}개입니다.`,
    MOVE_MENU: (v) => `해당 페이지를 [${v}] 메뉴 끝으로 이동하였습니다.`,
    MAX_LEN: (v = 10) => `더이상(최대 ${v}개) 추가할 수 없습니다.`,
    UPLOAD_MAX: (v = 10) => `등록 가능한 최대개수는 ${v}개 입니다.`,
    UPLOAD_COUNT: (v = 10) => `현재 등록된 개수는 ${v}개 입니다.`,
    LINK_NOTI: (v = 'https://') => `LINK(URL)은 ${v}로 시작해야합니다.`,
  },

  OPTS: {
    TITLE: "화면 옵션 설정",
    BOX: "박스",
    LAYOUT: "레아아웃",
    LABEL: "제목",
    SUBJECT: "타이틀",
    ALIGN: "정렬",
    WEIGHT: "두께",
    DESC: "설명",
    PADDING: "안쪽여백",
    MARGIN: "바깥여백",
    BORDER: "외곽선",
    SIZE: "크기",
    TOPMARGIN: "위(아래)쪽여백",
    BOTTOMMARGIN: "아래쪽여백",
    FONT: "폰트",
    COLOR: "컬러",
    RADIUS: "모서리반경",
    BACKGROUND: "배경",
    FRAME: "프레임",
    ANIMATION: "에니메이션",
    EFFECT: "효과",
    LINE: "경계선",
    TOP: "위",
    BOTTOM: "아래",
    CONTENT: "내용",
    TEXT: "설명",
    LEFT: '왼쪽정렬',
    RIGHT: '오른쪽정렬',
    CENTER: '가운데정렬',
    THIN: '얇은',
    SEMI: '중간',
    BOLD: '두꺼운',
    THICK: '아주두꺼운',
    MARGIN_H: "레이아웃 외부 위/아래 여백 옵션을 설정합니다.",
    PADDING_H: "레이아웃 내부 위/아래(좌/우) 여백 옵션을 설정합니다.",
    BOXBORDER_H: "이미지(박스) 테두리 두께",
    BOXSIZE_H: "이미지,제목,내용 등의 중앙을 중심으로\n왼쪽 끝에서 오른쪽 끝까지의 최대 크기.",
    RADIUS_H: "이미지(박스)의 모서리 둥글기.",
    ANIMATION_H: "화면등장 효과를 레이아웃에따라 선택해보세요.",
  },

  COMPANY: {
    TITLE: '회사소개',
    INFO: '회사소개',
    BOSS: '대표이사',
    BOARD: '공지사항',
    QNA: '문의하기',
    PRIVATE: '개인정보취급방침',
    PUBLIC: '이용약관',
    LICENSE: '사업자등록번호: ',
    BIZ_LINCESE: '통신판매업신고: ',
    BIZ_URL: '(사업자정보확인)',
    TEL: '고객센터: ',
    EMAIL: '이메일: ',
    FAX: '팩스: ',
    KAKAO: '카카오톡 옐로아이디: 비움반찬',
    BANK: '무통장입금 계좌번호',
  },

  BOARD: {
    TITLE: '공지사항',
    POPUP: '공지팝업관리',
    IMAGE: '이미지 추가',
    SUBJECT: '제목',
    TEXT: '내용',
    ENABLE: '팝업보이기',
    DISABLE: '팝업숨기기',
    LINK: '상세 공지사항 선택하기(연결하기)',
    LINKAT: (v) => `[${v}]로 연결합니다`,
    LINK_H: '클릭시 이동할 공지사항이 있으면 선택하세요',
    CLOSE: '더이상 보지않기',
    IMAGE_SAVE: '이미지가 변경되었습니다.\n이미지업로드를 하시겠습니까?',
  },

  MENU: {
    HOME: '홈',
    BOARD: '공지사항',
    QNA: '문의하기',
    FILE: '파일',
  },

  MAKER: {
    SELECT: '선택하세요',
    HISTORY: '히스토리(내용)을 여기에 입력하세요.',
    TYPE1: '스타일 A',
    TYPE2: '스타일 B',
    TYPE3: '스타일 C',
    TYPE4: '스타일 D',
  },

  ADMIN: {
    PREVIEW: '미리보기',
    TITLE: "제목",
    UPLOAD: "업로드",
    AUTO_UPLOAD: (v) => `${v}초후 자동업로드`,
    UPLOAD_NOTI: "업로드 파일을 선택하세요",
    RESET_PWD: "기본 비밀번호는 보안에 취약합니다.\n관리자 비밀번호를 재발급하세요.",

    NOTI: {
      CLEAR: (v) => `${v}건 모두삭제`,
      EDITIMG: '편집중인 항목이 있습니다.\n먼저 저장해야합니다.',
      FULL_PAGE: '박스크기를 FULL(fullscreen)로 할경우 실제(사용자) 화면과 관리자 화면이 다르게 보일수 있습니다.\n상단에 미리보기(Preview)로 실제 화면을 확인하세요',
    },

    SYSINIT: {
      GUIDE: '설정 가이드 바로가기',
      CONFIRM: "처음방문을 환영합니다.\n\n시스템 처음 사용을 위한 설정을 시작하세요.",
      TITLE: "시스템 처음 사용을 위한 설정 안내입니다.",
      GBOOK: "가이드북을 다운받으시려면 여기를 클릭하세요.",
      POP_CLOSE: "더이상 이 팝업 보지않기.",
      LINK: "바로가기",
      ABOUT: "회사소개",
      ABOUT_H: "대표이미지/슬로건/경영철학/오프라인 매장 정보 등을 설정하세요.",
      ABOUT_G: " - 대표이미지: 회사소개 페이지에 작게 보여질 대표 이미지(아이콘)을 설정하세요.\n"
        + " - 슬로건: 회사를 소개할 문장을 간결하게 입력하세요.\n"
        + " - 경영철학: 경영철학이 있다면 여기에 입력하세요.\n"
        + " - 회사위치: 회사의 주소 및 위치를 입력합니다.",
      SETTING: "기본설정",
      SETTING_H: "회사정보/개인정보취급방침/이용약관 등을 설정하세요",
      SETTING_G: " - 회사정보: 웹페이지 하단 및 회사 정보가 필요한 곳에 표시할 회사이름,고객센터,사업자등록번호 등, 회사 정보를 입력해야합니다.\n"
        + " - 개인정보취급방침: 필수항목으로 정보통신법(?)에 의하여 개인정보취급방침을 입력해야합니다.\n"
        + " - 이용약관: 필수항목으로 정보통신법(?)에 의하여 이용약관을 입력해야합니다.\n",
    },

    MAIN: {
      NOTIFY: '새로운 알림',
      USERS: '사용자 통계',
      ORDERS: '주문/배송 통계',
      ORDERING: '주문/배송 대기중(최근 3일)',
    },

    MGMT: {
      TITLE: '시스템 관리',
      INITS: '시스템 설정',
      TABLES: '테이블 관리',
      ADMINS: '관리자 설정',
      WEBSET: '웹앱 설정',
      SEARCH: '검색 사이트 등록',
      LOGINS: '로그인 로그',
      QUERYS: '쿼리 로그',
      USER: '사용자',
      ADMIN: '관리자',

      NO_ENGLISH: '계정은 영문 및 숫자만 입력할수 있습니다.',
      PASSWORD: "특수문자/영문/숫자를 조합하여 8~20자로 입력하세요",
      NO_DELETE_ADMIN: "기본 관리자는 삭제할수 없습니다.",
      PASS_RESET: (v) => `비밀번호가 ${v}로 변경되었습니다.`,

      ROWID: '고유아이디',
      USERID: '회원번호',
      ACCOUNTID: '계정아이디(권한)',
      ACCOUNT: '계정아이디',
      AUTH: '권한',
      CONTENT: '내용',
      LOGINTIME: '마지막로그인시간',

      TYPE: '타입',
      QUERY: '쿼리 내용',
      ERROR: '에러 내용',

      TABEL: '테이블명',
      ROWS: '레코드수',
      COMMENT: '설명',
      SIZE: '사용량(MB)',
      CLEAR: '내역정리',
      CLEAR_NOTI: '정리가 필요한 테이블은 [내역정리]에 정리버튼이 활성화됩니다.\n6개월 주기마다 필요에따라 자동정리되므로 정리버튼이 활성화되도 정리가 필수 사항은 아닙니다.',
      TABLE_INIT: '데이터베이스 정보를 재설정하고 시스템을 리부팅힙니다.\n설정이 잘못될 경우, 웹서버에 오류가 발생할 수 있다는 것에 주의하세요.\n웹 시스템을 관리하는 관리자만 이 정보를 설정/관리하시기 바랍니다.',

      THREE: '3개월',
      FOUR: '4개월',
      FIVE: '5개월',
      CLEARL_MSG: '선택한 기간 이전데이터를\n모두 정리(삭제) 하시겠습니까?',

      PREVIEW: '미리보기(링크 공유시 카카오톡 등에 아래와 같이 보여집니다.)',
      HOST_ONLY: '특수문자(. - _) 및 영/숫자만 입력할수 있습니다.',
      INPUT_ONLY: 'database/host/user 입력란에는 특수문자(. - _) 및 영/숫자만 입력할수 있습니다.',
      DAB_SAVE: 'DB 설정을 변경 하시겠습니까?\n\n변경후 장애가 발생되면 이후 이 화면에서는 설정이 불가능합니다.',
      CREATEDB: 'DB 생성',
      CREATE_DB_PASS: 'DB 생성을 위한 인증코드를 입력하세요.',
      CREATE_DB_PCODE: 'DB 권한 생성을 위한 비밀번호를 입력하세요.',
      CONN_TEST: '연결테스트',
      CONN_SUCCESS: "DB 연결에 성공하였습니다.\n저장후 리부팅하세요.",
      CONN_FAIL: "DB 연결에 실패하였습니다.\n설정 정보를 확인후 다시 시도하세요.",
      INIT_SUCCESS: (v = 10) => `${v}초후 시스템이 재시작됩니다.\n재로그인을위해 관리자 로그인 화면으로 이동합니다.`,

      SEARCH_SAVE: '검색 등록을 위한 설정 정보를 변경 하시겠습니까?\n\n변경후 웹서버가 재부팅되는데 수초의 시간이 필요합니다.',
      SEARCH_NAVER: 'HTML 메타 태그를 여기에 붙여넣으세요.',

      WEBSET_SAVE: '웹앱설정을 변경 하시겠습니까?\n\n변경후 웹서버가 재부팅되는데 수초의 시간이 필요합니다.',
      WEBSET_DOMAIN_RE: (s = '', t = '') => `현재 설정된 URL(${s})과 접속한 URL(${t})이 다릅니다.\n접속한 URL로 재설정 하시겠습니까?`,
      WEBSET_URL_H: '접속중인 홈페이지 URL(https://www.oooooo.co.kr)을 입력하세요.',
      WEBSET_TITLE_H: '웹사이트의 이름을 입력하세요(GOOGLE/NAVER/DAUM 등 검색엔진이 이 값을 참고합니다)',
      WEBSET_DESC_H: '웹사이트의 간단한 설명 입력하세요(GOOGLE/NAVER/DAUM 등 검색엔진이 이 값을 참고합니다)',
      WEBSET_MAIN_H: '웹페이지 표시(로딩)전 상단탭에 표시되는 제목입니다(로딩이 완료되면 회사명으로 자동 표시됩니다)',
      WEBSET_ICON_H: '웹페이지 상단탭 앞에 표시되는 아이콘입니다\n아이콘 변경시 화면을 완전히 종료후 다시 접속하면\n수정한 아이콘을 확인할수 있습니다.',
      WEBSET_IMAGE_H: '웹페이지를 대표하는 이미지입니다.\n일반적으로 카카오톡 등에 URL 링크 공유시 표시되는 이미지입니다.\n(최소 200*200 이상, 최대 1200*630, 권장: 600*300)',

      NOTI: {
        NOPASS: '인증코드 및 비밀번호를 입력하세요',
        FAIL: '처리중 오류가 발생했습니다.',
        SUCCESS: '정상적으로 처리중입니다.',
      }
    },

    APPSET: {
      DEFAULT: '기본정보',
      COMPANY: '회사정보',
      TRANS: '배송일반',
      COMPANY_NAME: '회사이름',
      COMPANY_YEAR: '설립년도',
      COMPANY_NAME_ENG: '회사이름(영문)',
      COMPANY_PHONE: '고객센터 대표번호',
      COMPANY_EMAIL: '고객센터 이메일',
      COMPANY_ADDR: '회사주소',
      COMPANY_ADDR_REST: '상세주소',
      COMPANY_BOSS: '대표이사',
      COMPANY_LICENSE: '사업자등록번호',
      COMPANY_BIZ_LICENSE: '통신판매업신고',
      COMPANY_BIZ_URL: '공정거래위워회 사이트 라이선스(URL)',
      COMPANY_FAX: '팩스번호',
      COMPANY_BANK: '은행 계좌번호',
      IMPORT: '샘플 불러오기',
      PRIVACY: '개인정보취급방침 안내문을 아래에 입력하세요.\n샘플 불러오기를 통해 가져올 경우 내용을 모두 살펴보신후 본인에 맞도록 수정후 사용하시기 바랍니다.\n특히 ***** 등으로 표시된 부분은 꼭 수정하시기 바랍니다.',
      PUBLIC: '이용약관 안내문을 아래에 입력하세요\n샘플 불러오기를 통해 가져올 경우 내용을 모두 살펴보신후 본인에 맞도록 수정후 사용하시기 바랍니다.\n특히 ***** 등으로 표시된 부분은 꼭 수정하시기 바랍니다.',
      NO_CHANGE: '변경/삭제할수 없습니다.',
      NOT_TEXT: `특수문자 " \\ 는 입력할 수 없습니다.`,
    },

    ABOUT: {
      TITLE: '회사 정보 설정',
      INFO: '회사소개 화면 설정',
      SLOGAN: '경영철학 화면 설정',
      STORE: '오프라인 매장 화면 설정',
      LABEL: '회사 슬로건 또는 타이틀(20자이내)',
      SUMMARY: '회사 철학 또는 이력 등',
      COMPANY: '회사명',
      ICON_IMG: '회사 메인 아이콘(중간 아이콘)',
      IMG_SIZE: '권장사이즈(120 * 120)',
      BOSS_IMG: '대표 이미지(썸네일)',
      BACK_IMG: '회사소개 배경 이미지',
      BACK_IMG_NOTI: '여기에 이미지를 업로드하면 회사소개 화면의 흰바탕화면을 배경이미지로 교체할 수 있습니다.\n최대 16:9 비율의 ([2000 * 1100 or 1600 * 900] 사이즈를 권장합니다. 현재 보이는 사이즈는 [640 * 320]입니다.',
      STORE_IMG: '대표매장 이미지(썸네일)',
      ADDRESS: '매장 주소',
      STORE_TITLE: '지점명(본점 or 1호점 or 강남점 등)',
      PHONE: '매장 대표연락처',
      OPEN: '매장 영업시간 (예시: 11시 ~ 20시 [토요일 19시 / 일요일 휴무])',
      STORE_NOTI: '본사 연락처 및 주소는 [회사설정] 메뉴에서 수정할 수 있습니다.',
    },

    NAVI: {
      MAIN: '메인',
      MENU: '메뉴관리',
      MEMBER: '회원관리',
      BOARD: '공지사항',
      ABOUT: '회사소개',
      SETTING: '기본설정',
      PAGE: '페이지설정',
      SYSINIT: '초기설정',
      FILE: '파일관리',
      MANAGEMENT: '시스템관리',
      GUIDE: '도움말',
      USER: '관리자',
      LOGIN: '로그인',
      QNA: '게시판',
      PRODQNA: '제품문의',
      LOGOUT: '로그아웃',
    },

    LOGIN: {
      TITLE: "로그인",
      WELCOME: `관리자 로그인`,
      PASSWORD_FIND: "비밀번호 찾기",
      NOTI_LOGIN: "로그인 정보를 정확히 입력하세요.",
    },

    MEMBER: {
      TITLE: '회원',
      NO: "번호",
      USERID: "회원번호",
      ACCOUNTID: "사용자아이디",
      AUTH: "권한",
      NAME: "이름(닉네임)",
      PHONE: "연락처",
      EMAIL: "이메일",
      SDATE: "가입일",
      PASSWORD: "비밀번호",
    },

    HOME: {
      TITLE: '슬라이드 항목 설정',
      NOTI: '슬라이드할 목록을 아래에 등록하세요.',
      LINK: '여기를 클릭해서 이 페이지와 연결될 URL를 설정하세요.',
      MENUS: '메뉴로 바로가기',
      PRODUCTS: '상품으로 바로가기',
    },

    MENU: {
      TITLE: '메뉴',
      GUIDE: '* [메인홈]은 사용자가 홈페이지를 방문할 경우 바로 보여지는 홈 화면입니다.',
      VISIBLE: "메뉴 비활성화",
      VISIBLE_G: "체크를 해제하면 메뉴를 표시하지 않습니다.",
      LABEL: "소개문구",
      LABEL_G: "50자 이내로 간단한 설명을 추가하세요.",
      HOME: "전체보기",
      PAGE: "패이지수",
      SELECT: '선택변경',
      // LEFT: '왼쪽정렬',
      // RIGHT: '오른쪽정렬',
      // CENTER: '가운데정렬',
      HOME_G: "메인(전체보기) 화면으로 표시됩니다.",
      MAX_NOTI: (c = 20) => `메뉴는 최대 ${c}개까지만 등록할수 있습니다.`,
      SEL_STYLE: "페이지에 대한 화면스타일을 선택하세요.",
      DELETE_NOTI: (m, v) => `[${m}] 메뉴에 등록된 페이지가 ${v}개 있습니다.\n그래도 삭제하길 원하시면 "delete" 문자를 아래에 입력하세요.`,

      STYLE: {
        SCROLL: "스크롤타입(일반형)",
        ONEPAGE: "원페이지타입(페이지전환형)",
        SCROLL_H: "일반 상하 스크롤 형식의 여러 레이아웃을 마우스 상하 스크롤로 이동하는 스타일.",
        ONEPAGE_H: "하나의 페이지 하나의 레이아웃을 가운데 배치하고,\n스크롤시 페이지를 전환하는 스타일.",
      },
    },

    PAGE: {
      TRIPLE_NORMAL: '일반형',
      TRIPLE_WIDE: '와이드형',
      NORMAL: '일반형',
      MEDIUM: '중형',
      SMALL: '소형',
      LARGE: '대형',
      COUNT: (n = 1) => `${n}개`,
      BOX_SIZE: '이미지 크기',
    },

    ANIM: {
      ZOOMIN: '작은 이미지를 점점 크게 확대하는 에니메이션',
      ZOOMOUT: '화면보다 큰 이미지를 점점 작게 축소하는 에니메이션',
      SHOWIN: '투명 상태의 이미지를 불투명 상태로 점점 보여주는 에니메이션',
      SLIDEUP: '이미지를 화면 아래에서 위로 올리며 보여주는 에니메이션',
    }
  },

  HOME: {
    TITLE: "전체",
    MAIN: "전체",
  },

  ABOUT: {
    TITLE: '회사소개',
    INFO: '회사소개',
    SLOGAN: '경영철학',
    STORE: '회사위치 안내',
    SHOP: '홈페이지가기',
    LABEL: '레이블.....',
    SUMMARY: `소개 내용.....`,
    STORE_COME: '오시는길',
    STORE_OPEN: '영업시간',
    PHONE: '고객선터 대표번호',
    ADDR: '본사주소',
  },

  LOGIN: {
    TITLE: "로그인",
    WELCOME: `안녕하세요! 누리숍입니다.`,
    KAKAO: "카카오계정으로 바로 이용하기",
    NAVER: "네이버로 로그인",
    FACEBOOK: "페이스북으로 로그인",
    PASSWORD_FIND: "아이디/비밀번호 찾기",
    NOTI_LOGIN: "로그인 정보를 정확히 입력하세요.",
    REST_LOGIN_ERROR: "일시적 오류가 발생하였습니다.\n잠시후 다시 이용해주세요",
    SIGNUP_ERROR: "회원가입을 먼저 해주세요.\n회원가입 화면으로 이동합니다.",
    SIGNUP_TIME: (v) => `회원가입을 먼저 해주세요.\n${v}초후 회원가입 화면으로 이동합니다.`,
  },
}

export const ST = KOR;

/**
 * 이미지들에 대한 정의
 */
export const IMG = {
  GMAIL_1: require('../images/gmail_1.jpg'),
  GMAIL_2: require('../images/gmail_2.jpg'),
  GMAIL_3: require('../images/gmail_3.jpg'),
  GMAIL_4: require('../images/gmail_4.jpg'),
  GMAIL_5: require('../images/gmail_5.jpg'),
  GMAIL_6: require('../images/gmail_6.jpg'),
  GMAIL_7: require('../images/gmail_7.jpg'),
  GMAIL_8: require('../images/gmail_8.jpg'),
  GMAIL_9: require('../images/gmail_9.jpg'),
  GMAIL_10: require('../images/gmail_10.jpg'),

  NAVER_1: require('../images/naver_1.jpg'),
  NAVER_2: require('../images/naver_2.jpg'),
  NAVER_3: require('../images/naver_3.jpg'),
  NAVER_4: require('../images/naver_4.jpg'),
  NAVER_5: require('../images/naver_5.jpg'),
  NAVER_6: require('../images/naver_6.jpg'),
  NAVER_7: require('../images/naver_7.jpg'),
}

export const HELP = [
  { type: 'text', value: '', url: '' },
  { type: 'image', value: '', url: '' },
  { type: 'desc', value: '' },
  { type: 'line', value: '2px' },
];

export const GMAIL_HELP = [
  { type: 'text', value: '1. 보내기 메일 GMAIL 설정 방법', url: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp&hl=ko' },
  { type: 'image', value: IMG.GMAIL_1, url: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp&hl=ko' },
  { type: 'desc', value: '보내기 메일을 무료 메일인 GMAIL로 설정하기위해서 GMAIL(https://www.google.com/gmail/)을 방문하여 먼저 가입하세요' },
  { type: 'desc', value: '만약 사용중인 GMAIL이 있다면 GMAIL(https://myaccount.google.com/lesssecureapps)에 방문하여 로그인을 하세요' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '2. 웹 서버에서 메일 전송을 위한 Gmail 설정 방법', url: 'https://support.google.com/accounts/answer/185833?hl=ko' },
  { type: 'image', value: IMG.GMAIL_2, url: 'https://support.google.com/accounts/answer/185833?hl=ko' },
  { type: 'desc', value: 'GMAIL 보내기 메일 서버 설정을 위해서 [앱 비밀번호]를 생성해야 합니다' },
  { type: 'desc', value: '상세 내용을 확인하고 싶다면 여기(https://support.google.com/accounts/answer/185833?hl=ko)를 방문하세요.', url: 'https://support.google.com/accounts/answer/185833?hl=ko' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '3. [앱 비밀번호] 설정하기', url: 'https://myaccount.google.com/security' },
  { type: 'image', value: IMG.GMAIL_3, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(1) [2단계 인증]을 활설화 해야합니다.' },
  { type: 'image', value: IMG.GMAIL_4, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(2) [2단계 인증]을 시작하여, 따라하면 2단계 인증을 활성화 할수 있습니다.' },
  { type: 'image', value: IMG.GMAIL_5, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(3) [2단계 인증]을 시작하여, 따라하면 2단계 인증을 활성화 할수 있습니다.' },
  { type: 'image', value: IMG.GMAIL_6, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(4) 비밀번호 입력창이 표시되면 사용중인 이메일 계정의 비밀번호를 입력하고 [다음]을 클릭하세요.' },
  { type: 'image', value: IMG.GMAIL_7, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(5) [앱 선택]에서 [메일]을 선택하고 [기기 선택]에서 [Windows 컴퓨터]를 선택하세요.\n - 맞춤 이름을 선택하여 사용자가 직접 입력해도 됩니다.' },
  { type: 'image', value: IMG.GMAIL_8, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(6) 생성된 [앱 비밀번호] 16자를 복사합니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '4. 복사한 [앱 비밀번호]를 해당 입력란에 붙여넣으세요', url: '/admin/mailer' },
  { type: 'image', value: IMG.GMAIL_7, url: '/admin/mailer' },
  { type: 'desc', value: '비밀번호를 붙여넣을때 중간에 공백이 없어야 합니다(복사붙여넣기를 하면 공백은 복사되지 않습니다).' },
  { type: 'text', value: '5. [EMAIL TEST] 버튼을 클릭하면 잠시후 다음과 같은 화면이 표시되어야 합니다.', url: '/admin/mailer' },
  { type: 'image', value: IMG.GMAIL_8, url: '/admin/mailer' },
  { type: 'desc', value: '위와 같은 메시지가 표시되면 해당 메일이 도착했는지 이메일을 확인해보시기 바랍니다. 메일을 정상적으로 받았다면 메일 설정이 정상적으로 동작하는 것입니다.' },
  { type: 'line', value: '5px' },
  { type: 'text', value: '*만약 웹 서버를 설정하였으나, 메일 테스트시 정상적으로 동작하지 않는다면 아래의 내용을 확인해 보시기 바랍니다.', url: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp' },
  { type: 'desc', value: '보안 수준이 낮은 앱 엑세스 허용하기 위해 아래의 메뉴로 진입하세요' },
  { type: 'desc', value: '[Google 계정 관리 -> 보안 -> 보안 수준이 낮은 앱의 액세스]를 사용으로 변경하세요' },
  { type: 'desc', value: '여기(https://myaccount.google.com/lesssecureapps)를 접속하여 [보안 수준이 낮은 앱 허용: 사용]으로 설정하거나', url: 'https://myaccount.google.com/lesssecureapps' },
  { type: 'desc', value: '여기(https://accounts.google.com/DisplayUnlockCaptcha)를 접속하여 절차에따라 [계속]을 클릭하세요', url: 'https://accounts.google.com/DisplayUnlockCaptcha' },
];


export const NAVER_HELP = [
  { type: 'text', value: '1. 네이버 사이트 등록 방법', url: 'https://searchadvisor.naver.com/' },
  
  { type: 'text', value: '2. 웹마스터도구로 접속', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_1, url: 'https://searchadvisor.naver.com/' },
  { type: 'desc', value: '네이버 사이트 검색 등록을 위해서 네이버(https://searchadvisor.naver.com/)을 방문하여 먼저 가입하세요' },

  { type: 'text', value: '3. 사용 중인 도메인 주소 입력', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_2 },
  { type: 'desc', value: '사이트 정보 입력에서는 사용하고 있는 도메인의 주소를 입력하세요' },

  { type: 'text', value: '4. 사이트 소유확인', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_3 },
  { type: 'desc', value: '검색 등록을 위해 사이트 소유확인이 필요하며 두번째의 HTML 태그 방식을 선택하여 아래의 내용을 복사하세요' },

  { type: 'text', value: '4. 사이트 소유확인', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_4 },
  { type: 'desc', value: '검색 등록을 위해 사이트 소유확인이 필요하며 두번째의 HTML 태그 방식을 선택하여 아래의 내용을 복사하세요' },
  { type: 'desc', value: '등록한 내용을 시스템에 적용하기위해 약 10초후 웹시스템이 재시작됩니다.' },

  { type: 'text', value: '5. 마지막 등록 확인', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_5 },
  { type: 'desc', value: '위와 같이 보안코드가 표시되고, 보안 코드를 정상적으로 등록했다면' },
  { type: 'image', value: IMG.NAVER_6 },
  { type: 'desc', value: '등록이 완료되었다는 메시지를 확인할수 있습니다.' },  

  { type: 'line', value: '5px' },

  { type: 'text', value: '# 사이트 간단 체크', url: 'https://searchadvisor.naver.com/tools/sitecheck' },
  { type: 'image', value: IMG.NAVER_7 },
  { type: 'desc', value: '사이트 간단 체크를 사용하여 등록된 웹사이트의 상태를 체크할 수 있습니다.' },
  { type: 'desc', value: '*** 네이버의 검색로봇이 방문한 뒤 최대 1주일 이내에 사이트의 콘텐츠가 검색에 반영됩니다. 네이버 검색창에 사이트명을 입력해 보세요. 또한, 웹문서 검색에서 site: 질의를 활용하면 특정 사이트의 콘텐츠 검색 반영 여부를 확인할 수 있습니다.' }
];