import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Util, Listbox } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.qna-list { ${cs.w.full} ${cs.max.wlg} ${cs.min.h('calc(100vh - 100px)')} ${cs.align.xcenter} ${cs.pos.relative} 
    ${cs.h.fit} ${cs.p.h50} ${cs.p.b40}

    &.show { ${cs.disp.block} }
    &.hide { ${cs.disp.hide} }

    .list { ${cs.pos.relative} ${cs.font.xl} ${cs.min.h('70vh')}
      .search-frame { ${cs.font.center}
        .search-box { ${cs.bg.white} ${cs.box.light}
          .input { ${cs.bg.white} }
          .cb-sel { ${cs.bg.trans} }
        }
      }
      .lbx-body .lbx-li { ${cs.border.color(cs.color.lightgray)} ${cs.font.line(22)} ${cs.h.fit} }
      .lbx-cnt { ${cs.disp.none} }
      .lbx-date { ${cs.align.rbottom} ${cs.font.line(10)} }

      .l-tit { ${cs.font.md} }
      .l-req { ${cs.font.orangehover} ${cs.p.l10} ${cs.font.xs} }
    }
  }

  &.qna-view {
    ${cs.w.full} ${cs.max.wlg} ${cs.min.h('calc(100vh - 100px)')} ${cs.align.xcenter} ${cs.pos.relative} 
    ${cs.h.fit} ${cs.p.h50} ${cs.p.b40}

    &.show { ${cs.disp.block} }
    &.hide { ${cs.disp.hide} }

    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.m.t0} 
      ${cs.font.line(36)} ${cs.disp.block} ${cs.font.ellipsis}
      .tit { ${cs.disp.inblock} ${cs.mouse.pointer} &:hover { ${cs.font.primary} } }
      .sim { ${cs.disp.inblock} ${cs.p.h10} ${cs.font.gray} }
    }

    .close { ${cs.m.t10} ${cs.right(30)} }
    
    .p-box { ${cs.p.v20} ${cs.border.bottom} ${cs.border.width(3)} ${cs.p.h10}
      .p-row { ${cs.p.a5} ${cs.m.b10} ${cs.disp.block} ${cs.w.full} ${cs.pos.relative} 
        ${cs.font.line(20)} ${cs.font.prewrap} ${cs.font.md} ${cs.border.bottom} ${cs.border.alphablack}
        &:last-child { ${cs.border.none} }
        .pl { ${cs.pos.relative} ${cs.disp.inblock} ${cs.w.get(100)} ${cs.float.left} }
        .pr { ${cs.pos.relative} ${cs.disp.inblock} ${cs.w.calc('100% - 100px')} }
        .desc { ${cs.min.h(100)} }
      }
    }
  }

  &.prod-qna { ${cs.bg.trans} ${cs.max.wlg} ${cs.w.full} ${cs.m.t30}
    ${cs.m.left(0)} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(30)}

    .q-scroll { ${cs.pos.sticky} ${cs.bottom(40)} ${cs.float.left} ${cs.m.l50} }

    .qna { ${cs.bg.dark} ${cs.font.white} ${cs.m.t30}
      .qna-frame { ${cs.min.h('calc(100% - 200px)')}
        & > .title { ${cs.disp.hide} } 
        .list .lbx-body .lbx-li { ${cs.font.white} 
          .l-req { ${cs.font.orange} } 
          &:hover { ${cs.bg.darkhover} }
        }
      }
    }
  }
  
  @media screen and (max-width : 1024px) { 
    &.prod-qna { 
      .tool-bar { ${cs.right(20)} } 
      .q-scroll { ${cs.m.l0} ${cs.left(0)} ${cs.bottom(10)} }
    }    
  }

  @media screen and (max-width : 800px) {
    &.prod-qna .tool-bar { ${cs.p.r0} }
  }
}`;

const ProdQna = (props) => {
  const [select, setSelect] = useState(null);

  useEffect(() => { }, []);
  const onClickItem = (v) => {
    setSelect(v);
  }

  return <StyledObject className={cx('prod-qna')}>
    <QnaList className={cx(select ? 'hide' : 'show')} onClick={onClickItem} />
    {select && <QnaView item={select} onClick={() => setSelect(null)} />}
  </StyledObject>
};

export default ProdQna;

const QnaView = (props) => {
  const { item } = props;

  const onClose = () => {
    props.onClick && props.onClick('close');
  }

  const opts = item ? JSON.parse(item.opts) : {};
  return <StyledObject className={cx('qna-view', props.className)}>
    <div className={cx('title')}>
      <span className={'tit'} onClick={onClose}>{ST.ADMIN.NAVI.PRODQNA}</span>
      <span className={'sim'}>{'>'}</span>
      <span className={'txt'}>{`${item ? item.title : "N/A"}`}</span>
    </div>

    <div className={'p-box'}>
      <div className={'p-row'}>
        <span className={'pl'}>{ST.PAGE.SUBJECT}</span><p className={cx('pr tit')}>{item.title || ''}</p>
      </div>
      <div className={'p-row'}>
        <span className={'pl'}>{ST.PAGE.PHONE}</span><p className={cx('pr phone')}>{opts.phone || ''}</p>
      </div>
      <div className={'p-row'}>
        <span className={'pl'}>{ST.PAGE.EMAIL}</span><p className={cx('pr email')}>{opts.email || ''}</p>
      </div>
      <div className={'p-row'}>
        <span className={'pl'}>{ST.PAGE.CONTENT}</span><p className={cx('pr desc')}>{item.ctext || ''}</p>
      </div>
    </div>

    <Button className={'right dark lg close'} title={ST.CLOSE} onClick={onClose} />
  </StyledObject>;
}

const QnaList = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: 'title', value: '' });
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const order = 'ctime desc';
  const limit = 15;

  const searchs = [
    { name: ST.SUBJECT, id: 'title', check: true },
    { name: ST.CTIME, id: 'ctime', check: false },
  ];

  useEffect(() => {
    const param = actions.popParam();
    doReload(1, search, order, limit, (array = null) => {
      setLoaded(true);
      if (param) {
        const { rowid } = param;
        if (rowid && array) {
          const t = array.find(a => String(a.rowid) === String(rowid))
          props.onClick && props.onClick(t);
        }
      }
      setTimeout(() => global.onLoaded && global.onLoaded(true), 100);
    });
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "${s.value}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(URL.API.PROD_QNA, value, true).then(({ result }) => {
      setTotal(result.page && result.page.total)
      setPage(result.page && result.page.current);
      setMax(result.page && result.page.max);
      setSearch(s);

      result.list = result.list.map(item => {
        item.subject = `${item.no}. ${item.title}`;
        item.date = item.ctime ? item.ctime.substr(0, 8) : '';
        return item;
      });

      setList(result.list);
      callback && callback(result.list);
    });
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (rowid, e, item) => {
    if (!rowid) return;

    if (item.status === 'new') {
      actions.doUpdate(URL.API.QNA_CHECK, { rowid }).then(() => {
        let temp = list.find(a => String(a.rowid) === String(rowid));
        temp.status = 'check';
        setList([...list]);
      });
    }

    props.onClick && props.onClick(item);
  }

  const formatter = (item) => {
    const { status } = item;
    let label = '', color = cs.color.gray;
    if (status === 'new') { label = ST.QNA.NEW; color = cs.color.yellow; }

    return <div className={''}>
      <span className={'l-tit'}>{item.title}</span>
      {label && <span className={'l-req'} style={{ color }}>{label}</span>}
      <span className={cx('lbx-date')}>{Util.toStringSymbol(item.ctime).substr(0, 10)}</span>
    </div>
  }

  if (!loaded) return <div></div>;

  return <StyledObject className={cx('qna-list', props.className)}>
    <Listbox className={cx("list", 'dark')} list={list} title={'subject'} naviClass={'dark'}
      pos={page} max={max} total={total} rowid="rowid" searchkey={search.key} searchs={searchs}
      onClickPage={onClickPage} onSelect={onSelect} onClickSearch={onClickSearch} formatter={formatter} />
  </StyledObject>
};