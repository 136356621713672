import React, { useEffect, useState, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { cs, Uploadbox, Svg, Util, Button } from 'object/src';
import { ST } from 'svc/Lang';
import { SIZE, SCREEN } from 'svc/Enum';

const StyledObject = styled.div`{ 
  &.page-bg {
    ${cs.size.full} ${cs.disp.block} ${cs.z.back} ${cs.pos.absolute} ${cs.top(0)} ${cs.opac.invisible}

    & > div { ${cs.size.full} ${cs.disp.block} ${cs.pos.absolute} }
    .bg-image {  ${cs.bg.size('cover')}  ${cs.bg.pos('center')} }

    &.noanim, &.no { ${cs.opac.visible} }

    &.zoomout {
      ${cs.opac.visible} ${cs.anim.zoomin('5s', '1.3', '1', 1, 'page-zoom-out')}
    }

    &.zoomin {
      ${cs.opac.visible} ${cs.anim.zoomin('5s', '0.8', '1', 1, 'page-zoom-in')}
    }

    &.showin {
      ${cs.anim.fadein('3s', '0.1', '1', 'page-show-in', 'ease-out')} ${cs.anim.delay('0.1s')}
    }

    &.slideup {
      ${cs.opac.visible} ${cs.anim.slideup('2s', '80%', '0', 'page-slide-up', 'ease-in-out')}
    }
  }

  &.p-show-bg { ${cs.align.top} ${cs.w.fit} ${cs.align.xcenter} ${cs.border.radius(3)} ${cs.top(-20)}
    ${cs.font.orange} ${cs.font.sm} ${cs.font.line(30)} ${cs.p.h20}
    ${cs.font.center} ${cs.z.get(99)} ${cs.font.bold} ${cs.mouse.pointer} ${cs.bg.black}
  }

  &.bg-uploader { ${cs.align.left} ${cs.top(0)} ${cs.size.full} ${cs.disp.none} ${cs.min.h(340)}
    
    &.float { ${cs.w.get(500)} ${cs.h.get(340)} ${cs.align.ctop} ${cs.disp.none} ${cs.top(5)}
      ${cs.border.shadow()}
    }

    &.sm { ${cs.min.w(400)} ${cs.min.h(200)} }
    &.lg { ${cs.min.w(600)} ${cs.min.h(400)} }
    
    .p-img { ${cs.h.full} ${cs.p.a5} .up-frame .upf-preview { ${cs.box.shadow} } }
    .p-noti { ${cs.align.top} ${cs.top(10)} ${cs.left(5)} ${cs.p.h5} ${cs.z.get(99)} }

    &.show { ${cs.z.get(90)} ${cs.disp.block} }
    &.active { ${cs.disp.block} }
  }

  @media screen and (max-width : 1280px) { }

  @media screen and (max-width : 1024px) {}

  @media screen and (max-width : 860px) {
  }
};`;

export const PageBackground = (props) => {
  const { src = null, className, color = null, anim = 'noanim' } = props;

  if (!src && !color) return null;
  const bg = src ? { backgroundImage: `url(${src})` } : { background: color };
  return <StyledObject className={cx('page-bg', className, anim || 'noanim')}>
    {/* <div className={'bg-front'} /> */}
    <div className={'bg-image'} style={bg} ></div>
  </StyledObject>
}

export const BgUploader = (props) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);

  const { maxImage = { width: 1000, height: 600 }, noti, src, link, type = 'image', size = 'full',
    onError = null, onLoad = null, onChange = null, className, children } = props;

  useEffect(() => {
    setActive(props.src);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  return <Fragment>
    <StyledObject className={cx('bg-uploader', { show }, className, { active })}>
      {noti && <label className={'p-noti'}>{noti}</label>}
      <Uploadbox className="p-img up-box" link={link} type={type} fit={'cover'} value={src} size={size}
        onLoad={onLoad} onError={onError} eid={"url"} maxImage={maxImage}
        onChange={onChange && ((v, e, f) => { setActive(true); onChange(v, e, 'url', f); })}
        onClickLinked={props.onClickLinked} />
    </StyledObject>
    <StyledObject className={'p-show-bg'} onClick={() => setShow(!show)}>{show ? ST.OK : ST.PAGE.BOX_BG}</StyledObject>
    {children}
  </Fragment>
}

const DEFAULT = {
  "box": { "size": "md", "padding": "0px", "border": "0px", "radius": "0px", "color": "#ffffff", "margin": "0px", "anim": "noanim" },
  "label": { "size": "28px", "color": "#000000", "align": "left", "weight": "bold", "margin": "10px" },
  "desc": { "size": "14px", "color": "#000000", "align": "left", "weight": "semi", "margin": "10px" },
  "frame": { "color": "#ffffff00", "opacity": "1.0" },
}

export const WIDTH = (type = 'md', onlynum = true) => {
  const { width = '1024px' } = SIZE[type];
  let value = width;

  // 숫자만 가져올 경우.
  if (onlynum) {
    value = String(width).replace(/[^0-9]/g, '');
    if (!value) value = window.innerWidth;
    return Number(value);
  } else {
    return value;
  }
};

WIDTH('xxl');
WIDTH('xl');
WIDTH('lg');
WIDTH('md');
WIDTH('sm');
WIDTH('xs');
WIDTH('xxs');

export const PageOptions = {
  maxPadding(maxWidth, padding = '5px') {
    const { innerWidth } = window;
    const maxw = Number(String(maxWidth).replace(/[^0-9]/g, ''));
    return innerWidth < maxw ? padding : '';
  },
  height(s = '') {
    switch (s) {
      case 'xl': return 380;
      case 'lg': return 340;
      case 'md': return 280;
      case 'sm': return 210;
      case 'xs': return 150;
      default: return 280;
    }
  },
  maxWidth(s = '', unit = 'px') {
    if (s === 'full') return '100%';

    let value = 0;
    switch (s) {
      case 'xl': value = WIDTH('xl'); break;
      case 'lg': value = WIDTH('lg'); break;
      case 'md': value = WIDTH('md'); break;
      case 'sm': value = WIDTH('sm'); break;
      case 'xs': value = WIDTH('xs'); break;
      default: value = WIDTH('lg'); break;
    }
    if (unit) { value += unit }
    return value;
  },
  max(s = '', unit = 'px') {
    if (s === 'full') return { height: '100%', width: '100%', padding: 0 };

    let value = 0;
    switch (s) {
      case 'xl': value = { height: 'fit-content', width: WIDTH('xl'), padding: 0 }; break;
      case 'lg': value = { height: 'fit-content', width: WIDTH('lg'), padding: 0 }; break;
      case 'md': value = { height: 'fit-content', width: WIDTH('md'), padding: 0 }; break;
      case 'sm': value = { height: 'fit-content', width: WIDTH('sm'), padding: 0 }; break;
      case 'xs': value = { height: 'fit-content', width: WIDTH('xs'), padding: 0 }; break;
      default: value = { height: 'fit-content', width: WIDTH('lg'), padding: 0 }; break;
    }
    if (unit) { Object.keys(value).map(key => value[key] += unit) }
    return value;
  },
  thumbsize(s = '', unit = 'px') {
    let value = null;
    switch (s) {
      case 'full': value = { height: 340, width: 260, padding: 10 }; break;  // 380 * 286
      case 'xl': value = { height: 340, width: 260, padding: 10 }; break;  // 380 * 286
      case 'lg': value = { height: 330, width: 254, padding: 10 }; break;  // 340 * 254
      case 'md': value = { height: 270, width: 202, padding: 8 }; break;  // 280 * 202.8
      case 'sm': value = { height: 200, width: 158, padding: 6 }; break;  // 210 * 158
      case 'xs': value = { height: 150, width: 118, padding: 4 }; break;  // 150 * 118
      default: value = { height: 280, width: 202, padding: 8 }; break;  // 280 * 202.8
    }

    value = this.fthumbs(value);

    if (unit) { Object.keys(value).map(key => value[key] += unit) }

    // const dw = global.device.width;
    // if (dw < 400) {
    //   value.width = '50%';
    //   value.height = 'fit-content';
    // };

    return value;
  },
  image(url, path, type = 'image') {
    if (!url || url.length < 1) return '';
    if (url.indexOf("http") === 0) return url;
    return (type === "youtube" || type === "link") ? url : (url.indexOf("data:") === 0 ? url : url ? path + url : '');
  },
  jsons(value) {
    return (value && typeof value === 'string') ? JSON.parse(value) : value;
  },
  options(value) {
    const json = (value && typeof value === 'string') ? JSON.parse(value) : value;
    const opts = {
      ...json, frame: this.frame(json), box: this.box(json),
      label: this.label(json), desc: this.desc(json),
    };
    return opts;
  },
  frame(value) {
    // 타이틀의 color, size, border;
    const json = (value && typeof value === 'string') ? JSON.parse(value) : value;
    const v = (json ? json.frame : DEFAULT.frame);
    let border = {};
    v && v.tweight && (border['borderTop'] = `${v.tweight} solid ${v.tcolor || '#000000'}`);
    v && v.bweight && (border['borderBottom'] = `${v.bweight} solid ${v.bcolor || '#000000'}`);
    return { ...v, options: { background: v && v.color, ...border }, border, anim: (v && v.anim) || '' };
  },
  box(value) {
    // 이미지 박스의 color, size, border, padding, radius;
    const json = (value && typeof value === 'string') ? JSON.parse(value) : value;
    const v = (json ? json.box : DEFAULT.box);

    const { pathname = null } = window.location;
    const isadmin = pathname.indexOf('/admin') === 0;
    if (!isadmin) {
      v.padding = this.fpad(v.padding);
      v.margin = this.fpad(v.margin);
    }

    return { ...v, options: { border: `solid ${v.border} ${v.color}`, borderRadius: v.radius } };
  },
  label(value) {
    // 타이틀의 color, size;
    const json = (value && typeof value === 'string') ? JSON.parse(value) : value;
    const v = (json ? json.label : DEFAULT.label);
    const size = this.fcons(v.size);
    const weight = this.fweight(v.weight);
    const lineHeight = (Number(String(size).replace(/[^0-9]/g, '')) * 1.2).toFixed(0) + 'px'; //`calc(${size} * 1.2)`;
    return {
      ...v, lineHeight, options: {
        fontSize: size, color: v.color, lineHeight: lineHeight,
        textAlign: v.align, fontWeight: weight, paddingTop: v.margin
      }
    };
  },
  desc(value) {
    // 설병의 color, size;
    const json = (value && typeof value === 'string') ? JSON.parse(value) : value;
    const v = (json ? json.desc : DEFAULT.desc);
    let size = this.fcons(v.size);
    const weight = this.fweight(v.weight);
    return {
      ...v, options: {
        fontSize: size, color: v.color, lineHeight: `calc(${size} * 1.2)`,
        textAlign: v.align, fontWeight: weight, paddingTop: v.margin
      }
    };
  },
  default(box = null, label = null, desc = null) {
    var temps = { ...DEFAULT };
    box && (temps.box = { ...temps.box, ...box });
    label && (temps.label = { ...temps.label, ...label });
    desc && (temps.desc = { ...temps.desc, ...desc });
    return temps;
  },
  fweight(type) {
    if (type === 'thin') return 300;
    else if (type === 'semi') return 400;
    else if (type === 'bold') return 600;
    else if (type === 'thick') return 800;
    else return '';
  },
  fcons(size, min = 12) {
    const width = window.outerWidth;

    const unit = String(size).replace(/[0-9]/g, '');
    const fsize = String(size).replace(/[^0-9]/g, '');
    let value = Number(fsize);

    (width >= SCREEN.PC) && Math.round(value *= 1.0); // 1280
    (width < SCREEN.TABLET) && Math.round(value *= 0.84); // 1024
    (width < SCREEN.MOBILE) && Math.round(value *= 0.72); // 800

    // font가 12보다 작으면 무조건 12로
    if (value < min) value = min;
    // font가 100보다 크면 무조건 100로(이정도로 클일이?)
    // if (value > max) value = max;

    value = Number(value).toFixed(0) + unit;
    // console.dir(value);
    return value;
  },
  fpad(size, min = 0) {
    const width = window.outerWidth;

    const unit = String(size).replace(/[0-9]/g, '');
    const fsize = String(size).replace(/[^0-9]/g, '');
    let value = Number(fsize);

    (width >= SCREEN.PC) && Math.round(value *= 1.0); // 1280
    (width < SCREEN.TABLET) && Math.round(value *= 0.8); // 1024
    (width < SCREEN.MOBILE) && Math.round(value *= 0.7); // 800

    // font가 12보다 작으면 무조건 12로
    if (value < min) value = min;
    // font가 100보다 크면 무조건 100로(이정도로 클일이?)
    // if (value > max) value = max;

    value = Number(value).toFixed(0) + unit;
    // console.dir(value);
    return value;
  },
  fthumbs({ width, height, padding }) {
    const dw = window.innerWidth;

    if (dw < 600) {
      const p = dw / 2 / width;
      const value = { width: dw / 2 - 10, height: height * p - 10, padding: padding * 0.5 };
      return value;
    }

    let per = 1;
    if (dw >= SCREEN.PC) { per = 1.0 }; // 1280
    if (dw < SCREEN.TABLET) { per = 0.9 }; // 1024
    if (dw < SCREEN.MOBILE) { per = 0.7 }; // 800
    // if (dw < 600) { per = 0.7 }; // 600
    // if (dw < 600) { per = 0.6 }; // 800

    Math.round(width *= per);
    Math.round(height *= per);

    if (height < 150) {
      return { height: 150, width: 118, padding: 4 }
    }

    width = Number(width).toFixed(0);
    height = Number(height).toFixed(0);
    padding = Number(padding).toFixed(0);

    return { width, height, padding };
  }
}

const StyledLink = styled.div`{ 
  &.link-button { ${cs.pos.relative}
    .ibtn { ${cs.align.rbottom} ${cs.bottom(15)} ${cs.right(10)} 
      ${cs.bg.alphagray} ${cs.border.alphablack}
      .svg { ${cs.p.a0} } 
    }
  }
}`;


export const LinkButton = (props) => {
  const { className, color = 'white', size = 'lg' } = props;

  useEffect(() => {
    return () => {
    }
  }, [])

  const onClick = () => {
    props.onClick && props.onClick();
  }

  return <StyledLink className={cx('link-button', className)}>
    <Svg className={cx('ibtn border box radius', color, size)} icon={'link'} onClick={onClick} />
  </StyledLink>
}

const StyledUploader = styled.div`{ 
  &.up-box { 
    .upf-preview .upv-img { ${cs.bg.frame} }
    .remove { ${cs.top(5)} ${cs.right(-10)} ${cs.box.border} ${cs.border.radius(5)} ${cs.bg.black} .svg { ${cs.p.a3} }  }
    .save { ${cs.bottom(12)} ${cs.right(12)} }
    .upload { ${cs.bottom(12)} ${cs.right(12)} ${cs.w.calc('100% - 24px')} }
    .upv-clear { ${cs.disp.hidden} }
  }
}`;

var stime = 10;
export const ThumbUploader = (props) => {
  const { item, style, maxImage = { width: 370, height: 480 }, index = 0, path = '' } = props;
  const imgpath = Util.getImage(item.url, path);
  const [count, setCount] = useState(stime);
  var [timer, setTimer] = useState(null);
  var time = stime; //5s
  // var timer = null;

  useEffect(() => {
    return () => {
      if (timer) { clearInterval(timer); setTimer(null); }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (v, e) => {
    props.onChangeImage && props.onChangeImage(v, index);
    if (timer) { clearInterval(timer); setTimer(null); timer = null; }

    let temp = setInterval(() => {
      time = time - 1;
      if (time <= 0) {
        props.onClickSave && props.onClickSave(index);
        if (timer) { clearInterval(timer); setTimer(null); }
      } else {
        setCount(time);
      }
    }, 1000);
    timer = temp;
    setTimer(temp);
  }

  const onClick = (eid, i) => {
    if (timer) { clearInterval(timer); setTimer(null); }

    if (eid === 'save') props.onClickSave && props.onClickSave(i);
    if (eid === 'delete') props.onClickDelete && props.onClickDelete(i);
  }

  return <StyledUploader className={'img-box up-box'} style={style} >
    <Uploadbox className="g-img" type={'image'} fit={'cover'} value={imgpath} size={'full'}
      onLoad={props.onLoad} onError={props.onError} maxImage={maxImage}
      onChange={onChange} onClickLinked={() => props.onClickLink(item)} />

    <Svg className={'remove center bottom white'} icon={'delete'} onClick={() => onClick('delete', index)} />
    {item.edited && <Button className={'upload xs primary right bottom'} title={ST.ADMIN.AUTO_UPLOAD(count)}
      onClick={() => onClick('save', index)} disabled={!item.url} />}
  </StyledUploader>
}

const StyledScroll = styled.div`{ 
  &.sc-navi { 
    ${cs.align.right} ${cs.bottom(-7)} ${cs.right(-4)}
    .prev, .next { ${cs.disp.block} ${cs.opac.invisible} }
    // .next { ${cs.align.bottom} ${cs.bottom(-10)} ${cs.right(-5)} }

    &.both { & > span { ${cs.opac.show} } }
    &.top { & > .prev { ${cs.opac.show} } }
    &.bottom { & > .next { ${cs.opac.show} } }
  }
}`;

export const ScrollNavi = (props) => {
  const [show, setShow] = useState(null);
  const { layer, style } = props;
  const { pageopts } = global;

  useEffect(() => {
    if (!props.layer) return;

    setTimeout(() => {
      const { scrollHeight, scrollTop, offsetHeight } = props.layer;

      // 스크롤이 생기지 않으면 패스
      if (scrollHeight === offsetHeight) return setShow(null);
      // 스크롤이 최상단이면 아래만 표시
      if (scrollTop <= 0) return setShow('bottom');
      // 스크롤이 최하단이면 위에만 표시
      if (scrollTop + offsetHeight >= scrollHeight) return setShow('top');
      // 스크롤이 중간이면 둘다 표시
      if (offsetHeight < scrollHeight) setShow('both');

    }, 300);

    return () => {
    }
  }, [props.layer, props.refresh]);

  const onClickNavi = (eid, e) => {
    const { currentTarget } = e;

    if (currentTarget && layer) {
      if (eid === 'up') {
        layer.scrollTo({ top: layer.scrollTop - 100, behavior: 'smooth' });
      } else {
        layer.scrollTo({ top: layer.scrollTop + 100, behavior: 'smooth' });
      }

      setTimeout(() => {
        const { scrollHeight, scrollTop, offsetHeight } = layer;
        if (scrollTop <= 0) return setShow('bottom');
        if (scrollTop + offsetHeight >= scrollHeight) return setShow('top');
        setShow('both');
      }, 300);
    }
  }

  if (pageopts && pageopts.style && pageopts.style === 'onepage') {
    return <StyledScroll className={cx('sc-navi', show)} style={{ ...style }}>
      <span className={'prev'}><Svg className={'sm dark'} icon={'top'} onClick={(eid, e) => onClickNavi("up", e)} /></span>
      <span className={'next'}><Svg className={'sm dark'} icon={'bottom'} onClick={(eid, e) => onClickNavi("down", e)} /></span>
    </StyledScroll>
  } else {
    return null;
  }
}

// export const OptionAlign = (props) => {
//   const { rowid, opts } = props;
//   const [style, setStyle] = useState(opts && opts.style ? { ...opts.style } : { align: 'left' });

//   const TALIGN = [
//     { id: 'left', title: ST.ADMIN.MENU.LEFT },
//     { id: 'center', title: ST.ADMIN.MENU.CENTER },
//     { id: 'right', title: ST.ADMIN.MENU.RIGHT },
//   ]

//   const onClick = (eid, v) => {
//     if (!eid || !v) return;

//     const temps = { ...style, [eid]: v };
//     setStyle({ ...temps });
//     const params = { rowid: rowid, opts: { ...opts, style: { ...temps } } };
//     actions.doUpdate(URL.API.ADMIN.PAGE_OPTS, params).then(({ code }) => {
//       (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
//       props.onClick && props.onClick(eid, v, { ...temps });
//     });
//   }

//   return <TabButton className={"tab-align"} size={'sm'} list={TALIGN} select={style.align} color={'gray'}
//     onClick={(eid) => onClick('align', eid)} />
// }

// export const OptionColor = (props) => {
//   const { rowid, opts } = props;
//   const [style, setStyle] = useState(opts && opts.style ? { ...opts.style } : { align: 'left' });

//   const TALIGN = [
//     { id: 'left', title: ST.ADMIN.MENU.LEFT },
//     { id: 'center', title: ST.ADMIN.MENU.CENTER },
//     { id: 'right', title: ST.ADMIN.MENU.RIGHT },
//   ]

//   const onClick = (eid, v) => {
//     if (!eid || !v) return;

//     const temps = { ...style, [eid]: v };
//     setStyle({ ...temps });
//     const params = { rowid: rowid, opts: { ...opts, style: { ...temps } } };
//     actions.doUpdate(URL.API.ADMIN.PAGE_OPTS, params).then(({ code }) => {
//       (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
//       props.onClick && props.onClick(eid, v, { ...temps });
//     });
//   }

//   return <TabButton className={"tab-align"} size={'sm'} list={TALIGN} select={style.align} color={'gray'}
//     onClick={(eid) => onClick('color', eid)} />
// }