import React, { useState, useEffect } from 'react';
import { cs, Button, Loading, Svg, Editbox, TabButton, Util } from 'object/src';
import { ST } from 'svc/Lang';
import { URL } from 'svc/Enum';
import styled from 'styled-components';
import cx from 'classnames/bind';
import { PageOptions as OPTS } from '../pages/PageCom';
import * as actions from 'object/src/actor/Action';
import * as PAGE from '../pages';
import { Fragment } from 'react';

const StyledObject = styled.div`{
  &.link-editor {
    ${cs.align.right} ${cs.pos.fixed} ${cs.h.get('calc(100vh - 60px)')} ${cs.w.get(400)} ${cs.top(60)} ${cs.z.popup}
    ${cs.bg.darkhover} ${cs.border.left} ${cs.anim.slidein('200ms', '100%', '0')}
    .head, .foot { ${cs.disp.block} ${cs.align.top} ${cs.h.get(40)} ${cs.w.full} ${cs.border.bottom} ${cs.p.a10} ${cs.p.l20}
      ${cs.bg.black} ${cs.border.darkgray} ${cs.z.over} ${cs.font.md} ${cs.font.thickbold} ${cs.font.gray}
      .close { ${cs.align.right} ${cs.top(5)} ${cs.right(10)} }
      &.foot { ${cs.align.bottom} 
        .cancel { ${cs.left(5)} }
        .save { ${cs.right(5)} ${cs.min.w(80)} }
        .delete { ${cs.right(5)} ${cs.min.w(80)} }
      }
    }
    .body { ${cs.disp.block} ${cs.h.full} ${cs.over.yauto} ${cs.scrollbar.t2} ${cs.p.t40} ${cs.p.b40}
      .p-tabs { ${cs.p.a5} ${cs.w.full} .tab-frame { ${cs.w.full} .tab-btn { ${cs.w.r50} } } }
      .le-edit {
        ${cs.p.a5}
        .p-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.m.t10} ${cs.m.b5} }
        .p-btn { ${cs.m.t10} ${cs.font.right} & > span { ${cs.m.l10} } }
      }
      .le-ul {
        ${cs.p.h5} ${cs.p.b10}
        .le-li { ${cs.m.t10} ${cs.p.v10} ${cs.mouse.pointer} ${cs.p.h5} ${cs.border.radius(5)} 
          ${cs.box.line} ${cs.border.trans}
          .l-tit { ${cs.font.sm} ${cs.font.ellipsis} ${cs.w.full} ${cs.m.b5} ${cs.p.b5} 
            ${cs.disp.block} ${cs.border.bottom} ${cs.border.darkgray} 
          }
          .l-box { ${cs.zoom(0.2)} }

          &.active { ${cs.bg.alphablack} }
          &:hover { ${cs.bg.dark} ${cs.box.line} }
        }
      }
    }
  }
}`;

// const API = URL.API.ADMIN;
const LinkEditor = (props) => {
  // const { item } = props;
  const [loaded, setLoaded] = useState(false);
  const [menulist, setMenulist] = useState(null);
  const [list, setList] = useState(null);
  const [edit, showEdit] = useState(false);
  const [value, setValue] = useState('');
  const [modified, setModified] = useState(false);
  const [noti, showNoti] = useState(null);
  const [select, setSelect] = useState(null);
  const [max, setMax] = useState(100);
  const [istotal, setTotal] = useState(false);

  useEffect(() => {
    if (!list) {
      setTimeout(() => {
        doReload();
      }, 300);
    }

    if (props && props.item) {
      const { link, sellinkid = null, jsons } = props.item;
      let temp = null;
      if (sellinkid && jsons) {
        temp = JSON.parse(jsons);
        temp && (temp = temp.find(a => String(a.uuid) === String(sellinkid)));
        temp && (temp = temp.link);
      } else if (link) {
        temp = JSON.parse(link);
      }

      if (temp) {
        if (temp.type === 'other') {
          setValue(Util.replaceAll(temp.url, "\n", ""));
          showEdit(true);
        } else {
          setSelect(temp.pageid);
          showEdit(false);
        }
      }
    }
    
    // const onScroll = (e) => {
    //   const i = frame && frame.scrollTop <= 10 ? 'bottom' : 'top';
    //   setIcon(i)
    // }

    // node[0].addEventListener("scroll", onScroll);
    // frame = node[0];
      
    // return () => {
    //   node[0].removeEventListener("scroll", onScroll);
    //   frame = null;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (limit = max) => {
    setLoaded(false);

    actions.doSelect(URL.API.PAGE, { menuid: null, max: limit }).then((result) => {
      const { value, menus } = result;
      value.map(a => {
        a.component = PAGE[a.ctag];
        if (!a.component) a.component = PAGE.NoPage;  // 기본 태그
        a.opts = a.opts ? JSON.parse(a.opts) : OPTS.default();
        return null;
      });

      var temps = [];
      menus.forEach(a => {
        const arr = value.filter(b => String(a.rowid) === String(b.menuid));
        temps = [...temps, ...arr];
      });

      // setFrame(result.frame);
      // setMenu(rowid);
      setTotal(list && value ? value.length <= list.length : false);
      setList(temps);
      setMenulist(menus);
      setLoaded(true);
    });
  }

  const onCancel = () => {
    props.onClose && props.onClose();
  }

  // const onEdit = () => {
  // }

  const onSave = () => {
    if (!(value.indexOf('http://') === 0 || value.indexOf('https://') === 0)) {
      showNoti(ST.PAGE.LINK_NOTI('http:// 또는 https://')); setTimeout(() => showNoti(null), 3000);
      return;
    } else {
      props.onClick && props.onClick('select', props.item, JSON.stringify({ url: value, type: 'other' }));
    }
  }

  const onChange = (v) => {
    setValue(v);
    setModified(true);
  }

  const onClick = (item) => {
    const value = { url: URL.HOME, menuid: item.menuid, pageid: item.rowid, type: 'mypage' };
    props.onClick && props.onClick('select', props.item, JSON.stringify(value));
  }

  const onWheel = (e) => {
    // const frame = document.getElementById('le-scroll');
    // if (frame) {
    //   const { scrollTop, scrollHeight } = frame;
    //   // console.dir(`${scrollTop} ${scrollHeight} ${istotal}`);

    //   if (!istotal && scrollHeight < scrollTop + 3000) {
    //     let limit = max + 100;
    //     setMax(limit);
    //     doReload(limit);
    //   }
    // }
  }

  const onScroll = (e) => {
    const frame = document.getElementById('le-scroll');
    if (frame) {
      const { scrollTop, scrollHeight } = frame;
      // console.dir(`${scrollTop} ${scrollHeight} ${istotal}`);

      if (!istotal && scrollHeight < scrollTop + 3000) {
        let limit = max + 100;
        setMax(limit);
        doReload(limit);
      }
    }
  }

  return <StyledObject className={cx('link-editor')}>
    <div className={'head'}>
      <span>{ST.PAGE.SEL_PAGE}</span>
      <Button className={'btn sm black close'} title={ST.CLOSE} onClick={onCancel} />
    </div>
    <div className={'body'} id={'le-scroll'} onWheel={onWheel} onScroll={onScroll}>
      <TabButton className={'p-tabs full'} size={'md'} list={[{ id: 'mypage', title: ST.MY_PAGE }, { id: 'direct', title: ST.DIRECT_INPUT }]}
        onClick={(eid) => showEdit(eid === 'direct')} select={edit ? 'direct' : 'mypage'} />
      {/* {!edit && <Button className={'btn sm red gd-gray right middle'} title={ST.DIRECT_INPUT} onClick={() => showEdit(true)} />} */}
      {edit
        ? <div className={'le-edit'}>
          <p className={'p-noti'}>{noti || ST.PAGE.DIRECT_INPUT}</p>
          <Editbox className={'p-input fixed'} multi={true} height={100} value={value} onChange={onChange} />
          <div className={'p-btn'}>
            <Button className={'btn sm gd-gray'} title={ST.CANCEL} onClick={() => showEdit(false)} />
            <Button className={'btn sm red'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
          </div>
        </div>
        : <Fragment>
          {list && <ul className="le-ul">
            {list.map((item, i) => {
              if (props.item && item.rowid === props.item.rowid) return null;
              const { title, component, menuid } = item;
              const Component = component;
              const m = menulist && menulist.find(a => String(a.rowid) === String(menuid));
              const active = select && item.rowid === select;
              return <li key={i} className={cx('le-li', { active })} onClick={() => onClick(item)}>
                <span className={'l-tit'}>{`[${m && m.title}] ${title}`}</span>
                <div className={'l-box'}>
                  <Component item={item} index={i} doReload={doReload} preview={true} />
                </div>
              </li>
            })}
          </ul>}
        </Fragment>}
      {!loaded && <Loading type={'ring'} />}
    </div>
    <div className={'foot'}>
      {/* <Button className={'btn sm left middle cancel'} title={ST.CANCEL} onClick={onCancel} /> */}
      <Svg className={'white md middle left cancel'} icon={'exit'} onClick={onCancel} />
      {props.item && props.item.link && <Button className={'btn sm gd-gray right middle delete'} title={ST.DELETE}
        onClick={() => props.onClick('select', props.item, '')} />}
    </div>
  </StyledObject>
}

export default LinkEditor;