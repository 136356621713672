import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Loading, cs, Editbox, Util } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, BgUploader, PageOptions as OPTS, LinkButton } from './PageCom';

const StyledObject = styled.div`{ 
  &.jumbo-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()} 

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.h.fit}
      ${cs.disp.block} ${cs.object.contain} 
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} ${cs.top(0)} ${cs.w.full} 
          ${cs.min.h(100)} ${cs.h.fit} ${cs.bg.pos('center')} ${cs.bg.size('cover')}

          .p-tit { 
            ${cs.font.t1} ${cs.m.b10} ${cs.font.center} ${cs.font.bold}
          }

          .p-txt { 
            ${cs.font.lg} ${cs.font.center} ${cs.scrollbar.t0}
            ${cs.over.hidden} ${cs.over.yauto} ${cs.font.prewrap} ${cs.max.h(340)} 
            // ${cs.p.h50}
          }

          &.bg { ${cs.h.get(500)} }
        }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }

        &.link { ${cs.mouse.pointer} }
      }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.pointer} };
      
      &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
    }

    @media screen and (max-width : 600px) {
      .p-frame .p-cont { .p-txt { ${cs.p.h20} } }
    }
  }
  
  &.jumbo-viewer {
    &.full { ${cs.h.fit} ${cs.p.v0} ${cs.max.none}
      .p-frame { ${cs.align.unset} ${cs.pos.relative} ${cs.w.full} ${cs.max.none} ${cs.p.a0} 
      } 
    }

    .p-frame {
      ${cs.over.hidden} 
      .p-cont { ${cs.opac.invisible} .p-tit, .p-txt { ${cs.opac.invisible} } }

      &.noanim { 
        .p-cont { ${cs.opac.visible} .p-tit, .p-txt { ${cs.opac.visible} } }
      }
      &.type1 {
        .p-cont { ${cs.opac.visible}
          .p-tit { ${cs.opac.invisible} ${cs.anim.showin('1s', '0', '1', 'jb-type1-text-1')} }
          .p-txt { ${cs.opac.invisible} ${cs.anim.showin('1s', '0', '1', 'jb-type1-text-2')} ${cs.anim.delay('1s')} }
        }
      }
      &.type2 {
        .p-cont { ${cs.opac.visible}
          .p-tit { ${cs.opac.invisible} ${cs.anim.slideup('1s', '-100%', '0', 'jb-type2-text-1')} }
          .p-txt { ${cs.opac.invisible} ${cs.anim.slideup('1s', '100%', '0', 'jb-type2-text-2')} ${cs.anim.delay('1s')} }
        }
      }
      &.type3 {
        .p-cont {
          .p-tit, .p-txt { ${cs.opac.visible} }
          ${cs.anim.rotatezoom('2s', '0', '-1080deg', 'jb-type3', 'ease-out')} ${cs.anim.delay('0.1s')}
        }
      }
    }
  }

  &.jumbo-editor { ${cs.p.b40} ${cs.p.t30}
    .p-frame { 
      .p-cont {
        .p-img { ${cs.align.left} ${cs.pos.relative} ${cs.top(0)} ${cs.h.get(500)} }
        .p-txt { ${cs.over.hidden} ${cs.p.h0} }
        // .edit-box { ${cs.m.l10} ${cs.w.calc('100% - 20px')}}
        // .p-txt { ${cs.m.t20} }
      }
    }
  }

};`;

const JumboBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default JumboBox;

const BoxViewer = (props) => {
  const { title, cont, className, bg, path, url, opts, link } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  const [loaded, setLoaded] = useState(false);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  return (
    <StyledObject className={cx("jumbo-box jumbo-viewer", className, box.size)}>
      <PageBackground src={bgdata} color={frame.color} style={{ margin: `${box.margin} 0` }} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded }, { link }, anim || 'noanim')} style={{ maxWidth, margin: `${box.margin} 0`, padding: `0 ${OPTS.maxPadding(maxWidth)}`, ...frame.border }} >
        <div className={cx('p-cont', url && 'bg')} style={{ ...box.options, padding: box.padding, backgroundImage: `url(${urldata})` }}
          onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null} >
          {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{title}</p>}
          {cont && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{cont}</p>}
        </div>
      </div>
      { props.children}
      { url && !loaded && <Loading />}
    </StyledObject >
  )
}

const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onChange = (value, e, label, file) => {
    if (!props.item) return;
    const req = props.item.request;
    if (label === 'url') req[label] = file;
    else req[label] = Util.toText(value);
  }

  const { title, type, link, cont, className, bg, path, url, opts, request } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  return (
    <StyledObject className={cx("jumbo-box jumbo-editor", className)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth }}>
        <BgUploader link={link} type={type} src={urldata} size={"full"} className={cx("p-img")}
          maxImage={{ width: 1500, height: 600 }} noti={ST.PAGE.IMAGE_H + ` ${ST.IMAGE_SIZE('1000 * 400')}`}
          onLoad={onLoad} onError={onError} onChange={onChange} />
        <div className={cx("p-cont", url && 'bg')}>
          <Editbox className={cx("p-tit white")} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <Editbox className={cx("p-txt white sizefix")} value={request.cont || cont} guide={ST.PAGE.CONTENT_H(3000)} maxLength={3000}
            onChange={(v, e) => onChange(v, e, 'cont')} multi={true} minheight={400} maxheight={400} height={400} />
        </div>
        <LinkButton onClick={() => props.onClickLink('show', props.item)} />
      </div>
      {props.children}
    </StyledObject>
  )
}