import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames/bind';
import { cs, Loading } from 'object/src';

const StyledObject = styled.div`{
  &.page-box { ${cs.bg.trans} ${cs.p.h30} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wxl} ${cs.w.full} ${cs.noselect}

    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.m.t30} }
  }

  @media screen and (max-width : 600px) { 
    .widget-box .t-label { ${cs.min.h(120)} ${cs.h.get(120)} }
    .widget-box.md { ${cs.min.h(120)} ${cs.h.get(120)} }
  }
}`;

const NoPage = (props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    doReload();

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.noti) return;
    doReload();
    return () => {
    }
  }, [props.noti]);

  const doReload = () => {
    setLoaded(true)
  }

  if (loaded) {
    return <StyledObject className={cx("page-box")}>
      ------- No Service Page --------
    </StyledObject >
  } else {
    return <Loading />;
  }
};

export default NoPage;