import React, { useState, useEffect } from 'react';
import { cs, Callopsebox, Checkbox, Colorbox, Slider, Button, Svg, TabButton } from 'object/src';
import { ST } from 'svc/Lang';
import styled from 'styled-components';
import { PageOptions as OPTS } from '../pages/PageCom';
import { Fragment } from 'react';

const StyledObject = styled.div`{
  &.option-bar {
    ${cs.align.right} ${cs.pos.fixed} ${cs.h.get('calc(100vh - 60px)')} ${cs.w.get(400)} ${cs.top(60)} ${cs.z.popup}
    ${cs.bg.darkhover} ${cs.border.left} ${cs.anim.slidein('200ms', '100%', '0')}
    .head, .foot { ${cs.disp.block} ${cs.align.top} ${cs.h.get(40)} ${cs.w.full} ${cs.border.bottom} ${cs.p.a10} ${cs.p.l20}
      ${cs.bg.black} ${cs.border.darkgray} ${cs.z.over} ${cs.font.md} ${cs.font.thickbold} ${cs.font.gray}
      .close { ${cs.align.right} ${cs.top(5)} ${cs.right(10)} }
      &.foot { ${cs.align.bottom} 
        .cancel { ${cs.left(5)} }
        .save { ${cs.right(5)} ${cs.min.w(80)} }
      }
    }
    .body { ${cs.disp.block} ${cs.h.full} ${cs.over.yauto} ${cs.scrollbar.t2} ${cs.p.t40} ${cs.p.b40}
      .callopse-box { ${cs.border.alphablack} 
        .cls-title { ${cs.bg.alphablack} }
        .cls-frame { ${cs.border.black} 
          .op-li {
            &.tabs { ${cs.m.v10} }
          }
          .op-line {
            ${cs.m.v5} ${cs.bg.darkhover} ${cs.disp.block} ${cs.h.get(1)} ${cs.m.b20}
            // ${cs.m.h5} ${cs.w.calc('100% - 10px')} 
          }
          .op-noti { ${cs.font.sm} ${cs.font.yellow} ${cs.m.t10} ${cs.font.prewrap} }
        }
      }
    }
  }
}`;


const OptionBar = (props) => {
  const { data = null } = props;
  const [active, setActive] = useState(ST.OPTS.BOX);
  const [options, setOptions] = useState(OPTS.default());
  const [modified, setModified] = useState(false);

  useEffect(() => {
    const v = (typeof props.data === 'string') ? JSON.parse(props.data) : props.data;
    if (v) {
      setOptions(v);
    }
    return () => {
    }
  }, [props.data]);

  const onCancel = () => {
    const v = (typeof data === 'string') ? JSON.parse(data) : data;
    props.onClose && props.onClose(v);
  }

  const onSave = () => {
    props.onSave && props.onSave(options, props.item);
  }

  const onChange = (key, value, eid) => {
    options[key] = options[key] ? { ...options[key], ...value } : { ...value };
    setOptions(JSON.parse(JSON.stringify(options)))
    setModified(true);
    if (key === 'box' && Boolean(value['size'])) eid = 'boxsize';
    props.onChange && props.onChange(options, props.item, eid);
  }

  const onClick = (label) => {
    setActive(label);
  }

  const ctag = props.item && props.item.ctag.toLowerCase();
  const hide = ctag === 'slidebox' || ctag === 'qnabox';

  return <StyledObject className={'option-bar'}>
    <div className={'head'}>
      <span>{ST.PAGE.SEL_LAYOUT}</span>
      <Button className={'btn sm black close'} title={ST.CLOSE} onClick={onCancel} />
    </div>
    <div className={'body'}>
      <OptionBox item={props.item} data={options.box} active={active} onChange={onChange} onClick={onClick} />
      <OptionTitle item={props.item} data={options.label} active={active} min={10} max={150} onChange={onChange} onClick={onClick} />
      <OptionDescript item={props.item} data={options.desc} active={active} min={10} max={100} onChange={onChange} onClick={onClick} />
      <OptionBackground item={props.item} data={options.frame} active={active} onChange={onChange} onClick={onClick} />
      {!hide && <OptionAnimation item={props.item} data={options.box} active={active}
        onChange={(key, value) => onChange(key, value, 'anim')} onClick={onClick} />}
    </div>
    <div className={'foot'}>
      <Svg className={'white md middle left cancel'} icon={'exit'} onClick={onCancel} />
      <Button className={'btn sm red save right middle'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </div>
  </StyledObject>
}

export default OptionBar;

/* box padding, size, border */
const OptionBox = (props) => {
  const { data, active, onClick, tag = 'box', item } = props;

  const { pageopts } = global;
  const bgcolor = "rgba(0,0,0,0.5)";
  const OPT = ST.OPTS;
  var sizelist = [
    // { name: 'N (w:auto)', id: 'none', check: true },
    { name: `XL (w:${OPTS.maxWidth('xl')})`, id: 'xl', check: false },
    { name: `L (w:${OPTS.maxWidth('lg')})`, id: 'lg', check: false },
    { name: `M (w:${OPTS.maxWidth('md')})`, id: 'md', check: false },
    { name: `S (w:${OPTS.maxWidth('sm')})`, id: 'sm', check: false },
    { name: `XS (w:${OPTS.maxWidth('xs')})`, id: 'xs', check: false },
    { name: 'F (fullscreen)', id: 'full', check: false }
  ];

  if (pageopts && pageopts.style === 'onepage') {
    sizelist = [
      // { name: 'N (w:auto)', id: 'none', check: true },
      { name: `M (w:${OPTS.maxWidth('md')})`, id: 'md', check: false },
      { name: `S (w:${OPTS.maxWidth('sm')})`, id: 'sm', check: false },
      { name: `XS (w:${OPTS.maxWidth('xs')})`, id: 'xs', check: false },
    ]
  }

  const onChange = (value, label) => {
    props.onChange && props.onChange(tag, { [label]: value });
  }

  const onChangeCheck = (value) => {
    let result = value ? value.id : '';
    if (result === 'none') result = 'auto';
    props.onChange && props.onChange(tag, { 'size': result });
  }

  const show = item && item.ctag.toLowerCase() === 'textlistbox';

  return (
    <Callopsebox className={'dark op-grp'} label={`${OPT.BOX}(${OPT.LAYOUT})`} bgcolor={bgcolor}
      active={active === OPT.BOX} onClick={() => onClick(OPT.BOX)}>
      {pageopts && pageopts.style !== 'onepage' && <Fragment>
        <Slider className={'op-li'} value={data && data.margin} min={0} max={300} label={OPT.MARGIN}
          unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'margin')} />
        <p className={'op-noti'}>{ST.OPTS.MARGIN_H}</p>
        <div className={'op-line'} />
      </Fragment>}
      <Slider className={'op-li'} value={data && data.padding} min={0} max={100} label={OPT.PADDING}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'padding')} />
      <p className={'op-noti'}>{ST.OPTS.PADDING_H}</p>
      <div className={'op-line'} />
      <Checkbox className={'op-li'} value={data && data.size} list={sizelist} theme={"white"}
        onChange={onChangeCheck} radio={true} label={OPT.SIZE} />
      <p className={'op-noti'}>{ST.OPTS.BOXSIZE_H}</p>
      <div className={'op-line'} />
      <Slider className={'op-li'} value={data && data.border} min={0} max={50} label={OPT.BORDER}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'border')} />
      <p className={'op-noti'}>{ST.OPTS.BOXBORDER_H}</p>
      <div className={'op-line'} />
      <Slider className={'op-li'} value={data && data.radius} min={0} max={500} label={OPT.RADIUS}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'radius')} />
      <p className={'op-noti'}>{ST.OPTS.RADIUS_H}</p>
      <div className={'op-line'} />
      <Colorbox className={"op-li no-shadow black"} value={data && data.color} clear={true}
        onChange={(hex, rgb, e) => onChange(hex, 'color')} label={`${OPT.BORDER} ${OPT.COLOR}`} />
      <div className={'op-line'} />
      {show && <Colorbox className={"op-li no-shadow black"} value={data && data.bgcolor} clear={true}
        onChange={(hex, rgb, e) => onChange(hex, 'bgcolor')} label={`${OPT.BACKGROUND} ${OPT.COLOR}`} />}
    </Callopsebox >
  )
}

const TALIGN = [
  { id: 'left', title: ST.OPTS.LEFT },
  { id: 'center', title: ST.OPTS.CENTER },
  { id: 'right', title: ST.OPTS.RIGHT },
];
const TWEIGHT = [
  { id: 'thin', title: ST.OPTS.THIN },
  { id: 'semi', title: ST.OPTS.SEMI },
  { id: 'bold', title: ST.OPTS.BOLD },
  { id: 'thick', title: ST.OPTS.THICK },
];

/* title font-size, font-color */
const OptionTitle = (props) => {
  const { min = 0, max = 100, data, active, onClick, request = {}, tag = 'label' } = props;
  const bgcolor = "rgba(0,0,0,0.5)";
  const OPT = ST.OPTS;

  const onChange = (value, label) => {
    request[label] = value;
    props.onChange && props.onChange(tag, request);
  }

  return (
    <Callopsebox className={"dark op-grp"} label={`${OPT.LABEL}(${OPT.SUBJECT})`} active={active === OPT.LABEL}
      bgcolor={bgcolor} onClick={() => onClick(OPT.LABEL)}>
      <Slider className={'op-li'} value={data && data.size} min={min} max={max} label={`${OPT.LABEL} ${OPT.SIZE}`}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'size')} />
      <div className={'op-line'} />
      <Slider className={'op-li'} value={data && data.margin} min={0} max={200} label={`${OPT.LABEL} ${OPT.TOPMARGIN}`}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'margin')} />
      <div className={'op-line'} />
      <Colorbox className={"op-li no-shadow black"} value={data && data.color}
        onChange={(hex, rgb, e) => onChange(hex, 'color')} label={`${OPT.LABEL} ${OPT.COLOR}`} />
      <div className={'op-line'} />
      <TabButton className={"op-li tabs full"} size={'sm'} label={`${OPT.LABEL} ${OPT.ALIGN}`} list={TALIGN}
        select={data && data.align} color={'gray'} onChange={(eid) => onChange(eid, 'align')} />
      <div className={'op-line'} />
      <TabButton className={"op-li tabs full"} size={'sm'} label={`${OPT.LABEL} ${OPT.WEIGHT}`} list={TWEIGHT}
        select={data && data.weight} color={'gray'} onChange={(eid) => onChange(eid, 'weight')} />
    </Callopsebox>
  )
}

/* desc font-size, font-color */
const OptionDescript = (props) => {
  const { min = 0, max = 100, data, active, onClick, request = {}, tag = 'desc' } = props;
  const bgcolor = "rgba(0,0,0,0.5)";
  const OPT = ST.OPTS;

  const onChange = (value, label) => {
    request[label] = value;
    props.onChange && props.onChange(tag, request);
  }

  return (
    <Callopsebox className={"dark op-grp"} label={OPT.DESC} active={active === OPT.DESC}
      bgcolor={bgcolor} onClick={() => onClick(OPT.DESC)}>
      <Slider className={'op-li'} value={data && data.size} min={min} max={max} label={`${OPT.DESC} ${OPT.SIZE}`}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'size')} />
      <div className={'op-line'} />
      <Slider className={'op-li'} value={data && data.margin} min={0} max={200} label={`${OPT.DESC} ${OPT.TOPMARGIN}`}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'margin')} />
      <div className={'op-line'} />
      <Colorbox className={"op-li no-shadow black"} value={data && data.color}
        onChange={(hex, rgb, e) => onChange(hex, 'color')} label={`${OPT.DESC} ${OPT.COLOR}`} />
      <div className={'op-line'} />
      <TabButton className={"op-li tabs full"} size={'sm'} label={`${OPT.DESC} ${OPT.ALIGN}`} list={TALIGN}
        select={data && data.align} color={'gray'} onChange={(eid) => onChange(eid, 'align')} />
      <div className={'op-line'} />
      <TabButton className={"op-li tabs full"} size={'sm'} label={`${OPT.DESC} ${OPT.WEIGHT}`} list={TWEIGHT}
        select={data && data.weight} color={'gray'} onChange={(eid) => onChange(eid, 'weight')} />
    </Callopsebox>
  )
}

/* background-color */
const OptionBackground = (props) => {
  const { data, active, onClick, request = {}, tag = 'frame', min = 0, max = 30 } = props;
  const bgcolor = "rgba(0,0,0,0.5)";
  const OPT = ST.OPTS;

  const onChange = (value, label) => {
    request[label] = value;
    props.onChange && props.onChange(tag, request);
  }

  return (
    <Callopsebox className={"dark op-grp"} label={`${OPT.BACKGROUND}(${OPT.FRAME})`} bgcolor={bgcolor}
      active={active === OPT.BACKGROUND} onClick={() => onClick(OPT.BACKGROUND)}>
      <Colorbox className={"op-li no-shadow black"} value={data && data.color}
        onChange={(hex, rgb, e) => onChange(hex, 'color')} label={`${OPT.BACKGROUND} ${OPT.COLOR}`} clear={true} />
      <div className={'op-line'} />
      <Slider className={'op-li'} value={data && data.tweight} min={min} max={max}
        label={`${OPT.FRAME}(${OPT.TOP}) ${OPT.LINE} ${OPT.WEIGHT}`}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'tweight')} />
      <Colorbox className={"op-li no-shadow black"} value={data && data.tcolor}
        onChange={(hex, rgb, e) => onChange(hex, 'tcolor')} label={`${OPT.LINE} ${OPT.COLOR}`} clear={true} />
      <div className={'op-line'} />
      <Slider className={'op-li'} value={data && data.bweight} min={min} max={max}
        label={`${OPT.FRAME}(${OPT.BOTTOM}) ${OPT.LINE} ${OPT.WEIGHT}`}
        unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'bweight')} />
      <Colorbox className={"op-li no-shadow black"} value={data && data.bcolor}
        onChange={(hex, rgb, e) => onChange(hex, 'bcolor')} label={`${OPT.LINE} ${OPT.COLOR}`} clear={true} />
    </Callopsebox >
  )
}


/* background-color */
const OptionAnimation = (props) => {
  const { data, active, onClick, request = {}, tag = 'box' } = props;
  const bgcolor = "rgba(0,0,0,0.5)";
  const OPT = ST.OPTS;

  const TABS = [
    { id: 'noanim', title: 'NO', label: '' },
    { id: 'type1', title: 'TYPE A', label: ST.ADMIN.ANIM.ZOOMOUT },
    { id: 'type2', title: 'TYPE B', label: ST.ADMIN.ANIM.SLIDEUP },
    { id: 'type3', title: 'TYPE C', label: ST.ADMIN.ANIM.SHOWIN },
  ];

  const onChange = (value, label) => {
    request[label] = value;
    props.onChange && props.onChange(tag, request);
  }


  // const onClickAnim = (eid, v) => {
  //   const temps = JSON.parse(JSON.stringify(opts));
  //   temps && temps.box ? temps.box['anim'] = eid : temps['box'] = { eid };

  //   list.forEach((a, i) => {
  //     if (eid === 'noanim') {
  //       a.anim = eid;
  //     } else {
  //       a.anim = 'hide';
  //       setTimeout(() => {
  //         a.anim = eid;
  //         setList([...list]);
  //       }, 500 * i);
  //     }
  //   });

  //   actions.doUpdate(API.PAGE_OPTS, { rowid: props.item.rowid, opts: temps }).then((res) => { });
  //   setAnim(eid);
  // }

  return (
    <Callopsebox className={"dark op-grp"} label={`${OPT.ANIMATION} ${OPT.EFFECT}`} bgcolor={bgcolor}
      active={active === OPT.ANIMATION} onClick={() => onClick(OPT.ANIMATION)}>
      <div className={'op-noti'}>{`${OPT.ANIMATION_H}`}</div>
      <TabButton className={'op-li tabs full'} size={'sm'} list={TABS}
        onChange={(v, e) => onChange(v, 'anim')} select={(data && data.anim) || 'noanim'} />
      {/* <div className={'op-line'} /> */}
    </Callopsebox >
  )
}


// /* frame top or bottom line */
// const OptionLine = (props) => {
//   const { data, active, onClick, tag = 'frame', item } = props;

//   const { pageopts } = global;
//   const bgcolor = "rgba(0,0,0,0.5)";
//   const OPT = ST.OPTS;
//   var sizelist = [
//     // { name: 'N (w:auto)', id: 'none', check: true },
//     { name: `XL (w:${OPTS.maxWidth('xl')})`, id: 'xl', check: false },
//     { name: `L (w:${OPTS.maxWidth('lg')})`, id: 'lg', check: false },
//     { name: `M (w:${OPTS.maxWidth('md')})`, id: 'md', check: false },
//     { name: `S (w:${OPTS.maxWidth('sm')})`, id: 'sm', check: false },
//     { name: `XS (w:${OPTS.maxWidth('xs')})`, id: 'xs', check: false },
//     { name: 'F (fullscreen)', id: 'full', check: false }
//   ];

//   if (pageopts && pageopts.style === 'onepage') {
//     sizelist = [
//       // { name: 'N (w:auto)', id: 'none', check: true },
//       { name: `M (w:${OPTS.maxWidth('md')})`, id: 'md', check: false },
//       { name: `S (w:${OPTS.maxWidth('sm')})`, id: 'sm', check: false },
//       { name: `XS (w:${OPTS.maxWidth('xs')})`, id: 'xs', check: false },
//     ]
//   }
//   const borderlist = [
//     {eid: 'top',  }
//   ]

//   const onChange = (value, label) => {
//     props.onChange && props.onChange(tag, { [label]: value });
//   }

//   const onChangeCheck = (value) => {
//     let result = value ? value.id : '';
//     if (result === 'none') result = 'auto';
//     props.onChange && props.onChange(tag, { 'size': result });
//   }

//   const show = item && item.ctag.toLowerCase() === 'textlistbox';

//   return (
//     <Callopsebox className={'dark op-grp'} label={OPT.BOX} bgcolor={bgcolor}
//       active={active === OPT.BOX} onClick={() => onClick(OPT.BOX)}>
//       {pageopts && pageopts.style !== 'onepage' && <Fragment>
//         <Slider className={'op-li'} value={data && data.margin} min={0} max={300} label={OPT.MARGIN}
//           unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'margin')} />
//         <p className={'op-noti'}>{ST.OPTS.MARGIN_H}</p>
//         <div className={'op-line'} />
//       </Fragment>}
//       <Slider className={'op-li'} value={data && data.padding} min={0} max={100} label={OPT.PADDING}
//         unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'padding')} />
//       <p className={'op-noti'}>{ST.OPTS.PADDING_H}</p>
//       <div className={'op-line'} />
//       <Checkbox className={'op-li'} value={data && data.size} list={sizelist} theme={"white"}
//         onChange={onChangeCheck} radio={true} label={OPT.SIZE} />
//       <p className={'op-noti'}>{ST.OPTS.BOXSIZE_H}</p>
//       <div className={'op-line'} />
//       <Slider className={'op-li'} value={data && data.border} min={0} max={50} label={OPT.BORDER}
//         unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'border')} />
//       <p className={'op-noti'}>{ST.OPTS.BOXBORDER_H}</p>
//       <div className={'op-line'} />
//       <Slider className={'op-li'} value={data && data.radius} min={0} max={500} label={OPT.RADIUS}
//         unit={"px"} onChange={(v, e) => onChange(`${v}px`, 'radius')} />
//       <p className={'op-noti'}>{ST.OPTS.RADIUS_H}</p>
//       <div className={'op-line'} />
//       <Colorbox className={"op-li no-shadow black"} value={data && data.color} clear={true}
//         onChange={(hex, rgb, e) => onChange(hex, 'color')} label={`${OPT.BORDER} ${OPT.COLOR}`} />
//       <div className={'op-line'} />
//       {show && <Colorbox className={"op-li no-shadow black"} value={data && data.bgcolor} clear={true}
//         onChange={(hex, rgb, e) => onChange(hex, 'bgcolor')} label={`${OPT.BACKGROUND} ${OPT.COLOR}`} />}
//       <Togglebox className={'md'} size={'md'} list={[]} />
//     </Callopsebox >
//   )
// }