/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Listbox, Texteditor, Loading } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.board { ${cs.pos.relative} ${cs.bg.white}
    .br-frame { ${cs.w.full} ${cs.max.wlg} ${cs.min.h('calc(100vh - 100px)')} ${cs.align.xcenter} ${cs.pos.relative} 
      ${cs.h.fit} ${cs.p.h50} ${cs.p.b40}
      .title { ${cs.font.xl} ${cs.p.t40} ${cs.p.b20} ${cs.m.b20} ${cs.border.bottom} ${cs.font.t0} ${cs.font.himelody} ${cs.font.thickbold} }
      .list { ${cs.font.xl} ${cs.min.h('70vh')} ${cs.disp.none}
        &.show { ${cs.disp.block} }
        .search-frame { ${cs.font.center}
          .search-box { ${cs.bg.white} ${cs.box.light}
            .input { ${cs.bg.white} }
            .cb-sel { ${cs.bg.trans} }
          }
        }
      }
      .cont { ${cs.disp.none} ${cs.min.h('70vh')} 
        &.show { ${cs.disp.block} ${cs.anim.showin()} }
        .ed-head {
          .ed-title {
            ${cs.bg.white} ${cs.border.none} ${cs.border.bottom} ${cs.font.t1} ${cs.p.b10} ${cs.border.width(2)}
            ${cs.border.bottomcolor(cs.color.dark)}
          }
        }
        .ed-body { ${cs.border.none} ${cs.bg.white}
          .ed-editor { ${cs.h.fit} ${cs.max.height('fit-content')} ${cs.bg.white} }
        }
      }
    }
    
    
  @media screen and (max-width : 1024px) { }
  
  @media screen and (max-width : 600px) { 
    .br-frame { ${cs.p.h20} }
  }

  @media screen and (max-width : 480px) { }
  }
}`;

const Board = (props) => {
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: '', value: '' });
  // const [order, setOrder] = useState('ctime desc');
  // const [limit, setLimit] = useState(15);
  // const [running, setRunning] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [select, setSelect] = useState(null);
  const [compnay, setCompany] = useState('');
  const order = 'ctime desc';
  const limit = 15;

  const searchs = [
    { name: ST.SUBJECT, id: 'title', check: true },
    { name: ST.CTIME, id: 'ctime', check: false },
    { name: ST.UTIME, id: 'utime', check: false },
  ];

  useEffect(() => {
    const param = actions.getParam();
    doReload(1, search, order, limit, (array = null) => {
      setLoaded(true);
      if (param) {
        const { rowid } = param;
        if (rowid && array) {
          const t = array.find(a => String(a.rowid) === String(rowid))
          setSelect(t);
        }
      }
      setTimeout(() => global.onLoaded && global.onLoaded(true), 100);
    });
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    // setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "${s.value}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(URL.API.BOARD, value, true).then(({ result }) => {
      setTotal(result.page && result.page.total)
      setPage(result.page && result.page.current);
      setMax(result.page && result.page.max);
      setSearch(s);
      setCompany(result && result.comp && result.comp.length > 0 && result.comp[0].sval);

      result.list = result.list.map(item => {
        item.subject = `${item.no}. ${item.title}`;
        item.date = item.ctime ? item.ctime.substr(0, 8) : '';
        return item;
      });

      setList(result.list);
      // setRunning(false);

      callback && callback(result.list);
    });
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (rowid, e, item) => {
    setSelect(item);
    setTimeout(() => {
    }, 300);
  }

  const onClick = () => {
    setSelect(null);
  }

  if (!loaded) return <div></div>;

  return <StyledObject className={cx('board')}>
    <div className={'br-frame'}>
      <div className={'title'}>{compnay && ST.BOARD_TITLE(compnay)}</div>
      <Listbox className={cx("list", !select && 'show')} list={list} title={'subject'}
        pos={page} max={max} total={total} rowid="rowid" searchkey={search.key} searchs={searchs}
        onClickPage={onClickPage} onSelect={onSelect} onClickSearch={onClickSearch} />
      {select && <Texteditor className={cx("cont show")} title={'test'} rowid={select && select.rowid}
        api={URL.API.BOARD} onClick={onClick} readonly={true} />}
    </div>
    {/* {running && <Loading className={'fixed'} type={'ring'} />} */}
  </StyledObject>
};

export default Board;