import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Loading, cs, Editbox, Svg } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS, LinkButton } from './PageCom';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{ 
  &.textlist-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()} 

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.h.fit} 
      ${cs.disp.block} ${cs.object.contain} ${cs.min.h(100)} ${cs.p.a20} ${cs.z.front}
      ${cs.border.radius(5)} ${cs.over.hidden} 
      
        .p-cont { ${cs.z.front} ${cs.pos.relative}
          .p-tit { 
            ${cs.font.t1} ${cs.p.v5} ${cs.font.bold} ${cs.border.bottom} ${cs.p.bottom(10)}
          }

          .h-frame { ${cs.disp.autoflex} ${cs.min.h(180)} ${cs.p.a20} ${cs.m.t10}
            .h-list { ${cs.w.full} ${cs.disp.inblock} ${cs.font.line(20)} ${cs.border.radius(5)}
              .h-li { ${cs.disp.block} ${cs.p.h10} ${cs.border.bottom} ${cs.pos.relative} ${cs.p.t10}
                .li-tit { ${cs.disp.block} ${cs.w.full} ${cs.font.ellipsis} }
                .li-txt { ${cs.disp.block} ${cs.m.t10} ${cs.w.full} ${cs.font.prewrap} ${cs.opac.get(0.8)}
                  ${cs.font.spacing(-1)} }
                .li-msg { ${cs.disp.block} ${cs.w.fit} ${cs.font.ellipsis} ${cs.opac.get(1)} 
                  ${cs.align.bottom} ${cs.bottom(-8)} ${cs.font.line(16)} ${cs.z.front}
                  ${cs.font.right} ${cs.font.sm} ${cs.font.color("#00000090")} ${cs.font.shadow("#00000070")} 
                  ${cs.bg.lightgray} ${cs.border.radius(50)} ${cs.p.a0} ${cs.p.h10}
                  &.right { ${cs.right(0)} }
                  &.left { ${cs.left(0)} }
                }
              }
            }
          }
        }
      }
      
      .p-noti { ${cs.font.orange} }
    }
  }

  &.textlist-viewer {
    &.onepage {
      .p-frame .p-cont { ${cs.over.hidden} ${cs.max.h('80vh !important')} ${cs.min.h('400px !important')} }

      .h-frame { ${cs.over.yauto} ${cs.scrollbar.t1} ${cs.scrollbar.hide} ${cs.max.h('80vh')} ${cs.min.h(400)}
        &:hover { ${cs.scrollbar.t1} } 
      }

      @media screen and (max-width : 1440px) {
        .p-frame .p-cont {
          .cont-frame { ${cs.object.fit('cover !important')} }
        }
      } 
    }

    .p-frame.over {
      top: unset !important; bottom: unset !important; left: unset !important;
      right: unset !important; transform: none !important; ${cs.top('50px !important')}
    }

    .p-frame {
      .p-cont {
        .h-frame {
          .h-list { 
            .h-li.link { ${cs.mouse.alias} 
              &:hover { ${cs.box.shadow} ${cs.z.over} ${cs.anim.zoomin('0.3s', 1, 1.02)} ${cs.border.radius()} }
            }

            .h-li { ${cs.opac.invisible}
              &.noanim { ${cs.opac.visible} }
              &.type1 { ${cs.anim.slidein('0.5s', '100%', 0, 'hb-type1')} }
              &.type2 { ${cs.anim.slideup('0.3s', '-200%', 0, 'hb-type2', 'ease-in')} }
              &.type3 { ${cs.anim.showin('1.5s', 0, 1, 'hb-type3', 'ease-in')} }
            }
          }
        }
      }

      &.anim .p-cont { ${cs.anim.slidein('1.0s', '100%', 0, 'tx-anim-in', 'ease-in')} }
    }
  }

  &.textlist-editor { ${cs.p.b40} ${cs.bg.dark}
    .p-frame { ${cs.p.a10}
      .p-tit { ${cs.w.calc('100% - 40px')} ${cs.m.l20} }
      .p-cont .h-frame {
        .h-list { 
          .h-li { ${cs.p.b10}
            .li-tit { ${cs.m.t5} ${cs.w.calc('100% - 140px')} }
            .li-txt { ${cs.m.t10} ${cs.w.calc('100% - 80px')} }
            .li-msg { ${cs.w.get(120)} 
              ${cs.align.unset} ${cs.align.right} ${cs.top(15)}
              .input { ${cs.font.right} ${cs.bg.trans} }
            }
            .li-btn { ${cs.align.right} ${cs.bottom(10)} ${cs.disp.inblock} ${cs.w.get(80)} ${cs.h.get(60)} 
              .li-delete { ${cs.align.rbottom} ${cs.right(0)} ${cs.bg.alphagray} ${cs.border.alphablack} }
              .link-button {
                ${cs.align.lbottom} ${cs.bottom(-10)} ${cs.left(50)}
                // .svg-icon { ${cs.bottom('unset')} ${cs.bottom(5)} }
              }
            }
          } 
        }
      }
    }

    .h-new { ${cs.w.full} ${cs.mouse.pointer} ${cs.pos.relative} ${cs.m.t20}
      & > span {
        ${cs.box.line} ${cs.box.dashed} ${cs.align.center} ${cs.font.sm} ${cs.bg.frame}
        ${cs.p.a5} ${cs.p.h20} ${cs.font.orange} ${cs.border.radius(3)} 
      }
      &.max { ${cs.opac.get(0.7)} }
    }

    &.onepage {
      .p-frame .p-cont { ${cs.p.h10} ${cs.p.b20} ${cs.over.yauto} ${cs.scrollbar.t1} }
    }
  }

};`;

const TextlistBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default TextlistBox;

var timeouts = [];
const BoxViewer = (props) => {
  const { title, jsons, className, bg, path, url, opts } = props.item;
  const { pageopts } = global;
  const { innerHeight } = window;
  const bgdata = OPTS.image(bg, path);
  const list = OPTS.jsons(jsons);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const mp = OPTS.maxPadding(maxWidth);
  const maxstyle = mp && pageopts && pageopts.style === 'onepage' ? { width: `calc(100% - ${mp} - ${mp})`, margin: `${mp} 0` } : {}
  const linecolor = label.color.substr(0, 7) + "a0";
  const over = innerHeight < 600;

  const [loaded, setLoaded] = useState(false);
  const [delta, setDelta] = useState(0);
  const [refresh, setRefresh] = useState(null);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');
  const [array, setArray] = useState(box && box.anim === 'noanim' ? list : list.forEach(a => a.anim = 'hide'));

  useEffect(() => {
    setLoaded(true);

    const onResize = () => {
      setRefresh(new Date().getTime());
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      if (timeouts) {
        timeouts.forEach(a => clearTimeout(a));
        timeouts = [];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (props.active && box) {
    if (props.active) {
      if (box && box.anim && box.anim !== 'noanim') {
        setAnim('no');
        setTimeout(() => setAnim(box.anim), 200);

        if (box.anim === 'noanim') {
          setArray([...list]);
        } else {
          list.forEach((a, i) => {
            a.anim = 'hide';
            timeouts.push(setTimeout(() => {
              a.anim = box.anim;
              setArray([...list]);
            }, 100 * i));
          });
        }
      } else {
        list && setArray([...list.map(a => { a['anim'] = 'noanim'; return a; })]);
      }
    }
    // else {
    //   list && setArray([...list.map(a => { a['anim'] = 'noanim'; return a; })]);
    // }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  const onWheel = (e) => {
    const { currentTarget } = e;
    const isdown = e.deltaY > 0;

    if (currentTarget) {
      const { clientHeight, scrollTop, scrollHeight } = currentTarget;
      if (scrollHeight <= innerHeight) return;
      const pos = clientHeight + scrollTop;
      if (isdown && pos < scrollHeight) return e.stopPropagation();
      if (!isdown && scrollTop > 1) return e.stopPropagation();

      const d = delta + Math.abs(e.deltaY);
      setDelta(d);
      if (d < 300) return e.stopPropagation();
      setDelta(0);
    }
  }

  return (
    <StyledObject className={cx("textlist-box textlist-viewer", className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded }, { over })}
        style={{ maxWidth, ...box.options, padding: box.padding, margin: `${box.margin} 0`, ...maxstyle, backgroundColor: box.bgcolor || 'transparent', ...frame.border }}>
        <div className="p-cont">
          {title && <p className={cx("p-tit", anim && array ? anim : '')} eid={"title"} style={{ ...label.options, textAlign: 'center', borderColor: linecolor }}>{title}</p>}

          <div className={cx(`h-frame`)} onWheel={onWheel} refresh={refresh}>
            <ul className={"h-list"}>
              {array && array.map((item, index) => {
                const { link = null } = item;
                let marginTop = (label && label.margin) || '10px';
                if (index === 0) marginTop = 0;
                const align = desc.align === 'right' ? 'left' : 'right';

                return <li key={index} className={cx('h-li show', { link }, index === 1 && 'active', item.anim || 'noanim')}
                  // style={{ paddingTop: marginTop, borderColor: linecolor, backgroundColor: box.bgcolor || 'white' }}
                  style={{ paddingTop: marginTop, borderColor: linecolor }}
                  onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null} >
                  <span className={cx("li-tit white")} style={{ ...label.options, marginTop: '10px' }}>{item.title}</span>
                  <span className={cx("li-txt white")} style={{ ...desc.options, padding: `${desc.margin} 0`, marginBottom: '5px' }}>{item.desc}</span>
                  <span className={cx("li-msg white", align)} >{item.message}</span>
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
      {props.children}
      {url && !loaded && <Loading />}
    </StyledObject>
  )
}

const BoxEditor = (props) => {
  const [list, setList] = useState();
  const NEWITEM = { 'uuid': Util.getGenerateKey(), 'title': '', 'text': '' };
  const MAXLEN = 30;
  const { title, className, bg, path, opts, request } = props.item;

  useEffect(() => {
    if (props.item && props.item.jsons) {
      setList(JSON.parse(props.item.jsons));
    }
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, e, label, file) => {
    if (!props.item) return;
    const req = props.item.request;
    if (label === 'url') request[label] = file;
    else req[label] = Util.toText(value);
  }

  const onChangeJson = (value, e, label, item) => {
    if (!props.item) return;

    const req = props.item.request;
    const temp = list.find(a => a.uuid === item.uuid);
    temp[label] = value;
    req['jsons'] = list;
  }

  const onClickDelete = (index) => {
    const array = [...list.slice(0, index), ...list.slice(index + 1)];
    setList([...array]);

    const req = props.item.request;
    req['jsons'] = array;
  }

  const onClickNew = () => {
    if (list && list.length >= MAXLEN) return;
    const array = list ? [...list, NEWITEM] : [NEWITEM];
    setList([...array]);
  }

  const onClickLink = (item) => {
    const doUpdate = (value) => {
      actions.doUpdate(API.PAGE_IMAGELINK, { rowid: props.item.rowid, link: value }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
        props.onClickLink('close');
      });
    }

    props.onClickLink('show', { ...props.item, sellinkid: item.uuid }, (eid, page, target) => {
      const temp = list.find(a => String(a.uuid) === String(item.uuid));
      temp && (temp['link'] = JSON.parse(target));
      console.dir({ eid, page, target, temp });
      doUpdate(JSON.stringify(list));
    });
  }

  // const onClickOptions = (eid, value, e) => {
  //   if (!eid || !value) return;

  //   const temps = { ...style, [eid]: value };
  //   setStyle({ ...temps });
  //   const params = { rowid: props.item.rowid, opts: { ...props.item.opts, style: { ...temps } } };
  //   actions.doUpdate(URL.API.ADMIN.PAGE_OPTS, params).then(({ code }) => {
  //     (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
  //   });
  // }

  const bgdata = OPTS.image(bg, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const { pageopts } = global;

  return (
    <StyledObject className={cx("textlist-box textlist-editor", className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame")} style={{ maxWidth, ...box.options, padding: box.padding }} >
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />

          <div className={cx('h-new', list && list.length >= MAXLEN && 'max')} onClick={onClickNew}>
            <span>{list && list.length >= MAXLEN ? ST.PAGE.MAX_LEN(MAXLEN) : ST.PAGE.NEW_ITEM}</span>
          </div>

          <div className={cx(`h-frame`)}>
            <ul className={"h-list"}>
              {list && list.map((item, index) => {
                return <li key={index} className={"h-li"}>
                  <Editbox className={cx("li-tit white")} value={item.title} guide={ST.PAGE.SUBJECT}
                    onChange={(v, e) => onChangeJson(v, e, 'title', item)} maxLength={20} />
                  <Editbox className={cx("li-txt white")} value={item.desc} guide={ST.PAGE.CONTENT} maxLength={1000}
                    onChange={(v, e) => onChangeJson(v, e, 'desc', item)} multi={true} minheight={40} maxheight={100} height={60} />
                  <Editbox className={cx("li-msg white")} value={item.message} guide={ST.PAGE.SUBJECT}
                    onChange={(v, e) => onChangeJson(v, e, 'message', item)} maxLength={10} />
                  <span className={'li-btn'}>
                    <Svg className="lbx-icon lg white li-delete border radius box" name={'delete'} onClick={() => onClickDelete(index)} />
                    <LinkButton onClick={() => onClickLink(item)} />
                  </span>
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
      { props.children}
    </StyledObject >
  )
}