import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Imagebox, Loading, cs, Editbox, Uploadbox, Button } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS } from './PageCom';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{ 
  &.banner-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()}

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.h.fit}
      ${cs.disp.block} ${cs.object.contain} ${cs.over.hidden}
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} ${cs.top(0)} ${cs.w.full} 
          ${cs.min.h(100)} ${cs.h.fit} ${cs.bg.pos('center')} ${cs.bg.size('cover')}

          .p-img { ${cs.max.h(140)} ${cs.min.h(80)} ${cs.w.full} }

          .p-box {
            ${cs.align.ltop} ${cs.size.full}
            .p-tit { 
              ${cs.p.h30} ${cs.font.t0} ${cs.font.center} ${cs.font.bold} ${cs.font.ellipsis} ${cs.m.t5}
            }

            .p-txt { 
              ${cs.font.sm} ${cs.p.h30} ${cs.font.center} ${cs.font.ellipsis} ${cs.align.bottom} ${cs.p.b10}
              ${cs.w.full} ${cs.align.xcenter}
            }
          }

          &.bg { ${cs.h.get(500)} }
        }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }

        .link { ${cs.mouse.pointer} }
      }
      
      .p-noti { ${cs.font.yellow} ${cs.font.prewrap} ${cs.p.v3} &.gray { ${cs.font.orange} } }
      .pointer { ${cs.mouse.pointer} };
      
      &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
    }

    &.xl { .p-frame { } }
    &.lg { .p-frame { } }
    &.md { .p-frame { } }
    &.sm { .p-frame { } }
    &.xs { .p-frame { } }

    @media screen and (max-width : 1280px) {}  
    @media screen and (max-width : 1024px) {}  
    @media screen and (max-width : 860px) {}
  }
  
  &.banner-viewer {
    &.full { ${cs.h.fit} ${cs.p.v0} ${cs.max.none}
      .p-frame { ${cs.align.unset} ${cs.pos.relative} ${cs.w.full} ${cs.max.none} ${cs.p.a0} 
        .p-box { ${cs.align.xcenter} ${cs.m.v10} }
      } 
    }

    .p-frame .p-cont { ${cs.over.hidden}
        .p-img .ib-frame { ${cs.opac.invisible} }
        .p-tit, .p-txt { ${cs.opac.invisible} }

        &.noanim { 
          .p-img .ib-frame { ${cs.opac.visible} }
          .p-tit, .p-txt { ${cs.opac.visible} }
        }
        &.type1 {
          .p-tit, .p-txt { ${cs.opac.invisible} }
          .p-tit, .p-txt {
            ${cs.anim.zoomin('1s', '0.5', '1', 1, 'bnb-type1-text')} ${cs.anim.delay('1s')} ${cs.anim.origin('left top')}
          }
          .p-img .ib-frame { ${cs.anim.zoomin('3s', '1.1', '1', 1, 'bnb-type1')} }
        }
        &.type2 {
          .p-tit, .p-txt { ${cs.opac.invisible} }
          .p-tit, .p-txt { ${cs.opac.invisible} 
            ${cs.anim.slideup('0.5s', '-100%', '0', 'bnb-type2-text')} ${cs.anim.delay('0.5s')}
          }
          .p-img .ib-frame { 
            ${cs.anim.slideup('0.5s', '100%', 0, 'bnb-type2', 'ease-in-out')}
            ${cs.anim.origin('right top')} ${cs.anim.delay('0.1s')} 
          }
        }
        &.type3 {
          .p-tit, .p-txt { ${cs.opac.invisible} }

          .p-tit, .p-txt { ${cs.opac.invisible} 
            ${cs.anim.slidein('0.5s', '100%', '0', 'bnb-type3-text', 'ease-out')} ${cs.anim.delay('0.5s')}
          }
          .p-img .ib-frame { ${cs.opac.visible} ${cs.anim.slidein('1s', '-100%', '0', 'bnb-type3', 'ease-out')} ${cs.anim.origin('left')} }
        }
      }
  }

  &.banner-editor { ${cs.p.b40} ${cs.bg.dark}
    .p-frame {
      .p-cont { ${cs.bg.frame} }

      .p-editor { 
        .p-ul {
          ${cs.p.a10}
          .p-li {
            ${cs.disp.block} ${cs.h.fit} ${cs.box.line} ${cs.box.radius} ${cs.box.doted} 
            ${cs.border.darkgray} ${cs.p.a5} ${cs.m.t10}
            .p-tit, .p-txt {${cs.m.v5} }
            .p-btn { ${cs.m.t10} ${cs.font.right} ${cs.w.full} 
              .button { ${cs.m.l10} }
            }
          }
        }

        .p-box { ${cs.disp.block} ${cs.m.t10} }

        .new { 
        }
      }
    }

    &.onepage {
      .p-frame {
        .p-editor { ${cs.max.h(400)} ${cs.over.hidden} ${cs.over.yauto} ${cs.scrollbar.t2}
          .p-ul { ${cs.h.fit} }
        }
      }
    }
  }

};`;

const BannerBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default BannerBox;

const BoxViewer = (props) => {
  const { className, bg, path, url, opts, jsons } = props.item;
  const bgdata = OPTS.image(bg, path);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  const [loaded, setLoaded] = useState(false);
  const [select, setSelect] = useState(null);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  useEffect(() => {
    setLoaded(true);
    const list = OPTS.jsons(jsons);
    if (list && list.length > 0) {
      const index = Math.floor((Math.random() * list.length - 1) + 1);
      setSelect(list[index] || null);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const height = box.size === 'sm' || box.size === 'xs' ? 80 : box.size === 'md' ? 100 : 120;

  if (!select) return null;
  return (
    <StyledObject className={cx("banner-box banner-viewer", className, box.size)}>
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth, padding: box.padding, margin: `${box.margin} 0` }} >
        <div className={cx("p-cont", select && select.link && 'link', anim || 'noanim')} style={{ height }}
          onClick={select.link && props.onClickLink ? () => props.onClickLink('open', select.link) : null}>
          {select && select.url && <Imagebox className="p-img" type={'image'} fit={'fill'} style={{ ...box.options }}
            url={select && Util.getImage(select.url, path)} size={'full'} onLoad={onLoad} onError={onError} height={height} />}
          <div className={cx("p-box")}>
            {select && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{select.title || ''}</p>}
            {select && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options, paddingTop: 0, paddingBottom: desc.margin }}>{select.desc || ''}</p>}
          </div>
        </div>
      </div>
      { props.children}
      { url && !loaded && <Loading />}
    </StyledObject >
  )
}

const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [select, setSelect] = useState(null);
  const MAXLEN = 5;
  const NEWITEM = { 'uuid': Util.getGenerateKey(), 'url': '', edited: true, new: true };
  const { className, bg, path, opts } = props.item;

  useEffect(() => {
    if (props.item && props.item.jsons) {
      const temps = JSON.parse(props.item.jsons);
      setList(temps);
      setSelect(temps[0]);
    } else {
      setList([NEWITEM]);
    }
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onChange = (value, key, index) => {
    list[index][key] = value;
    list[index]['edited'] = true;
    setList([...list]);
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickNew = () => {
    if (editing) return;
    if (list && list.length >= MAXLEN) return;

    const array = list ? [...list, NEWITEM] : [NEWITEM];
    setList([...array]);
    props.item.request['noti'] = ST.PAGE.NO_SAVE_IMG;
  }

  const onClickDelete = (index) => {
    if (!list || list.length <= 0) return;
    const { rowid } = props.item;
    const value = list[index];

    if (value && value.edited && value.new) {
      const array = [...list.slice(0, index), ...list.slice(index + 1)];
      setList([...array]);
      props.item.request['noti'] = null;
    } else {
      global.openConfirm({
        type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
          (isOk) && actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
            Util.showAlert(props, code);
            if (code !== CODE.SUCCESS) return;

            const array = [...list.slice(0, index), ...list.slice(index + 1)];
            setList([...array]);
            props.item.request['noti'] = null;
          });
        }
      });
    }
  }

  const onClickSave = (index) => {
    const { rowid = 0 } = props.item;
    const value = list[index];
    delete value['edited'];
    delete value['new'];

    actions.doUpdate(API.PAGE_IMAGES, { rowid, value, index }).then((res) => {
      const { code, data } = res;
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }

      list[index] = data;
      actions.doUpdate(API.PAGE_JSON, { rowid, value: [...list], index }).then((r) => {
        if (r.code !== CODE.SUCCESS) { Util.showAlert(props, r.code); return; }
      });

      // list[index] = data;
      setList([...list]);
      props.item.request['noti'] = null;
    });
  }

  const onClickLink = (item) => {
    const doUpdate = (value) => {
      actions.doUpdate(API.PAGE_IMAGELINK, { rowid: props.item.rowid, link: value }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
        props.onClickLink('close');
      });
    }

    props.onClickLink('show', { ...props.item, sellinkid: item.uuid }, (eid, page, target) => {
      const temp = list.find(a => String(a.uuid) === String(item.uuid));
      temp && (temp['link'] = JSON.parse(target));
      console.dir({ eid, page, target, temp });
      doUpdate(JSON.stringify(list));
    });
  }

  const bgdata = Util.getImage(bg, path);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const editing = list && list.find(a => a && Boolean(a.edited || false));
  const height = box.size === 'sm' || box.size === 'xs' ? 80 : box.size === 'md' ? 100 : 120;
  const { pageopts } = global;

  return (
    <StyledObject className={cx("banner-box banner-editor", className, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth, ...frame.border }}>
        <label className={'p-noti'}>{ST.PAGE.BANNER_G}</label>
        <div className={cx("p-cont", select && select.link && 'link')} style={{ height }}
          onClick={select && select.link && props.onClickLink ? () => props.onClickLink('open', select.link) : null}>
          {select && select.url && <Imagebox className="p-img" type={'image'} fit={'fill'} url={select && Util.getImage(select.url, path)} size={'full'}
            onLoad={onLoad} onError={onError} height={height} onClickLinked={() => onClickLink(select)} />}
          <div className={cx("p-box")}>
            {select && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{select.title || ''}</p>}
            {select && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{select.desc || ''}</p>}
          </div>
        </div>
        <label className={'p-noti gray'}>{ST.PAGE.BANNER_L}</label>

        <div className={'p-editor'}>
          {list && list.length > 0 && <ul className={'p-ul'}>
            {list.map((item, i) => {
              const imgpath = Util.getImage(item.url, path);
              return <li className={'p-li'} key={item.uuid}>
                <Uploadbox className="p-img" type={'image'} fit={'fill'} value={imgpath} height={`${height}px`}
                  onLoad={onLoad} onError={onError} maxImage={{ width: 1600, height: 120 }}
                  // imagestyle={{ height, padding, margin }}
                  onChange={(v, e) => onChange(v, 'url', i)} onClickLinked={() => onClickLink(item)} />

                <div className={cx("p-box")}>
                  <label className={'p-noti'}>{ST.PAGE.BANNER_H}</label>
                  <Editbox className={cx("p-tit white sm")} value={item.title} guide={ST.PAGE.SUBJECT_H}
                    onChange={(v, e) => onChange(v, 'title', i)} maxLength={50} />
                  <Editbox className={cx("p-txt white sm sizefix")} value={item.desc} guide={ST.PAGE.CONTENT_H(100)}
                    onChange={(v, e) => onChange(v, 'desc', i)} maxLength={100} />
                </div>

                <div className={'p-btn'}>
                  {i === list.length - 1 && <Button className={'new md green left'} title={ST.ADD} onClick={onClickNew}
                    disabled={editing} />}
                  <Button className={'preview md gd-gray'} title={ST.PREVIEW} onClick={() => setSelect(list[i])} disabled={item.new} />
                  <Button className={'delete md black'} title={ST.DELETE} onClick={() => onClickDelete(i)} disabled={item.new} />
                  <Button className={'save md red'} title={ST.SAVE} onClick={() => onClickSave(i)} disabled={!item.edited} />
                </div>
              </li>
            })}
          </ul>}
        </div>
      </div>
      { props.children}
    </StyledObject >
  )
}