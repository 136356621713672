import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GMAIL_HELP, NAVER_HELP } from '../svc/Lang';
import cx from 'classnames/bind';
import { URL } from 'svc/Enum';
import { cs, Imagebox } from 'object/src';

const GuideBox = () => {
  const StyledComp = styled.div`{
    &.guide-box { ${cs.bg.trans} ${cs.w.full} ${cs.p.h30} ${cs.max.wlg} ${cs.min.wsm} ${cs.align.xcenter}
      .g-btn { ${cs.z.over} }

      &.right { .g-btn { ${cs.align.right} ${cs.right(0)} } }
      &.top { .g-btn { ${cs.align.top} ${cs.top(0)} } }
      &.left { .g-btn { ${cs.align.left} ${cs.left(0)} } }
      &.bottom { .g-btn { ${cs.align.bottom} ${cs.bottom(0)} } }

      .g-box { ${cs.disp.none} ${cs.w.full} ${cs.bg.trans} ${cs.m.v30}
        .g-frame { 
          ${cs.box.shadow} ${cs.p.a20} ${cs.pos.relative} ${cs.disp.block} ${cs.p.bottom(50)}

          .g-tit { ${cs.font.line(24)} ${cs.font.prewrap} ${cs.font.bold} ${cs.m.t20} ${cs.font.lg}
            &.url:hover { ${cs.font.underline} ${cs.font.yellow} ${cs.mouse.pointer} }
          }
          .g-img { 
            ${cs.m.center(10)} ${cs.m.h20}
            &.url:hover { ${cs.mouse.pointer} }
          }
          .g-txt {
            ${cs.font.sm} ${cs.font.prewrap} ${cs.font.line(20)} ${cs.p.h20}
            &.url { ${cs.font.underline} }
            &.url:hover { ${cs.font.underline} ${cs.font.yellow} ${cs.mouse.pointer} }
          }
          .g-lin { ${cs.border.bottom} ${cs.m.v10} ${cs.border.gray} 
            &.dark { ${cs.border.dark} }
            &.white { ${cs.border.white} }
            &.orange { ${cs.border.orange} }
          }

          // .close { ${cs.float.right} ${cs.font.dark} ${cs.z.over} }
        }

        &.show { ${cs.disp.block} ${cs.anim.showin('300ms')} }
      }
    }
  }`;

  // const [show, setShow] = useState(true);
  const [list, setList] = useState(null);

  useEffect(() => {
    const { pathname } = window.location;
    const param = pathname.replace(`${URL.ADMIN.GUIDE}/`, '');
    // console.dir(param);

    switch(param) {
      case 'naver': setList(NAVER_HELP); break;
      default: setList(GMAIL_HELP); break;
    };

  }, []);

  const onClick = (item) => {
    window.open(item.url);
  }

  return <StyledComp className={cx('guide-box')}>
    <div className={cx('g-box show')}>
      <div className={'g-frame'}>
        {list && list.map((item, i) => {
          const { type, value, url = null } = item;
          if (type === 'text') {
            return <p key={i} className={cx('g-tit', { url })} onClick={() => onClick(item)}>{value}</p>
          } else if (type === 'desc') {
            return <p key={i} className={cx('g-txt', { url })} onClick={() => onClick(item)}>{value}</p>
          } else if (type === 'line') {
            return <div key={i} className={cx('g-lin', item.color || '')} style={{ borderWidth: value || '1px' }} />
          } else {
            return <div key={i} className={cx('g-img', { url })} onClick={() => onClick(item)} >
              <Imagebox size={'full'} url={value} />
            </div>
          }
        })}
      </div>
    </div>
  </StyledComp>
}

export default GuideBox;