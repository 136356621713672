import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Editbox, Button, Util, TabButton, Loading, Formgroup, Postcode, Svg, Error } from 'object/src';
import { EID } from 'object/src/Config';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import { PRIVACY, PUBLIC } from '../svc/Privacy';
import moment from 'moment';

const StyledObject = styled.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }

  &.ad-set { ${cs.bg.trans}
    .tab-grp { ${cs.m.t30} ${cs.p.l20} }
    .hp-frame { ${cs.p.a20} }
  }

  &.sect {
    ${cs.m.v20} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wlg} ${cs.w.full}
    .edit-box { ${cs.m.t10} }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box { ${cs.p.a10} }
    p.noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} ${cs.font.line(16)}
      ${cs.font.prewrap}
      &.hover:hover { ${cs.font.underline} ${cs.mouse.pointer} } 
    }
  }

  &.set-comp {
    .add-box {
      .add-btn { ${cs.mouse.pointer} ${cs.pos.relative} & > .svg-icon { ${cs.bottom(5)} ${cs.right(5)} } }
      .add-edit { &.edit-box .box .input.readonly { ${cs.bg.frame} ${cs.mouse.pointer} } }
    }
  }

  &.set-appinfo {
    .s-row { ${cs.pos.relative} ${cs.m.t40}
      & > .button { ${cs.top(-20)} }
    }
  }

  &.set-privacy, .set-public {
    ${cs.max.wxl}
    & > .sec-box { ${cs.p.a10} ${cs.min.h(600)} }

    .preview { ${cs.right(180)} }
    .policy-agree { ${cs.font.dark} }
  }

  @media screen and (max-width : 600px) {
    &.ad-set .hp-frame { ${cs.p.h10} ${cs.m.t20} }
    &.sect .edit-box {  }
    &.set-appinfo .s-row { ${cs.m.top(70)} & > .button { ${cs.top(-40)} } }
    &.sect .preview { ${cs.align.top} ${cs.top(-40)} ${cs.right(0)} }
    &.set-privacy .policy-agree, &.set-public .policy-agree { ${cs.z.top} }
  }
}`;

const STA = ST.ADMIN.APPSET;

const Setting = (props) => {
  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('company');

  const doReload = (code) => {
    Util.showAlert(props, code);
    setRefresh(new Date());
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
  }

  const TABS = [
    { id: 'company', title: ST.COMPANY.TITLE },
    { id: 'privacy', title: ST.COMPANY.PRIVATE },
    { id: 'public', title: ST.COMPANY.PUBLIC },
  ]

  const issys = Util.isSystemAdmin();
  if (!issys) return <Error className={'t3'} title={ST.LOGINSYSADMIN} />

  return <StyledObject className={cx('ad-set')}>
    <TabButton className={"tab-grp"} size={'md'} onClick={onClick}
      list={TABS} select={tab} color={'gd-gray'} />
    {tab === 'company' && <div className={cx('hp-frame', tab)}>
      <SetCompany doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'privacy' && <div className={cx('hp-frame', tab)}>
      <SetPrivacy doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'public' && <div className={cx('hp-frame', tab)}>
      <SetPublic doReload={doReload} refresh={refresh} />
    </div>}
  </StyledObject>
};

export default Setting;

const SetCompany = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [post, setPost] = useState(false);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setList(result && result.length > 0 ? result : null);
      setData(json);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    // 회사이름(영문) 입력오류 체크
    // if (!checkValidat(refs.name_eng, /^[0-9a-zA-Z.]*$/gi, STA.NAMEE_N)) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'company', skey: key, sval: refs[key].getValue() }
    });

    actions.doInsert(URL.API.ADMIN.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);

      actions.doUpdate(URL.API.ADMIN.SETTING, { stag: 'system', skey: 'init', sval: 'success' });
    });

    return;
  }


  const onClickAddress = (eid, e) => {
    let temps = { ...data };
    Object.keys(refs).map(key => temps[key] = refs[key].getValue());
    setData({ ...temps });
    setPost(!post);
  }

  const onComplete = (address, item) => {
    setTimeout(() => {
      setPost(false);
      setData({ ...data, addr: address, zipcode: item.zonecode });
      setModified(true);
    }, 200);
  }

  if (!data) return null;
  return <StyledObject className={cx('set-comp sect')}>
    <p className={'title'}>{STA.COMPANY}</p>
    <div className={'sec-box'}>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.name = ref }} className={"name"} value={data.name}
          name="name" type="text" label={STA.COMPANY_NAME} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.name_eng = ref }} className={"name_eng"} value={data.name_eng}
          name="name_eng" type="text" label={STA.COMPANY_NAME_ENG} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.year = ref }} className={"year"} value={data.year}
          name="year" type="number" label={STA.COMPANY_YEAR} validate={true} onChange={() => setModified(true)} />
      </Formgroup>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.phone = ref }} className={"phone"} value={data.phone} name="phone" type="text"
          label={STA.COMPANY_PHONE} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.email = ref }} className={"email"} value={data.email} name="email" type="text"
          label={STA.COMPANY_EMAIL} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.fax = ref }} className={"fax"} value={data.fax} name="fax" type="text"
          label={STA.COMPANY_FAX} validate={false} onChange={() => setModified(true)} />
      </Formgroup>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.bank = ref }} className={"bank"} value={data.bank} name="fax" type="text"
          flex={'2 1 100px'} label={STA.COMPANY_BANK} validate={false} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.boss = ref }} className={"boss"} value={data.boss} name="boss" type="text"
          flex={'1 1 100px'} label={STA.COMPANY_BOSS} validate={true} onChange={() => setModified(true)} />
      </Formgroup>
      <div className={'add-box'}>
        <Formgroup inline={true}>
          <div className={'add-btn'} flex={'2 1 100px'} onClick={onClickAddress}>
            <Editbox className={'add-edit'} ref={(ref) => { refs.addr = ref }} value={data.addr}
              label={STA.COMPANY_ADDR} validate={true} readonly={true} />
            <Svg className={'sm right'} icon={'find'} />
          </div>
          <Editbox ref={(ref) => { refs.addr_rest = ref }} value={data.addr_rest} label={STA.COMPANY_ADDR_REST} type="text"
            flex={'1 1 100px'} validate={true} onChange={() => setModified(true)} />
        </Formgroup>
        {post && <Postcode className={'post-box'} onComplete={onComplete} onCancel={() => setPost(false)} />}
      </div>
      {/* <Editbox ref={(ref) => { refs.addr = ref }} className={"addr"} value={data.addr} name="addr" type="text"
        label={STA.COMPANY_ADDR} validate={true} onChange={() => setModified(true)} /> */}
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.bizlicense = ref }} className={"bizlicense"} value={data.bizlicense} name="bizlicense" type="text"
          flex={'2 1 100px'} label={STA.COMPANY_BIZ_LICENSE} validate={false} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.license = ref }} className={"license"} value={data.license} name="license" type="text"
          flex={'1 1 100px'} label={STA.COMPANY_LICENSE} validate={true} onChange={() => setModified(true)} />
      </Formgroup>
      <Editbox ref={(ref) => { refs.bizurl = ref }} className={"bizurl"} value={data.bizurl} name="bizurl" type="text"
        label={STA.COMPANY_BIZ_URL} validate={false} onChange={() => setModified(true)} />

    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetPrivacy = (props) => {
  var refs = null;
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'privacy' }).then(({ result }) => {
      setData(result ? result[0] : null);
      setLoaded(true);
      setModified(false);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;
    const value = { rowid: data ? data.cfgid : null, stag: 'app', skey: 'privacy', sval: refs.getValue() };
    actions.doInsert(URL.API.ADMIN.SETTING, value).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onImport = () => {
    setData({ sval: PRIVACY('[*****]', moment().format('YYYY년 MM월 DD일')) });
    setModified(true);
  }

  return <StyledObject className={cx('set-privacy sect')}>
    <p className={'title'}>{ST.COMPANY.PRIVATE}</p>
    <Button className={'gd-gray top right import'} title={STA.IMPORT} onClick={onImport} />
    <Button className={'gd-gray top right preview'} title={ST.PREVIEW} disabled={modified}
      onClick={() => actions.open(URL.PRIVACY, { type: 'pri' })} />
    <div className={'sec-box'}>
      <p className={'noti'}>{`${STA.PRIVACY}`}</p>
      {loaded && <Editbox ref={(ref) => { refs = ref }} className={"privacy"} value={data && data.sval}
        type="text" guide={ST.COMPANY.PRIVATE} validate={true} onChange={() => setModified(true)}
        multi={true} height={"1000px"} minheight={400} maxheight={1600} />}
      {!loaded && <Loading className={''} />}
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetPublic = (props) => {
  var refs = null;
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'public' }).then(({ result }) => {
      setData(result ? result[0] : null);
      setLoaded(true);
      setModified(false);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;
    const value = { rowid: data ? data.cfgid : null, stag: 'app', skey: 'public', sval: refs.getValue() };
    actions.doInsert(URL.API.ADMIN.SETTING, value).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onImport = () => {
    const { origin } = window.location;
    setData({ sval: PUBLIC('[*****]', origin, moment().format('YYYY년 MM월 DD일')) });
    setModified(true);
  }

  return <StyledObject className={cx('set-public sect')}>
    <p className={'title'}>{ST.COMPANY.PUBLIC}</p>
    <Button className={'gd-gray top right import'} title={STA.IMPORT} onClick={onImport} />
    <Button className={'gd-gray top right preview'} title={ST.PREVIEW} disabled={modified}
      onClick={() => actions.open(URL.PRIVACY, { type: 'pub' })} />
    <div className={'sec-box'}>
      <p className={'noti'}>{`* ${STA.PUBLIC}`}</p>
      {loaded && <Editbox ref={(ref) => { refs = ref }} className={"privacy"} value={data && data.sval}
        type="text" guide={ST.COMPANY.PUBLIC} validate={true} onChange={() => setModified(true)}
        multi={true} height={"1000px"} minheight={400} maxheight={1600} />}
      {!loaded && <Loading className={''} />}
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}