import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Loading, cs, Editbox, Util } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS, LinkButton, ScrollNavi } from './PageCom';

const StyledObject = styled.div`{ 
  &.text-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full}
    ${cs.bg.trans} ${cs.font.black}
    // ${cs.anim.showin()} 

    .p-frame { 
      ${cs.max.wlg} ${cs.p.a5} ${cs.p.t40} ${cs.align.xcenter} ${cs.pos.relative} ${cs.disp.block}
      .p-cont { ${cs.disp.block} ${cs.h.fit} ${cs.w.full} ${cs.p.t30} ${cs.font.left} ${cs.over.hidden}
        ${cs.align.xcenter} ${cs.pos.relative} ${cs.min.h(60)} ${cs.object.fill} ${cs.p.a0}

        .p-tit { 
          ${cs.p.a0} ${cs.font.t1} ${cs.font.line(40)} ${cs.opac.show} ${cs.font.spacing(5)}
        }
  
        .p-txt { 
          ${cs.w.full} ${cs.opac.show} ${cs.font.line(20)} ${cs.font.prewrap}
        }

        &.link { ${cs.mouse.pointer} }
      }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.pointer} };

      &.loaded { ${cs.opac.visible} }
    }

    @media screen and (max-width : 600px) {
      .p-frame {
        .p-cont {
          .p-tit { ${cs.p.h5} }
          .p-txt { ${cs.p.h5} }  
        }
      }
    }
  }
  
  &.text-viewer {
    &.onepage { ${cs.min.h('100vh')} 
      .p-frame {
        // ${cs.max.h('90vh')}
        .p-cont { ${cs.h.full} 
          .p-txt { ${cs.over.yauto} ${cs.scrollbar.t1} ${cs.h.full} }
        }
      }
    }
    
    .p-frame {
      .p-cont {
        .p-tit, .p-txt { ${cs.opac.invisible} }

        &.noanim {
          .p-tit, .p-txt { ${cs.opac.visible} }
        }
        &.type1 {
          .p-tit { ${cs.opac.invisible} ${cs.anim.showin('1s', '0', '1', 'tb-type1-text-1')} }
          .p-txt { ${cs.opac.invisible} ${cs.anim.showin('1s', '0', '1', 'tb-type1-text-2')} ${cs.anim.delay('0.5s')} }
        }
        &.type2 {
          .p-tit { ${cs.opac.invisible} ${cs.anim.slideup('1s', '-100%', '0', 'tb-type2-text-1')} }
          .p-txt { ${cs.opac.invisible}  ${cs.anim.slideup('1s', '100%', '0', 'tb-type2-text-2')} ${cs.anim.delay('0.5s')} }
        }
        &.type3 {
          .p-tit { ${cs.opac.invisible} ${cs.anim.zoomin('1s', 0, 1, 1, 'tb-type3-text-1')} }
          .p-txt { ${cs.anim.foldup('2s', '0', '100%', 'tb-type3-text-3', 'ease-out')} ${cs.anim.delay('0.5s')} }
        }
      }
    }
  }

  &.text-editor { ${cs.p.b40} ${cs.bg.trans}
    .p-frame {
      .p-cont {
        .p-txt { ${cs.m.t20} }

        &.left { .input { ${cs.font.left} } }
        &.center { .input { ${cs.font.center} } }
        &.right { .input { ${cs.font.right} } }

        .input { ${cs.box.line} }
      }
      .link-button { ${cs.bottom(-50)} }
    }

    &.onepage {
      .btn-bg { ${cs.align.cbottom} ${cs.bottom(50)} }
    }
  }

};`;

const TextBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default TextBox;

const BoxViewer = (props) => {
  const { title, cont, className, bg, path, opts, link } = props.item;
  const bgdata = OPTS.image(bg, path);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const { pageopts } = global;

  const [loaded, setLoaded] = useState(false);
  const [delta, setDelta] = useState(0);
  const [layer, setLayer] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    setLoaded(true);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoaded(true);
    if (props.active && box && box.anim && box.anim !== 'noanim') {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  const onWheel = (e) => {
    const { currentTarget } = e;
    const isdown = e.deltaY > 0;

    if (currentTarget) {
      const { clientHeight, scrollTop, scrollHeight } = currentTarget;
      const pos = clientHeight + scrollTop;
      setRefresh(new Date().getTime());
      if (isdown && pos < scrollHeight) return e.stopPropagation();
      if (!isdown && scrollTop > 1) return e.stopPropagation();

      const d = delta + Math.abs(e.deltaY);
      setDelta(d);
      if (d < 300) return e.stopPropagation();
      setDelta(0);
    }
  }

  const isonepage = pageopts && pageopts.style && pageopts && pageopts.style === 'onepage';
  const maxFrameHeight = isonepage ? '80vh' : '100%';
  const maxHeight = isonepage ? `calc(${maxFrameHeight} - ${label.margin} - ${label.lineHeight} - ${desc.margin} - ${box.padding} - ${box.padding})` : '';
  return (
    <StyledObject className={cx("text-box text-viewer", className, box.size, pageopts && pageopts.style)} >
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded })}
        style={{ maxWidth, padding: `0 ${OPTS.maxPadding(maxWidth)}`, margin: `${box.margin} 0`, ...frame.border, maxHeight: maxFrameHeight }}>
        <div className={cx("p-cont", { link }, anim || 'noanim')} style={{ maxWidth, ...box.options, padding: box.padding }}
          onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null}>
          {title && <p className={cx("p-tit")} style={{ ...label.options }}>{title}</p>}
          {cont && <p className={cx("p-txt")} ref={ref => setLayer(ref)} onWheel={onWheel}
            style={{ ...desc.options, paddingTop: 0, marginTop: desc.margin, maxHeight }}>{cont}</p>}
          <ScrollNavi layer={layer} refresh={refresh} />
        </div>
      </div>

      {props.children}
      {!loaded && <Loading />}
    </StyledObject>
  )
}

const BoxEditor = (props) => {
  const { title, cont, className, bg, path, opts, request } = props.item;

  useEffect(() => {
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, e, label) => {
    if (!props.item) return;
    const req = props.item.request;
    req[label] = Util.toText(value);
  }

  const bgdata = OPTS.image(bg, path);
  const { frame, box } = OPTS.options(opts);
  const { pageopts } = global;
  const maxWidth = OPTS.maxWidth(box.size);

  return (
    <StyledObject className={cx("text-box text-editor", className, box.size, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame")} style={{ maxWidth }}>
        <div className={cx("p-cont")} >
          <Editbox className={cx("p-tit white")} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} maxLength={100} />
          <Editbox className={cx("p-txt white")} value={request.cont || cont} guide={ST.PAGE.CONTENT_H(5000)} maxLength={5000}
            onChange={(v, e) => onChange(v, e, 'cont')} multi={true} minheight={200} maxheight={800} height={300}
            helper={ST.MAXLEN(ST.PAGE.CONTENT, 5000)} />
        </div>
        <LinkButton onClick={() => props.onClickLink('show', props.item)} />
      </div>
      {props.children}
    </StyledObject>
  )
}