/* eslint-disable react/no-direct-mutation-state */
import React, { useState, useEffect } from 'react';
import { STAT, URL, CODE, SIZE } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { Util, Editbox, cs, TabButton, Button, Tablebox, Loading, Storage, Header } from 'object/src';
import styled from 'styled-components';
import cx from 'classnames/bind';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{
  &.ad-menu { ${cs.bg.trans} ${cs.pos.relative} ${cs.p.a10} ${cs.max.wxl}

    .s-preview { ${cs.font.black} ${cs.p.a10} ${cs.m.t10} 
      .tit { ${cs.font.t0} ${cs.p.b10} ${cs.font.white} }
      .head-set { ${cs.m.t5} ${cs.font.left}
        .tab-align { ${cs.m.r20} }
      }
      header.header { ${cs.z.over} }
    }

    .s-head { ${cs.m.t20} ${cs.pos.relative}
      .prev-txt { ${cs.font.gray} ${cs.font.t0} ${cs.m.v10} }
      .guide { ${cs.pos.relative} ${cs.m.l20} ${cs.font.yellow} ${cs.font.bold} ${cs.m.v10} }
    }

    .s-body { ${cs.p.h20} 
      .tb-body {
        .tb-col {
          .home-btn { ${cs.disp.block} ${cs.size.full} ${cs.font.sm} ${cs.opac.get(0)} &:hover { ${cs.bg.white} ${cs.font.black} ${cs.opac.show} } }
          .edit-btn { ${cs.bg.trans} &:hover { ${cs.bg.white} ${cs.font.black} } }
        }
      }
    }

    .s-foot { ${cs.m.t10} ${cs.font.right} ${cs.p.r20}
      .btn-new { }
      .btn-save { ${cs.m.r20} }
    }
  }

  &.menu-modal {
    .title, .label { ${cs.m.b20} }
    .check { ${cs.m.t5} }
    .m-tabs { 
      .m-label { ${cs.font.sm} ${cs.font.yellow} ${cs.p.v3} }
      .m-desc { ${cs.font.sm} ${cs.font.gray} ${cs.p.a5} ${cs.font.prewrap} }
    }
  }

  &.check-modal {
    .noti { ${cs.m.b10} ${cs.font.sm} ${cs.font.yellow} ${cs.font.prewrap} ${cs.disp.block} }
  }

  @media screen and (max-width : 600px) { 
    &.ad-menu { ${cs.p.b50}
      .s-head .guide { ${cs.m.l5} ${cs.font.sm} } .s-body { ${cs.p.h5} } .s-foot { ${cs.p.r10} } 

      .s-preview { ${cs.font.black} ${cs.p.a0} ${cs.m.t10} ${cs.m.b30} 
        .header { ${cs.z.over} }
        .tit { ${cs.font.t0} ${cs.p.b10} ${cs.font.white} }
        .head-set { ${cs.m.t5} ${cs.font.left}
          .tab-align { ${cs.m.a0} ${cs.m.v5} ${cs.w.full} }
        }
      }
    }
  }
}`

const LIMIT = 20;

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false, list: [], search: '', modified: false, running: false, draging: false,
      order: 'ctime desc', page: { current: 1, total: 1, max: 1 },
      head: [
        { key: 'odr', title: ST.NUM, flex: '1 1 60px', },
        { key: 'title', title: ST.TITLE, flex: '10 1 140px', align: 'left' },
        { key: 'pages', title: ST.ADMIN.MENU.PAGE, flex: '1 1 80px', align: 'center', mobile: 'show' },
        {
          key: 'edit', title: ST.EDIT, flex: '1 1 80px',
          formatter: (cell, param, pos) => {
            const onClick = (v, item, e) => {
              e.stopPropagation();
              if (!item.opts) item.opts = { "style": "scroll" };
              global.openModal({
                title: ST.UPDATE, state: STAT.U, children: Modal, data: { ...item }, className: 'dark',
                onOk: (data) => {
                  (data != null) && actions.doUpdate(API.MENU, data).then(({ code, result }) => {
                    (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
                    this.doReload({});
                  });
                }
              });
            }

            return <Button className={"edit-btn sm full"} title={ST.EDIT} onClick={(eid, e) => onClick(eid, param, e)} />
          }
        },
        {
          key: 'visible', title: ST.VISIBLE, flex: '2 1 130px', mobile: 'hide',
          formatter: (cell, param, pos) => {
            const tabs = [
              { id: 'Y', title: ST.YES, color: 'green' },
              { id: 'N', title: ST.NO, color: 'black' },
            ];

            const onClick = (v, item, e) => {
              e.stopPropagation();
              const value = { visible: v, rowid: item.rowid };
              const { list } = this.state;
              const temp = list.find(a => a.rowid === item.rowid);
              temp && (temp.visible = v);

              actions.doUpdate(API.MENU, value).then(({ code, result }) => {
                (code !== CODE.SUCCESS) ? Util.showAlert(this.props, code) : this.setState({ list: [...list] });
              });
            }

            const { ishome } = param;
            return <TabButton className={"tab-visible"} size={'sm'} list={tabs} select={cell} color={'gray'}
              onClick={(eid, p, e) => onClick(eid, param, e)} disabled={ishome === 'Y'} />
          }
        },
        {
          key: 'ishome', title: ST.ISHOME, flex: '1 1 80px', mobile: 'hide',
          formatter: (cell, param, pos) => {
            const onClick = (item, e) => {
              e.stopPropagation();
              // 홈으로 변경하면 visible는 무조건 Y로
              const value = { ishome: "Y", visible: "Y", rowid: item.rowid };
              actions.doUpdate(API.MENU, value).then(({ code, result }) => {
                (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
                this.doReload({});
              });
            }

            if (cell === 'Y') return <span className={'home-label'}>{'yes'}</span>
            else return <span className={'home-btn'} onClick={(e) => onClick(param, e)}>{ST.ADMIN.MENU.SELECT}</span>;
          }
        },
        {
          key: 'utime', title: ST.UTIME, flex: '2 1 120px', type: 'datetime', tablet: 'hide',
          formatter: (v) => moment(v).format('YY/MM/DD hh:mm'),
        }
      ],
    }
  }

  componentDidMount(e) {
    this.doReload({});

    const onSystemInit = (callback = null) => {
      actions.doSelect(URL.API.APPINFO, { stag: 'server', skey: 'domain' }).then(({ result }) => {
        let json = {};
        result.map(a => json[a.skey] = a.sval);
        // 도메인 설정 확인후 설정이 안되어있으면 도메인을 설정하자..
        if (!json.domain) {
          const { origin } = window.location;
          actions.doInsert(URL.API.ADMIN.SETTING, { rowid: null, stag: 'server', skey: 'domain', sval: origin }).then(() => {
            actions.doInsert(URL.API.ADMIN.RELOAD, { reboot: true }).then(({ code }) => {
              setTimeout(() => {
                callback ? callback() : window.location.reload();
              }, 1000);
            });
          });
        } else {
          callback && callback();
        }
      });
    }

    actions.doSelect(URL.API.ADMIN.ADMINS, {}).then(({ result }) => {
      if (result && result.length > 0) {
        const user = Storage.getLocalItem(Storage.key.userinfo);
        const item = result.find(a => a.accountid === user.accountid);
        const ispwd = item && item.reset_pwd === 'N';
        if (!ispwd) {
          global.openConfirm({
            type: 'warn', msg: ST.ADMIN.RESET_PWD, size: 'sm', cancel: false, className: 'black',
            onClicked: (isOk) => (isOk) && (window.location.href = URL.ADMIN.MANAGEMENT)
          });
        }
      }
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'system', skey: 'init' }).then(({ result }) => {
      const onClosePopup = () => {
        actions.doUpdate(URL.API.ADMIN.SETTING, { stag: 'system', skey: 'init', sval: 'popclose' }).then(() => {
          window.location.href = URL.ADMIN.MAIN;
        });
      }

      const StyledComp = styled.div`{
        &.sys-init { } 
          .close-btn { ${cs.align.lbottom} ${cs.bottom(15)} ${cs.left(0)} ${cs.font.gray} } 
        }
      }`;

      const { sval } = result && result.length > 0 && result[0];
      if (sval === 'ready') {
        setTimeout(() => {
          global.openConfirm({
            type: 'info', size: 'md', cancel: false, className: 'black',
            children: () => {
              return <StyledComp className={'sys-init'}>
                <span>{ST.ADMIN.SYSINIT.CONFIRM}</span>
                <Button className={'close-btn'} title={ST.ADMIN.SYSINIT.POP_CLOSE} onClick={onClosePopup} />
              </StyledComp>
            },
            onClicked: (isOk) => {
              if (isOk) {
                // 시스템 초기 가이 화면으로 이동.
                // window.location.href = URL.ADMIN.SYSINIT
                onSystemInit(() => window.location.href = URL.ADMIN.SYSINIT);
              }
            }
          });
        }, 200);
      }
    });
  }

  doReload = (value) => {
    actions.doList(API.MENU, value, true).then(({ result }) => {
      const { data } = result;
      data && data.forEach(a => a.opts = a.opts && JSON.parse(a.opts));
      this.setState({ list: data, loaded: true, move: false, modified: false, draging: false });
    })
  }

  onClickSearch = (value) => {
    this.state.search = value;
    this.doReload({});
  }

  onClickHeader = (eid, e) => {
    if (eid === "name") {
      const { order } = this.state;
      const label = "name";
      if (order.lastIndexOf("asc") > 0) {
        this.state.order = label + " desc";
      } else { // if(order.lastIndexOf("asc") > 0) {
        this.state.order = label + " asc";
      }

      this.doReload({});
    }
  }

  getItem = (id) => this.state.list.find(item => Number(item.rowid) === Number(id));

  onClickNew = (eid, id) => {
    const { list } = this.state;
    if (list.length >= LIMIT) {
      global.openConfirm({
        title: ST.ALARM, msg: ST.ADMIN.MENU.MAX_NOTI(LIMIT), className: 'dark', size: 'sm', cancel: false,
        onClicked: (isOk) => { },
      });
      return;
    }

    global.openModal({
      title: ST.ADD, state: STAT.I, children: Modal, data: {}, className: 'dark',
      onOk: (data) => {
        (data != null) && actions.doInsert(API.MENU, data, list).then(({ code, result }) => {
          (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
          this.doReload({});
        });
      }
    });
  }

  onClickCancel = (eid, e) => {
    this.doReload({});
  }

  onClickDelete = (id) => {
    const updateOrdering = (rowid) => {
      const array = this.state.list && this.state.list.filter(a => String(a.rowid) !== String(rowid));
      array.forEach((a, i) => a.odr = i + 1);
      actions.doUpdate(API.MENU_ORDER, { list: this.state.list }, null).then(({ code, result }) => {
        (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
        this.doReload({});
      });
    }

    let item = this.getItem(id);
    if (item.pages > 0) {
      global.openModal({
        title: ST.ALARM, state: STAT.U, children: CheckModal, data: { ...item }, className: 'dark', size: 'xs',
        onOk: (data) => {
          (data != null) && actions.doDelete(API.MENU, { rowid: data.rowid }).then(({ code, result }) => {
            updateOrdering(data.rowid);
          });
        }
      });
    } else {
      // global.openConfirm
      global.openConfirm({
        className: 'dark', size: 'sm',
        onClicked: (isOk) => {
          if (isOk) {
            actions.doDelete(API.MENU, { rowid: item.rowid }).then(({ code, result }) => {
              updateOrdering(item.rowid);
            });
          }
        },
      });
    }

  }

  onSelect = (id) => {
    actions.go(URL.ADMIN.PAGE, { rowid: id });
  }

  onDragDrop = (rowid, array) => {
    array.map((a, i) => a.odr = i + 1);
    this.setState({ modified: true, move: true, list: [...array], draging: true });
  }

  onClickSave = (eid, e) => {
    this.setState({ modified: false, draging: false });
    actions.doUpdate(API.MENU_ORDER, { list: this.state.list }, null).then(({ code, result }) => {
      (code !== CODE.SUCCESS) && Util.showAlert(this.props, code);
    });
  }

  render() {
    const { list, loaded, modified, draging, head } = this.state;
    const total = this.state.list.length;

    if (loaded) {
      return <StyledObject className="ad-menu">
        <Preview menus={list} />

        <div className={'s-head'}>
          <div className={"guide"}><span>{ST.ADMIN.MENU.GUIDE}</span></div>
        </div>

        <div className={'s-body'}>
          {loaded ? <Tablebox className={cx("dark md")}
            head={head} list={list} onClickDelete={this.onClickDelete} onSelect={this.onSelect}
            onDragDrop={this.onDragDrop} pos={1} max={1} total={total} rowid="rowid" /> : null}
        </div>

        <div className={'s-foot'}>
          <Button className={"btn-save red"} onClick={this.onClickSave} title={ST.SAVE} disable={!modified} />
          {!draging && <Button className={"btn-new red gd-gray"} onClick={this.onClickNew} title={ST.ADD} />}
          {draging && <Button className={"btn-cancel red gd-gray"} onClick={this.onClickCancel} title={ST.CANCEL} />}
        </div>
      </StyledObject>
    } else {
      return <Loading className={'fixed'} />;
    }
  };
};

const Preview = (props) => {
  const [company, setCompany] = useState(null);
  const [header, setHeader] = useState(null);
  const [list, setList] = useState(null);
  const { menus } = props;

  useEffect(() => {
    actions.doSelect(URL.API.APP).then((res) => {
      let json = {};
      res.header && res.header.map(a => json[a.skey] = a.sval);
      setHeader(json);

      json = {};
      res.company && res.company.map(a => json[a.skey] = a.sval);
      setCompany(json);

      setList(res.header);
    });
    return () => {
    }
  }, []);

  const onClickMenu = (e, item) => {
    (item) && actions.open(URL.HOME, { rowid: item.rowid });
  }

  const onClickAlign = (eid, value, e) => {
    const item = list && list.find(a => a.skey === 'align');
    const param = item ? { rowid: item.cfgid, sval: eid } : { rowid: null, stag: 'header', skey: 'align', sval: eid };
    // setAlign(param);
    actions.doInsert(URL.API.ADMIN.SETTING, param).then(({ code, result }) => {
      setHeader(header ? { ...header, align: eid } : { align: eid });
      setList(list ? [...list, { ...param, rowid: result }] : [{ ...param, rowid: result }]);
    });

    // const param = header.align ? { rowid: header.align.cfgid, sval: eid } : { rowid: null, stag: 'header', skey: 'align', sval: eid };
    // // setAlign(param);
    // actions.doInsert(URL.API.ADMIN.SETTING, param).then(({ code, result }) => {
    //   setHeader(header ? { ...header, ...param, cfgid: result } : { ...param, cfgid: result });
    // });
  }

  const onClickSize = (eid, value, e) => {
    const item = list && list.find(a => a.skey === 'size');
    const param = item ? { rowid: item.cfgid, sval: eid } : { rowid: null, stag: 'header', skey: 'size', sval: eid };
    // setAlign(param);
    actions.doInsert(URL.API.ADMIN.SETTING, param).then(({ code, result }) => {
      setHeader(header ? { ...header, size: eid } : { size: eid });
      setList(list ? [...list, { ...param, rowid: result }] : [{ ...param, rowid: result }]);
    });
  }

  const TALIGN = [
    { id: 'left', title: ST.OPTS.LEFT },
    { id: 'center', title: ST.OPTS.CENTER },
    { id: 'right', title: ST.OPTS.RIGHT },
  ]
  const TSIZE = [
    { id: 'sm', title: 'SMALL', width: SIZE.sm.width }, //800
    { id: 'md', title: 'MEDIUM', width: SIZE.md.width }, //1024
    { id: 'lg', title: 'LARGE', width: SIZE.lg.width }, //1280
    { id: 'xl', title: 'XLARGE', width: SIZE.xl.width }, //1400
    { id: 'full', title: 'FULL', width: SIZE.full.width }, //100%
  ]

  const headalign = (header && header.align) || 'right';
  const headsize = SIZE[(header && header.size) || SIZE.lg.label].width;

  return <StyledObject className={'s-preview'}>
    <p className={'tit'}>{ST.PREVIEW}</p>
    <Header title={company ? company.name : ''} onClick={() => actions.go('/', {})} align={headalign}
      maxWidth={headsize} menus={'show'} options={{ font: { color: cs.color.gray, type: 'Hi Melody' } }}
      list={menus.filter(a => a.visible === "Y")} pos={0} root={URL.ROOT} preview={true} onClickMenu={onClickMenu} />

    <div className={'head-set'}>
      <TabButton className={"tab-align"} size={'sm'} list={TALIGN} select={headalign} color={'gray'}
        onClick={onClickAlign} />
      <TabButton className={"tab-size"} size={'sm'} list={TSIZE} select={(header && header.size) || SIZE.lg.label} color={'gray'}
        onClick={onClickSize} />
    </div>
  </StyledObject>
}

/*******************************************************************
 Popup
*******************************************************************/
const Modal = (props) => {
  var refs = {};
  const { data } = props;
  const { title, label, opts = {} } = data;
  const [pagestyle, setPagestyle] = useState((opts && opts.style) || 'scroll');

  useEffect(() => {
    setTimeout(() => refs.title != null && refs.title.focus(), 300);
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  props.act.getData = (checkValidate) => {
    const datas = checkValidate(refs);
    if (opts.style === pagestyle && datas === 'modified') return null;
    if (datas === 'notvalid') return false;

    const item = { ...datas, rowid: data.rowid || null, opts: { ...opts, style: pagestyle } };
    return item;
  }

  const onClickStyle = (eid, value, e) => {
    setPagestyle(eid);
  }

  // setTimeout(() => refs.title != null && refs.title.focus(), 300);
  const STM = ST.ADMIN.MENU;
  const TABS = [
    { id: 'scroll', title: STM.STYLE.SCROLL, text: STM.STYLE.SCROLL_H },
    { id: 'onepage', title: STM.STYLE.ONEPAGE, text: STM.STYLE.ONEPAGE_H },
  ];

  const select = TABS.find(a => a.id === pagestyle);
  return (
    <StyledObject className="menu-modal">
      <Editbox ref={(ref) => { refs.title = ref }} className={"title"} value={title}
        name="title" type="text" label={STM.TITLE} validate={true} maxLength={10}
        helper={ST.MAXLEN(STM.TITLE, 10)} />
      <Editbox ref={(ref) => { refs.label = ref }} className={"label"} value={label}
        name="label" type="text" label={STM.LABEL} guide={ST.ADMIN.LABEL_G} maxLength={30}
        helper={ST.MAXLEN(STM.TITLE, 30)} />
      <div className={'m-tabs'} >
        <p className={'m-label'}>{STM.SEL_STYLE}</p>
        <TabButton className={"m-style full"} size={'sm'} list={TABS} select={pagestyle} color={'gray'}
          onClick={onClickStyle} />
        <p className={'m-desc'}>{select ? select.text : ''}</p>
      </div>
    </StyledObject>
  );
}


/*******************************************************************
 Popup
*******************************************************************/
const CheckModal = (props) => {
  var refs = {};

  props.act.getData = (checkValidate) => {
    const value = refs.check.getValue();

    if (value !== 'delete') return false;
    return { rowid: data.rowid };
  }

  const { data } = props;
  const { title, pages } = data;

  setTimeout(() => refs.title != null && refs.title.focus(), 300);

  return (
    <StyledObject className="check-modal">
      <span className={'noti'}>{ST.ADMIN.MENU.DELETE_NOTI(title, pages)}</span>
      <Editbox ref={(ref) => { refs.check = ref }} className={"check"} value={''} type="text" validate={true} />
    </StyledObject>
  );
}