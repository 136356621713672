const KOR = {
  OK: "확인",
  CANCEL: "취소",
  SAVE: "저장",
  CLOSE: "닫기",
  CLEAR: "초기화",
  REGIST: "등록",
  DELETE: "삭제",
  ADD: "추가",
  EDIT: "편집",
  UPDATE: "수정",
  SETTING: "설정",
  ALARM: "알림",
  WARNING: "경고",
  ERROR: "에러",
  AUTH: "권한",
  NEXT: "다음",
  PREV: "이전",
  TITLE: "타이틀",
  TYPE: "타입",
  CAPTION: "캡션 타이틀",
  SELECTION: "선택하세요",
  ALL: "모두",
  NUM: "번호",
  URL: "URL",
  ORDER: "순번",
  KIND: "종류",
  DESC: "설명",
  STATUS: "상태",
  CTIME: "등록일",
  UTIME: "수정일",
  IMAGE: "이미지",
  VIDEO: "동영상",
  PDF: "PDF",
  CONT: "컨텐츠",
  LOGIN: "로그인",
  LOGOUT: "로그아웃",
  ID: "아이디",
  TOTAL: "TOTAL",
  MORE: "더보기",
  NONE: "N/A",
  STOP: "STOP",
  MANAGEMENT: "관리",
  SHUTDOWN: 'SHUTDOWN',
  MORE_INFO: "추가정보",
  DOWNLOAD: "다운로드",
  SELECT_FILE: "파일선택",
  NOIMAGE: "NO IMAGE!",
  YES: "YES",
  NO: "NO",
  FIRST: "처음",
  LAST: "끝",
  WEEK: "주일",
  YEAR: "년",
  MONTH: "월",
  DAY: "일",
  DATE: "날자",
  COUNT: "개",
  PHONE: "연락처",
  PASSWORD: "비밀번호",
  MEMBERSHIP: "회원가입",
  VISIBLE: "사용",
  ISHOME: "메인홈",
  THUMB: "썸네일",
  PREVIEW: "미리보기",
  TEXTCOLOR: "글자색",
  BGCOLOR: "배경색",
  SET_OPTIONS: "옵션설정",
  IMAGESIZE: "이미지 크기(표준)",
  IN_TITLE: "여기에 제목을 입력하세요.",
  IN_CONT: "여기에 간단히 설명을 입력하세요.",
  IN_VALUE: "여기에 값을 입력하세요.",
  CHECK_DUP: "중복체크",
  CHECK_OK: "확인되었습니다",
  CHECK_CONN: "연결확인",
  NEW: "신규생성",
  CHECK_DUP_CLICK: "중복체크 버튼을 클릭하세요.",
  CHECK_DB_CLICK: "DB연결 버튼을 클릭하세요",
  DUP_DATA: "중복 domain 또는 ip 입니다",
  DB_CONN_ERR: "DB를 연결할수 없습니다.",
  DB_CONN_ERR_AND_NEW: "DB를 연결할수 없습니다.\nDB를 새로 생성하고 하시겠스니까?",
  DB_CONN_OK: "DB가 정상으로 연결되었습니다.",
  DB_CRE_OK: "DB가 생성되었습니다.",
  DB_CRE_ERR: "DB 생성중 오류가 발생했습니다.",
  OPEN_DETAIL: "자세히...",
  
  REFRESH: "화면갱신",
  NO_SERVICE: "서비스 준비중",
  NOT_SERVICE: "기능을 사용할 수 없습니다.",

  BACKUP: {
    TITLE: "백업",
    UPDATE: "상태갱신",
    SCHEMA: "스키마",
    MEDIA: "미디어", 
    ROLLBACK: "복구",
    CLONE: "복제",
  },

  ADVERT: {
    TITLE: "배너 광고 모집중",
    IMAGE: "이미지명",
    LINK: "LINK URL",
    MENU: "메뉴(화면)",    
  },

  CONTENT: {
    MEDIA: "이미지/동영상 URL",
    LINK: "다운로드 링크",
    BG: "배경 이미지 URL"
  },

  NOTI: {
    SAVE: "저장되었습니다.",
    EDIT: "수정되었습니다.",
    DELETE: "삭제되었습니다.",
    NODATA: "표시할 데이터가 없습니다.",
    SUCCESS: "정상적으로 처리되었습니다.",
    FAILUER: "오류가 발생했습니다.",
    WARNING: "경고",
    DUP_ITEM: "이미 등록된 항목입니다.",
    DUP_MEMBER: "이미 등록된 아이디 입니다.",
    NOCONTENT: "표시할 데이터가 없습니다. \n관리자 로그인 후 화면을 설정하세요. \n\n로그인 하려면 여기를 클릭하세요",
    NOT_SUPPERT_EDITOR: "IE에서는 EDIT를 사용할수 없습니다.\nChrome/IE Edge를 이용하세요.",
    NOT_DOUBLE_QUARTER: "문장안에 \" 대신 ' 를 사용하세요",
    ONLY_NUMBER: "숫자(0-9)만 입력 가능합니다.",
    NO_DEL_SYSTEM_ADMIN: "시스템 사용자는 삭제할 수 없습니다.",
    URL_FIRST_SYMBOL: "URL의 첫 글자에 / 를 입력하세요.",
    URL_ENG: "URL은 알파벳(a-z)만 입력 가능합니다.",
    SELECT_IMAGE: "이미지를 등록하세요",
    ISDELETE: "정말로 삭제하시겠습니까?",
    NOT_SAVE: "변경된 내용을 저장후 이용하세요.",
    SIZE_CHECK: (v) => `이미지 최대 크기는 [${v}] 입니다.`,
  },

  APPUP: {
    TITLE: "업데이트",
    WEB: "WEB 업데이트",
    APP: "APP 업데이트",
    TXT: "으로 업데이트 되었습니다.",
    DESC: "를 시작하시겠습니까? \n시스템에따라 몇분정도 소요됩니다.",
    GUIDE: "WEB(APP)을 업데이트 중입니다.\n잠시만 기다려주세요...",
    LOGIN: "WEB(APP) 업데이트가 완료되었습니다. \n재로그인을 위해 로그인 화면으로 이동합니다...",
  },

  NO_INPUT_VALUE: "값을 입력하세요",
  SELECT_OBJECT: "셀을 선택하세요",
  SELECT_SCHEMA: "스키마를 선택하세요",
  IS_CREATE_TABLE: "테이블을 생성하시겠습니까?",
  IS_CLEAR_TABLE: "테이블을 모두 클리어 하시겠습니까?",
  IS_CLEAR_TABLE_ROWS: "테이블 데이터(ROW)을 클리어 하시겠습니까?",
  IS_DELETE: "삭제 하시겠습니까?",
  IS_DELETE_BACKUP: "선택한 백업 파일을 삭제 하시겠습니까?",
  IS_BACKUP: "을(를) 백업 하시겠습니까?",
  IS_ROLLBACK: "을(를) 복원 하시겠습니까?",
  IS_CLONE: "을(를) 복제 하시겠습니까?",
  IS_CREATE_PACKAGE: "선택한 패키지(docker image)을 생성 하시겠습니까?",
  IS_DELETE_PACKAGE: "선택한 패키지(docker image)을 삭제 하시겠습니까?",
  IS_CLEAR_PACKAGE: "사용하지 않는 패키지를 정리하시겠습니까?",
  IS_DELETE_ITEM: "선택한 항목(docker)을 삭제 하시겠습니까?",
  IS_RERUN: "선택한 항목을 다시 실행 하시겠습니까?",
}

export const AUTH = {
  SYSTEM: 'system',
  SUPER: 'super',
  USER: 'user',

  ST: {
    SYSTEM: '시스템관리자',
    SUPER: '관리자',
    USER: '사용자',
  }
}

export const SCREEN = {
  MOBILE: 800,
  TABLET: 1024,
  PC: 1280,
  // MOBILE: 600,
  // TABLET: 800,
  // PC: 1024,

  ST: {
    MOBILE: "s-mobile",
    TABLET: "s-tablet",
    PC: "s-pc",
  }
}

export const STAT = {
  U: "UPDATE",
  D: "DELETE",
  I: "INSERT",
  R: "ORDER",
  N: "NONE",
}

export const EID = {
  NEW: "new",
  OK: "ok",
  CANCEL: "cancel",
  CHECK: 'check',
  UNCHECK: 'uncheck',
  SETTING: "setting",
  HIDE: "hide",
  SHOW: "show",
  ADD: "add",
  COPY: "copy",
  BOOK: "book",
  EDIT: "edit",
  SAVE: "save",
  SELECT: "select",
  DELETE: "delete",
  REMOVE: "remove",
  INSERT: "insert",
  UPDATE: "update",
  FIND: "find",
  SEARCH: "search",
  DIC: "dic",
  MENU: "menu",
  HOME: "home",
  PAGE: "page",
  NEXT: "next",
  PREV: "prev",
  BACK: "back",
  OPEN: 'open',
  CLOSE: 'close',
  CLEAR: 'clear',
  MORE: 'more',
  PREVIEW: "preview",
  HIDE_ALL: "hide-all",
  SHOW_ALL: "show-all",
  MINUS: "minus",
  PLUS: "plus",
  MIN: "min",
  MAX: "max",
};

export const KEY = {
  ENTER: "Enter",
};

export const CODE = {
  SUCCESS: 0,
  FAILUER: -1,
  WARNING: -2,
  DUPITEM: 10001,
  PWDERR: 11001,
  LOGINERR: 12001,
};

export const CONT_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
  YOUTUBE: "youtube",
  TEXT: "text",
  PDF: "pdf",
  LINK: "link",
};

export const ST = KOR;