import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Loading, cs, Editbox, Util, Button } from 'object/src';
import { ST } from 'svc/Lang';
import { URL, CODE } from 'svc/Enum';
import { PageBackground, PageOptions as OPTS } from './PageCom';
import * as actions from 'object/src/actor/Action';

const API = URL.API;

const StyledObject = styled.div`{ 
  &.qna-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black} ${cs.h.fit}
    // ${cs.anim.showin()} 

    .p-frame { 
      ${cs.max.wlg} ${cs.h.fit} ${cs.disp.block} ${cs.object.contain} ${cs.w.full}
      ${cs.align.xcenter} ${cs.pos.relative}
      // ${cs.align.center}
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} ${cs.top(0)} ${cs.w.full} 
          ${cs.min.h(100)} ${cs.h.fit}

          .p-box { ${cs.p.a10} 
            .p-line { ${cs.disp.block} ${cs.m.v10} ${cs.pos.relative} ${cs.h.fit} ${cs.bg.trans}
              .pl { ${cs.w.get(100)} ${cs.disp.inblock} ${cs.float.left} 
                ${cs.font.md} ${cs.p.v5} ${cs.p.h10} ${cs.font.white} ${cs.bg.trans}
              }
              .pr { ${cs.w.calc('100% - 100px')} ${cs.disp.inblock} ${cs.box.light} }
              // .edit-box { ${cs.box.lightgray} }
            }

          }
          
          .foot { ${cs.h.get(60)} ${cs.w.full} ${cs.pos.relative}
            .save { ${cs.max.w(200)} ${cs.m.v0} ${cs.h.get(60)} ${cs.right(10)} 
              &.full { ${cs.max.w('none')} ${cs.right(0)} }
            }
          }

          .p-tit { 
            ${cs.p.b10} ${cs.border.bottom} ${cs.border.dark} ${cs.border.width(3)}
          }

          .p-txt { 
            ${cs.border.bottom} ${cs.border.dark} ${cs.border.width(3)} ${cs.p.a10} 
            ${cs.font.darkgray} ${cs.font.prewrap}
          }

          &.bg { ${cs.h.get(500)} }
        }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }

        &.link { ${cs.mouse.pointer} }
      }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.pointer} };
      
      &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
    }

    &.xl { .p-frame { } }
    &.lg { .p-frame { } }
    &.md { .p-frame { } }
    &.sm { .p-frame { } }
    &.xs { .p-frame { } }

    @media screen and (max-width : 1280px) {}  
    @media screen and (max-width : 1024px) {}  
    @media screen and (max-width : 860px) {}
    @media screen and (max-width : 600px) {
      &.qna-box .p-frame {
        ${cs.border.get('none !important;')} ${cs.p.get('0 !important;')}
        .p-cont { ${cs.p.a10}
          .p-box {
            .p-line {
              .pl { ${cs.w.get(80)} ${cs.font.sm} }
              .pr { ${cs.w.calc('100% - 80px')} }
            }
          }
          .foot { 
            .save { ${cs.max.w('none')} ${cs.w.full} ${cs.right(0)} }
          }
        }
      }
    }
  }
  
  &.qna-viewer {
    &.full { ${cs.h.fit} ${cs.p.v0} ${cs.max.none}
      .p-frame { ${cs.align.unset} ${cs.pos.relative} ${cs.w.full} ${cs.max.none} ${cs.p.a0} } 
    }
  }

  &.qna-editor { ${cs.bg.trans}
    .p-frame {
      .p-cont { 
        .p-box { ${cs.p.a10} ${cs.box.line} ${cs.box.dotted} ${cs.border.darkgray} ${cs.m.v10}
          .edit-box .box .input.readonly { ${cs.bg.frame} }
          .p-line { }
          .foot {
            .save { ${cs.right(0)} }
          }
        }
      }
    }

    &.onepage { ${cs.p.a0}
      .p-cont {
        ${cs.min.h('80vh')} ${cs.h.get('80vh')} ${cs.max.h('80vh !important')}
      }
    }
  }

};`;

const QnaBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default QnaBox;

const BoxViewer = (props) => {
  const { title, cont, className, bg, path, url, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const theight = box.size === 'sm' || box.size === 'xs' ? 200 : box.size === 'md' ? 300 : 400;
  const { pageopts } = global;

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  useEffect(() => {
    setLoaded(true);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = () => {

    let message = '';
    // if (!data) message = ST.QNA.NODATA;
    if (!data.title) message = ST.PAGE.SUBJECT_H;
    else if (!(data.email || data.phone)) message = ST.QNA.NOPHONE;
    else if (!data.desc) message = ST.PAGE.CONTENT_H(3000);

    if (message) {
      // setNoti(alarm);
      global.openConfirm({ type: 'info', msg: message, size: 'sm', cancel: false });
      return;
    }

    const value = {
      title: data.title, desc: data.desc,
      opts: JSON.stringify({ email: data.email, phone: data.phone })
    };

    actions.doInsert(API.PROD_QNA, value).then(({ code }) => {
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
      global.openConfirm({
        type: 'info', msg: ST.PAGE.QNA_OK, size: 'sm', cancel: false,
        onClicked: () => { setData({}); }
      });
    });
  }

  const onChange = (val, key) => {
    let temp = data || {};
    temp[key] = val;
    setData(JSON.parse(JSON.stringify(temp)));
  }

  return (
    <StyledObject className={cx("qna-box qna-viewer", className, box.size, pageopts && pageopts.style)} style={{ ...frame.border }}>
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth, ...box.options, margin: `${box.margin} 0`, padding: box.padding }}>
        <div className={cx("p-cont")}>
          {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options, paddingTop: 0, marginBottom: label.margin }}>{title}</p>}

          <div className={'p-box'}>
            <div className={'p-line p-sub'}>
              <span className={'pl'} style={{ color: desc.color }}>{ST.PAGE.SUBJECT}</span>
              <Editbox className={cx("pr white")} guide={ST.PAGE.SUBJECT_H} value={data.title || ''}
                onChange={(v) => onChange(v, 'title')} maxLength={100} />
            </div>
            <div className={'p-line p-email'}>
              <span className={'pl'} style={{ color: desc.color }}>{ST.PAGE.EMAIL}</span>
              <Editbox className={cx("pr white")} guide={ST.PAGE.EMAIL_H} value={data.email || ''}
                onChange={(v) => onChange(v, 'email')} maxLength={100} />
            </div>
            <div className={'p-line p-phone'}>
              <span className={'pl'} style={{ color: desc.color }}>{ST.PAGE.PHONE}</span>
              <Editbox className={cx("pr white")} type={'phone'} guide={ST.PAGE.PHONE_H} value={data.phone || ''}
                onChange={(v) => onChange(v, 'phone')} maxLength={20} />
            </div>
            <div className={'p-line p-desc'}>
              <span className={'pl'} style={{ color: desc.color }}>{ST.PAGE.CONTENT}</span>
              <Editbox className={cx("pr white sizefix", !cont && 'nodata')} guide={ST.PAGE.CONTENT_H(3000)} value={data.desc || ''}
                multi={true} minheight={theight} maxheight={theight} height={theight} onChange={(v) => onChange(v, 'desc')}
                maxLength={3000} />
            </div>
          </div>

          {cont && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{cont}</p>}

          <div className={'foot'}>
            <Button className={cx('save primary lg right', box.size === 'xs' && 'full')} onClick={onSave} title={ST.PAGE.QNA_SAVE} />
          </div>
        </div>
      </div>

      { props.children}
      { url && !loaded && <Loading />}
    </StyledObject >
  )
}

const BoxEditor = (props) => {
  useEffect(() => {
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, e, label) => {
    if (!props.item) return;
    const { request } = props.item;
    request[label] = Util.toText(value);;
  }

  const { title, cont, className, bg, path, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const { frame, box, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  const { pageopts } = global;

  let theight = box.size === 'sm' || box.size === 'xs' ? 200 : box.size === 'md' ? 300 : 400;
  let pheight = 200
  if (pageopts && pageopts.style === 'onepage') {
    pheight = 100;
    theight = 200;
  };
  // const isonepage = pageopts && pageopts.style === 'onepage';

  return (
    <StyledObject className={cx("qna-box qna-editor", className, pageopts && pageopts.style)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame")} style={{ maxWidth }}>
        <div className={cx("p-cont")}>
          <Editbox className={cx("pr white")} value={title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />

          <div className={'p-box'}>
            <span className={'p-noti'}>{ST.PAGE.QNA_GUIDE}</span>
            <div className={'p-line p-sub'}>
              <span className={'pl'}>{ST.PAGE.SUBJECT}</span>
              <Editbox className={cx("pr white")} placeholder={ST.PAGE.SUBJECT_H} readonly />
            </div>
            <div className={'p-line p-email'}>
              <span className={'pl'}>{ST.PAGE.EMAIL}</span>
              <Editbox className={cx("pr white")} placeholder={ST.PAGE.EMAIL_H} readonly />
            </div>
            <div className={'p-line p-phone'}>
              <span className={'pl'}>{ST.PAGE.PHONE}</span>
              <Editbox className={cx("pr white")} placeholder={ST.PAGE.PHONE_H} readonly />
            </div>
            <div className={'p-line p-desc'}>
              <span className={'pl'}>{ST.PAGE.CONTENT}</span>
              <Editbox className={cx("pr white sizefix", !cont && 'nodata')} placeholder={ST.PAGE.CONTENT_H(3000)}
                maxLength={3000} multi={true} minheight={theight} maxheight={theight} height={theight} readonly />
            </div>

            {cont && <p className={cx("p-txt")} eid={"cont"} style={{ ...desc.options }}>{cont}</p>}

            <div className={'foot'}>
              <Button className={cx('save primary lg right', box.size === 'xs' && 'full')} readonly onClick={() => { }} title={ST.PAGE.QNA_SAVE} />
            </div>
          </div>

          <span className={'p-noti'}>{ST.PAGE.QNA_HELP}</span>
          <Editbox className={cx("pr white sizefix", !cont && 'nodata')} value={cont} guide={ST.PAGE.CONTENT_H(1000)} maxLength={1000}
            multi={true} minheight={pheight} maxheight={pheight} height={pheight} onChange={(v, e) => onChange(v, e, 'cont')} />
        </div>
      </div>
      {props.children}
    </StyledObject>
  )
}