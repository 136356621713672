import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Loading, cs, Editbox, Thumblist, Button, Svg, Imagebox, Thumbbox } from 'object/src';
import { ST } from 'svc/Lang';
import { URL, CODE, EID } from 'svc/Enum';
import { PageBackground, PageOptions as OPTS } from './PageCom';
import * as actions from 'object/src/actor/Action';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{ 
  &.photo-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} 
    ${cs.bg.trans} ${cs.font.black}

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.p.a0} ${cs.h.fit}
      ${cs.disp.block} ${cs.object.contain}       
      
      .p-cont { ${cs.z.front} ${cs.pos.relative} 
        .p-tit { 
          ${cs.p.a0} ${cs.font.t1} ${cs.m.b10} ${cs.font.center} ${cs.font.bold} 
        }

        .p-box { ${cs.pos.relative} ${cs.h.get(480)} ${cs.p.v50}
          .image-box { ${cs.min.h(300)} ${cs.max.h(480)} ${cs.align.center} ${cs.w.full} }

          .p-next, .p-prev { ${cs.pos.absolute} ${cs.align.ycenter}
            &.p-prev { ${cs.left(10)} }
            &.p-next { ${cs.right(10)} }
          }
        }

        .p-tlist { ${cs.over.inherit} ${cs.m.t20}
          .t-label { ${cs.top(-30)} ${cs.right(10)} }
        }
      }

      .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }
      
      .p-noti { ${cs.font.orange} }
      .pointer { ${cs.mouse.pointer} };
      
      &.center { text-align: center; }
      &.loaded { ${cs.opac.visible} }
      
      .p-img .ib-frame { ${cs.opac.invisible} }

      &.noanim { 
        .p-img .ib-frame { ${cs.opac.visible} }
      }
      &.type1 {
        .p-img .ib-frame { ${cs.opac.invisible} 
          ${cs.anim.showin('1s', '0', '1', 'phb-type1-text')} ${cs.anim.delay('0.2s')}
        }
      }
      &.type2 {
        .p-img .ib-frame { ${cs.opac.invisible} 
          ${cs.anim.slideup('0.5s', '100%', '0', 'phb-type2-text')} ${cs.anim.delay('1s')}
        }
      }
      &.type3 {
        .p-img .ib-frame { ${cs.opac.invisible} 
          ${cs.anim.slidein('0.5s', '100%', '0', 'phb-type3-text')} ${cs.anim.delay('1s')}
        }
      }
    }

    &.bg { ${cs.min.h('calc(100vh - 300px)')} }
 
    @media screen and (max-width : 1280px) {}  
    @media screen and (max-width : 1024px) {}
    @media screen and (max-width : 600px) {
      .p-frame {
        .p-box { ${cs.p.h10} ${cs.pos.relative} .p-img { ${cs.w.full} ${cs.p.h10} } }
      }
    }
  }

  &.photo-viewer {
    &.full { ${cs.h.fit} ${cs.p.v0} ${cs.max.none}
      .p-frame { ${cs.align.unset} ${cs.pos.relative} ${cs.w.full} ${cs.max.none} ${cs.p.a0} 
        .p-cont {
          .p-tit {
            ${cs.align.ctop} ${cs.top(20)} ${cs.z.over} ${cs.w.full} ${cs.p.h20}
          }
        }
      } 
    }
  }

  &.photo-editor { ${cs.p.b40} ${cs.bg.dark}
    .p-frame { 
      .p-txt { ${cs.m.t20} }
      .p-box { ${cs.min.h(300)} }
      .p-img .ib-frame { ${cs.opac.visible} }

      .p-cont { }
      .p-multi { ${cs.align.rbottom} ${cs.bottom(-32)} }
    }
  }

  &.s-popup {
    ${cs.disp.popup(999, cs.color.alphablack, 600, 400)} ${cs.top(0)}
    .pop-box { ${cs.bg.dark} ${cs.border.black} ${cs.border.shadow()} ${cs.font.white}
      .head { ${cs.h.get(40)} ${cs.bg.trans} ${cs.font.lg} ${cs.font.line(40)} ${cs.p.l10} 
        .close { ${cs.align.right} ${cs.top(5)} ${cs.right(5)} }
      }
      .body { ${cs.h.calc('100% - 80px')} ${cs.p.a20} ${cs.pos.relative} ${cs.bg.trans} ${cs.p.t30}
        .p-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.m.b3} ${cs.align.ltop} 
          ${cs.left(20)} ${cs.top(10)} ${cs.z.over} 
        }
        .uploader { ${cs.h.full} }
      }
      .foot { ${cs.h.get(40)} ${cs.font.right} ${cs.font.line(40)} ${cs.p.t5} 
        ${cs.border.top} ${cs.bg.trans}
        .button { ${cs.m.r10} &.delete { ${cs.align.left} ${cs.left(10)} } }
      }
    }
  }

  &.m-popup {
    ${cs.disp.popup(999, cs.color.alphablack, 542, 500)} ${cs.top(0)}
    .pop-box { ${cs.bg.dark} ${cs.border.black} ${cs.border.shadow()} ${cs.font.white}
      .head { ${cs.h.get(40)} ${cs.bg.trans} ${cs.font.lg} ${cs.font.line(40)} ${cs.p.l10} 
        .close { ${cs.align.right} ${cs.top(5)} ${cs.right(5)} }
      }
      .body { ${cs.h.calc('100% - 80px')} ${cs.p.a20} ${cs.pos.relative} ${cs.bg.trans}
        .p-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.m.b3} ${cs.align.ltop} 
          ${cs.left(20)} ${cs.top(10)} ${cs.z.over} 
        }
        .up-file { ${cs.disp.none} }
        .thumb-frame { ${cs.pos.relative} ${cs.w.get(100)} ${cs.h.get(80)} ${cs.disp.inblock}
          .thumb-box { ${cs.p.a2} }
          .up-res { ${cs.font.white} ${cs.font.sm} ${cs.font.center} ${cs.bg.alphablack}
            ${cs.align.center} ${cs.w.fit} ${cs.p.h10} ${cs.p.v3} ${cs.border.radius(5)}
          }
        } 
        .up-file-btn { ${cs.p.a10} ${cs.font.orange} ${cs.font.line(24)} ${cs.font.md} ${cs.font.center}
          ${cs.w.full} ${cs.h.full} ${cs.top(0)} ${cs.pos.relative} ${cs.disp.block} ${cs.mouse.pointer}
          & > p { ${cs.font.prewrap} ${cs.align.ycenter} ${cs.w.full} }
        }
        .re-btn { ${cs.m.b10} ${cs.m.t10} }
      }
      .foot { ${cs.h.get(40)} ${cs.font.right} ${cs.font.line(40)} ${cs.p.t5} 
        ${cs.border.top} ${cs.bg.trans}
        .button { ${cs.m.r10} &.delete { ${cs.align.left} ${cs.left(10)} } }
      }
    }
  }

};`;

const PhotoBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default PhotoBox;

const BoxViewer = (props) => {
  const { title, jsons, className, bg, path, opts, thumbpath, rowid } = props.item;
  const bgdata = OPTS.image(bg, path);
  const list = OPTS.jsons(jsons);
  const { frame, box, label } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [pos, setPos] = useState(0);
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    setLoaded(true);
    if (props.active && box) {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  useEffect(() => {
    setLoaded(true);

    const onResize = (e) => {
      setRefresh(new Date());
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  // const onClickLink = (e, item, index) => {
  //   if (!item || !item.link) return;
  //   props.onClickLink && props.onClickLink('open', item.link);
  // }

  const onClickNavi = (eid) => {
    let index = 0;
    const max = list ? list.length - 1 : 0;
    if (eid === 'next') {
      index = pos + 1;
    } else if (eid === 'prev') {
      index = pos - 1;
    }

    if (index > max) index = 0;
    if (index < 0) index = max;
    setPos(index);
  }

  const onSelect = (rid) => {
    let index = list ? list.findIndex(a => String(a.uuid) === String(rid)) : 0;
    setPos(index);
  }

  const imageurl = pos >= 0 && list[pos] ? `${path}${list[pos].url}` : '';
  const thumbs = list ? list.map(a => { return { uuid: a.uuid, url: a.thumb } }) : [];

  return (
    <StyledObject className={cx("photo-box photo-viewer", className, box.size)} refresh={refresh}>
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded }, anim || 'noanim')} style={{ maxWidth, margin: `${box.margin} 0`, ...frame.border }}>
        <div className={cx('p-cont')} style={{ padding: box.padding }}>
          {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options, paddingTop: 0, paddingBottom: `${label.margin}` }}>{title}</p>}
          <div className={'p-box'} style={{ ...box.options }} >
            <Imagebox className={cx("p-img")} url={imageurl} onLoad={onLoad} onError={onError} size={"full"} eid={"url"}
            // onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null}
            />
            <span className={'p-prev'}> <Svg className={'lg gray'} icon={'left'} onClick={() => onClickNavi('prev')} /> </span>
            <span className={'p-next'}> <Svg className={'lg gray'} icon={'right'} onClick={() => onClickNavi('next')} /> </span>
          </div>

          <Thumblist className={cx("p-tlist")} list={thumbs} uuid={rowid}
            onSelect={onSelect} path={thumbpath} size={'md'} pos={pos} total={thumbs ? thumbs.length : 0} rowid="uuid" />
        </div>
      </div>
      {props.children}
      {!loaded && <Loading />}
    </StyledObject>
  )
}

const MAXCOUNT = 50;
const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [popup, setPopup] = useState(null);
  const [list, setList] = useState(null);
  const [pos, setPos] = useState(0);
  const [limit, setLimit] = useState(MAXCOUNT);

  useEffect(() => {
    setLimit(MAXCOUNT);
    if (props.item && props.item.jsons) {
      const array = JSON.parse(props.item.jsons);
      setList(array);
      array && array.length > 0 && setPos(array.length - 1);
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onChange = (value, e, label) => {
    if (!props.item) return;
    const req = props.item.request;
    req[label] = Util.toText(value);
  }

  const onClickDelete = (uuid) => {
    if (!list || list.length <= 0) return;
    const { rowid } = props.item;
    const index = list.findIndex(a => a.uuid === uuid);
    const value = list[index];

    actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
      if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }

      const temps = [...list.slice(0, index), ...list.slice(index + 1)];
      setList([...temps]);
      props.item.request['noti'] = null;
    });

    // global.openConfirm({
    //   type: 'info', size: 'sm', className: 'gray', onClicked: (isOk) => {
    //     (isOk) && actions.doDelete(API.PAGE_IMAGES, { rowid, value, index }).then(({ code, result }) => {
    //       if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }

    //       const temps = [...list.slice(0, index), ...list.slice(index + 1)];
    //       setList([...temps]);
    //       props.item.request['noti'] = null;
    //     });
    //   }
    // });
  }

  const onSelect = (rid) => {
    let index = list ? list.findIndex(a => String(a.uuid) === String(rid)) : 0;
    setPos(index);
  }

  const onClickNavi = (eid) => {
    let index = 0;
    const max = list ? list.length - 1 : 0;
    if (eid === 'next') {
      index = pos + 1;
    } else if (eid === 'prev') {
      index = pos - 1;
    }

    if (index > max) index = 0;
    if (index < 0) index = max;
    setPos(index);
  }
  const onClickPopup = (eid, array = []) => {
    setPopup(null);
    if (eid === EID.CANCEL) return;
    else {
      setList([...array]);
      setTimeout(() => {
        setPos(array.length - 1);
      }, 500);
    }
    props.onReload && props.onReload();
  }

  const onDragDrop = (eid, arrays, e) => {
    if (eid === 'drop') {
      request['jsons'] = arrays;
      setList([...arrays]);
    }
  }

  const { title, className, bg, path, request, opts, rowid } = props.item;
  const bgdata = OPTS.image(bg, path);
  const { frame, box } = OPTS.options(opts);
  const imageurl = list && pos >= 0 && list[pos] ? `${path}${list[pos].url}` : '';
  const total = list ? list.length : 0;

  return (
    <StyledObject className={cx("photo-box photo-editor", className, box.size)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth: '1024px' }}>
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <div className={'p-box'} style={{ ...box.options }} >
            <Imagebox className={cx("p-img")} url={imageurl} onLoad={onLoad} onError={onError} size={"full"} eid={"url"} />
            <span className={'p-prev'}> <Svg className={'lg gray'} icon={'left'} onClick={() => onClickNavi('prev')} /> </span>
            <span className={'p-next'}> <Svg className={'lg gray'} icon={'right'} onClick={() => onClickNavi('next')} /> </span>
          </div>
        </div>

        <Thumblist className={cx("p-tlist")} list={list}
          onClickDelete={onClickDelete} onSelect={onSelect} path={path} uuid={rowid}
          size={'md'} onDragDrop={onDragDrop} pos={pos} total={total} rowid="uuid" />
        <span className={'p-multi'}>
          <Button className={'btn sm green new'} title={ST.ADD} icon={'new'} onClick={() => setPopup(props.item)}
            disable={list && (list.length >= MAXCOUNT)} />
          {/* <Svg className={'lg gray'} icon={'new'} onClick={() => setPopup(props.item)} /> */}
        </span>
        <p className={'p-noti'}>{ST.PAGE.UPLOAD_MAX(limit)}</p>
      </div>

      {popup && <MultiPopupBox item={popup} list={list} pos={total} onClick={onClickPopup} />}
      {props.children}
    </StyledObject>
  )
}

const LIMIT = 20;

const MultiPopupBox = (props) => {
  var upload = React.createRef();
  const { item, list = [] } = props;
  // const [files, setFiles] = useState(null);
  const [thumbs, setThumbs] = useState(null);
  // const [refresh, setRefresh] = useState(null);
  const [result, setResult] = useState('ready');
  const [max, setMax] = useState(LIMIT);
  const [progress, setProgress] = useState(0);
  const [guide, setGuide] = useState('');
  // const [guide, setGuide] = useState(`${ST.IMAGE_SIZE('1600 * 900')}\n${ST.PAGE.MULTI_UPLOAD(LIMIT)}`);

  useEffect(() => {
    const count = list ? MAXCOUNT - list.length : LIMIT;
    if (count < LIMIT) {
      setMax(count);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    const temps = thumbs && thumbs.map(a => {
      a.url = a.image;
      return a;
    });
    const array = list ? [...list, ...temps] : [...temps];
    props.onClick && props.onClick(EID.CLOSE, array);
  }

  const onCancel = () => {
    props.onClick && props.onClick(EID.CANCEL);
  }

  const onClick = () => {
    upload.click();
  }

  const onSave = async () => {
    const doSave = (rowid, value) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await actions.doUpdate(API.PAGE_IMAGES, { rowid, value });
          value.result = "SUCCESS";
          if (res && res.code === CODE.SUCCESS) {
            const { data } = res;
            value.image = data.url;
            value.thumb = data.thumb;
          }
          resolve(rowid);
        } catch (error) {
          reject(rowid);
          value.result = "FAIL";
        } finally {
          // setRefresh(new Date().getTime());
        }
      });
    }

    (async () => {
      let count = 0;
      for await (var tfile of thumbs) {
        count = count + 1;
        await doSave(item.rowid, tfile);
        setProgress(count);
      }
    })();

    setResult('success');
  }

  const onChange = (e) => {
    var upfiles = e.target.files;
    if (!upfiles || upfiles.length <= 0) {
      return;
    }

    let arrays = [];
    const ukey = Util.getGenerateKey();
    // 이미지 등록최대 개수는 50개이며, 한번에 등록 가능한 개수는 20개이다.
    let total = upfiles.length > max ? max : upfiles.length;
    if (max !== LIMIT) {
      setGuide(`${ST.PAGE.UPLOAD_MAX(MAXCOUNT)}\n${ST.PAGE.UPLOAD_COUNT(`${list.length} + ${total}(추가)`)}`);
    }

    for (let i = 0; i < total; i++) {
      let file = upfiles[i];
      getBase64(file, (buf, err) => {
        const uuid = Number(ukey) + Number(i);
        arrays.push({ uuid, name: file.name, url: buf });
        setThumbs([...arrays]);
      });
    }
  }

  const getBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result);
    };
    reader.onerror = (error) => {
      console.error('Error: ', error);
      callback(null, error);
    };
  }

  return <StyledObject className={cx("m-popup")}>
    <div className={'pop-bg'} onClick={onClose} />
    <div className={'pop-box'}>
      <div className={'head'}>
        <span>{ST.PAGE.MULTI_UPLOAD_TITLE}</span>
        <Svg className={'close gray md right top'} icon={EID.CANCEL} onClick={onCancel} />
      </div>
      <div className={'body'}>
        <label className={'p-noti'}>{guide}</label>
        {result === 'ready' && !thumbs &&
          <span className={'up-file-btn'} onClick={onClick}><p>{`${ST.PAGE.NEW_IMG}\n${ST.PAGE.MULTI_UPLOAD(LIMIT)}\n${ST.IMAGE_SIZE('1200 * 800')}`}</p></span>
        }
        {thumbs && <Button className={'btn sm gd-gray full re-btn'} title={'RESELECT'} onClick={onClick} disable={result === 'success'} />}

        <input className={'up-file'} type="file" accept={'.jpg, .jpeg, .png, .gif'} name="file"
          ref={ref => upload = ref} onChange={onChange} required multiple />

        {thumbs && thumbs.map((file, i) => {
          return <span key={i} className={'thumb-frame'}>
            <Thumbbox className={cx('')} thumb={file.url} anim={true} delay={i * 50} />
            {file.result && <span className={'up-res'}>{file.result}</span>}
          </span>
        })}

        {/* {guide && <div className={'m-guide'}><p>{guide}</p></div>} */}
      </div>
      <div className={'foot'}>
        {result === 'ready' && <React.Fragment>
          <Button className={'btn sm gray cancel'} title={ST.CANCEL} onClick={onCancel} />
          <Button className={'btn sm red save'} title={ST.SAVE} onClick={onSave} />
        </React.Fragment>}
        {result !== 'ready' &&
          <Button className={'btn sm gd-gray close'} title={ST.CLOSE} onClick={onClose} disable={progress < thumbs.length} />
        }
      </div>
    </div>
  </StyledObject>
}