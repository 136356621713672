import React, { useState, useEffect, Fragment } from 'react';
import cx from 'classnames/bind';
import styled from 'styled-components';
import { Util, Imagebox, Loading, cs, Editbox, Uploadbox, Svg } from 'object/src';
import { ST } from 'svc/Lang';
import { PageBackground, PageOptions as OPTS, LinkButton, ScrollNavi } from './PageCom';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{ 
  &.history-box { 
    ${cs.bg.pos('center')} ${cs.bg.repeat('no-repeat')} ${cs.pos.relative}
    ${cs.bg.size('cover')} ${cs.over.hidden} ${cs.w.full} ${cs.anim.showin()} ${cs.max.w('100vw')} 
    ${cs.bg.trans} ${cs.font.black}

    .p-frame { 
      ${cs.max.wlg} ${cs.align.xcenter} ${cs.pos.relative} ${cs.h.fit} ${cs.bg.trans}
      ${cs.disp.block} ${cs.object.contain} ${cs.min.h(100)} ${cs.p.a20} ${cs.z.front}
      ${cs.border.radius(5)} ${cs.over.hidden}
      
        .p-cont { ${cs.z.front} ${cs.pos.relative} ${cs.border.radius(5)}
          // ${cs.box.line} ${cs.border.color('#ffffff30')} 
          .p-tit { 
            ${cs.font.t1} ${cs.m.b10} ${cs.font.center} ${cs.font.bold} ${cs.p.t20} 
            ${cs.w.calc('100% - 40px')} ${cs.m.l20}
          }

          .h-frame { ${cs.disp.autoflex} ${cs.min.h(180)} ${cs.p.a20}
            .h-thumb {
              ${cs.disp.inblock} ${cs.h.full} ${cs.box.line} ${cs.border.radius(5)} ${cs.border.lightgray}
              ${cs.h.get(180)} ${cs.w.get(140)}
              .up-frame, .up-frame .upf-preview, .media-box .noimage { ${cs.border.none} }
              .up-frame .upf-preview .upv-img { ${cs.bg.frame} }
            }
            .h-list {
              ${cs.w.calc('100% - 150px')} ${cs.disp.inblock} ${cs.font.line(20)} ${cs.bg.frame} ${cs.m.l10} ${cs.border.radius(5)}
              ${cs.p.v5}
              .tab-grp { ${cs.w.calc('100% - 20px')} ${cs.m.h10} ${cs.m.b10} }
              .h-li { ${cs.disp.autoflex} ${cs.m.b5} ${cs.opac.invisible}
                .li-tit { ${cs.disp.inblock} ${cs.w.get(80)} ${cs.p.l10} ${cs.font.right} }
                .li-txt { ${cs.disp.inblock} ${cs.w.calc('100% - 90px')} ${cs.p.l10} ${cs.font.prewrap} }
              }
            }
          }

          .h-frame { ${cs.bg.trans} .h-thumb { ${cs.border.color('#ffffff1a')} } .h-list { ${cs.bg.trans} } }

          &.image { .p-txt {  ${cs.min.h(400)} } }
        }

        .input { ${cs.bg.get('rgba(255,255,255,0.9)')} }
      }

      .bg {
        .p-cont { ${cs.bg.white} ${cs.border.color('#00000030')} }
        // ${cs.bg.white}
        // .p-cont { ${cs.box.line} ${cs.border.color('#ffffff30')} ${cs.border.radius(5)}
        //   .h-frame { ${cs.bg.trans} .h-thumb { ${cs.border.color('#ffffff1a')} } .h-list { ${cs.bg.trans} } }
        // }
      }

      .bgcolor {
        
      }
      
      .p-noti { ${cs.font.orange} }
      &.loaded { ${cs.opac.visible} }
    }

    &.onepage {
      &.history-box .p-frame {
        ${cs.max.h(600)} ${cs.h.get('90vh')} ${cs.min.h(500)}
        .p-cont { ${cs.h.full} ${cs.bg.white}
          .h-frame { ${cs.h.full} 
            .h-list { 
              ${cs.h.full} ${cs.pos.relative} 
              .h-ul { ${cs.over.hidden} ${cs.over.yauto} ${cs.scrollbar.t1} ${cs.h.full}  }
            }
          }
        }
      }
    }

    @media screen and (max-width : 860px) {
      &.history-box .p-frame .p-cont .h-frame { ${cs.p.h10}
        .h-thumb { 
          ${cs.h.get(140)} ${cs.w.get(110)}
        }
        .h-list {
          ${cs.w.calc('100% - 110px')}
        }
      }
    }

    @media screen and (max-width : 600px) {
      &.history-box .p-frame {
        ${cs.p.left('0px !important;')} ${cs.p.right('0px !important;')} ${cs.p.bottom('10px !important;')}
        .p-cont { ${cs.h.full} 
          .h-frame { ${cs.p.h10} ${cs.disp.block} ${cs.h.calc('100% - 40px')}
            .h-thumb {
              ${cs.disp.block} ${cs.h.get(180)} ${cs.w.get(140)} ${cs.align.xcenter} ${cs.pos.relative}
            }
            .h-list {
              ${cs.disp.block} ${cs.w.full} ${cs.pos.relative} ${cs.p.h0} ${cs.m.t20}
              .h-li {
                .li-tit { ${cs.disp.inblock} ${cs.w.fit} ${cs.p.l0} }
                .li-txt { ${cs.disp.inblock} ${cs.w.auto} }
              }
            }
          }
        }
      }

      &.onepage {
        &.history-box .p-frame {
          .p-cont {
            .h-frame { 
              .h-list { ${cs.h.calc('100% - 230px')}  }
            }
          }
        }
      }
    }
  }

  &.history-viewer {
    .p-frame { 
      .p-cont .h-frame {
        .h-list { 
          .h-li.link { ${cs.mouse.alias} 
            &:hover { ${cs.anim.in('0.1s')} ${cs.font.orange} ${cs.font.underline} 
              .li-tit, .li-txt { ${cs.anim.in('0.1s')} ${cs.font.color(`${cs.color.orange} !important`)} } 
            }
          }

          .h-li { ${cs.opac.invisible}
            &.noanim { ${cs.anim.showin('0.3s')} }
            &.type1 { ${cs.anim.slidein('0.5s', '100%', 0, 'hb-type1')} }
            &.type2 { ${cs.anim.slideup('0.3s', '200%', 0, 'hb-type2', 'ease-in')} }
            &.type3 { ${cs.anim.showin('1.5s', 0, 1, 'hb-type3', 'ease-in')} }
          }
        }
      }

      &.anim .p-cont { ${cs.anim.slidedown('1.5s', '-150%', 0, 'hi-anim-in', 'ease-in')} }
    }
  }

  &.history-editor { ${cs.p.b40} ${cs.bg.dark}
    .p-frame {
      .p-tit { ${cs.w.calc('100% - 40px')} ${cs.m.l20} }
      .p-cont { ${cs.bg.white} }
      .p-cont .h-frame { 
        .h-list {
          .h-li { ${cs.pos.relative} ${cs.opac.visible}
            .li-tit {  }
            .li-txt { ${cs.p.right(80)} }
            .li-btn { ${cs.align.right} ${cs.bottom(0)} ${cs.disp.inblock} ${cs.w.get(80)} ${cs.h.get(60)} 
              .li-delete { ${cs.align.rbottom} ${cs.right(0)} ${cs.bg.alphagray} ${cs.border.alphablack} }
              .link-button {
                ${cs.align.lbottom} ${cs.bottom(-10)} ${cs.left(50)}
                // .svg-icon { ${cs.bottom('unset')} ${cs.bottom(5)} }
              }
            }
          } 
        }
      }
    }

    .h-new { ${cs.w.full} ${cs.mouse.pointer} ${cs.pos.relative} ${cs.m.t20}
      & > span {
        ${cs.box.line} ${cs.box.dashed} ${cs.align.center} ${cs.font.sm} ${cs.bg.frame}
        ${cs.p.a5} ${cs.p.h20} ${cs.font.orange} ${cs.border.radius(3)} 
      }
      &.max { ${cs.opac.get(0.7)} }
    }
  }

};`;

const HistoryBox = (props) => {
  const { edited = false } = props;
  return (
    <Fragment>
      {edited ? <BoxEditor {...props} /> : <BoxViewer {...props} />}
    </Fragment>
  )
}

export default HistoryBox;

var timeouts = [];
const BoxViewer = (props) => {
  const { title, jsons, className, bg, path, url, opts } = props.item;
  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const list = OPTS.jsons(jsons);
  const { frame, box, label, desc } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);
  const mp = OPTS.maxPadding(maxWidth);
  const { pageopts } = global;
  const maxstyle = mp && pageopts && pageopts.style === 'onepage' ? { width: `calc(100% - ${mp} - ${mp})`, margin: `${mp} 0` } : {}

  const [loaded, setLoaded] = useState(false);
  const [delta, setDelta] = useState(0);
  const [layer, setLayer] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [array, setArray] = useState(box && box.anim === 'noanim' ? list : list.forEach(a => a.anim = 'hide'));
  const [anim, setAnim] = useState((box && box.anim) || 'noanim');

  useEffect(() => {
    setLoaded(true);
    return () => {
      if (timeouts) {
        timeouts.forEach(a => clearTimeout(a));
        timeouts = [];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.active && box && box.anim && box.anim !== 'noanim') {
      setAnim('no');
      setTimeout(() => setAnim(box.anim), 200);

      if (box.anim === 'noanim') {
        setArray([...list]);
      } else {
        list.forEach((a, i) => {
          a.anim = 'hide';
          timeouts.push(setTimeout(() => {
            a.anim = box.anim;
            setArray([...list]);
          }, 100 * i));
        });
      }
    } else {
      list && setArray([...list.map(a => { a['anim'] = 'noanim'; return a; })]);
    }

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active, props.refresh]);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onWheel = (e) => {
    const { currentTarget } = e;
    const isdown = e.deltaY > 0;

    if (currentTarget) {
      const { clientHeight, scrollTop, scrollHeight } = currentTarget;
      const pos = clientHeight + scrollTop;
      setRefresh(new Date().getTime());

      if (isdown && pos < scrollHeight) return e.stopPropagation();
      if (!isdown && scrollTop > 1) return e.stopPropagation();

      const d = delta + Math.abs(e.deltaY);
      setDelta(d);
      if (d < 300) return e.stopPropagation();
      setDelta(0);
    }
  }

  const listheight = `calc(100% - ${label.margin} - ${label.lineHeight})`;
  const bgclass = bgdata ? 'bg' : frame.color ? 'bgcolor' : '';

  return (
    <StyledObject className={cx("history-box history-viewer", className, box.size, pageopts && pageopts.style)} >
      <PageBackground src={bgdata} color={frame.color} anim={anim === 'no' ? 'no' : frame.anim} />
      <div className={cx("p-frame", { loaded }, bgclass)}
        style={{ maxWidth, ...box.options, padding: 0, margin: `${box.margin} 0`, ...maxstyle, ...frame.border }}>
        <div className="p-cont" style={{ padding: box.padding }} >
          {title && <p className={cx("p-tit")} eid={"title"} style={{ ...label.options }}>{title}</p>}
          <div className={cx(`h-frame`)}>
            <div className={'h-thumb'}>
              <Imagebox className="h-img" fit={'cover'} src={`${urldata}`} size={"full"}
                onLoad={onLoad} onError={onError} />
            </div>
            <div className={"h-list"} style={{ height: listheight }}>
              <ul ref={ref => setLayer(ref)} className={"h-ul"} onWheel={onWheel}>
                {array && array.map((item, index) => {
                  const { link = null } = item;
                  let marginTop = (desc && desc.margin) || '10px';
                  if (index === 0) marginTop = 0;
                  return <li key={index} className={cx('h-li', { link }, item.anim)} style={{ marginTop }}
                    onClick={link && props.onClickLink ? () => props.onClickLink('open', link) : null} >
                    <span className={cx("li-tit white")} style={{ ...desc.options, paddingTop: 0 }}>{item.title}</span>
                    <span className={cx("li-txt white")} style={{ ...desc.options, paddingTop: 0 }}>{item.desc}</span>
                  </li>
                })}
              </ul>
              <ScrollNavi layer={layer} refresh={refresh} />
            </div>
          </div>
        </div>
      </div>
      {props.children}
      {url && !loaded && <Loading />}
    </StyledObject>
  )
}

const BoxEditor = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState();
  const NEWITEM = { 'uuid': Util.getGenerateKey(), 'title': '', 'text': '' };
  const MAXLEN = 30;
  const { title, className, bg, path, url, opts, request } = props.item;

  useEffect(() => {
    if (props.item && props.item.jsons) {
      setList(JSON.parse(props.item.jsons));
    }
    (!props.item.opts) && (props.item['request'] = { opts: OPTS.default() });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad(null);
  }

  const onError = (e) => {
    setLoaded(true);
    props.onLoad && props.onLoad('error');
  }

  const onChange = (value, e, label, file) => {
    if (!props.item) return;
    const req = props.item.request;
    if (label === 'url') request[label] = file;
    else req[label] = Util.toText(value);
  }

  const onChangeJson = (value, e, label, item) => {
    if (!props.item) return;

    const req = props.item.request;
    const temp = list.find(a => a.uuid === item.uuid);
    temp[label] = value;
    req['jsons'] = list;
  }

  const onClickDelete = (index) => {
    const array = [...list.slice(0, index), ...list.slice(index + 1)];
    setList([...array]);

    const req = props.item.request;
    req['jsons'] = array;
  }

  const onClickNew = () => {
    if (list && list.length >= MAXLEN) return;

    const array = list ? [...list, NEWITEM] : [NEWITEM];
    setList([...array]);
  }

  const onClickLink = (item) => {
    const doUpdate = (value) => {
      actions.doUpdate(API.PAGE_IMAGELINK, { rowid: props.item.rowid, link: value }).then((res) => {
        const { code } = res;
        if (code !== CODE.SUCCESS) { Util.showAlert(props, code); return; }
        props.onClickLink('close');
      });
    }

    // const pos = list.find(a => String(a.uuid) === String(item.uuid));
    props.onClickLink('show', { ...props.item, sellinkid: item.uuid }, (eid, page, target) => {
      const temp = list.find(a => String(a.uuid) === String(item.uuid));
      temp && (temp['link'] = JSON.parse(target));
      doUpdate(JSON.stringify(list));
    });
  }

  const bgdata = OPTS.image(bg, path);
  const urldata = OPTS.image(url, path);
  const { frame, box } = OPTS.options(opts);
  const maxWidth = OPTS.maxWidth(box.size);

  return (
    <StyledObject className={cx("history-box history-editor", className, box.size)}>
      <PageBackground src={bgdata} color={frame.color} />
      <div className={cx("p-frame", { loaded })} style={{ maxWidth, ...box.options, padding: box.padding }} >
        <div className="p-cont">
          <Editbox className={cx("p-tit white", !title && 'nodata')} value={request.title || title} guide={ST.PAGE.SUBJECT_H}
            onChange={(v, e) => onChange(v, e, 'title')} />
          <div className={cx('h-new', list && list.length >= MAXLEN && 'max')} onClick={onClickNew}>
            <span>{list && list.length >= MAXLEN ? ST.PAGE.MAX_LEN(MAXLEN) : ST.PAGE.NEW_ITEM}</span>
          </div>
          <div className={cx(`h-frame`)}>
            <div className={'h-thumb'}>
              <Uploadbox className="h-img" fit={'cover'} value={`${urldata}`} size={"full"}
                onLoad={onLoad} onError={onError} maxImage={{ width: 1900, height: 1100 }}
                onChange={(v, e, file) => onChange(v, e, 'url', file)} />
            </div>
            <div className={"h-list"}>
              <ul className={"h-ul"}>
                {list && list.map((item, index) => {
                  return <li key={index} className={cx("h-li noanim")}>
                    <Editbox className={cx("li-tit white")} value={item.title} guide={ST.PAGE.SUBJECT}
                      onChange={(v, e) => onChangeJson(v, e, 'title', item)} maxLength={20} />
                    <Editbox className={cx("li-txt white")} value={item.desc} guide={ST.PAGE.CONTENT} maxLength={500}
                      onChange={(v, e) => onChangeJson(v, e, 'desc', item)} multi={true} minheight={40} maxheight={100} height={60} />
                    <span className={'li-btn'}>
                      <Svg className="lbx-icon lg white li-delete border radius box" name={'delete'} onClick={() => onClickDelete(index)} />
                      <LinkButton onClick={() => onClickLink(item)} />
                    </span>
                  </li>
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </StyledObject>
  )
}