export { default as PageBox } from "./PageBox";
export { default as NewsBox } from "./NewsBox";
export { default as ProdBox } from "./ProdBox";
export { default as JumboBox } from "./JumboBox";
export { default as TripleBox } from "./TripleBox";
export { default as SlideBox } from "./SlideBox";
export { default as HistoryBox } from "./HistoryBox";
export { default as GalleryBox } from "./GalleryBox";
export { default as BannerBox } from "./BannerBox";
export { default as TextBox } from "./TextBox";
export { default as QnaBox } from "./QnaBox";
export { default as NoPage } from "./NoPage";

export { default as TextlistBox } from "./TextlistBox";
export { default as TileBox } from "./TileBox";
export { default as PhotoBox } from "./PhotoBox";